import { ICommandBarItemProps } from '@talxis/react-components/dist/components/CommandBar/CommandBar.types';
import { SiteMap as ISiteMap } from '../../interfaces/sitemap';
import { history } from '@providers/AppProvider';
import { ContextualMenuItemType, GroupShowAll, INavLink, INavLinkGroup } from '@fluentui/react';
import cloneDeep from 'lodash/cloneDeep';
import { AppModule } from '@configuration/AppModule';
import { metadataRetrieveMultiple } from '@definitions/MetadataApi';
import { SpaConfiguration } from '@configuration/SpaConfiguration';

export enum SiteMarker {
    NotFound = "not_found",
}

interface ITalxisWebPage {
    talxis_webpageid: string;
    talxis_name: string;
    talxis_slug: string;
    talxis_requiresauthentication: boolean;
}
export interface IPageRoute {
    id: string;
    slug: string;
    requiresAuthentication: boolean;
}
export class SiteMap {
    private _siteMapDefinition: ISiteMap.Root;
    private _pageRoutes: IPageRoute[];

    public constructor(siteMapDefinition: ISiteMap.Root) {
        this._siteMapDefinition = siteMapDefinition;
    }
    public getDefinition(): ISiteMap.Root {
        return cloneDeep(this._siteMapDefinition);
    }
    public async loadStaticPages(): Promise<void> {
        if (!this._pageRoutes) {
            this._pageRoutes = await this._getStaticPageRoutes(this._siteMapDefinition.areas);

            for (const area of this._siteMapDefinition.areas) {
                for (const group of area.groups) {
                    for (const subArea of group.subAreas) {
                        if (subArea.url.includes("/control/TALXIS.PCF.WebPage")) {
                            const url = new URL(subArea.url, window.location.origin);
                            const queryParams = new URLSearchParams(url.search);
                            const webPages = this._pageRoutes;
                            const params = JSON.parse(queryParams.get("data"));
                            if (params["id"] && webPages.find(x => x.id === params["id"])) {
                                const page = webPages.find(x => x.id === params["id"]);
                                const currentAppModule = AppModule.get().uniquename;
                                let routeContainsAppModule = false;
                                if (window.location.pathname.startsWith(`/${currentAppModule}`)) {
                                    routeContainsAppModule = true;
                                }
                                if (currentAppModule !== SpaConfiguration.get().defaultAppModule || routeContainsAppModule) {
                                    subArea.url = `/${currentAppModule}${page.slug}`;
                                }
                                else {
                                    subArea.url = `${page.slug}`;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    public getVerticalNavigationItems(): INavLinkGroup[] {
        const areas: INavLinkGroup[] = [];
        for (const siteMapArea of this._siteMapDefinition.areas) {
            if (siteMapArea.id === 'other_pages') {
                continue;
            }
            const area: INavLinkGroup = {
                name: this._siteMapDefinition.areas.length > 1 && siteMapArea.title,
                links: this._mapGroups(siteMapArea.groups)
            };
            areas.push(area);
        }
        return areas;
    }
    private _createCommandBarItem(siteMapItem: ISiteMap.Area | ISiteMap.Group | ISiteMap.SubArea): ICommandBarItemProps | undefined {
        const area = siteMapItem as ISiteMap.Area;
        const group = siteMapItem as ISiteMap.Group;
        const subArea = siteMapItem as ISiteMap.SubArea;

        const isArea = area.groups?.length > 0;
        const isGroup = group.subAreas?.length > 0;

        if (subArea.visible === false) {
            return undefined;
        }
        return {
            key: subArea.url ?? siteMapItem.id,
            text: siteMapItem.title,
            disabled: subArea.disabled,
            title: subArea.disabledMessage ?? siteMapItem.title,
            itemType: isGroup && ContextualMenuItemType.Section,
            //only groups can have sectionProps
            sectionProps: isGroup ? {
                title: siteMapItem.title,
                bottomDivider: true,
                items: group.subAreas?.map(group => this._createCommandBarItem(group)).filter(item => item !== undefined)
            } : undefined,
            //only areas can have subMenuProps
            subMenuProps: isArea ? {
                items: area.groups?.map(group => this._createCommandBarItem(group)).filter(item => item !== undefined)
            } : undefined,
            href: (siteMapItem as ISiteMap.SubArea).url,
            'data-id': `sitemap-entity-${siteMapItem.id}`,
            onClick: subArea.url ? (e) => {
                e.preventDefault();
                console.log(`Navigating to ${subArea.url}`);
                if (subArea.url.startsWith("http")) {
                    window.open(subArea.url, '_blank');
                }
                history.push(subArea.url);
                //used to hide the callout when navigating between pages
                document.querySelector('body')?.click();
            } : undefined
        };
    }
    private _moveGroupUp(areas: ICommandBarItemProps[], groups: ICommandBarItemProps[], currentArea: ICommandBarItemProps): void {
        const areaIndex = areas.indexOf(currentArea);
        if (groups.length === 1) {
            areas[areaIndex] = groups[0];
            areas[areaIndex].subMenuProps = {
                items: areas[areaIndex].sectionProps?.items
            };
            areas[areaIndex].sectionProps = undefined;
        }

    }
    private _moveSubAreaUp(areas: ICommandBarItemProps[], subAreas: ICommandBarItemProps[], currentArea: ICommandBarItemProps): void {
        const areaIndex = areas.indexOf(currentArea);
        //we have only one subarea within the group and the area has only one group => move straight to Area
        if (subAreas?.length === 1 && areas[areaIndex].subMenuProps?.items?.length === 1) {
            areas[areaIndex] = subAreas[0];
        }
    }
    public async getHorizontalNavigationItems(): Promise<ICommandBarItemProps[]> {
        let items: ICommandBarItemProps[] = [];
        for (const area of this._siteMapDefinition.areas) {
            if (area.id === 'other_pages') {
                continue;
            }
            const commandBarItem = this._createCommandBarItem(area);
            if (commandBarItem) {
                items.push(commandBarItem);
            }
        }
        for (const area of items) {
            this._moveGroupUp(items, area.subMenuProps?.items, area);
        }
        for (const area of items) {
            for (const group of area.subMenuProps?.items) {
                //move the area up, some group might already been on area place from previous step, hence [group] is actually subArea
                this._moveSubAreaUp(items, group.sectionProps?.items ?? group.subMenuProps?.items ?? [group], area);
            }
        }
        const groups = items[0].subMenuProps?.items;
        //one area with one group => spread the subAreas to area position
        if (items.length === 1 && groups?.length === 1) {
            items = groups[0].sectionProps.items;
        }
        //one area with multiple groups => spread the groups to area position
        else if (items.length === 1 && groups?.length > 1) {
            for (const group of groups) {
                const groupIndex = groups.indexOf(group);
                if (group.sectionProps?.items) {
                    group.subMenuProps = {
                        items: group.sectionProps?.items
                    };
                }
                if (group.subMenuProps?.items.length === 1) {
                    items[0].subMenuProps.items[groupIndex] = group.subMenuProps.items[0];
                }
            }
            items = groups;
        }
        return items;
    }

    public disableSiteMapSubArea(id: string, disabledMessage: string): void {
        const subArea = this._getSiteMapSubArea(id);
        subArea.disabled = true;
        subArea.disabledMessage = disabledMessage;
    }
    public enableSiteMapSubArea(id: string) {
        const subArea = this._getSiteMapSubArea(id);
        subArea.disabled = false;
        subArea.disabledMessage = undefined;
    }

    public toggleSiteMapSubAreaVisibility(id: string, visible: boolean): void {
        const subArea = this._getSiteMapSubArea(id);
        subArea.visible = visible;
    }

    public getWellKnownPage(marker: SiteMarker): string {
        switch (marker) {
            case SiteMarker.NotFound: {
                const subArea = this._getSiteMapSubArea(SiteMarker.NotFound);
                return this._getStaticPageId(subArea);
            }
        }
    };

    public getStaticPageRoutes(): IPageRoute[] {
        return this._pageRoutes;
    }

    private _getStaticPageId(subArea?: ISiteMap.SubArea) {
        if (!subArea) {
            return null;
        }
        const params = new URLSearchParams(subArea.url.substring(subArea.url.indexOf('?')));
        const queryData = JSON.parse(params.get('data'));
        return queryData?.id;
    }
    private async _getStaticPageDefinitions(areas: ISiteMap.Area[]): Promise<ITalxisWebPage[]> {
        const ids: string[] = [];
        for (const area of areas) {
            for (const group of area.groups) {
                for (const subArea of group.subAreas) {
                    const id = this._getStaticPageId(subArea);
                    if (id) {
                        ids.push(id);
                    }
                }
            }
        }
        if (ids.length === 0) {
            return [];
        }
        const filter = `?$select=talxis_webpageid,talxis_name,talxis_slug,talxis_requiresauthentication&$filter=(${ids.map(x => `talxis_webpageid eq ${x}`).join(' or ')})`;
        const response = await metadataRetrieveMultiple(`v9.1/talxis_webpages${filter}`);
        return response.entities.map(x => {
            const webPage: ITalxisWebPage = {
                talxis_name: x["talxis_name"],
                talxis_requiresauthentication: x["talxis_requiresauthentication"],
                talxis_slug: x["talxis_slug"],
                talxis_webpageid: x["talxis_webpageid"]
            };
            return webPage;
        });
    }
    private async _getStaticPageRoutes(areas: ISiteMap.Area[]): Promise<IPageRoute[]> {
        return (await this._getStaticPageDefinitions(areas)).map(page => {
            return {
                id: page.talxis_webpageid,
                slug: page.talxis_slug,
                requiresAuthentication: page.talxis_requiresauthentication
            };
        });
    }

    private _getSiteMapSubArea(id: string): ISiteMap.SubArea | null {
        for (const area of this._siteMapDefinition.areas) {
            for (const group of area.groups) {
                const subArea = group.subAreas.find(subArea => subArea.id === id);
                if (subArea) {
                    return subArea;
                }
            }
        }
        return null;
    }
    private _mapGroups(groups: ISiteMap.Group[]): INavLink[] {
        const _groups: INavLink[] = [];
        groups.forEach((group) => {
            const _group: INavLink = {
                isExpanded: true,
                name: group.title,
                disabled: groups.length < 2,
                url: null,
                links: this._mapSubAreas(group.subAreas)
            };
            _groups.push(_group);
        });
        return _groups;
    };

    private _mapSubAreas = (subAreas: ISiteMap.SubArea[]): INavLink[] => {
        const _subAreas: INavLink[] = [];
        subAreas.forEach((subArea) => {
            const _subArea: INavLink = {
                key: subArea.url,
                name: subArea.title,
                url: subArea.url,
                title: subArea.title,
                dataIcon: subArea.icon,
                disabled: subArea.disabled,
                buttonStyles: {
                    root: {
                        display: subArea.visible ? null : 'none'
                    }
                },
                disabledTooltipText: subArea.disabledMessage,
                iconProps: {
                    iconName: subArea.icon?.type === 'fluent' && subArea.icon?.value
                },
                onClick: (e) => {
                    e.preventDefault();
                    console.log(`Navigating to ${subArea.url}`);
                    if (subArea.url.startsWith("http")) {
                        window.open(subArea.url, '_blank');
                    }
                    history.push(subArea.url);
                }
            };
            _subAreas.push(_subArea);
        });
        return _subAreas;
    };
}