import { DEFAULT_LANGUAGE } from "@localization/helpers";

interface IUserSettings {
    accessPrincipalId: string;
    userName: string;
    languageId: number;
}

export class UserSettings {
    private static _userSettings: IUserSettings = null;

    public static readonly DEFAULT_USERNAME: string = null;

    public static getUserSettings(): IUserSettings {
        return this._userSettings;
    }

    public static async retrieveUserSettings(accessPrincipalId: string): Promise<IUserSettings> {
        if (!this._userSettings) {
            try {
                const retrivedUserSettings = await Xrm.WebApi.retrieveRecord("talxis_accessprincipal", accessPrincipalId, "?$select=talxis_accessprincipalid&$expand=talxis_accessprincipalsettingsid($select=talxis_language),talxis_contactid($select=emailaddress1)");
                this._userSettings = {
                    accessPrincipalId: accessPrincipalId,
                    userName: retrivedUserSettings?.['talxis_contactid']?.['emailaddress1'] ?? this.DEFAULT_USERNAME,
                    languageId: retrivedUserSettings?.['talxis_accessprincipalsettingsid']?.['talxis_language'] ?? DEFAULT_LANGUAGE
                };
            }
            catch (ex) {
                console.warn("UserSettings", "Unable to retrieve user settings, falling back to built-in defaults!", ex);
                this._userSettings = {
                    accessPrincipalId: accessPrincipalId,
                    userName: this.DEFAULT_USERNAME,
                    languageId: window.TALXIS.Portal.Translations._lcid ?? DEFAULT_LANGUAGE
                };
            }
        }
        return this._userSettings;
    }

    /**
     * Returns local browser's timezone offset in miliseconds
     */
    public static getLocalTimeZoneOffset(): number {
        return (new Date()).getTimezoneOffset() * 60000;
    }

    /**
     * Returns current user's timezone offset in miliseconds from talxis_accessprincipalsettings in future
     */
    public static getConfiguredUserTimeZoneOffset(): number {
        return (new Date()).getTimezoneOffset() * 60000;
    }
};
