import { mergeStyles } from "@fluentui/react/lib/Styling";

export const rootStyles = mergeStyles({
    selectors: {
        '.TALXIS-Portal-alertDialog-title-icon': {
            position: 'relative',
            top: 2,
            marginRight: 5
        }
    }
});