
import { mergeStyles, useTheme } from '@fluentui/react';
import React from 'react';
import styles from './ViewLoading.module.css';

export const ViewLoading: React.FC = () => {
    const theme = useTheme();
    const getStyles = () => {
        return `${styles.root} ${mergeStyles({
            'hr': {
                backgroundColor: theme.semanticColors.bodyDivider
            }
        })}`;
    };

    return (
        <div className={getStyles()}>
            <hr />
            <div className={styles.wrapper}>
                {Array.from(Array(88).keys()).map((x) =>
                    <div key={x} className={styles.line} />
                )}
            </div>
        </div>
    );
};