import React from 'react';
import ReactDOM from "react-dom";
import { View as ViewComponent } from './components/View/View';
import { AggregationButtonPlacementTypes as AggregationButtonPlacementTypes } from './components/View/View';
import { Liquid } from "liquidjs";
import { EntityDefinition } from '@definitions/EntityDefinition';

interface IInputs {
    dataSetGrid: ComponentFramework.PropertyTypes.DataSet;
    gridPageNumber: ComponentFramework.PropertyTypes.WholeNumberProperty;
    EnableGroupBy: ComponentFramework.PropertyTypes.StringProperty;
    EnableNavigation: ComponentFramework.PropertyTypes.StringProperty;
    IsViewPage: ComponentFramework.PropertyTypes.StringProperty;
    EnableQuickFind: ComponentFramework.PropertyTypes.StringProperty;
    SelectableRows: ComponentFramework.PropertyTypes.StringProperty;
    SearchPlaceholder: ComponentFramework.PropertyTypes.StringProperty;
    GroupByAttributes: ComponentFramework.PropertyTypes.StringProperty;
    AggregateObjectArray: ComponentFramework.PropertyTypes.StringProperty;
    TargetEntityType: ComponentFramework.PropertyTypes.StringProperty;
    EnableFiltering: ComponentFramework.PropertyTypes.StringProperty;
    EnableSorting: ComponentFramework.PropertyTypes.StringProperty;
    AggregationButtonPlacement: ComponentFramework.PropertyTypes.EnumProperty<AggregationButtonPlacementTypes>;
}
interface IOutputs {
    value?: string;
}

export class View implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _context: ComponentFramework.Context<IInputs>;
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;
    private _isLoaded = false;
    private _liquid: Liquid;

    constructor() {
        this._liquid = new Liquid();
    }
    private _enableNavigation = false;
    private _entityDefinition: ComponentFramework.PropertyHelper.EntityMetadata

    private async _getEntityDefinition() {
        this._entityDefinition = await EntityDefinition.getAsync(this._context.parameters.TargetEntityType.raw);
        this.updateView(this._context);
    }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
        this._context = context;
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = document.createElement("div");
        container.appendChild(this._container);
        this._enableNavigation = this._context.parameters.EnableNavigation.raw === "true";
        if (this._enableNavigation) {
            this._getEntityDefinition();
        }
    }

    public updateView(context: ComponentFramework.Context<IInputs>): void {
        this._context = context;
        if (!this._enableNavigation || (this._entityDefinition)) {
            ReactDOM.render(React.createElement(ViewComponent, {
                dataset: context.parameters.dataSetGrid,
                primaryName: this._enableNavigation && this._entityDefinition.PrimaryNameAttribute,
                isViewPage: context.parameters.IsViewPage?.raw === "true",
                onOpenDatasetItem: this._enableNavigation && this._context.parameters.dataSetGrid.openDatasetItem,
                pageNumber: context.parameters.gridPageNumber.raw,
                enableQuickFind: context.parameters.EnableQuickFind.raw === "true",
                enableGroupBy: context.parameters.EnableGroupBy.raw === "true",
                selectableRows: context.parameters.SelectableRows.raw,
                enableFiltering: context.parameters.EnableFiltering.raw !== "false",
                enableSorting: context.parameters.EnableSorting.raw !== "false",
                searchPlaceholder: context.parameters.SearchPlaceholder.raw,
                aggregateObjectArray: JSON.parse(context.parameters.AggregateObjectArray?.raw),
                groupByAttributes: context.parameters.GroupByAttributes?.raw ? context.parameters.GroupByAttributes?.raw.replaceAll(' ', '').split(',') : null,
                aggregationButtonPlacement: context.parameters.AggregationButtonPlacement?.raw,
                getTranslation: this.getTranslation.bind(this),
                factory: context.factory,
                formatting: context.formatting,
                fireEvent: (eventName, object) => {
                    // @ts-ignore - fireEvent is not part of PCF types at the moment
                    context.factory.fireEvent(eventName, object);
                }
            }), this._container);
        }
    }
    public getTranslation(string: string, variables: {} = {}): string {
        const localString = this._context.resources.getString(string);
        return variables !== {} ? this._liquid.parseAndRenderSync(localString, variables) : localString;
    }

    public getOutputs(): IOutputs {
        return {};
    }

    public destroy(): void {
        if (this._container) {
            ReactDOM.unmountComponentAtNode(this._container);
            this._container = null;
        }
    }
}