import { MultiSelectOptionSetPicker } from './component/index';
import React from 'react';
import ReactDOM from "react-dom";
import { IMultiSelectOptionSetProps } from './interfaces';

interface IInputs {
    value: ComponentFramework.PropertyTypes.MultiSelectOptionSetProperty;
    EnableBorder: ComponentFramework.PropertyTypes.StringProperty;
}
interface IOutputs {
    value?: number[];
}

export class MultiSelectOptionSet implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _context: ComponentFramework.Context<IInputs>;
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;
    private _value: number[] = [];

    constructor() { }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
        this._context = context;
        if (context.parameters.value.raw) {
            if (!Array.isArray(context.parameters.value.raw)) {
                // TODO: This should never happen when we implement multiselect optionset type in portal
                let values = (context.parameters.value.raw as string).split(',');
                for (let value of values)
                    this._value.push(+value);
            }
            else {
                this._value = context.parameters.value.raw;
            }
        }
        else if (context.parameters.value.attributes?.DefaultValue) {
            this._value.push(context.parameters.value.attributes.DefaultValue);
        }
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = document.createElement("div");
        container.appendChild(this._container);
    }

    public updateView(context: ComponentFramework.Context<IInputs>): void {
        this._context = context;

        let comboBoxProps: IMultiSelectOptionSetProps = {
            placeholder: "---",
            options: this._context.parameters.value.attributes?.Options.map(x => { return { key: x.Value, text: x.Label }; }),
            defaultSelectedKey: this._value ?? [],
            borderless: this._context.parameters.EnableBorder?.raw !== 'true',
            selectedKey: this._value,
            disabled: this._context.mode.isControlDisabled,
            multiSelect: true,
            allowFreeInput: true,
            autoComplete: "on",
            useComboBoxAsMenuWidth: true,
            openOnKeyboardFocus: true,
            onOptionsChange: (selectedKeys) => {
                this._value = selectedKeys.length === 0 ? null : selectedKeys;
                this._notifyOutputChanged();
            }
        };

        ReactDOM.render(React.createElement(MultiSelectOptionSetPicker, comboBoxProps), this._container);
    }

    public getOutputs(): IOutputs {
        return {
            value: this._value
        };
    }

    public destroy(): void {
    }
}