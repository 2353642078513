import { SiteMapMapper } from '@mappers/SiteMapMapper';
import { AppComponents } from '@configuration/AppComponents';
import { QueryData } from '@pages/Control/interfaces';
import queryString from 'query-string';
import { IPromiseCache, cachedWrapper } from '@utilities/MemoryCachingHelpers';
import { sendMetadataGetRequest } from './MetadataApi';
import { IPageRoute, SiteMap, SiteMarker } from '@models/SiteMap';

interface Page {
    url: string;
    entityName: string;
    strippedUrl: string;
    similarity?: number;
}
export class SitemapDefinition {
    private static _sitemapDefinitionCache: IPromiseCache<SiteMap> = {};
    private static _currentSitemap: SiteMap;
    public static async getAsync(sitemapId: string): Promise<SiteMap> {
        return cachedWrapper(sitemapId, () => this._getSitemapDefinitionAsync(sitemapId), this._sitemapDefinitionCache);
    }
    public static getCurrentSiteMap(): SiteMap {
        if (!this._currentSitemap) {
            throw new Error('Current sitemap is undefined, have you called loadCurrentSitemapAsync?');
        }
        return this._currentSitemap;
    }
    public static async loadCurrentSitemapAsync(): Promise<void> {
        const siteMapId = AppComponents.get().filter(appComponent => appComponent.componenttype == 62)[0].objectid;
        this._currentSitemap = await this.getAsync(siteMapId);
    }
    private static _getPage(url: string): Page {
        const paramsData = queryString.parse(url.substring(url.indexOf('?')))?.data as string;
        const data: QueryData = (paramsData) ? JSON.parse(paramsData) : null;
        //strip unnecessary properties
        const urlObject = new URL(url, url.startsWith("http") ? undefined : window.location.origin);
        let strippedUrl;
        if (!data) {
            strippedUrl = urlObject.pathname;
        }
        else {
            const { extraqs, ...strippedData } = data;
            strippedUrl = `${urlObject.pathname}?data=${JSON.stringify(strippedData)}`;
        }
        return {
            entityName: data?.entityName,
            url: decodeURIComponent(url),
            strippedUrl: strippedUrl
        };
    }
    static getCurrentPageKey(): string {
        const sitemapPages: Page[] = [];
        const currentPage = this._getPage(window.location.pathname + window.location.search);
        //get similarity to current page with all sitemap pages
        for (const area of this._currentSitemap.getDefinition().areas) {
            for (const group of area.groups) {
                for (const subArea of group.subAreas) {
                    const sitemapPage = this._getPage(subArea.url);
                    sitemapPage.similarity = this._similarity(currentPage.strippedUrl, sitemapPage.strippedUrl);
                    sitemapPages.push(sitemapPage);
                }
            }
        }
        sitemapPages.sort((a, b) => {
            if ((a.similarity < b.similarity)) {
                return 1;
            }
            if (a.similarity === b.similarity) {
                return 0;
            }
            else return -1;
        });
        //find first item with biggest similarity and same entityName
        for (const sitemapPage of sitemapPages) {
            if (sitemapPage.entityName === currentPage.entityName) {
                return sitemapPage.url;
            }
        }
        //no adequate page was found
        return null;
    };

    public static getWellKnownPage(marker: SiteMarker): string {
        return this._currentSitemap.getWellKnownPage(marker);
    }

    public static getStaticPageRoutes(): IPageRoute[] {
        return this._currentSitemap.getStaticPageRoutes();
    }

    private static async _getSitemapDefinitionAsync(siteMapId: string): Promise<SiteMap> {
        const response = await sendMetadataGetRequest(`v9.1/sitemaps(${siteMapId})?$select=sitemapxml`);
        const json = await response.json();
        const siteMapXml = json['sitemapxml'];
        const siteMapRoot = await SiteMapMapper.parseAsync(siteMapXml);
        const sitemap = new SiteMap(siteMapRoot);
        await sitemap.loadStaticPages();
        return sitemap;
    }
    //returns the similarity of current page and sitemap page (https://stackoverflow.com/questions/10473745/compare-strings-javascript-return-of-likely)
    private static _similarity(s1: string, s2: string) {
        var longer = s1;
        var shorter = s2;
        if (s1.length < s2.length) {
            longer = s2;
            shorter = s1;
        }
        var longerLength = longer.length;
        if (longerLength == 0) {
            return 1.0;
        }
        return (longerLength - this._editDistance(longer, shorter)) / parseFloat(longerLength.toString());
    }
    private static _editDistance(s1: string, s2: string) {
        s1 = s1.toLowerCase();
        s2 = s2.toLowerCase();

        var costs = new Array();
        for (var i = 0; i <= s1.length; i++) {
            var lastValue = i;
            for (var j = 0; j <= s2.length; j++) {
                if (i == 0)
                    costs[j] = j;
                else {
                    if (j > 0) {
                        var newValue = costs[j - 1];
                        if (s1.charAt(i - 1) != s2.charAt(j - 1))
                            newValue = Math.min(Math.min(newValue, lastValue),
                                costs[j]) + 1;
                        costs[j - 1] = lastValue;
                        lastValue = newValue;
                    }
                }
            }
            if (i > 0)
                costs[s2.length] = lastValue;
        }
        return costs[s2.length];
    }
}