import React, { useContext } from 'react';
import { AppContext } from '@providers/AppProvider';
import { IAppContext } from '@providers/AppProvider/interfaces';
import { getFooterStyles } from './Styles';
import { Text } from '@fluentui/react/lib/Text';
import { SpaConfiguration } from '@configuration/SpaConfiguration';

export const Footer: React.FC = () => {
    const appContext: IAppContext = useContext(AppContext);
    return (
        <div className={getFooterStyles(appContext.navigationVisible, SpaConfiguration.get().navigationType)}>
            <Text>{SpaConfiguration.get().footerText}</Text>
        </div>
    );
};
export default Footer;