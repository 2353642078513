import React from 'react';
import ReactDOM from "react-dom";
import { DatePickerField as DatePickerFieldBase } from './component';
import { DateTimeType, IDateTimePickerProps } from './interfaces';
import { DomParser } from '@app/Constants';

interface IDateTimeControlInputs {
  value: ComponentFramework.PropertyTypes.DateTimeProperty;
  enableDeleteButton?: ComponentFramework.PropertyTypes.StringProperty;
  hideYearSelection?: ComponentFramework.PropertyTypes.StringProperty;
  hideMonthSelection?: ComponentFramework.PropertyTypes.StringProperty;
  hideWeekSelection?: ComponentFramework.PropertyTypes.StringProperty;
  hideDaySelection?: ComponentFramework.PropertyTypes.StringProperty;
  availableDatesQuery?: ComponentFramework.PropertyTypes.StringProperty;
  EnableBorder?: ComponentFramework.PropertyTypes.StringProperty;
  excludedDatesQuery?: ComponentFramework.PropertyTypes.StringProperty;
  availableOptions?: ComponentFramework.PropertyTypes.EnumProperty<DateTimeType>;
  excludeOptions?: ComponentFramework.PropertyTypes.EnumProperty<DateTimeType>;
  excludeDaysOfTheWeekArray?: ComponentFramework.PropertyTypes.StringProperty;
  Format: ComponentFramework.PropertyTypes.StringProperty;
}
interface IDateTimeControlOutputs {
  value?: Date;
}

interface IDateTimeAttributes {
  from: string | number;
  to: string | number;
}

export class DateTime implements ComponentFramework.StandardControl<IDateTimeControlInputs, IDateTimeControlOutputs> {
  private _context: ComponentFramework.Context<IDateTimeControlInputs>;
  private _container: HTMLDivElement;
  private _notifyOutputChanged: () => void;
  private _enableDeleteButton: boolean;
  private _hideYearSelection: boolean;
  private _hideMonthSelection: boolean;
  private _hideWeekSelection: boolean;
  private _hideDaySelection: boolean;
  private _value: Date = null;
  private _availableDates?: Date[];
  private _excludedDates?: Date[];
  private _availableOptions?: DateTimeType;
  private _excludeOptions?: DateTimeType;
  private _availableDateAttributeNames: IDateTimeAttributes;
  private _excludeDateAttributeNames: IDateTimeAttributes;
  private _excludeDaysOfTheWeekArray?: number[];
  private _entityName: string;
  private _languageCode: number;
  private _format: string;
  private _behavior: ComponentFramework.FormattingApi.Types.DateTimeFieldBehavior;
  constructor() { }

  public init(context: ComponentFramework.Context<IDateTimeControlInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
    this._context = context;
    this._container = document.createElement("div");
    this._notifyOutputChanged = notifyOutputChanged;

    this._enableDeleteButton = this._context.parameters.enableDeleteButton?.raw === "true";
    this._hideYearSelection = this._context.parameters.hideYearSelection?.raw === "true";
    this._hideMonthSelection = this._context.parameters.hideMonthSelection?.raw === "true";
    this._hideWeekSelection = this._context.parameters.hideWeekSelection?.raw === "true";
    this._hideDaySelection = this._context.parameters.hideDaySelection?.raw === "true";
    this._languageCode = this._context.userSettings.languageId;
    this._behavior = this._context.parameters.value.attributes.Behavior ?? 0;
    if (this._context.parameters.availableDatesQuery?.raw)
      this._availableDateAttributeNames = this._getDateAttributes(this._context.parameters.availableDatesQuery?.raw);
    if (this._context.parameters.excludedDatesQuery?.raw)
      this._excludeDateAttributeNames = this._getDateAttributes(this._context.parameters.excludedDatesQuery?.raw);
    this._excludeDaysOfTheWeekArray = JSON.parse(this._context.parameters.excludeDaysOfTheWeekArray?.raw);
    this._value = this._context.parameters.value.raw ?? null;
    container.appendChild(this._container);
  }

  public async updateView(context: ComponentFramework.Context<IDateTimeControlInputs>): Promise<void> {
    this._context = context;
    this._value = this._context.parameters.value.raw ?? null;
    this._format = this._context.parameters.Format?.raw ?? this._context.parameters.value.attributes.Format ?? "Date";
    // GET DATES
    if (this._availableDateAttributeNames && !this._availableDates && this._availableDates?.length !== 0) {
      this._availableOptions = this._context.parameters.availableOptions?.raw as DateTimeType ?? DateTimeType.DateAndTime;
      this._entityName = this._getEntityName(this._context.parameters.availableDatesQuery?.raw);
      await this._getAvailableDates();
    }
    if (this._excludeDateAttributeNames && !this._excludedDates && this._excludedDates?.length !== 0) {
      this._excludeOptions = this._context.parameters.excludeOptions?.raw as DateTimeType ?? DateTimeType.DateAndTime;
      this._entityName = this._getEntityName(this._context.parameters.excludedDatesQuery?.raw);
      await this._getExcludeDates();
    }

    const dateBoxProps: IDateTimePickerProps = {
      value: this._value,
      readOnly: this._context.mode.isControlDisabled,
      borderless: this._context.parameters.EnableBorder?.raw !== 'true',
      allowTextInput: !this._context.mode.isControlDisabled,
      enableDeleteButton: this._enableDeleteButton,
      availableDates: this._availableDates,
      availableOptions: this._availableOptions,
      excludedDates: this._excludedDates,
      excludeOptions: this._excludeOptions,
      excludeDaysOfTheWeekArray: this._excludeDaysOfTheWeekArray,
      hideYearSelection: this._hideYearSelection,
      hideMonthSelection: this._hideMonthSelection,
      hideWeekSelection: this._hideWeekSelection,
      hideDaySelection: this._hideDaySelection,
      languageCode: this._languageCode,
      format: this._format,
      behavior: this._behavior,
      onSelectDate: (date: Date) => {
        this._value = date;
        this._notifyOutputChanged();
      },
      onDeleteButtonClick: () => {
        this._value = null;
        this._notifyOutputChanged();
      }
    };

    ReactDOM.render(React.createElement(DatePickerFieldBase, dateBoxProps), this._container);
  }

  public getOutputs(): IDateTimeControlOutputs {
    return {
      value: this._value
    };
  }

  public destroy(): void {
  }

  private async _getExcludeDates(): Promise<void> {
    const returnedRecords = await this._getRecords(this._context.parameters.excludedDatesQuery?.raw);
    if (returnedRecords.length > 1000)
      throw Error('1000 records limit exceeded for Excluded Dates Query! Please limit your query.');
    if (!this._excludedDates) this._excludedDates = [];
    for (const record of returnedRecords) {
      this._excludedDates.push(new Date(record[this._excludeDateAttributeNames.from]));
    }
  }
  private async _getAvailableDates(): Promise<void> {
    const returnedRecords = await this._getRecords(this._context.parameters.availableDatesQuery?.raw);
    if (returnedRecords?.length > 1000)
      throw Error('1000 records limit exceeded for Available Dates Query! Please limit your query.');
    if (!this._availableDates) this._availableDates = [];
    for (const record of returnedRecords) {
      //if push only unique values to date array
      if (this._availableOptions === 'DateAndTime' && !this._availableDates.some(from => new Date(from)?.toDateString() === new Date(record[this._availableDateAttributeNames.from])?.toDateString())) {
        this._availableDates.push(new Date(record[this._availableDateAttributeNames.from]));
      }
      else if (this._availableOptions === 'TimeOnly') {
        this._availableDates.push(new Date(record[this._availableDateAttributeNames.from]));
      }
    }
  }
  private async _getRecords(fetchXml: string): Promise<ComponentFramework.WebApi.Entity[]> {
    try {
      return (await this._context.webAPI.retrieveMultipleRecords(this._entityName, '?fetchXml=' + fetchXml)).entities;
    } catch (e) {
      console.log(e);
    }
  }
  private _getEntityName(fetchXml: string): string {
    const parsedFetchXml = DomParser.parseFromString(fetchXml ?? "", "text/xml");
    return parsedFetchXml.getElementsByTagName("entity")[0].getAttribute("name");
  }
  private _getDateAttributes(fetchXml: string): IDateTimeAttributes {
    const parsedFetchXml = DomParser.parseFromString(fetchXml ?? "", "text/xml");
    return {
      from: parsedFetchXml?.getElementsByTagName("attribute")[0]?.getAttribute("name"),
      to: parsedFetchXml?.getElementsByTagName("attribute")[1]?.getAttribute("name"),
    };
  }
}