import React from 'react';
import { Text } from '@fluentui/react/lib/Text';
import styles from './MainLoading.module.css';
import { mergeStyles, Spinner, SpinnerSize } from '@fluentui/react';
import { useMemo } from 'react';
import { BODY_BACKGROUND_COLOR } from '../../../app/Constants';

const MainLoading: React.FC = () => {
    const className = useMemo(() => {
        return `${styles.root} ${mergeStyles({
            backgroundColor: BODY_BACKGROUND_COLOR
        })}`;
    }, []);
    return (
        <div className={className}>
            <div className={styles.spacer}></div>
            <div className={styles.loadingWrapper}>
                <Spinner size={SpinnerSize.large} className={styles.spinner} />
                <Text variant="large" >
                    {window.TALXIS.Portal.Translations.getLocalizedString("@controls/loadings/MainLoading")}
                </Text>
            </div>
        </div>
    );
};
export default MainLoading;