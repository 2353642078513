import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import styles from './NavigationPromptDialog.module.css';

export interface INavigationPromptDialogProps {
    formContext: Xrm.FormContext;
}

export const NavigationPromptDialog: React.FC<INavigationPromptDialogProps> = (props) => {
    return (
        <NavigationPrompt
            when={props !== null && Object.keys(props).length > 0}
        >
            {({ isActive, onCancel, onConfirm }) => {
                if (isActive && props !== null && Object.keys(props).length > 0 && props.formContext.data.getIsDirty()) {
                    return (
                        <Dialog
                            hidden={false}
                            onDismiss={() => {
                                onCancel();
                            }}
                            modalProps={{
                                className: styles.root
                            }}
                            dialogContentProps={{
                                showCloseButton: true,
                                type: DialogType.normal,

                                title: window.TALXIS.Portal.Translations.getLocalizedString('components/dialogs/NavigationPromptDialog/Title'),
                                subText: window.TALXIS.Portal.Translations.getLocalizedString('components/dialogs/NavigationPromptDialog/SubText'),
                            }}
                        >
                            <DialogFooter>
                                <PrimaryButton onClick={() => {
                                    const onClickAsync = async () => {
                                        window.Xrm.Utility.showProgressIndicator(window.TALXIS.Portal.Translations.getLocalizedString('@definitions/RibbonDefinition/Saving'));
                                        const result = await props.formContext.data.save();
                                        if (!result) {
                                            //some form has failed to save
                                            onCancel();
                                            window.Xrm.Utility.closeProgressIndicator();
                                            return;
                                        }
                                        window.TALXIS.Portal.Context.setNavigationPromptDialogProps(null);
                                        window.Xrm.Utility.closeProgressIndicator();
                                        onConfirm();

                                    };
                                    onClickAsync();
                                }}
                                    text={window.TALXIS.Portal.Translations.getLocalizedString('components/dialogs/NavigationPromptDialog/SaveAndContinue')} />
                                <DefaultButton
                                    onClick={() => {
                                        window.TALXIS.Portal.Context.setNavigationPromptDialogProps(null);
                                        window.TALXIS.Portal.Context.setShouldRefreshMain(true);
                                        onConfirm();
                                    }}
                                    text={window.TALXIS.Portal.Translations.getLocalizedString('components/dialogs/NavigationPromptDialog/DiscardChanges')} />
                            </DialogFooter>
                        </Dialog>
                    );
                }
                else {
                    onConfirm();
                }
            }}
        </NavigationPrompt>
    );
};