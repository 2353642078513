import {
    BaseSlots,
    createTheme,
    getColorFromString,
    getTheme,
    isDark,
    ITheme,
    IThemeRules,
    ThemeGenerator,
    themeRulesStandardCreator,
} from '@fluentui/react';

export enum ThemeType {
    RIBBON = "ribbon",
    MAIN = "main",
    NAVIGATION = "navigation",
    NAVBAR = "navbar",
}

export class ThemeDesigner {

    public static generateTheme(
        {
            primaryColor, textColor, backgroundColor, themeType, defaultTheme
        }: {
            primaryColor: string, textColor: string, backgroundColor: string, themeType: ThemeType, defaultTheme?: ITheme;
        },
    ): any {
        if (!ThemeDesigner._isValidColorValue([primaryColor, textColor, backgroundColor], themeType)) {
            return ThemeDesigner.generateTheme(ThemeDesigner._getDefaultColors(themeType, defaultTheme));
        }
        const themeRules = themeRulesStandardCreator();
        const colors = {
            primaryColor: getColorFromString(primaryColor)!,
            textColor: getColorFromString(textColor)!,
            backgroundColor: getColorFromString(backgroundColor)!,
        };

        const isCustomization = false;
        const overwriteCustomColor = true;

        ThemeGenerator.setSlot(
            themeRules[BaseSlots[BaseSlots.backgroundColor]],
            colors.backgroundColor,
            undefined,
            isCustomization,
            overwriteCustomColor,
        );

        const currentIsDark = isDark(themeRules[BaseSlots[BaseSlots.backgroundColor]].color!);

        ThemeGenerator.setSlot(
            themeRules[BaseSlots[BaseSlots.primaryColor]],
            colors.primaryColor,
            currentIsDark,
            isCustomization,
            overwriteCustomColor,
        );
        ThemeGenerator.setSlot(
            themeRules[BaseSlots[BaseSlots.foregroundColor]],
            colors.textColor,
            currentIsDark,
            isCustomization,
            overwriteCustomColor,
        );

        // strip out the unnecessary shade slots from the final output theme
        const abridgedTheme: IThemeRules = Object.entries(themeRules).reduce(
            (acc, [ruleName, ruleValue]) => (
                (
                    ruleName.indexOf('ColorShade') === -1
                    && ruleName !== 'primaryColor'
                    && ruleName !== 'backgroundColor'
                    && ruleName !== 'foregroundColor'
                    && ruleName.indexOf('body') === -1
                )
                    ? {
                        ...acc,
                        [ruleName]: ruleValue,
                    }
                    : acc
            ),
            {} as IThemeRules,
        );

        return createTheme({ palette: ThemeGenerator.getThemeAsJson(abridgedTheme), isInverted: isDark(themeRules[BaseSlots[BaseSlots.backgroundColor]].color!) });
    }
    private static _isValidColorValue(colors: string[], themeType: ThemeType): boolean {
        for (const color of colors) {
            if (!color) {
                console.warn(`A color is mising in ${themeType} theme definition. Applying default ${themeType} theme.`);
                return false;
            }
            if (!CSS.supports('color', color)) {
                console.warn(`${color} is not a valid value for ${themeType} theme color. Applying default ${themeType} theme.`);
                return false;
            }
        }
        return true;
    }
    private static _getDefaultColors(themeType: ThemeType, theme?: ITheme): any {
        const defaultTheme = theme ?? getTheme();
        switch (themeType) {
            case ThemeType.MAIN: {
                return {
                    primaryColor: defaultTheme.palette.themePrimary,
                    backgroundColor: defaultTheme.semanticColors.bodyBackground,
                    textColor: defaultTheme.palette.black,
                    themeType: themeType
                };
            }
            case ThemeType.NAVBAR: {
                return {
                    primaryColor: defaultTheme.palette.white,
                    backgroundColor: defaultTheme.palette.themePrimary,
                    textColor: defaultTheme.palette.white,
                    themeType: themeType
                };
            }
            case ThemeType.NAVIGATION: {
                return {
                    primaryColor: defaultTheme.palette.themePrimary,
                    backgroundColor: defaultTheme.semanticColors.buttonBackgroundPressed,
                    textColor: defaultTheme.palette.black,
                    themeType: themeType
                };
            }
            case ThemeType.RIBBON: {
                return {
                    primaryColor: defaultTheme.palette.themePrimary,
                    backgroundColor: defaultTheme.semanticColors.buttonBackgroundHovered,
                    textColor: defaultTheme.palette.black,
                    themeType: themeType
                };
            }

        }
    }
}
