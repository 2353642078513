import { SpaConfiguration } from "@configuration/SpaConfiguration";
import { MultitenantProvider } from "../MultitenantProvider";
import { IODataResponse } from "../../interfaces/general";
import { isDev } from "../../Functions";
import { RingProvider } from "../RingProvider";

export const sendMetadataGetRequest = async (relativePath: string): Promise<Response> => {
    return fetch(buildMetadataUrl(relativePath, true), {
        headers: {
            ...MultitenantProvider.getFetchHeader().headers,
        }
    });
};

export const metadataRetrieveMultiple = async (relativePath: string): Promise<ComponentFramework.WebApi.RetrieveMultipleResponse> => {
    const response = await sendMetadataGetRequest(relativePath);
    const json: IODataResponse<ComponentFramework.WebApi.Entity> = await response.json();
    return {
        entities: json.value,
        nextLink: null
    };
};

export const getWebResourceUrl = (relativePath: string): string => {
    let url: URL;
    const configuration = SpaConfiguration.get();
    let webResourceHash = configuration.webResourceHash;
    const ring = RingProvider.get();

    if (ring) {
        webResourceHash = `ring_override:${ring}`;
    }

    if (configuration.metadataApi.startsWith("http")) {
        const metadataUrl = new URL(configuration.metadataApi);

        url = new URL(relativePath, `${metadataUrl.origin}/${webResourceHash}/`);
    }
    else {
        url = new URL(relativePath, `${window.location.origin}/${webResourceHash}/`);
    }

    if (isDev()) {
        url.searchParams.set("hostname", MultitenantProvider.getHostname());
    }

    return url.toString();
};

export const getMediaLibraryFileUrl = (relativePath: string): string => {
    let url: URL;
    const configuration = SpaConfiguration.get();
    let webResourceHash = configuration.talxisMetadataVersion;
    const ring = RingProvider.get();

    if (ring) {
        webResourceHash = `ring_override:${ring}`;
    }

    if (configuration.metadataApi.startsWith("http")) {
        const metadataUrl = new URL(configuration.metadataApi);

        url = new URL(relativePath, `${metadataUrl.origin}/${webResourceHash}/`);
    }
    else {
        url = new URL(relativePath, `${window.location.origin}/${webResourceHash}/`);
    }

    if (isDev()) {
        url.searchParams.set("hostname", MultitenantProvider.getHostname());
    }

    return url.toString();
};

export const buildMetadataUrl = (relativePath: string, appendMetadataVersion: boolean = false, appendHostname: boolean = false): string => {
    let url: URL;
    const configuration = SpaConfiguration.get();
    if (configuration.metadataApi.startsWith("http")) {
        url = new URL(relativePath, `${configuration.metadataApi}/`);
    }
    else {
        url = new URL(relativePath, `${window.location.origin}${configuration.metadataApi}/`);
    }

    if (appendMetadataVersion) {
        const ring = RingProvider.get();
        url.searchParams.set("metadataVersion", ring ? `ring_override:${ring}` : configuration.metadataVersion);
    }
    if (appendHostname) {
        url.searchParams.set("hostname", MultitenantProvider.getHostname());
    }

    return url.toString();
};