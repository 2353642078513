import React from 'react';
import ReactDOM from "react-dom";
import { IDecimalProps as IDurationProps, ILocalizedStrings } from './interfaces';
import DurationField from './components';
import { Liquid } from "liquidjs";
import { IComboBox, IComboBoxOption } from '@fluentui/react';
import humanizeDuration from 'humanize-duration';
import lcidCodes from 'lcid/lcid.json';

interface IInputs {
    value: ComponentFramework.PropertyTypes.WholeNumberProperty;
    EnableBorder?: ComponentFramework.PropertyTypes.StringProperty
}
interface IOutputs {
    value?: number;
}

export class Duration implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _context: ComponentFramework.Context<IInputs>;
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;
    private _value: number = 0;
    private _liquid: Liquid;
    private _options: IComboBoxOption[];
    private _localizedStrings: ILocalizedStrings;
    private _decimalSeparator: string;
    private _timeUnit = { Minute: 0, Hour: 1, Day: 2 };
    private _humanizer: humanizeDuration.Humanizer;

    constructor() {
        this._liquid = new Liquid();
    }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
        this._context = context;
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = document.createElement("div");

        let language = lcidCodes[this._context.userSettings.languageId].split("_")[0];
        this._humanizer = humanizeDuration.humanizer({
            maxDecimalPoints: 2,
            language: language,
            fallbacks: ["en"],
            delimiter: this._getDecimalSeparator(this._context.userSettings)
        });

        this._localizedStrings = this._getLocalizedStrings();
        this._decimalSeparator = this._getDecimalSeparator(this._context.userSettings);
        this._options = this._getDefaultOptions();
        container.appendChild(this._container);
    }

    public updateView(context: ComponentFramework.Context<IInputs>): void {
        this._context = context;
        this._value = this._context.parameters.value.raw ?? null;

        const durationFieldProps: IDurationProps = {
            value: this._value ?? null,
            options: this._options,
            borderless: this._context.parameters.EnableBorder?.raw !== 'true',
            localizedStrings: this._localizedStrings,
            readOnly: this._context.mode.isControlDisabled,
            validate: this._validate.bind(this),
            getTranslatedString: this._getTranslatedString.bind(this),
            formatNumber: this._formatNumber.bind(this),
            onChange: (e: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
                if (option) {
                    this._value = option.key as number;
                }
                else {
                    this._value = this._validate(value);
                }
                this._notifyOutputChanged();
            }
        };

        ReactDOM.render(React.createElement(DurationField, durationFieldProps), this._container);
    }

    public getOutputs(): IOutputs {
        return {
            value: this._value ?? null
        };
    }

    public destroy(): void { }

    private _formatNumber(value: number, precision?: number) {
        return this._humanize(value);
    }
    private _getLocalizedStrings = () => {
        return {
            days: this._context.resources.getString("days"),
            day: this._context.resources.getString("day"),
            hours: this._context.resources.getString("hours"),
            hour: this._context.resources.getString("hour"),
            minutes: this._context.resources.getString("minutes"),
            minute: this._context.resources.getString("minute")
        };
    }
    private _validate = (value: string) => {
        // These values come from hummanize-duration-ts package
        const formatMinutes = this._context.resources.getString("formatMinutes").split(",");
        const formatHours = this._context.resources.getString("formatHours").split(",");
        const formatDays = this._context.resources.getString("formatDays").split(",");

        const minutes = new RegExp(formatMinutes.map(x => `^${x}\\s|\\s${x}$`).join("|"), "i");
        const hours = new RegExp(formatHours.map(x => `^${x}\\s|\\s${x}$`).join("|"), "i");
        const days = new RegExp(formatDays.map(x => `^${x}\\s|\\s${x}$`).join("|"), "i");

        if (value && value.trim()) {
            let trimmedValue = value.trim(), timeInterval = this._timeUnit.Minute;

            if (minutes.test(trimmedValue)) {
                trimmedValue = trimmedValue.replace(minutes, "").trim();
            } else if (hours.test(trimmedValue)) {
                trimmedValue = trimmedValue.replace(hours, "").trim();
                timeInterval = this._timeUnit.Hour;
            } else if (days.test(trimmedValue)) {
                trimmedValue = trimmedValue.replace(days, "").trim();
                timeInterval = this._timeUnit.Day;
            }

            const number = this._parseNumber(trimmedValue);
            if (!isNaN(number)) {
                const result = this._getDurationInMinutes(number, timeInterval);
                return Math.round(result);
            }
            return NaN;
        }
        return 0;

    }
    private _getDurationInMinutes = (value: number, timeUnit: string | number) => {
        switch (timeUnit) {
            case this._timeUnit.Hour:
                return 60 * value;
            case this._timeUnit.Day:
                return 60 * value * 24;
            case this._timeUnit.Minute:
            default:
                return value;
        }
    }
    private _parseNumber(value: string) {
        let regExp: RegExp;
        let decimalSeparator = this._decimalSeparator;
        try {
            regExp = new RegExp("[^-+0-9" + decimalSeparator + "]", "g");
        } catch (e) {
            decimalSeparator = this._getDecimalSeparator(null);
            regExp = new RegExp("[^-+0-9" + decimalSeparator + "]", "g");
        }
        const result = value.replace(regExp, "").replace(decimalSeparator, ".");
        return parseFloat(result);
    }
    private _getDecimalSeparator = (userSettings: ComponentFramework.UserSettings) => {
        return userSettings?.numberFormattingInfo?.numberDecimalSeparator?.length > 0 ? userSettings.numberFormattingInfo.numberDecimalSeparator : Intl.NumberFormat(navigator.language).format(1.1).charAt(1);
    }
    private _getDefaultOptions(): IComboBoxOption[] {
        const options = [1, 15, 30, 45, 60, 60 + 30, 2 * 60, 2 * 60 + 30, 3 * 60, 3 * 60 + 30, 4 * 60, 4 * 60 + 30, 5 * 60, 5 * 60 + 30, 6 * 60, 6 * 60 + 30, 7 * 60, 7 * 60 + 30, 8 * 60, 24 * 60, 2 * 24 * 60, 3 * 24 * 60,];
        return options.map(x => {
            return {
                key: x,
                text: this._humanize(x)
            };
        });
    }
    private _getTranslatedString(string: string, variables = {}): string {
        return Object.keys(variables).length !== 0 ? this._liquid.parseAndRenderSync(this._context.resources.getString(string), variables) : this._context.resources.getString(string);
    }
    private _humanize(minutes: number): string {
        minutes = parseFloat(minutes.toFixed(2));
        let units: humanizeDuration.Unit = "m";
        if (minutes < 60) {
            units = "m";
        }
        else if (minutes < 24 * 60) {
            units = "h";
        }
        else {
            units = "d";
        }
        return this._humanizer(minutes * 60 * 1000, { units: [units] });
    }
}