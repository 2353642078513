import React, { useState, useEffect, useRef } from 'react';
import { IAppContext, IAppProviderProps } from './interfaces';
import { IDialogProps, Overlay, Text } from '@fluentui/react';
import { IConfirmationDialogProps } from '../../components/dialogs/ConfirmationDialog/interfaces';
import { IFormDialogProps } from '../../components/dialogs/FormDialog/interfaces';
import { ISpaConfiguration, SpaConfiguration } from '@configuration/SpaConfiguration';
import { AppModule } from '@configuration/AppModule';
import { AppComponents } from '@configuration/AppComponents';
import MainLoading from '../../components/loadings/MainLoading';
import { Authentication } from '@app/classes/Authentication';
import { getClient, loadOfficeJs, isDev } from '@app/Functions';
import { Guid } from 'guid-typescript';
import { IXrmGlobalNotification } from '../../Xrm/interfaces/IXrmGlobalNotification';
import { IGlobalNotification } from '@pages/Layout/components/GlobalNotification/interfaces/IGlobalNotification';
import Loading from '../../components/loadings/Loading';
import { SitemapDefinition } from '@definitions/SitemapDefinition';
import { EntityDefinition } from '@definitions/EntityDefinition';
import ErrorDialog from '../../components/dialogs/ErrorDialog';
import { IErrorDialogProps } from '../../components/dialogs/ErrorDialog/interfaces';
import { EnvironmentNotSelectedException } from '@app/classes/exceptions/EnvironmentNotSelectedException';
import { Exception } from '@app/classes/exceptions/Exception';
import EnvironmentSelectionDialog from '../../components/dialogs/EnvironmentSelectionDialog';
import { useBoolean } from '@fluentui/react-hooks';
import DevKit from '@src/components/utilities/DevKit';
import { createBrowserHistory } from 'history';
import { UserSettings } from '@app/classes/UserSettings';
import { AppSwitcherDialog } from '../../components/dialogs/AppSwitcherDialog';
import { INavigationPromptDialogProps, NavigationPromptDialog } from '../../components/dialogs/NavigationPromptDialog';
import { ThemeDefinition } from '@definitions/ThemeDefinition';
import { ILookupPanelProps } from '@src/components/navigation/panels/LookupPanel';

export const history = createBrowserHistory();

export const AppContext = React.createContext<IAppContext | null>(null);

const AppProvider: React.FC<IAppProviderProps> = (props) => {
    const [appSwitcherDialogVisible, setAppSwitcherDialogVisible] = useState<boolean>(false);
    const [applicationId, setApplicationId] = useState<string>(null);
    const [refreshSiteMap, setRefreshSiteMap] = useState<boolean>(false);
    const [isNavbarLoaded, setIsNavbarLoaded] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isPanelLoading, setPanelLoading] = useState<boolean>(false);
    const [currentKey, setCurrentKey] = useState<string>("");
    const [menuExtended, setMenuExtended] = useState<boolean>(false);
    const [errorDialogProps, setErrorDialogProps] = useState<IDialogProps>(null);
    const [confirmationDialogProps, setConfirmationDialogProps] = useState<IConfirmationDialogProps>(null);
    const [formDialogs, setFormDialogs] = useState<IFormDialogProps[]>([]);
    const [alertDialogProps, setAlertDialogProps] = useState<IDialogProps>(null);
    const [environmentSelectionDialogProps, setEnvironmentSelectionDialogProps] = useState<IDialogProps>(null);
    const [navigationPromptDialogProps, setNavigationPromptDialogProps] = useState<INavigationPromptDialogProps>(null);
    const [navigationVisible, setNavigationVisibility] = useState<boolean>(null);
    const [globalNotifications, setGlobalNotifications] = useState<IGlobalNotification[]>([]);
    const [configurationLoaded, setConfigurationLoaded] = useState<boolean>(false);
    const [applicationLoaded, setApplicationLoaded] = useState<boolean>(false);
    const [shouldRefreshMain, setShouldRefreshMain] = useState<boolean>(false);
    const [progressIndicatorMessage, setProgressIndicatorMessage] = useState<string>(null);
    const [showDevelopmentModeButton, { toggle: toggleshowDevelopmentModeButton }] = useBoolean(false);
    const [connectedEnvironmentDetails, setConnectedEnvironmentDetails] = useState<{ metadataServiceUrl: string, backendUrl: string, environmentUrl: string }>(null);
    const [lookupPanelProps, setLookupPanelProps] = useState<ILookupPanelProps>(null);

    let _spaConfiguration: ISpaConfiguration;
    const globalNotificationsRef = useRef<IGlobalNotification[]>([]);

    const addGlobalNotification = async (notification: IXrmGlobalNotification): Promise<string> => {
        const id = Guid.create().toString();
        const globalNotification: IGlobalNotification = {
            notification: notification,
            id: id
        };

        globalNotificationsRef.current.push(globalNotification);

        setGlobalNotifications([...globalNotificationsRef.current]);

        return id;
    };

    const clearGlobalNotification = async (id: string): Promise<void> => {
        let newGlobalNotifications = globalNotificationsRef.current.filter(x => x.id !== id);

        globalNotificationsRef.current = newGlobalNotifications;

        setGlobalNotifications([...globalNotificationsRef.current]);
    };

    const closeFormDialog = (formId?: string, entity?: ComponentFramework.WebApi.Entity, dialogInstanceId?: string) => {
        setFormDialogs((currentFormDialogs) => {
            const newFormDialogs = [...currentFormDialogs];
            if (formId) {
                const dialogFormNames = currentFormDialogs.map(x => x.id);
                const indexOfLastDialogWithMatchingFormName = dialogFormNames.lastIndexOf(formId);
                if (indexOfLastDialogWithMatchingFormName > -1) {
                    const closedDialog = newFormDialogs.splice(indexOfLastDialogWithMatchingFormName, 1);
                    if (closedDialog.length === 1) {
                        const dialog = closedDialog[0];
                        dialog.onCloseCallback?.apply(null, [{ parameters: entity }]);
                    }
                    return newFormDialogs;
                }
            }
            else if (dialogInstanceId) {
                return currentFormDialogs.filter(x => x.id !== dialogInstanceId);
            }
            // In other cases remove the last dialog
            const dialogToClose = newFormDialogs.pop();
            dialogToClose?.onCloseCallback?.apply(null, [{ parameters: entity }]);

            return newFormDialogs;
        });
    };

    const openFormDialog = (dialogProps: IFormDialogProps) => {
        if (!dialogProps.id) {
            dialogProps.id = Guid.create().toString();
        }
        setFormDialogs((currentFormDialogs) => [...currentFormDialogs, dialogProps]);
    };

    const initAsync = async () => {
        try {
            if (isDev()) {
                toggleshowDevelopmentModeButton();
            }
            await SpaConfiguration.loadAsync();
            _spaConfiguration = SpaConfiguration.get();
            setConnectedEnvironmentDetails(
                {
                    backendUrl: _spaConfiguration.edsApi,
                    metadataServiceUrl: _spaConfiguration.metadataApi,
                    environmentUrl: _spaConfiguration.instanceUrl
                }
            );
        }
        catch (error) {
            console.error(error);

            let exception = (error instanceof Exception) ? (error as Exception) : new Exception((error as Xrm.Async.ErrorCallbackObject).message);

            // DevBox and Dev Defaults env selection dialog
            if (exception.GetInnerMostException() instanceof EnvironmentNotSelectedException) {
                setEnvironmentSelectionDialogProps({
                    hidden: false
                });
                return;
            }

            const dialogProps: IErrorDialogProps = {
                hidden: false,
                modalProps: {
                    isBlocking: false // Allow users to disconnect from sandbox
                },
                dialogContentProps: {
                    title: window.TALXIS.Portal.Translations.getLocalizedString("@providers/AppProvider/bootError-title"),
                    subText: window.TALXIS.Portal.Translations.getLocalizedString("@providers/AppProvider/bootError-configurationError"),
                    cancelButtonLabel: window.TALXIS.Portal.Translations.getLocalizedString("@providers/AppProvider/bootError-refresh"),
                    moreDetailsText: exception.Print()
                },
                onDismiss: () => {
                    window.location.reload();
                }
            };
            setErrorDialogProps(dialogProps);
            return; // do not continue
        }
        setConfigurationLoaded(true);
        // Stop executing when login is redirecting...
        try {
            if (getClient() === "Outlook") {
                await loadOfficeJs();
            }
            const authenticationResult = await Authentication.trySilentLoginAsync();
            if (authenticationResult) {
                await UserSettings.retrieveUserSettings(Authentication.getUser().accessPrincipalId);
            }
            //module not selected, try to show app switcher or load single found module
            if (!await AppModule.loadAsync()) {
                const allAppModules = await AppModule.getAllAppModules();
                if (allAppModules.length > 1) {
                    setAppSwitcherDialogVisible(true);
                    return;
                }
                //load the single found app module
                await AppModule.loadAsync(allAppModules[0].uniquename);
            }
            await ThemeDefinition.loadTheme(); // Needs to be called after createGlobalWindowProps because it uses Xrm.WebApi to fetch themes
            setApplicationId(AppModule.get().appmoduleid);

            await AppComponents.loadAsync();
            await SitemapDefinition.loadCurrentSitemapAsync();
            setApplicationLoaded(true);
        }
        catch (error) {
            console.error(error);
            //clear the local storage so user can switch to different app on error
            localStorage.removeItem('appModuleName');
            let exception = (error instanceof Exception) ? (error as Exception) : new Exception((error as Xrm.Async.ErrorCallbackObject).message);
            const dialogProps: IErrorDialogProps = {
                hidden: false,
                modalProps: {
                    isBlocking: false // Allow users to disconnect from sandbox
                },
                dialogContentProps: {
                    title: window.TALXIS.Portal.Translations.getLocalizedString("@providers/AppProvider/bootError-title"),
                    subText: window.TALXIS.Portal.Translations.getLocalizedString("@providers/AppProvider/bootError-authenticationError"),
                    cancelButtonLabel: window.TALXIS.Portal.Translations.getLocalizedString("@providers/AppProvider/bootError-signOut"),
                    moreDetailsText: exception.Print()
                },
                onDismiss: () => {
                    Authentication.logout();
                }
            };
            setErrorDialogProps(dialogProps);
        }
    };

    useEffect(() => {
        initAsync();
    }, []);

    useEffect(() => {
        if (appSwitcherDialogVisible) {
            setLoading(false);
        }
    }, [appSwitcherDialogVisible]);

    return (
        <div>
            {/*  <PWAPrompt promptOnVisit={1} timesToShow={3} copyClosePrompt="Close" permanentlyHideOnDismiss={false}/> */}
            {applicationLoaded &&
                <AppContext.Provider
                    value={{
                        applicationId: applicationId,
                        refreshSiteMap: refreshSiteMap,
                        isNavbarLoaded: isNavbarLoaded,
                        isLoading: isLoading,
                        isPanelLoading: isPanelLoading,
                        currentKey: currentKey,
                        menuExtended: menuExtended,
                        errorDialogProps: errorDialogProps,
                        confirmationDialogProps: confirmationDialogProps,
                        formDialogs: formDialogs,
                        alertDialogProps: alertDialogProps,
                        environmentSelectionDialogProps: environmentSelectionDialogProps,
                        navigationVisible: navigationVisible,
                        configurationLoaded: configurationLoaded,
                        shouldRefreshMain: shouldRefreshMain,
                        globalNotifications: globalNotifications,
                        lookupPanelProps: lookupPanelProps,
                        setNavigationVisibility: setNavigationVisibility,
                        setAlertDialogProps: setAlertDialogProps,
                        setEnvironmentSelectionDialogProps: setEnvironmentSelectionDialogProps,
                        setConfirmationDialogProps: setConfirmationDialogProps,
                        openFormDialog: openFormDialog,
                        closeFormDialog: closeFormDialog,
                        setErrorDialogProps: setErrorDialogProps,
                        SetLoading: setLoading,
                        setNavigationPromptDialogProps: setNavigationPromptDialogProps,
                        SetPanelLoading: setPanelLoading,
                        setCurrentKey: setCurrentKey,
                        setMenuExtended: setMenuExtended,
                        setIsNavbarLoaded: setIsNavbarLoaded,
                        setShouldRefreshMain: setShouldRefreshMain,
                        addGlobalNotification: addGlobalNotification,
                        clearGlobalNotification: clearGlobalNotification,
                        showProgressIndicator: (message: string) => {
                            setProgressIndicatorMessage(message);
                        },
                        closeProgressIndicator: () => {
                            setProgressIndicatorMessage(null);
                        },
                        setSitemapItemDisabled: (id: string, disabled: boolean, message?: string) => {
                            const siteMap = SitemapDefinition.getCurrentSiteMap();
                            if (disabled) {
                                siteMap.disableSiteMapSubArea(id, message);
                            }
                            else {
                                siteMap.enableSiteMapSubArea(id);
                            }
                            setRefreshSiteMap(!refreshSiteMap);
                        },
                        setSitemapItemVisible: (id: string, visible: boolean) => {
                            SitemapDefinition.getCurrentSiteMap().toggleSiteMapSubAreaVisibility(id, visible);
                            setRefreshSiteMap(!refreshSiteMap);
                        },
                        setLookupPanelProps: setLookupPanelProps

                    }}>
                    {props.children}
                </AppContext.Provider>
            }
            <ErrorDialog {...errorDialogProps} />
            <EnvironmentSelectionDialog {...environmentSelectionDialogProps} />
            {isLoading &&
                <MainLoading />
            }
            {progressIndicatorMessage !== null &&
                <Overlay styles={{
                    root: {
                        background: '#808080ad',
                        top: '0',
                        bottom: '0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        left: '0',
                        verticalAlign: 'middle',
                        position: 'fixed',
                        right: '0',
                        margin: 0,
                        textAlign: 'center',
                        zIndex: 1000010
                    }
                }}>
                    <div style={{ opacity: 1 }}>
                        <Loading styles={{ circle: { width: 50, height: 50, borderColor: 'rgb(19 153 255) rgba(255, 255, 255) rgba(255, 255, 255) !important' }, root: { marginBottom: 20 } }} />
                        <Text styles={{ root: { fontWeight: 'bold', color: 'white' } }}>{progressIndicatorMessage}</Text>
                    </div>
                </Overlay>
            }
            <NavigationPromptDialog {...navigationPromptDialogProps} />
            {appSwitcherDialogVisible &&
                <AppSwitcherDialog />
            }
            {showDevelopmentModeButton &&
                <DevKit connectedEnvironmentDetails={connectedEnvironmentDetails} />
            }
        </div>
    );
};
export default AppProvider;