import React, { useEffect, useState } from 'react';
import { IItemColumnProps } from './interfaces';
import { ICommandBarProps, Link } from '@fluentui/react';
import Loading from '../../../../../loadings/Loading';
import { Text } from '@fluentui/react/lib/Text';
import { CommandBarWrapper } from './components/CommandBarWrapper';
import { QueryData } from '@pages/Control/interfaces';
import { AppModule } from '@configuration/AppModule';
import { ribbonButtonsColumnName } from '@app/Constants';
import { DataType } from '@ComponentFramework/interfaces/DataType';

enum FieldType {
    Text = 'Text',
    Lookup = 'Lookup',
    Primary = 'Primary',
}

const getFieldType = (entityName: string, entityId: string, primaryName: string, fieldName: string): FieldType => {
    if (entityName && entityId) {
        return FieldType.Lookup;
    }
    else if (primaryName === fieldName) {
        return FieldType.Primary;
    }
    return FieldType.Text;
};

export const ItemColumn: React.FC<IItemColumnProps> = (props) => {
    const getFormattedValue = () => {
        const result = props.item.record.getFormattedValue(props.column.fieldName);
        if (typeof result === 'string') {
            return result;
        }
        return undefined;
    };
    const isRibbonButtonsColumn = props.column.key === ribbonButtonsColumnName;
    const [ribbonCommandBarProps, setRibbonCommandBarProps] = useState<ICommandBarProps>(null);
    const value = props.item.record.getValue(props.column.fieldName);
    const formattedValue = getFormattedValue();
    const lookupEntityName = (value as ComponentFramework.LookupValue)?.entityType;
    const lookupEntityId = (value as ComponentFramework.LookupValue)?.id;
    const fieldType = getFieldType(lookupEntityName, lookupEntityId, props.primaryName, props.column.fieldName);

    const isNumberColumn = (): boolean => {
        switch (props.column.dataType) {
            case DataType.Currency:
            case DataType.WholeNone:
            case DataType.Decimal:
                return true;
        }
        return false;
    };

    const isDateColumn = (): boolean => {
        switch (props.column.dataType) {
            case DataType.DateAndTimeDateAndTime:
            case DataType.DateAndTimeDateOnly:
                return true;
        }
        return false;
    };

    const getLinkURL = () => {
        let query: QueryData = {};
        if (fieldType === FieldType.Lookup) {
            query.entityName = lookupEntityName;
            query.entityId = lookupEntityId;
        }
        else {
            query.entityName = props.item.record.getNamedReference().etn;
            query.entityId = props.item.record.getRecordId();
        }
        return `/${AppModule.get().uniquename}/control/form?data=${JSON.stringify(query)}`;

    };

    useEffect(() => {
        const initAsync = async () => {
            const ribbonCommandBarProps = await props.ribbon?.getRibbonCommandBarProps(props.formContext, [props.item.record], {
                refresh: props.refresh,
                getFetchXml: () => { throw new Error("getFetchXml call is unsupported here!"); },
                getViewColumns: () => { throw new Error("getViewColumns call is unsupported here!"); },
                getViewSelector: () => { throw new Error("getViewSelector call is unsupported here!"); },
                getGridType: () => { throw new Error("getGridType call is unsupported here!"); },
                getRelationship: () => { throw new Error("getRelationship call is unsupported here!"); },
            }, true);
            setRibbonCommandBarProps(ribbonCommandBarProps);
        };

        if (isRibbonButtonsColumn) {
            initAsync();
        }
    }, [props.ribbon, props.item]);

    if (isRibbonButtonsColumn) {
        if (!ribbonCommandBarProps && props.ribbon) {
            return <Loading />;
        };
        return (
            <>
                {ribbonCommandBarProps &&
                    <CommandBarWrapper
                        {...ribbonCommandBarProps}
                        items={[]}
                        farItems={ribbonCommandBarProps.items}
                        className={`${ribbonCommandBarProps.className} TALXIS__command-bar--view-item`} />
                }
            </>
        );
    }
    //case of lookup or primary name field => render link
    else if ((fieldType === FieldType.Lookup || fieldType === FieldType.Primary) && props.onOpenDatasetItem) {
        return (
            <Link
                title={formattedValue}
                href={getLinkURL()}
                style={{
                    fontSize: 14
                }}
                onClick={(e) => {
                    e.preventDefault();
                    if (fieldType === FieldType.Lookup) {
                        props.onOpenDatasetItem({
                            etn: lookupEntityName,
                            name: '',
                            id: {
                                guid: lookupEntityId
                            }
                        });
                    }
                    else {
                        props.onOpenDatasetItem({
                            etn: props.item.record.getNamedReference().etn,
                            name: props.item.record.getNamedReference().name,
                            id: {
                                guid: props.item.record.getRecordId()
                            }
                        });
                    }
                }}>
                {formattedValue}
            </Link>
        );
    }
    else {
        //Props are unaware of data-* attributes
        const additionalProps: any = {};
        if (isNumberColumn()) {
            additionalProps['data-type-number'] = "true";
            if (Number(formattedValue) > 0) {
                additionalProps['data-gt-zero'] = "true";
            }
        }
        else if (isDateColumn()) {
            if (new Date(Date.parse(formattedValue)) < new Date()) {
                additionalProps['data-lt-today'] = "true";
            }
            else {
                const date = new Date();
                date.setDate(date.getDate() + 60);
                if (new Date(Date.parse(formattedValue)) < date) {
                    additionalProps['data-lt-twomonths'] = "true";
                }
            }
        }
        return (
            <Text {...additionalProps} title={formattedValue}>{formattedValue}</Text>
        );
    }
};