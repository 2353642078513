import React, { useState } from 'react';
import { SpaConfiguration } from '@configuration/SpaConfiguration';
import { MultitenantProvider } from '@app/classes/MultitenantProvider';
import { buildMetadataUrl } from '@definitions/MetadataApi';

export const Admin: React.FC = () => {
    const [metadataClearEnabled, setMetadataClearEnabled] = useState(true);
    const [apiClearEnabled, setApiClearEnabled] = useState(true);

    const clearMetadataCache = async () => {
        setMetadataClearEnabled(false);

        await SpaConfiguration.loadAsync();
        var response = await fetch(buildMetadataUrl("v9.1/ClearCache"), {
            method: 'POST',
            headers: {
                ...MultitenantProvider.getFetchHeader().headers
            }
        });

        var responseJson = await response.text();

        alert(`Metadata cache cleared. Response: ${responseJson}`);

        setMetadataClearEnabled(true);
    };

    const clearApiCache = async () => {
        setApiClearEnabled(false);

        await SpaConfiguration.loadAsync();
        var configuration = SpaConfiguration.get();
        var response = await fetch(`${configuration.edsApi}/Portal/ClearCache`, {
            method: 'POST',
            headers: {
                ...MultitenantProvider.getFetchHeader().headers
            }
        });

        var responseJson = await response.text();

        alert(`API cache cleared. Response: ${responseJson}`);

        setApiClearEnabled(true);
    };

    return (
        <div>
            <p>Read documentation before doing any of this!</p>
            <button onClick={clearMetadataCache} disabled={!metadataClearEnabled}>Clear metadata cache</button>
            <button onClick={clearApiCache} disabled={!apiClearEnabled}>Clear API cache</button>
        </div>
    );
};