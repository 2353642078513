import { Guid } from "guid-typescript";
import { FormDefinition, FormType } from '@definitions/FormDefinition';
import { Form } from "@controls/native/Form/interfaces/form";
import { FormControlType } from "@controls/native/Form/interfaces/enums";
import { ScriptLoader } from "@loaders/ScriptLoader";
import { ControlLoader } from "@loaders/ControlLoader";
import { LocalizeLabel, ILocalizedLabel } from "@localization/helpers";
import { EntityDefinition } from "@definitions/EntityDefinition";
import { sanitizeGuid } from "../../Functions";

export class FormMapper {
    private _recordId: string;
    private _entityName: string;
    private _controlDescriptions: Form.ControlDescription[] = [];
    private _formId?: string;
    private _formUniqueName?: string;
    private _labels: { [key: string]: ILocalizedLabel[] };

    constructor(entityName: string, recordId: string, formId?: string, formUniqueName?: string) {
        this._recordId = recordId;
        this._entityName = entityName;
        this._formId = formId;
        this._formUniqueName = formUniqueName;
    }
    public getForm = async (): Promise<Form.Root> => {
        const formDefinition = await FormDefinition.getAsync(this._entityName, this._formId, this._formUniqueName);
        this._labels = formDefinition.Labels;

        this._controlDescriptions = this._parseControlDescriptions(formDefinition.FormXml.getElementsByTagName('controlDescription'));

        const isTitleElement = formDefinition.FormXml.getElementsByTagName('IsTitle')?.[0];
        const formHeader = isTitleElement?.parentElement?.parentElement?.parentElement;
        const formHeaderId = formHeader?.getAttribute('id');
        const formEvents = await this._parseEvents(formDefinition.FormXml.getElementsByTagName('event'));
        const form: Form.Root = {
            id: formDefinition.Id, // We may not know form ID from constructor
            formXml: formDefinition.FormXml,
            header: await this._parseHeader(formDefinition.FormXml.querySelector('header'), formEvents),
            tabs: await this._parseTabs(formDefinition.FormXml.getElementsByTagName('tab'), formEvents, formDefinition.Type),
            events: formEvents,
            title: (formDefinition.Type === FormType.Dialog) ? this._getLabel((isTitleElement?.innerHTML === "true") ? formHeader : null, this._labels[sanitizeGuid(formHeaderId)]) : null
        };
        return form;
    }

    private _parseHeader = async (headerElement: Element, formEvents: Form.Event[]): Promise<Form.Header> => {
        if (!headerElement) {
            return null;
        }
        return {
            id: headerElement.getAttribute('id'),
            rows: await this._parseRows(headerElement.getElementsByTagName('row'), formEvents)
        };
    }

    private _parseEvents = async (eventCollection: HTMLCollectionOf<Element>): Promise<Form.Event[]> => {
        const events: Form.Event[] = [];

        for (const event of eventCollection) {
            events.push({
                name: event.getAttribute("name"),
                handlers: await this._parseEventHandlers(event.getElementsByTagName("Handler")),
                attribute: event.getAttribute("attribute"),
                control: event.getAttribute("control")
            });
        }

        return events;
    }

    private _parseEventHandlers = async (handlerCollection: HTMLCollectionOf<Element>): Promise<Form.Handler[]> => {
        const handlers: Form.Handler[] = [];

        for (const handler of handlerCollection) {
            const libraryName = handler.getAttribute("libraryName");

            handlers.push({
                functionName: handler.getAttribute("functionName"),
                libraryName: libraryName,
                handlerUniqueId: handler.getAttribute("handlerUniqueId"),
                enabled: handler.getAttribute("enabled") === "true" ? true : false,
                parameters: handler.getAttribute("parameters"),
                passExecutionContext: handler.getAttribute("passExecutionContext") === "true" ? true : false
            });

            const webResource = await ScriptLoader.loadWebResourceLibraryAndDependencies(libraryName);
        }

        return handlers;
    }

    private _parseControlDescriptions = (controlDescriptionsCollection: HTMLCollectionOf<Element>): Form.ControlDescription[] => {
        const controlDescriptions: Form.ControlDescription[] = [];

        for (const controlDescription of controlDescriptionsCollection) {
            controlDescriptions.push({
                forControl: controlDescription.getAttribute('forControl'),
                customControls: this._parseCustomControls(controlDescription.querySelectorAll('customControl'))
            });
        }

        return controlDescriptions;
    }

    private _parseTabs = async (tabsCollection: HTMLCollectionOf<Element>, formEvents: Form.Event[], formType: FormType): Promise<Form.Tab[]> => {
        const tabs: Form.Tab[] = [];
        for (const tab of tabsCollection) {
            const matchingLabels = this._labels[sanitizeGuid(tab.getAttribute("labelid") ?? tab.getAttribute("id"))];
            let label = this._getLabel(tab, matchingLabels);
            if (label === null) label = '';

            const labelMatches = [...label.matchAll(/#(.*)#(.*)/g)];
            const labelMatchResult = labelMatches.length === 1 ? labelMatches[0] : [];
            const iconName = labelMatchResult.length > 2 ? labelMatchResult[1] : null;
            label = labelMatchResult.length > 2 ? labelMatchResult[2] : label;

            tabs.push({
                id: tab.getAttribute('id'),
                name: tab.getAttribute('name'),
                showLabel: this._showLabel(tab),
                label: label,
                columns: await this._parseColumns(tab.getElementsByTagName('column'), formEvents),
                iconName: iconName,
                visible: this._visible(tab),
                availableForPhone: this._availableForPhone(tab),
                tabFooter: (formType === FormType.Dialog) ? (await this._parseSections(tab.getElementsByTagName('tabfooter'), formEvents))?.[0] : null
            });
        }
        return tabs;
    }
    private _parseCustomControls = (customControlsCollection: NodeListOf<Element>) => {
        const customControls: Form.CustomControl[] = [];

        for (const customControl of customControlsCollection) {
            customControls.push({
                name: customControl.getAttribute('name'),
                bindingParameters: this._parseCustomControlBindings(customControl.getAttribute('name'), customControl.getElementsByTagName('parameters')[0]),
                formFactor: customControl.getAttribute('formFactor')
            });
        }
        return customControls;
    }

    private _parseCustomControlBindings = (controlName: string, parametersElement: Element): Form.ControlBindings => {
        const bindings: Form.ControlBindings = {};
        if (parametersElement) {
            for (const parameter of parametersElement.children) {
                const isStatic = parameter.getAttribute("static") === "true" ? true : false;
                let value = isStatic ? parameter.textContent : parameter.innerHTML;
                if (parameter.hasChildNodes() && parameter.getElementsByTagName("BindAttribute")?.[0]) {
                    value = parameter.getElementsByTagName("BindAttribute")?.[0]?.textContent;;
                }
                bindings[parameter.tagName] = {
                    isStatic: isStatic,
                    type: parameter.getAttribute("type"),
                    value: value,
                    extraParameters: parameter.hasChildNodes() ? parameter.innerHTML : undefined
                };
            }
        }

        return bindings;
    }

    private _parseColumns = async (columnsCollection: HTMLCollectionOf<Element>, formEvents: Form.Event[]): Promise<Form.Column[]> => {
        const columns: Form.Column[] = [];
        for (const column of columnsCollection) {
            columns.push({
                // Columns don't appear to have an ID defined
                id: Guid.create().toString(),
                width: column.getAttribute('width'),
                sections: await this._parseSections(column.getElementsByTagName('section'), formEvents)
            });
        }
        return columns;
    }

    private _parseSections = async (sectionsCollection: HTMLCollectionOf<Element>, formEvents: Form.Event[]): Promise<Form.Section[]> => {
        const sections: Form.Section[] = [];
        for (const section of sectionsCollection) {
            const matchingLabels = this._labels[sanitizeGuid(section.getAttribute("labelid") ?? section.getAttribute("id"))];
            sections.push({
                id: section.getAttribute("id"),
                showLabel: this._showLabel(section),
                numOfColumns: section.getAttribute("columns")?.length || 1,
                label: this._getLabel(section, matchingLabels),
                rows: await this._parseRows(section.getElementsByTagName('row'), formEvents),
                visible: this._visible(section),
                availableForPhone: this._availableForPhone(section),
                name: section.getAttribute("name")
            });
        }
        return sections;
    }

    private _parseRows = async (rowsCollection: HTMLCollectionOf<Element>, formEvents: Form.Event[]): Promise<Form.Row[]> => {
        const rows: Form.Row[] = [];
        for (const formRow of rowsCollection) {
            const row: Form.Row = {
                // Rows don't appear to have an ID defined
                id: Guid.create().toString(),
                cells: []
            };

            for (const cell of formRow.getElementsByTagName('cell')) {
                row.cells.push(await this._parseCell(cell, formEvents));
            }

            if (row.cells.length > 0) {
                rows.push(row);
            }
        }
        return rows;
    }

    private _parseCell = async (cellElement?: Element, formEvents?: Form.Event[]): Promise<Form.Cell> => {
        if (!cellElement) {
            return null;
        }
        const _control = cellElement.getElementsByTagName('control')[0];
        let matchingLabels = this._labels[sanitizeGuid(cellElement.getAttribute("labelid") ?? cellElement.getAttribute("id"))];
        if (!matchingLabels && this._entityName) {
            const entityDefinition = await EntityDefinition.getAsync(this._entityName);
            matchingLabels = entityDefinition.Attributes.find(x => x.LogicalName === _control?.getAttribute("datafieldname"))?.DisplayName?.LocalizedLabels;
        }
        const cell: Form.Cell = {
            id: cellElement.getAttribute("id"),
            showLabel: this._showLabel(cellElement),
            label: this._getLabel(cellElement, matchingLabels),
            colspan: cellElement.getAttribute("colspan"),
            rowspan: cellElement.getAttribute("rowspan"),
            control: await this._parseControl(_control, formEvents, this._visible(cellElement)),
            visible: this._visible(cellElement),
            availableForPhone: this._availableForPhone(cellElement)
        };
        return cell;
    }

    private _visible = (element: Element): boolean => {
        const _visible = element.getAttribute('visible');
        const _userspacer = element.getAttribute('userspacer');
        if (_visible === 'false' || _userspacer === 'true') {
            return false;
        }
        return true;
    }
    private _availableForPhone = (element: Element): boolean => {
        const _afp = element.getAttribute('availableforphone');
        if (_afp === 'false') {
            return false;
        }
        return true;
    }

    private _parseControl = async (controlElement: Element, formEvents: Form.Event[], visible: boolean = true): Promise<Form.Control> => {
        if (!controlElement) {
            return null;
        }
        const controlId = controlElement.getAttribute('id');
        const controlUniqueId = controlElement.getAttribute('uniqueid');
        let controlName = this._getControlName(controlElement);
        let controlType: FormControlType = FormControlType.Field;
        const indicationOfSubgrid = controlElement.getAttribute('indicationOfSubgrid');
        if (indicationOfSubgrid === "true") {
            controlType = FormControlType.DataSet;
        }
        const controlNameUpper = controlName.toUpperCase();
        // Full map reference: https://dev.azure.com/thenetworg/INT0015/_wiki/wikis/INT0015.wiki/4083/Control-Map
        // Control with type customcontrol (classid = f9a8a302-114e-466a-b582-6771b2ae0d92) is resolved with _getControlName above
        if (controlNameUpper === "{4273EDBD-AC1D-40D3-9FB2-095C621B552D}" || // SingleLine.Text
            controlNameUpper === "{C6D124CA-7EDA-4A60-AEA9-7FB8D318B68F}" || // Number
            controlNameUpper === "{0D2C745A-E5A8-4C8F-BA63-C6D3BB604660}" || // Floating Point Number
            controlNameUpper === "{ADA2203E-B4CD-49BE-9DDF-234642B43B52}" || // EmailAddressControl
            false) {
            controlName = "TALXIS.PCF.Portal.TextField";
        }
        else if (controlNameUpper === "{E0DECE4B-6FC8-4A8F-A065-082708572369}") { // MultipleLinesOfText
            controlName = "TALXIS.PCF.Portal.MultilineText";
        }
        else if (controlNameUpper === "{5B773807-9FB2-42DB-97C3-7A91EFF8ADFF}") { // DateTime
            controlName = "TALXIS.PCF.Portal.DateTime";
        }
        else if (controlNameUpper === "{C3EFE0C3-0EC6-42BE-8349-CBD9079DFD8E}") { // Decimal
            controlName = "TALXIS.PCF.Portal.Decimal";
        }
        else if (controlNameUpper === "{533B9E00-756B-4312-95A0-DC888637AC78}") { // Currency
            controlName = "TALXIS.PCF.Portal.Currency";
        }
        else if (controlNameUpper == "{B0C6723A-8503-4FD7-BB28-C8A06AC933C2}" || // Boolean
            controlNameUpper === "{67FAC785-CD58-4F9F-ABB3-4B7DDC6ED5ED}"  // TwoOptions
        ) {
            controlName = "TALXIS.PCF.Portal.TwoOptions";
        }
        else if (controlNameUpper === "{270BD3DB-D9AF-4782-9025-509E298DEC0A}" || // Lookup 
            controlNameUpper === "{CBFB742C-14E7-4A17-96BB-1A13F7F64AA2}" || // PartyList
            controlNameUpper === "{F3015350-44A2-4AA0-97B5-00166532B5E9}" // Regarding
        ) {
            controlName = "TALXIS.PCF.Portal.SimpleLookup";
        }
        else if (controlNameUpper === "{E7A81278-8635-4D9E-8D4D-59480B391C5B}") { // View
            controlName = "TALXIS.PCF.Portal.View";
            controlType = FormControlType.DataSet;
        }
        // This handles an edge case caused by solution import bug: https://dev.azure.com/thenetworg/INT0015/_workitems/edit/22596/
        else if (controlNameUpper === "{F9A8A302-114E-466A-B582-6771B2AE0D92}" && controlType === FormControlType.DataSet &&
            controlNameUpper === controlElement.getAttribute('classid').toUpperCase()
        ) {
            controlName = "TALXIS.PCF.Portal.View";
        }
        else if (controlNameUpper === "{3EF39988-22BB-4F0B-BBBE-64B5A3748AEE}") { // Option Set
            controlName = "TALXIS.PCF.Portal.OptionSet";
        }
        else if (controlNameUpper === "{4AA28AB7-9C13-4F57-A73D-AD894D048B5F}") { // MultiSelectOptionSet
            controlName = "TALXIS.PCF.Portal.MultiSelectOptionSet";
        }
        else if (controlNameUpper === "{39354E4A-5015-4D74-8031-EA9EB73A1322}") { // Label
            controlName = "TALXIS.PCF.Portal.Label";
        }
        else if (controlNameUpper === "{00AD73DA-BD4D-49C6-88A8-2F4F4CAD4A20}") { // Dialog Button
            controlName = "TALXIS.PCF.Portal.Button";
            controlType = FormControlType.Button;
        }
        else if (controlNameUpper === "{AA987274-CE4E-4271-A803-66164311A958}") { // Duration
            controlName = "TALXIS.PCF.Portal.Duration";
        }
        else if (controlNameUpper === "MSCRMCONTROLS.MODELFORM.MODELFORMCONTROL") {
            controlName = "TALXIS.PCF.Portal.Form";
        }
        // Unsupported controls
        else if (controlNameUpper === "{7C624A0B-F59E-493D-9583-638D34759266}" || // Timezone
            controlNameUpper === "{06375649-C143-495E-A496-C962E5B4488E}" || // Notes
            controlNameUpper === "{671A9387-CA5A-4D1E-8AB7-06E39DDCF6B5}" || // Language
            (controlNameUpper.startsWith("{") && controlNameUpper.endsWith("}")) || // All other unmatched native controls
            false
        ) {
            console.warn(`Found unsupported control! Name: ${controlName}, datafieldname: ${controlElement.getAttribute('datafieldname')}`);
            controlName = null;
        }

        let dataFieldName: string;
        switch (controlType) {
            case FormControlType.Field:
                dataFieldName = controlElement.getAttribute('datafieldname');
                if (!dataFieldName) {
                    // When we are in dialog, we treat `id` field as `datafieldname` for default value output
                    dataFieldName = controlElement.getAttribute('id');
                }
                break;
        }

        const isUnbound = controlElement.getAttribute('isunbound') === "true";
        const isRequired = controlElement.getAttribute('isrequired') === "true";
        const control: Form.Control = {
            name: controlName,
            type: controlType,
            classId: controlElement.getAttribute('classid'),
            id: controlElement.getAttribute('id'),
            disabled: controlElement.getAttribute('disabled') === 'true' ? true : false,
            // Visible comes from cell since it is primarily manipulated on the control level
            visible: visible,
            isRequired: isRequired,
            isUnbound: isUnbound,
            datafieldname: dataFieldName,
            bindings: this._parseControlBindings(dataFieldName, controlElement.getElementsByTagName('parameters')[0], controlName, controlUniqueId, controlId, isUnbound, isRequired, controlNameUpper),
            onClickEventHandlers: formEvents.filter(x => x.attribute === controlElement.getAttribute('id') && x.name === "onclick")?.[0]?.handlers,
            // Fix rendering for unsupported control types
            definition: controlName !== null ? await ControlLoader.getAsync(controlName) : null
        };

        return control;
    }

    private _parseControlBindings = (datafieldname: string, parametersElement: Element, controlName: string, uniqueId: string, controlId: string, isUnbound: boolean, isRequired: boolean, classId: string): Form.ControlBindings => {
        const controlDescriptions = this._controlDescriptions.find(x => x.forControl === uniqueId)?.customControls?.filter(x => x.formFactor === "0" || x.formFactor === "1");
        let controlDescriptionBindings: Form.ControlBindings = {};
        for (const controlDescription of controlDescriptions ?? []) {
            // TODO: Add isRequired true to first bound field
            controlDescriptionBindings = { ...controlDescriptionBindings, ...controlDescription.bindingParameters };
        }

        // TODO: Primary (binding) field is the first field set to "bound" in PCF manifest, which we don't know here yet, so we just use the first non-static field
        const primaryBoundField = Object.entries(controlDescriptionBindings).find(x => x[1].isStatic === false);
        if (primaryBoundField) {
            controlDescriptionBindings[primaryBoundField[0]].isRequired = isRequired;
        }

        const formControlInlineBindings: Form.ControlBindings = {};
        if (parametersElement) {
            for (const parameter of parametersElement.children) {
                formControlInlineBindings[parameter.tagName] = {
                    isStatic: true,
                    value: parameter.hasChildNodes() ? parameter.innerHTML : parameter.textContent,
                };
            }
        }

        // This is fallback for native controls which have no bindings explicitly specified in the form
        if (Object.entries({ ...controlDescriptionBindings, ...formControlInlineBindings }).length === 0 ||
            // Handles unbound controls like those on Dialogs, especially OptionSet
            (isUnbound && !Object.keys({ ...controlDescriptionBindings, ...formControlInlineBindings }).includes("value")) ||
            (!isUnbound && Object.entries({ ...controlDescriptionBindings, ...formControlInlineBindings }).find(x => x[1].isStatic === false) !== null) ||
            // Set proper lookup value when we are a bound form control for lookup to work correctly.
            (controlName === "TALXIS.PCF.Portal.Form" && Object.keys({ ...controlDescriptionBindings, ...formControlInlineBindings }).includes("value")) ||
            (controlName === "TALXIS.PCF.Portal.SimpleLookup" && !Object.keys({ ...controlDescriptionBindings, ...formControlInlineBindings }).includes("value"))
        ) {
            // TODO: This should come dynamically from manifest (which we don't have at this time yet)
            const property = "value";
            // Prevent conflict with other hard set values - primarily for Form Control
            if (controlDescriptionBindings && controlDescriptionBindings[property]) delete controlDescriptionBindings[property];
            if (datafieldname != null) {
                // If control is Party List and is not "from", set MultipleEnabled to true
                if (classId === "{CBFB742C-14E7-4A17-96BB-1A13F7F64AA2}" && datafieldname !== "from") {
                    formControlInlineBindings["MultipleEnabled"] = {
                        isStatic: true,
                        value: "true",
                        isRequired: isRequired
                    };
                }
                formControlInlineBindings[property] = {
                    isStatic: false,
                    value: datafieldname,
                    isRequired: isRequired
                };
            }
            else {
                // In dialogs controls don't have datafieldname specified but use their ID as a virtual attribute on the form
                formControlInlineBindings[property] = {
                    isStatic: false,
                    value: controlId,
                    isRequired: isRequired
                };
            }
        }

        return { ...controlDescriptionBindings, ...formControlInlineBindings };
    }

    private _getLabel = (element: Element, localizedLabels?: ILocalizedLabel[]): string => {
        if (!element || !this._showLabel(element)) {
            // TODO: This should be handled on the future PCF label level
            return null;
        }

        if (localizedLabels && localizedLabels.length > 0) {
            return LocalizeLabel(localizedLabels);
        }

        const labelsWrap = element.getElementsByTagName('labels')[0];
        if (!labelsWrap) {
            throw new Error('No labels were set for this object. Either add them or set showlabel parameter to false.');
        }

        const labels: ILocalizedLabel[] = [];
        for (const label of labelsWrap.getElementsByTagName('label')) {
            labels.push({
                Label: label.getAttribute('description'),
                LanguageCode: parseInt(label.getAttribute('languagecode'))
            });
        }

        if (labels.length === 0) {
            throw new Error('No labels were set for this object. Either add them or set showlabel parameter to false.');
        }

        return LocalizeLabel(labels) ?? "Unknown";
    }

    private _getControlName = (element: Element): string => {
        let name;
        const uniqueId = element.getAttribute('uniqueid');
        if (uniqueId) {
            const controlDescription = this._controlDescriptions.filter(x => x.forControl === uniqueId)[0];
            if (controlDescription) {
                name = controlDescription.customControls.filter(customControl => customControl.name != undefined)[0]?.name;
                if (name) {
                    const parts = name.split("_");
                    if (parts[1]) {
                        name = parts[1];
                    }
                }
            }
        }
        if (!name) {
            name = element.getAttribute('classid');
        }
        return name;
    }

    private _showLabel = (element: Element): boolean => {
        const _showLabel = element.getAttribute('showlabel');
        if (_showLabel === 'false') {
            return false;
        }
        return true;
    }
    private _decode = (escapedStr: string): string => {
        const txt = document.createElement("textarea");
        txt.innerHTML = escapedStr;
        return txt.value;
    }
}