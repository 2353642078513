/// <reference types="@types/wicg-file-system-access" />

import { IAppContext } from "@providers/AppProvider/interfaces";
import { ControlLoader } from "@loaders/ControlLoader";
import { WebApi as PcfWebApi } from "@ComponentFramework/PropertyClasses/WebApi";
import { WebApi as XrmWebApi } from "../Xrm/WebApi";
import { Navigation } from "@ComponentFramework/PropertyClasses/Navigation";
import { Authentication } from "./classes/Authentication";
import { EntityDefinition } from "@definitions/EntityDefinition";
import { AppModule } from "@configuration/AppModule";
import { getTheme } from "@fluentui/react";
import { Guid } from "guid-typescript";
import { UserSettings } from "./classes/UserSettings";
import * as lcid from "lcid";

import TranslationCz from '@localization/czech.1029.json';
import TranslationEn from '@localization/english.1033.json';
import { DEFAULT_LANGUAGE } from "@localization/helpers";
import { GetEntityMetadataResponse } from "../Xrm/GetEntityMetadataResponse";
import * as queryString from 'query-string';
import { QueryData } from "@pages/Control/interfaces";
import { IEntityFormPageContext } from "./interfaces/entityformpagecontext";
import { history } from '@providers/AppProvider';
import { INavigationOptions } from "@talxis/client-libraries/dist/interfaces/INavigationOptions";
import { IXrmControlPageContext } from "../Xrm/interfaces/IXrmControlPageContext";
import { IXrmPageContext } from "../Xrm/interfaces/IXrmPageContext";
import { useEffect, useRef } from "react";
import { RingProvider } from "./classes/RingProvider";
import { OptionSetDefinition } from "@definitions/OptionSetDefinition";
import { Utility } from "@src/ComponentFramework/Implementation";
import { getWebResourceUrl } from "./classes/definitions/MetadataApi";
import { IInlineDialogPageContext } from "./interfaces/inlinedialogpagecontext";
import { IXrmInlineDialogContext } from "@src/Xrm/interfaces/IXrmInlineDialogContext";

export const changeTheme = (colorHex: string, darkThemeEnabled: boolean) => {
    window.location.reload();
};

export function usePreviousValue<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const getContext = (): IAppContext => {
    return window.TALXIS.Portal.Context;
};

export const getClient = (): Xrm.Client => {
    try {
        // TODO: Validate this on Mobile, Web and Desktop Outlook
        // Office Add-ins have window.name set to something like: {"baseFrameName":"<random>","hostInfo":"Outlook|Web|16.01|en-US|07dbdb1a-2816-9216-8624-9dcbce3a7c19|||16","xdmInfo":"<random>|<random>|https://outlook.office.com","serializerVersion":1,"flights":"[]"}
        const name = JSON.parse(window.name);
        if ((name["hostInfo"] as string)?.includes("Outlook")) {
            return "Outlook";
        }
    } catch (e) {
        console.warn("Failed to parse window.name to determine client!", window.name);
    }
    return "Web";
};

export const convertToArray = <T extends unknown>(object: T | T[] | undefined): T[] => {
    if (object !== undefined) {
        if (!Array.isArray(object)) {
            let array: T[] = [];
            array.push(object);
            return array;
        }
        else {
            return object;
        }
    }
    else {
        return [];
    }
};

export const getEmptyProperties = (obj: any): string[] | null => {
    let errors: string[] = [];
    for (var key in obj) {
        if (!obj[key]) {
            errors.push(key);
        }
    }
    if (errors.length > 0) {
        return errors;
    }
    return null;
};

export const loadStyles = (url: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const link: HTMLLinkElement = document.createElement("link");
        link.rel = "stylesheet";
        link.href = url;
        link.onload = () => {
            resolve(true);
        };
        link.onerror = () => {
            reject(false);
        };
        document.head.appendChild(link);
    });
};

let _pageType: string = undefined;
export const setPageType = (pageType: string) => {
    _pageType = pageType;
};

const getPageContext = (): IXrmPageContext => {
    const controlName = _pageType;
    const paramsData = queryString.parse(window.location.search.substring(1))?.data as string;
    const data: QueryData = (paramsData) ? JSON.parse(paramsData) : null;
    if (controlName === 'form') {
        let extraQs: { [key: string]: string } = undefined;
        if (data?.extraqs) {
            extraQs = {};
            for (const [key, value] of Object.entries(queryString.parse(data?.extraqs))) {
                if (Array.isArray(value)) {
                    extraQs[key] = value[0];
                    if (value.length > 1) {
                        console.warn(`Array parsed from extraQs: ${key}`, value);
                    }
                }
                else {
                    extraQs[key] = value;
                }
            }
        }
        const input: IEntityFormPageContext = {
            entityName: data?.entityName,
            entityId: formatXrmGuid(data?.entityId),
            pageType: "entityrecord",
            data: extraQs,
            formId: formatXrmGuid(data?.formId)
        };
        return { input: input };
    }
    else if (controlName === 'view') {
        const input: Xrm.EntityListPageContext = {
            entityName: data?.entityName,
            pageType: "entitylist",
            viewId: formatXrmGuid(data?.viewId),
            viewType: "savedquery",
        };
        return { input: input };
    }
    else if (controlName === "dialog") {
        const input: IInlineDialogPageContext = {
            pageType: "inlinedialog",
            uniqueName: data?.name
        };
        return { input: input };
    }
    else {
        const input: IXrmControlPageContext = {
            pageType: "control",
            controlName: controlName,
            data: data
        };
        return { input: input };
    }
};
export const createGlobalWindowProps = () => {
    window.TALXIS = {
        Portal: {
            Context: null,
            Theme: null,
            WebResouces: {},
            User: {
                getDisplayName: () => { return Authentication.getUser()?.displayName; },
                getUserPrincipalName: () => { return Authentication.getUser()?.userPrincipalName; },
                profileEditRedirect: () => { return Authentication.profileEditRedirect(); },
                isProfileEditRedirectAvailable: () => { return Authentication.isProfileEditRedirectAvailable(); },
                passwordChangeRedirect: () => { return Authentication.passwordChangeRedirect(); },
                logout: () => { return Authentication.logout(); },
                login: (popup) => { return Authentication.loginAsync(popup); }

            },
            App: {
                sitemap: {
                    getItem: () => {
                        throw new Error("Portal hasn't been initialized yet!");
                    }
                }
            },
            Navigation: {
                openEnvironmentSelectionDialog: () => {
                    throw new Error("Portal hasn't been initialized yet!");
                },
                navigateTo: () => {
                    throw new Error("Portal hasn't been initialized yet!");
                }
            },
            Translations: {
                _lcid: null,
                _builtinTranslations: {
                    "1029": TranslationCz,
                    "1033": TranslationEn
                },
                getLocalizedString(key: string): string {
                    let currentLcid = DEFAULT_LANGUAGE;
                    try {
                        // TODO: This should come from Xrm?.Utility?.getGlobalContext()?.userSettings?.languageId but it is not initialized yet at this point
                        currentLcid = UserSettings.getUserSettings().languageId ?? DEFAULT_LANGUAGE;
                        if (currentLcid != this._lcid) {
                            this._lcid = currentLcid;
                            console.warn(`Language set to ${currentLcid} from UserSettings.`);
                        }
                    } catch (e) {
                        // We store the LCID which didn't come from user settings for later use so we don't constantly emmit errors into the console
                        if (this._lcid == null) {
                            const browserLanguage = navigator.language.replace("-", "_");
                            currentLcid = lcid.to(browserLanguage);
                            if (!currentLcid) {
                                currentLcid = parseInt(Object.entries(lcid.all).find(x => x[0].startsWith(`${browserLanguage}_`))[1]);
                            }
                            console.warn(`Failed to load language from UserSettings (${e})! Falling back to browser: ${currentLcid} (${browserLanguage})`);

                            this._lcid = currentLcid;
                        } else {
                            currentLcid = this._lcid;
                        }
                    }
                    if (!window.TALXIS.Portal.Translations._builtinTranslations?.[currentLcid]) {
                        console.warn(`Translations not available for LCID ${currentLcid}, falling back to ${DEFAULT_LANGUAGE}`);
                        currentLcid = DEFAULT_LANGUAGE;
                    }
                    return window.TALXIS.Portal.Translations._builtinTranslations?.[currentLcid]?.[key] ?? key;
                }
            }
        },
        DevKit: {
            LocalMachineDebugging: {
                _formDefinitionRedirectionEntries: [],
                setFormDefinitionRedirection: (formId: Guid, filePath: string, formFile: FileSystemFileHandle) => {
                    throw new Error("Portal hasn't been initialized yet!");
                },
                removeFormDefinitionRedirection: (formId: Guid) => {
                    throw new Error("Portal hasn't been initialized yet!");
                },
                getRedirectedFormXml: async (formId: string) => {
                    throw new Error("Portal hasn't been initialized yet!");
                }
            }
        }
    };

    window.Xrm = {
        ///@ts-ignore - We are not implementing PromiseLike on navigateTo
        Navigation: new Navigation(),
        // TODO: We should have a global instance of PcfWebApi in the context, so we can recycle it
        ///@ts-ignore - We are not implementing PromiseLike
        WebApi: new XrmWebApi(new PcfWebApi()),
        App: {
            addGlobalNotification: () => { throw new Error("Xrm.App.addGlobalNotification was called when uninitialized!"); },
            clearGlobalNotification: () => { throw new Error("Xrm.App.clearGlobalNotification was called when uninitialized!"); },
            sidePanes: undefined
        },
        Utility: {
            // @ts-ignore - Support for returning IXrmControlPageContext
            getPageContext: () => {
                return getPageContext();
            },
            closeProgressIndicator: (): void => undefined,
            getAllowedStatusTransitions: (): PromiseLike<number[]> => undefined,
            getEntityMetadata: (): Xrm.Async.PromiseLike<Xrm.Metadata.EntityMetadata> => undefined,
            getGlobalContext: (): Xrm.GlobalContext => undefined,
            getLearningPathAttributeName: (): string => undefined,
            getResourceString: (): string => undefined,
            invokeProcessAction: (): Xrm.Async.PromiseLike<any> => undefined,
            lookupObjects: (): Xrm.Async.PromiseLike<Xrm.LookupValue[]> => undefined,
            refreshParentGrid: (): void => undefined,
            showProgressIndicator: (): void => undefined,
            alertDialog: (): void => undefined,
            confirmDialog: (): void => undefined,
            isActivityType: (): boolean => undefined,
            openQuickCreate: (): Xrm.Async.PromiseLike<Xrm.Async.OpenQuickCreateSuccessCallbackObject> => undefined,
            openEntityForm: (): void => undefined,
            openWebResource: (): Window => undefined,

        }
    };
    window.ComponentFramework = {
        registerControl: ControlLoader.registerControl
    };
};

export const updateGlobalContext = (context: IAppContext) => {
    // TODO: Extend global AppContext with user information
    window.Xrm.Utility = {
        ///@ts-ignore - We are not implementing entire Xrm.GlobalContext
        getGlobalContext: () => {
            return {
                userSettings: {
                    // TODO: This should come from AppContext
                    userId: Authentication.getUser()?.accessPrincipalId,
                    userName: Authentication.getUser()?.displayName,
                    // TODO: Pull user settings from AppContext, stored in EDS
                    languageId: UserSettings.getUserSettings()?.languageId ?? window.TALXIS.Portal.Translations._lcid,
                },
                isOnPremise: () => false,
                getCurrentAppProperties: () => {
                    const appModule = AppModule.get();
                    if (!appModule) throw Error('Could not load the AppModule!');
                    return {
                        appId: appModule.appmoduleid,
                        displayName: appModule.name,
                        uniqueName: appModule.uniquename
                    };
                },
                getCurrentAppUrl: () => {
                    const appModule = AppModule.get();
                    if (!appModule) throw Error('Could not load the AppModule!');
                    return `${window.location.origin}/${appModule.uniquename}`;
                },
                getWebResourceUrl: (webResourceName: string) => {
                    return getWebResourceUrl(`webresources/${webResourceName}`);
                },
                client: {
                    getClient: () => {
                        return getClient();
                    }
                }
            };
        },
        getResourceString: (webResourceName: string, key: string): string => {
            if (window.TALXIS.Portal.WebResouces[webResourceName] && window.TALXIS.Portal.WebResouces[webResourceName][key]) {
                return window.TALXIS.Portal.WebResouces[webResourceName][key];
            }
            else {
                return null;
            }
        },
        ///@ts-ignore - We are not implementing PromiseLike
        getEntityMetadata: async (entityName: string, attributes?: string[]): Promise<EntityDefinition> => {
            return new GetEntityMetadataResponse(await EntityDefinition.getAsync(entityName), attributes, await OptionSetDefinition.getAsync(entityName));
        },
        showProgressIndicator: (message: string) => {
            context.showProgressIndicator(message);
        },
        closeProgressIndicator: () => {
            context.closeProgressIndicator();
        },
        // @ts-ignore - Support for returning IXrmControlPageContext
        getPageContext: () => {
            return getPageContext();
        },
        ///@ts-ignore - We are not implementing PromiseLike
        lookupObjects: (lookupOptions: Xrm.LookupOptions): Promise<Xrm.LookupValue[]> => {
            return new Promise(async (resolve, reject) => {
                if (lookupOptions.defaultEntityType || lookupOptions.viewIds || lookupOptions.disableMru || lookupOptions.filters || lookupOptions.searchText || lookupOptions.showBarcodeScanner) {
                    reject('Unsuported lookup options detected. Only allowMultiSelect, defaultViewId and entityTypes are currently supported.');
                    return;
                }
                const pcfUtility = new Utility();
                //@ts-ignore - PCF types have all parameters required, but Xrm has only one required
                const result = await pcfUtility.lookupObjects(lookupOptions);
                resolve(result);
            });
        }
    };

    /// @ts-ignore - Xrm.App is not included in Xrm typings, yet exists within Xrm object: https://docs.microsoft.com/en-us/powerapps/developer/model-driven-apps/clientapi/reference/xrm-app
    window.Xrm.App = {
        ///@ts-ignore - We are not implementing PromiseLike
        addGlobalNotification: context.addGlobalNotification,
        ///@ts-ignore - We are not implementing PromiseLike
        clearGlobalNotification: context.clearGlobalNotification
    };

    window.TALXIS.Portal.Context = context;
    if (!window.TALXIS.Portal.Theme) window.TALXIS.Portal.Theme = getTheme();

    window.TALXIS.Portal.App.sitemap.getItem = (subAreaName: string) => {
        return {
            setDisabled: (disabled: boolean, disabledMessage?: string) => {
                context.setSitemapItemDisabled(subAreaName, disabled, disabledMessage);
            },
            setVisible: (visible: boolean) => {
                context.setSitemapItemVisible(subAreaName, visible);
            }
        };
    };

    window.TALXIS.Portal.Navigation.openEnvironmentSelectionDialog = () => {
        return new Promise<void>((resolve, reject) => {
            window.TALXIS.Portal.Context.setEnvironmentSelectionDialogProps({
                hidden: false
            });
        });
    };

    window.TALXIS.Portal.Navigation.navigateTo = (
        pageInput:
            | Xrm.Navigation.PageInputEntityRecord
            | Xrm.Navigation.PageInputEntityList
            | Xrm.Navigation.CustomPage
            | Xrm.Navigation.PageInputHtmlWebResource
            | Xrm.Navigation.Dashboard
            | IXrmControlPageContext
            | IXrmInlineDialogContext,
        navigationOptions?: INavigationOptions,
    ) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const appName = navigationOptions?.appIdentifier ? navigationOptions.appIdentifier : AppModule.get().uniquename;
                const url = window.location;
                localStorage.setItem('appModuleName', appName);
                switch (pageInput.pageType) {
                    case 'entitylist': {
                        const data: QueryData = {
                            entityName: pageInput.entityName,
                            viewId: pageInput.viewId,
                            viewType: pageInput.viewType
                        };
                        const composedUrl = `/${appName}/control/view?data=${JSON.stringify(data)}`;
                        history.push(composedUrl);
                        url.replace(`${url.origin}${composedUrl}`);
                        resolve(undefined);
                        return;
                    }
                    case 'entityrecord': {
                        if (navigationOptions?.appIdentifier && navigationOptions?.target !== 1) {
                            throw Error(`Unsupported navigationOptions value combination. App Name:${navigationOptions.appIdentifier} Target: ${navigationOptions.target}`);
                        }

                        if (navigationOptions?.appIdentifier) {
                            const data: QueryData = {
                                entityName: pageInput.entityName,
                                formId: pageInput.formId,
                                entityId: pageInput.entityId,
                                pageType: pageInput.pageType
                            };
                            const composedUrl = `/${appName}/control/form?data=${JSON.stringify(data)}`;
                            history.push(composedUrl);
                            url.replace(`${url.origin}${composedUrl}`);
                            resolve(undefined);
                            return;
                        } else {
                            const promise = await window.Xrm.Navigation.openForm({
                                entityName: pageInput.entityName,
                                entityId: pageInput.entityId,
                                formId: pageInput.formId,
                                createFromEntity: pageInput.createFromEntity,
                                ...(navigationOptions?.target === 2 && {
                                    useQuickCreateForm: true,
                                    //TODO: implement support for SizeValue -- need to add support for these in form dialog
                                    width: navigationOptions.width as number,
                                    windowPosition: navigationOptions.position,
                                    //TODO: support for passing of title
                                })
                            });

                            if (navigationOptions?.target === 2) {
                                resolve(promise);
                                return;
                            }

                            resolve(undefined);
                            return;
                        }
                    }
                    case "inlinedialog": {
                        const composedUrl = `/${appName}/control/dialog?data=${JSON.stringify({ name: pageInput.uniqueName })}`;
                        history.push(composedUrl);
                        url.replace(`${url.origin}${composedUrl}`);
                        resolve(undefined);
                        return;
                    }
                    case 'control': {
                        let composedUrl = `/${appName}/control/${pageInput.controlName}`;
                        if (pageInput.data) {
                            composedUrl += `?data=${JSON.stringify(pageInput.data)}`;
                        }
                        history.push(composedUrl);
                        url.replace(`${url.origin}${composedUrl}`);
                        resolve(undefined);
                        return;
                    }
                    default: {
                        //TODO: support for Custom page, HTML web resource
                        reject(`Page type ${pageInput.pageType} is not supported.`);
                    }
                }
            }
            catch (err) {
                reject(`An error ocurred during calling navigateTo \n Error: ${err}`);
            }
        });
    };

    window.TALXIS.DevKit.LocalMachineDebugging._formDefinitionRedirectionEntries = [];
    window.TALXIS.DevKit.LocalMachineDebugging.setFormDefinitionRedirection = (formId: Guid, filePath: string, formFile: FileSystemFileHandle) => {
        window.TALXIS.DevKit.LocalMachineDebugging._formDefinitionRedirectionEntries.push({ formId, filePath, formFile });
    };
    window.TALXIS.DevKit.LocalMachineDebugging.removeFormDefinitionRedirection = (formId: Guid) => {
        window.TALXIS.DevKit.LocalMachineDebugging._formDefinitionRedirectionEntries.splice(
            window.TALXIS.DevKit.LocalMachineDebugging._formDefinitionRedirectionEntries.findIndex(item => item.formId.equals(formId), 1));
    };
    window.TALXIS.DevKit.LocalMachineDebugging.getRedirectedFormXml = async (formId: string) => {
        const fileHandle = window.TALXIS.DevKit.LocalMachineDebugging._formDefinitionRedirectionEntries.find(item => item.formId.equals(Guid.parse(formId))).formFile;
        //@ts-ignore - TODO: Explore avoiding use of ts-ignore since it doesn't seem to make much sense here
        return fileHandle.text();
    };
};

// Exeucte function passed by name and await for the result (or throw an exception)
export async function executeFunctionByName(name: string, context: any, args: any[] = [], throwError = false, timeout: number = null): Promise<boolean> {
    try {
        let namespaces = name.split(".");
        let func: any = namespaces.pop();
        for (const name of namespaces) {
            context = context[name];
        }
        const result = context[func].apply(context, args);
        if (isPromise(result)) {
            let awaitedResult: any = null;
            if (timeout !== null) {
                awaitedResult = await wrapPromise(result, timeout, `Execution of ${name} had exceeded ${timeout}ms and therefore had been cancelled!`);
            }
            else {
                awaitedResult = await result;
            }
            return awaitedResult;
        }
        else {
            return result;
        }
    }
    catch (err) {
        console.error(`An error was ecountered during custom JS function ${name} execution: ${err}`);
        if (throwError) {
            throw err;
        }
    }
};

const wrapPromise = (promise: Promise<any>, delay: number, reason: string) =>
    Promise.race([promise, awaitTimeout(delay, reason)]);

const awaitTimeout = (delay: number, reason: string) => {
    return new Promise((resolve, reject) =>
        setTimeout(
            () => (reason === undefined ? resolve() : reject(reason)),
            delay
        )
    );
};

export function isPromise(p: object) {
    return p && Object.prototype.toString.call(p) === "[object Promise]";
}

export function sanitizeGuid(guid: string): string {
    return guid?.replace("{", "")?.replace("}", "");
}
export function formatXrmGuid(guid: string): string {
    if (guid && !guid.startsWith("{") && !guid.endsWith("}")) {
        return `{${guid}}`;
    }
    else {
        return guid;
    }
}
/**
 * When we are in a dialog, we don't currently have any decent way of detecting lookup attributes, this helper method attempts to detect lookup values to help us sanitize the input/output
 */
export function isLookupSoft(value?: Xrm.LookupValue[]): boolean {
    if (Array.isArray(value)) {
        if (value.length > 0) {
            if (value[0].entityType && value[0].id) {
                return true;
            }
        }
    }
    return false;
}

export const loadOfficeJs = async () => {
    const start = performance.now();
    // Required workaround because of https://github.com/OfficeDev/office-js/issues/2327
    const historyCache = {
        replaceState: window.history.replaceState,
        pushState: window.history.pushState
    };
    return new Promise<void>((resolve, reject) => {
        const officeJs = document.createElement("script");
        officeJs.src = "https://appsforoffice.microsoft.com/lib/1/hosted/office.js";
        officeJs.async = true;
        officeJs.onload = async () => {
            window.history.replaceState = historyCache.replaceState;
            window.history.pushState = historyCache.pushState;
            console.log(`Office.js loaded in ${performance.now() - start}ms`);
            const info = await Office.onReady();
            console.log(`Office.js ready`, info);
            resolve();
        };
        officeJs.onerror = (event, source, lineno, colno, error) => {
            console.error("Failed to load Office.js!", event, source, lineno, colno, error);
            reject(error);
        };
        console.log(`Loading Office.js to the DOM.`);
        document.body.appendChild(officeJs);
    });
};

export const replaceLast = (string: string, find: string, replace: string) => {
    var lastIndex = string.lastIndexOf(find);

    if (lastIndex === -1) {
        return string;
    }

    var beginString = string.substring(0, lastIndex);
    var endString = string.substring(lastIndex + find.length);

    return beginString + replace + endString;
};

export const isDev = (): boolean => {
    return (window.location.hostname === 'localhost' || window.location.hostname.endsWith('frontend.portals.talxis.com') || RingProvider.get() !== null);
};