import React, { useContext, useEffect, useRef, useState } from 'react';
import { IControlPageProps, QueryData } from './interfaces';
import { AppContext, history } from '@providers/AppProvider';
import { IAppContext } from '@providers/AppProvider/interfaces';
import * as QueryString from 'query-string';
import ViewPage from './View';
import { FormPage } from './Form';
import { SitemapDefinition } from '@definitions/SitemapDefinition';
import { MessageBar } from '@fluentui/react';
import { FieldControl } from '@controls/FieldControl';
import { FormControlType } from '@controls/native/Form/interfaces/enums';
import { IEntityFormPageContext } from '@app/interfaces/entityformpagecontext';
import { sanitizeGuid, setPageType, usePreviousValue } from '@app/Functions';
import { Authentication, requireAuthentication } from '@app/classes/Authentication';
import { IInlineDialogPageContext } from '@src/app/interfaces/inlinedialogpagecontext';
import FormDialog from '@src/components/dialogs/FormDialog';

enum PageType {
    Form = 'form',
    View = 'view',
    Dialog = 'dialog'
}

export const ControlPage: React.FC<IControlPageProps> = (props) => {
    const [queryData, setQueryData] = useState<IEntityFormPageContext | Xrm.EntityListPageContext | IInlineDialogPageContext>(null);
    const previousQueryData = usePreviousValue(queryData);
    const [controlName, setControlName] = useState<string>(() => {
        setPageType(props.match.params.controlName);
        return props.match.params.controlName;
    });
    const appContext: IAppContext = useContext(AppContext);
    const [shouldReload, setShouldReload] = useState<boolean>(false);
    const extraQsRef = useRef<QueryString.ParsedQuery<string>>(null);

    useEffect(() => {
        if (!Authentication.isAuthenticated()) {
            requireAuthentication();
            return;
        }
        appContext.SetLoading(false);
        const _controlName = props.match.params.controlName;
        setControlName(_controlName);
        setPageType(_controlName);
        const data = Xrm.Utility.getPageContext().input as (Xrm.EntityFormPageContext | Xrm.EntityListPageContext | IInlineDialogPageContext);
        if (_controlName === PageType.Form || _controlName === PageType.View || _controlName === PageType.Dialog) {
            if (!data?.pageType || (data.pageType === "entityrecord" && !data.entityName) || (data.pageType === "entitylist" && !data.entityName)) {
                //landing page - no entity selected yet
                const firstSitemapItemUrl = SitemapDefinition.getCurrentSiteMap().getDefinition().areas[0]?.groups[0]?.subAreas[0]?.url;
                if (firstSitemapItemUrl) {
                    history.push(firstSitemapItemUrl);
                }
                else {
                    throw Error("Unable to load the first sitemap item.");
                }
            }
            else {
                switch (data.pageType) {
                    case "entityrecord":
                        setQueryData({
                            entityName: data?.entityName,
                            pageType: data?.pageType,
                            formId: data?.formId,
                            entityId: sanitizeGuid(data?.entityId),
                            data: (data as IEntityFormPageContext)?.data
                        });
                        break;
                    case "entitylist":
                        setQueryData({
                            viewId: data?.viewId,
                            viewType: data?.viewType,
                            entityName: data?.entityName,
                            pageType: data?.pageType
                        });
                        break;
                    case "inlinedialog": {
                        setQueryData({
                            pageType: data.pageType,
                            uniqueName: data?.uniqueName
                        });
                        break;
                    }
                }

                extraQsRef.current = (data as IEntityFormPageContext)?.data !== null ? (data as IEntityFormPageContext).data : null;
                const key = SitemapDefinition.getCurrentPageKey();
                console.log(`URL has changed. Current page key: ${key}`);
                if (!key) return;
                appContext.setCurrentKey(key);
            }
        }
    }, [props.location.search, props.match.params?.controlName]);

    useEffect(() => {
        if (appContext.shouldRefreshMain) {
            appContext.setShouldRefreshMain(false);
        }
    }, [appContext.shouldRefreshMain]);

    useEffect(() => {
        if (appContext.isNavbarLoaded) {
            setShouldReload(true);
        }
    }, [appContext.isNavbarLoaded]);

    // We need to unmount and mount the Form component to be able to execute onload scripts
    // In future this will need a custom comparison logic with previous values to decide when to re-render.
    useEffect(() => {
        if (previousQueryData?.pageType === "entitylist" && queryData?.pageType === "entitylist" &&
            previousQueryData?.viewId !== queryData?.viewId &&
            previousQueryData.entityName === queryData.entityName &&
            previousQueryData?.viewType === queryData?.viewType
        ) {
            // Skip reload to prevent flickering of view when only viewId has changed
        }
        else {
            setShouldReload(true);
        }
    }, [queryData]);

    useEffect(() => {
        if (shouldReload) {
            setShouldReload(false);
        }
    }, [shouldReload]);

    if (!appContext.shouldRefreshMain && !shouldReload) {
        if (controlName === PageType.Form && !appContext.isLoading && queryData?.pageType === 'entityrecord' && queryData?.entityName) {
            return <FormPage
                bindings={{
                    "entityName": {
                        isStatic: true,
                        value: queryData.entityName
                    },
                    "entityId": {
                        isStatic: true,
                        value: queryData.entityId,
                    },
                    "formId": {
                        isStatic: true,
                        value: queryData.formId,
                    },
                    "extraQs": {
                        isStatic: true,
                        value: QueryString.stringify(extraQsRef.current, { skipNull: false, skipEmptyString: false }),
                    },
                    "isNavbarLoaded": {
                        isStatic: true,
                        value: appContext.isNavbarLoaded.toString()
                    },
                    //optional props, could be used to conditionally render ribbon and form picker on form page
                    "EnableFormPicker": {
                        isStatic: true,
                        value: "true"
                    },
                    "EnableFormPageRibbon": {
                        isStatic: true,
                        value: "true"
                    }
                }}
            />;
        }
        //TODO: Support for individual control pages.
        else if (controlName === PageType.View && queryData?.pageType === 'entitylist' && queryData?.entityName) {
            return <ViewPage
                viewId={queryData.viewId}
                bindings={{
                    "ViewId": {
                        value: queryData.viewId,
                        isStatic: true
                    },
                    "TargetEntityType": {
                        isStatic: true,
                        value: queryData.entityName
                    },
                    "EnableQuickFind": {
                        isStatic: true,
                        value: "true"
                    },
                    "EnableNavigation": {
                        isStatic: true,
                        value: "true"
                    },
                    "IsViewPage": {
                        isStatic: true,
                        value: "true"
                    },
                    "SelectableRows": {
                        isStatic: true,
                        value: "multiple"
                    },
                    "EnableViewPicker": {
                        isStatic: true,
                        value: "true"
                    }
                }}
            />;
        }
        else if (!appContext.isLoading && controlName === PageType.Dialog && queryData?.pageType === 'inlinedialog') {
            return <FormDialog formName={queryData.uniqueName} position={-100} />;
        }
        else if (!appContext.isLoading && controlName !== PageType.Form && controlName !== PageType.View) {
            return <FieldControl name={controlName}
                // TODO: Assemble bindings https://dev.azure.com/thenetworg/INT0015/_workitems/edit/22993/
                bindings={{}}
                childeventlisteners={[]}
                disableLoading
                id={controlName}
                classId=""
                datafieldname={null}
                disabled={false}
                type={FormControlType.Field}
                visible={true}
                isUnbound={true}
                isRequired={false}
                definition={null}
            />;
        }
        else {
            return (
                <MessageBar>
                    Unable to match a control to render content!
                </MessageBar>
            );
        }
    }
    return null;
};