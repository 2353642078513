import { CommandBarButton } from '@fluentui/react/lib/components/Button/CommandBarButton/CommandBarButton';
import React, { createRef, useEffect, useRef } from 'react';
import { Context } from '@ComponentFramework/PropertyClasses/Context';
import { IControlProps } from '@controls/interfaces';

interface IPcfNavbarWrapperProps extends IControlProps {
    instance: ComponentFramework.StandardControl<any, any>;
    onResolve: () => void;
}

export const PcfNavbarWrapper: React.FC<IPcfNavbarWrapperProps> = (props) => {
    const ref = useRef<HTMLDivElement>();

    useEffect(() => {
        (async () => {
            const context = await Context.createRibbonContext(props);
            props.instance.init(context, () => { props.onResolve(); }, null, ref.current);
        })();
    }, []);

    return (
        <div className={props.name} ref={ref}></div>
    );
};