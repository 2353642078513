import { IconButton, mergeStyles, useTheme } from "@fluentui/react";
import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { history } from "@providers/AppProvider";

let isInitialPageLoad = true;

export const BackButton: React.FC = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(isInitialPageLoad);
    const theme = useTheme();
    const className = useMemo(() => {
        return `TALXIS__navigate-back-btn ${mergeStyles({
            height: 44,
            width: 44,
            '&[data-disabled="true"]': {
                pointerEvents: 'none',
                'i': {
                    color: theme.semanticColors.inputIconDisabled
                }
            },
            borderRight: `1px solid ${theme.semanticColors.bodyDivider}`
        })}`;
    }, [isDisabled]);
    useEffect(() => {
        isInitialPageLoad = false;
        const unlisten = history.listen(() => {
            setIsDisabled(false);
        });
        return () => {
            unlisten();
        };
    }, []);
    return (
        <IconButton data-disabled={isDisabled} className={className} onClick={() => history.goBack()} iconProps={{
            iconName: 'Back',
        }} />
    );
};