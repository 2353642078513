import React from 'react';
import ReactDOM from "react-dom";
import { Multiselect, IMultiselectProps, IMultiselectItem } from './component';
import { ViewDefinition } from '@definitions/ViewDefinition';
import { ConditionOperator } from '../../DatasetControl/FetchXmlUtils';
import * as FetchXmlUtils from '../../DatasetControl/FetchXmlUtils';
import { EntityDefinition } from '@definitions/EntityDefinition';
import { Liquid } from "liquidjs";
import { DomParser } from '@app/Constants';
import { IItemButtonProps } from '@talxis/react-components';

interface IInputs {
    value: ComponentFramework.PropertyTypes.LookupProperty;
    IsInlineNewEnabled: ComponentFramework.PropertyTypes.StringProperty;
    MultipleEnabled: ComponentFramework.PropertyTypes.StringProperty;
    EnableBorder: ComponentFramework.PropertyTypes.StringProperty
}
interface IOutputs {
    value?: ComponentFramework.LookupValue[];
}

const MultiSelectItemToLookupValue = (values: IMultiselectItem[]): ComponentFramework.LookupValue[] => {
    return values?.map(x => {
        return {
            id: x.id,
            entityType: x.entityType,
            name: x.name
        };
    });
};

export class Lookup implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _context: ComponentFramework.Context<IInputs>;
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;
    private _targets: string[];

    private _value: ComponentFramework.LookupValue[] = null;

    private _liquid: Liquid;

    constructor() {
        this._liquid = new Liquid();
    }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement): void {
        this._context = context;
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = document.createElement("div");
        container.appendChild(this._container);
    }

    public updateView(context: ComponentFramework.Context<IInputs>): void {
        this._context = context;
        this._value = context.parameters.value.raw;
        const targets = (context.parameters.value.attributes as ComponentFramework.PropertyHelper.FieldPropertyMetadata.LookupMetadata)?.Targets;

        const props: IMultiselectProps = {
            readOnly: this._context.mode.isControlDisabled,
            borderless: this._context.parameters.EnableBorder?.raw !== 'true',
            //enabled by default unless explicitly disabled
            isInlineNewEnabled: context.parameters.IsInlineNewEnabled.raw === "false" ? false : true,
            multipleEnabled: context.parameters.MultipleEnabled.raw === "true",
            targets: targets,
            onChange: async (value: IMultiselectItem[]) => {
                const mappedValue = MultiSelectItemToLookupValue(value);
                if (JSON.stringify(this._value) !== JSON.stringify(mappedValue)) {
                    this._value = mappedValue ?? null;
                    this._notifyOutputChanged();
                }
            },
            onCreateRecord: async (entityName: string): Promise<IMultiselectItem> | null => {
                let result: ComponentFramework.NavigationApi.OpenFormSuccessResponse = await this._context.navigation.openForm({
                    entityName: entityName,
                    useQuickCreateForm: true
                });
                if (result.savedEntityReference !== null)
                    return {
                        key: result.savedEntityReference[0].id,
                        name: result.savedEntityReference[0].name,
                        id: result.savedEntityReference[0].id,
                        entityType: result.savedEntityReference[0].entityType
                    } as IMultiselectItem;
                else
                    return null;
            },
            onSearch: async (entityName: string, searchText: string) => {
                // TODO: This is being also triggered when the control is initialized which is wrong
                const entityDefinition = await EntityDefinition.getAsync(entityName);
                let viewId: string;
                if (targets?.length > 1) {
                    // TODO: We need to figure out how to get DefaultViewId for polymorphic lookups from Form binding if it's available
                    viewId = (await ViewDefinition.getLookupViewAsync(entityName)).savedqueryid;
                }
                else {
                    viewId = this._context.parameters.value.getViewId();
                }
                const viewDefinition = await ViewDefinition.getAsync(viewId, entityName);
                const quickFindViewDefinition = await ViewDefinition.getQuickFindViewAsync(entityName);
                let fetchXml = viewDefinition.fetchxml;

                const parsedDefinition = DomParser.parseFromString(quickFindViewDefinition.fetchxml, "text/xml");
                const quickFindFilters = [...parsedDefinition.getElementsByTagName("condition")].filter(x => x.getAttribute("value") === "{0}");

                const conditions: ComponentFramework.PropertyHelper.DataSetApi.ConditionExpression[] = [];
                for (const filter of quickFindFilters) {
                    if (searchText === '') {
                        continue;
                    }
                    let operator = ConditionOperator.Like;
                    conditions.push({
                        attributeName: filter.getAttribute("attribute"),
                        conditionOperator: operator,
                        value: searchText,
                        entityAliasName: filter.getAttribute("entityname")
                    });
                }

                // Distinct aliases https://codeburst.io/javascript-array-distinct-5edc93501dc4
                const aliases = [...new Set(conditions.map(x => x.entityAliasName).filter(x => x !== null && x !== undefined))];
                const linkedEntities = [...parsedDefinition.getElementsByTagName("link-entity")];
                for (const alias of aliases) {
                    const link = linkedEntities.find(x => x.getAttribute("alias") === alias);

                    fetchXml = FetchXmlUtils.addLinkedEntity(fetchXml, {
                        alias: alias,
                        from: link.getAttribute("from"),
                        linkType: link.getAttribute("link-type"),
                        name: link.getAttribute("name"),
                        to: link.getAttribute("to")
                    });
                }

                fetchXml = FetchXmlUtils.setFilter(fetchXml, {
                    conditions: conditions,
                    filterOperator: 1
                });

                let nameField = entityDefinition.PrimaryNameAttribute;
                let descriptionField: string | null = null;

                const nameFieldDefinition = viewDefinition.columns[0];
                if (nameFieldDefinition) {
                    nameField = nameFieldDefinition.name;
                }
                const descriptionFieldDefinition = viewDefinition.columns[1];
                if (descriptionFieldDefinition) {
                    descriptionField = descriptionFieldDefinition.name;
                }

                const results = await this._context.webAPI.retrieveMultipleRecords(entityName, `?fetchXml=${fetchXml}&$top=25`);
                const result: IMultiselectItem[] = results.entities.map(x => {
                    const item: IMultiselectItem = {
                        key: x[entityDefinition.PrimaryIdAttribute],
                        name: this._getValue(x, nameField)?.toString(),
                        text: this._getValue(x, nameField)?.toString(),
                        secondaryText: null,
                        id: x[entityDefinition.PrimaryIdAttribute],
                        entityType: entityName,
                        deleteButtonProps: this._getDeleteButtonProps()
                    };

                    if (descriptionField !== null) {
                        item.secondaryText = this._getValue(x, descriptionField)?.toString();
                    }

                    return item;
                });

                return result;
            },
            value: this._value?.length > 0 ? this._value.map(x => {
                return {
                    key: x.id,
                    deleteButtonProps: this._getDeleteButtonProps(),
                    buttons: [],
                    // TODO: This should be updated to use dynamic nameField as above
                    name: x?.name,
                    entityType: x.entityType,
                    id: x.id
                };
            }) : null,
            placeholder: `${this.getTranslation("search")} ${this._context.parameters.value.attributes?.DisplayName}`,
            getTranslation: this.getTranslation.bind(this)
        };

        // TODO: We shouldn't re-render the entire component on every change, however componentWillReceiveProps doesn't seem to be available with React Hooks
        ReactDOM.render(React.createElement(Multiselect, props), this._container);
    }

    public getOutputs(): IOutputs {
        return {
            value: this._value
        };
    }

    public destroy(): void {
        // Called when the control is to unmount
    }

    public getTranslation(string: string, variables: {} = {}): string {
        const localString = this._context.resources.getString(string);
        return variables !== {} ? this._liquid.parseAndRenderSync(localString, variables) : localString;
    }

    // TODO: This should be unified from view
    private _getValue(record: ComponentFramework.WebApi.Entity, columnName: string): string | Date | number | number[] | boolean | ComponentFramework.EntityReference | ComponentFramework.EntityReference[] {
        // TODO: Currently doesn't support lookups.
        // This handles case of $expands
        let aliasedRecord = record;
        if (columnName.includes('.')) {
            const expandedValue = record[columnName.split('.')[0]];
            // This handles M:M $expands, it should be eventually moved to the DataSet Control which handles data retrieval and paging
            if (Array.isArray(expandedValue)) {
                if (expandedValue.length > 0) {
                    aliasedRecord = expandedValue[0];
                }
            }
            else if (expandedValue) {
                aliasedRecord = expandedValue;
            }
            else {
                aliasedRecord = {};
            }

            columnName = columnName.split('.')[1];
        }

        if (aliasedRecord !== null) {
            const field = aliasedRecord[columnName];
            // This handles when lookup value is referenced by its ID field
            if (aliasedRecord.hasOwnProperty(`_${columnName}_value`)) {
                return aliasedRecord[`_${columnName}_value@OData.Community.Display.V1.FormattedValue`];
            }
            else if (field) {
                return field;
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
    private _getDeleteButtonProps(): IItemButtonProps | undefined {
        if (this._context.mode.isControlDisabled) {
            return undefined;
        }
        if (!this._context.mode.isControlDisabled) {
            return {
                key: 'remove',
                showOnlyOnHover: true,
                buttonStyles: {
                    root: {
                        minWidth: 25
                    }
                },
                iconProps: {
                    styles: {
                        root: {
                            fontSize: 12
                        }
                    },
                    iconName: 'ChromeClose'
                }
            };
        }
    }
}