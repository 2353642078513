import { ITextFieldProps } from '@talxis/react-components';
import { ITextFieldItemProps, TextField } from '@talxis/react-components/dist/components/TextField';
import React from 'react';
import ReactDOM from "react-dom";
import { CurrencyComponent } from './CurrencyComponent';
const currencyJS = require('currency.js');

interface IInputs {
    value: ComponentFramework.PropertyTypes.DecimalNumberProperty;
    enableDeleteButton?: ComponentFramework.PropertyTypes.StringProperty;
    enableCopyButton?: ComponentFramework.PropertyTypes.StringProperty;
    EnableBorder?: ComponentFramework.PropertyTypes.StringProperty;
}
interface IOutputs {
    value?: number;
}

export class Currency implements ComponentFramework.StandardControl<IInputs, IOutputs> {
    private _context: ComponentFramework.Context<IInputs>;
    private _notifyOutputChanged: () => void;
    private _container: HTMLDivElement;
    private _enableDeleteButton: boolean = false;
    private _enableCopyButton: boolean = false;
    private _value: number;

    private _retrievedRecords: { [recordId: string]: Xrm.Async.PromiseLike<any> | Promise<any> } = {};
    private async _getRecord(entityName: string, recordId: string): Promise<any> {
        if (this._retrievedRecords[recordId]) return this._retrievedRecords[recordId];
        this._retrievedRecords[recordId] = await Xrm.WebApi.retrieveRecord(entityName, recordId);
        return this._retrievedRecords[recordId];
    }
    private _currencies: { [recordId: string]: Xrm.Async.PromiseLike<any> | Promise<any> } = {};
    private async _getCurrencies(recordId: string): Promise<any> {
        if (!recordId) return null;
        if (this._currencies[recordId]) return this._currencies[recordId];
        this._currencies[recordId] = await Xrm.WebApi.retrieveRecord('transactioncurrency', recordId, '?$select=isocurrencycode,currencysymbol,currencyname,currencyprecision');
        return this._currencies[recordId];
    }

    constructor() { }

    public init(context: ComponentFramework.Context<IInputs>, notifyOutputChanged: () => void, state: ComponentFramework.Dictionary, container: HTMLDivElement) {
        this._context = context;
        this._notifyOutputChanged = notifyOutputChanged;
        this._container = document.createElement("div");
        container.appendChild(this._container);
        this._enableDeleteButton = this._context.parameters.enableDeleteButton?.raw === 'true';
        this._enableCopyButton = this._context.parameters.enableCopyButton?.raw === 'true';
        this._value = context.parameters.value.raw;
    }

    private _onClick = (): void => {
        this._value = null;
        this._notifyOutputChanged();
    }

    public async updateView(context: ComponentFramework.Context<IInputs>): Promise<void> {
        this._context = context;
        this._value = this._context.parameters.value.raw;
        const deleteButtonProps: ITextFieldItemProps = {
            key: 'clickToDelete',
            showOnlyOnInputHover: true,
            onClick: this._onClick,
            iconProps: { iconName: 'Cancel' },
        };
        const copyButtonProps: ITextFieldItemProps = {
            key: 'clickToCopy',
            showOnlyOnInputHover: true,
            iconProps: { iconName: 'Copy' }
        };

        const currencyFieldProps: ITextFieldProps = {
            value: this._context.parameters.value.formatted,
            readOnly: this._context.mode.isControlDisabled,
            deleteButtonProps: this._enableDeleteButton ? deleteButtonProps : null,
            clickToCopyProps: this._enableCopyButton ? copyButtonProps : null,
            borderless: this._context.parameters.EnableBorder?.raw !== 'true',
            errorMessage: this._context.parameters.value.errorMessage,
            onBlur: (input) => {
                let value = input.currentTarget.value;
                if (!value) {
                    this._value = null;
                }
                else {
                    const currencyValue = currencyJS(value, {
                        separator: context.userSettings.numberFormattingInfo.currencyGroupSeparator,
                        decimal: context.userSettings.numberFormattingInfo.currencyDecimalSeparator,
                    });
                    //case when input cannot be converted to valid number
                    if (currencyValue.value === 0 && !new RegExp('.*0.*').test(value)) {
                        this._value = NaN;
                    }
                    else {
                        //force update if the actual value did not change
                        if (this._value === currencyValue.value && this._context.parameters.value.formatted !== value) {
                            this.updateView(context);
                        }
                        this._value = currencyValue.value;
                    }
                }
                this._notifyOutputChanged();
            }
        };

        ReactDOM.render(React.createElement(CurrencyComponent, currencyFieldProps), this._container);
    }

    public getOutputs(): IOutputs {
        return {
            value: this._value
        };
    }

    public destroy(): void {
        this._retrievedRecords = {};
        this._currencies = {};
    }
}