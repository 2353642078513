import React, { useEffect, useState, useRef, useContext, useImperativeHandle } from 'react';
import { IControlProps } from '../interfaces';
import { ControlLoader, ControlRegistration } from '@loaders/ControlLoader';
import { Context } from '@ComponentFramework/PropertyClasses/Context';
import { ViewLoading } from '../../loadings/ViewLoading';
import { IFormContext } from '../native/Form/interfaces/IFormContext';
import { FormContext } from '../native/Form/Form';
import { ViewDefinition } from '@definitions/ViewDefinition';
import { IViewDefinition } from '../native/View/interfaces/viewdefinition';
import { EntityDefinition } from '@definitions/EntityDefinition';
import { MessageBar } from '@fluentui/react';
import * as FetchXmlUtils from './FetchXmlUtils';
import { IExtendedXrmGridControl } from './interfaces/IExtendedXrmGridControl';
import { IXrmViewColumn } from './interfaces/IXrmViewColumn';
import { ColumnLayoutJson } from '@app/interfaces/columnLayoutJson';
import { forwardRef } from 'react';
import { cloneDeep } from 'lodash';
import { XrmGridType as XrmGridType } from './interfaces/XrmGridType';
import { XrmRelationshipType } from './interfaces/XrmRelationshipType';
import { formatXrmGuid } from '@src/app/Functions';

export interface IDataSetControlRef {
    setFilter(expression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression): Promise<void>;
    addLinkedEntity(expression: ComponentFramework.PropertyHelper.DataSetApi.LinkEntityExposedExpression): Promise<void>;
    addColumn(name: string): Promise<void>;
    removeColumn(name: string): Promise<void>;
    updateColumnOrder(columnOrder: string[]): void;
}

const DEFAULT_PAGE_SIZE = 50;

const layout2viewColumns = (viewDefinition: IViewDefinition): IXrmViewColumn[] => {
    const layout: ColumnLayoutJson.RootObject = JSON.parse(viewDefinition.layoutjson);
    const columns: IXrmViewColumn[] = [];
    for (const column of viewDefinition.columns) {
        columns.push({
            name: column.name,
            __displayName: column.displayName,
            relatedEntityName: layout.Rows?.[0].Cells.find(cell => cell.Name === column.name)?.RelatedEntityName ?? ''
        });
    }
    return columns;
};

export const DatasetControl = forwardRef<IDataSetControlRef, IControlProps>((props, ref) => {
    const formContext: IFormContext = useContext(FormContext);
    const [currentPageEntities, setCurrentPageEntities] = useState<Xrm.RetrieveMultipleResult>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [selectedRecordIds, setSelectedRecordIds] = useState<string[]>([]);

    const viewDefinitionRef = useRef<IViewDefinition>(null);
    const pageNumberRef = useRef<number>(0);
    const pageSizeRef = useRef<number>(DEFAULT_PAGE_SIZE);
    const isLoadingRef = useRef<boolean>(true);
    const selectedRecordIdsRef = useRef<string[]>([]);
    const previousPagesRef = useRef<string[]>([]);
    const gridContextRef = useRef<IExtendedXrmGridControl>(null);
    const columnOrderRef = useRef<string[]>();

    const currentFilterExpression = useRef<ComponentFramework.PropertyHelper.DataSetApi.FilterExpression>(null);

    const controlRegistrationRef = useRef<ControlRegistration>(null);

    useImperativeHandle(ref, () => ({
        setFilter,
        addLinkedEntity,
        addColumn,
        removeColumn,
        updateColumnOrder
    }));

    const _loadItems = async (entityName: string, fetchXml: string, nextLink?: string, pageSize = DEFAULT_PAGE_SIZE): Promise<Xrm.RetrieveMultipleResult> => {
        let options = `?fetchXml=${encodeURIComponent(fetchXml)}&$count=true`;
        if (nextLink != null) {
            const nextLinkParts = nextLink.split('?');
            options = `?${nextLinkParts[1]}`;
        }
        const result = await window.Xrm.WebApi.retrieveMultipleRecords(entityName, options, pageSize);
        return result;
    };

    const extendPcfContextWithDatasetParams = (context: Context) => {
        context.parameters["gridPageNumber"] = {
            error: false,
            errorMessage: "",
            raw: pageNumberRef.current + 1,
            type: "WholeNumber.None"
        } as ComponentFramework.PropertyTypes.Property;
    };

    const container = useRef(null);
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const [renderFailed, setRenderFailed] = useState<boolean>(false);
    const [isInititalized, setIsInitialized] = useState<boolean>(false);

    // TODO: This should probably be ref instead.
    const [controlInstance, setControlInstance] = useState<ComponentFramework.StandardControl<any, any>>(null);
    const [controlDefinition, setControlDefinition] = useState<ControlRegistration>(null);

    useEffect(() => {
        // As per https://www.debuggr.io/react-update-unmounted-component/
        let mounted = true;

        const initAsync = async () => {
            try {
                // TODO: Control should be loaded once we have ViewDefinition since it contains controlDescriptors so we can support custom control binding for views
                controlRegistrationRef.current = await ControlLoader.getAsync(props.name);
                await controlRegistrationRef.current.load();

                let viewDefinition = await ViewDefinition.getAsync(props.bindings["ViewId"].value, props.bindings["TargetEntityType"].value);
                viewDefinitionRef.current = cloneDeep(viewDefinition);

                let entityName: string, attributeName: string, recordId: string, relationshipType: XrmRelationshipType, relationshipName: string;
                if (props.bindings["RelationshipName"]?.value) {
                    const targetEntityDefinition = await EntityDefinition.getAsync(props.bindings["TargetEntityType"].value);
                    const entityAlias = "__relatedEntityFilter";
                    let relationship = targetEntityDefinition.ManyToOneRelationships.find(x => x.SchemaName === props.bindings["RelationshipName"].value);
                    if (relationship) {
                        const fetchXmlLinkedEntity = FetchXmlUtils.addLinkedEntity(viewDefinition.fetchxml, {
                            alias: entityAlias,
                            from: relationship.ReferencedAttribute,
                            linkType: "inner",
                            name: relationship.ReferencedEntity,
                            to: relationship.ReferencingAttribute
                        });
                        const fetchXmlRelated = FetchXmlUtils.setFilter(fetchXmlLinkedEntity, {
                            filterOperator: 0,
                            conditions: [{
                                attributeName: relationship.ReferencedAttribute,
                                conditionOperator: 0,
                                value: formContext.entityId,
                                entityAliasName: entityAlias
                            }]
                        });

                        entityName = relationship.ReferencedEntity;
                        attributeName = relationship.ReferencingAttribute;
                        recordId = formContext.entityId;
                        relationshipType = XrmRelationshipType.OneToMany;
                        relationshipName = relationship.SchemaName;

                        viewDefinition = { ...viewDefinition, fetchxml: fetchXmlRelated };
                    }
                    else if (targetEntityDefinition.ManyToManyRelationships.find(x => x.SchemaName === props.bindings["RelationshipName"].value)) {
                        const many2Many = targetEntityDefinition.ManyToManyRelationships.find(x => x.SchemaName === props.bindings["RelationshipName"].value);
                        // Correctly locate the relationship direction - either parent > entity1 > child or parent > entity2 > child
                        const currentEntityIntersectAttribute = many2Many.Entity1LogicalName === props.bindings["TargetEntityType"].value ? many2Many.Entity1IntersectAttribute : many2Many.Entity2IntersectAttribute;
                        const relatedEntityIntersectAttribute = many2Many.Entity1LogicalName === props.bindings["TargetEntityType"].value ? many2Many.Entity2IntersectAttribute : many2Many.Entity1IntersectAttribute;

                        // Due to limitation in FetchXml2OData: https://dev.azure.com/thenetworg/INT0015/_workitems/edit/22082/
                        const fetchXmlM2M = FetchXmlUtils.addManyToManyFilter(viewDefinition.fetchxml, many2Many, {
                            alias: entityAlias,
                            from: currentEntityIntersectAttribute,
                            linkType: "inner",
                            name: many2Many.IntersectEntityName,
                            to: currentEntityIntersectAttribute
                        }, {
                            filterOperator: 0,
                            conditions: [{
                                attributeName: relatedEntityIntersectAttribute,
                                conditionOperator: 0,
                                value: formContext.entityId
                            }]
                        });
                        attributeName = many2Many.Entity2IntersectAttribute;
                        entityName = many2Many.Entity2LogicalName;
                        recordId = formContext.entityId;
                        relationshipType = XrmRelationshipType.ManyToMany;
                        relationshipName = many2Many.SchemaName;
                        viewDefinition = { ...viewDefinition, fetchxml: fetchXmlM2M };
                    }
                    else {
                        throw new Error(`Unable to find relationship ${props.bindings["RelationshipName"].value} in entity definitions!`);
                    }
                }

                gridContextRef.current = {
                    refresh: () => { refreshPage(); },
                    getEntityName: () => { return props.bindings["TargetEntityType"].value; },
                    getFetchXml: () => { return viewDefinitionRef.current.fetchxml; },
                    getViewColumns: () => { return layout2viewColumns(viewDefinitionRef.current); },
                    getParentForm: () => { return formContext; },
                    getViewSelector: () => {
                        return {
                            getCurrentView: () => {
                                return {
                                    name: viewDefinitionRef.current.name,
                                    id: formatXrmGuid(viewDefinitionRef.current.savedqueryid),
                                    entityType: viewDefinitionRef.current.returnedtypecode
                                };
                            },
                            isVisible: (): boolean => undefined,
                            setCurrentView: (viewSelectorItem: Xrm.LookupValue): void => undefined
                        };
                    },
                    getGridType: () => {
                        return formContext !== null ? XrmGridType.Subgrid : XrmGridType.HomePageGrid;
                    },
                    getRelationship: () => {
                        if (attributeName === undefined || recordId === undefined || relationshipType === undefined || entityName === undefined || relationshipName === undefined) return undefined;
                        return {
                            attributeName,
                            recordId,
                            relationshipType,
                            entityName,
                            name: relationshipName
                        };
                    }
                };

                viewDefinitionRef.current = viewDefinition;
                pageSizeRef.current = parseInt(props.bindings["RecordsPerPage"]?.value ?? "10", 10);

                props.getGridContextCallback(gridContextRef.current);
                // Do not load PCF when on new form when RelationshipName is specified (is related subgrid)
                if (formContext && !formContext.entityId && props.bindings["RelationshipName"]?.value) {
                    setIsRendered(true);
                    return;
                }
                const result = await _loadItems(props.bindings["TargetEntityType"].value, viewDefinition.fetchxml, null, pageSizeRef.current);
                ///@ts-ignore - The official PCF context doesn't expose constructor, but we need to create a new instance.
                const controlInstance = new (await controlRegistrationRef.current.registration).code();
                if (mounted) {
                    formContext?.registerChildControl(props.id, {
                        gridRefresh: () => {
                            refreshPage();
                        }
                    });
                    setPageSize(pageSizeRef.current);
                    setIsLoading(false);
                    setCurrentPageEntities(result);
                    setControlDefinition(controlRegistrationRef.current);
                    setControlInstance(controlInstance);
                    props.onDataSetControlInitialized();
                }
            }
            catch (err) {
                console.error(`Error message from custom control: ${props.name}`, err);

                if (mounted) setRenderFailed(true);
            }
            if (mounted) {
                setIsRendered(true);
            }
        };

        initAsync();

        return () => mounted = false;
    }, []);

    const loadNextPage = async () => {
        if (currentPageEntities.nextLink != null && !isLoadingRef.current) {
            setIsLoading(true);
            isLoadingRef.current = true;

            previousPagesRef.current.push(currentPageEntities.nextLink);
            const result = await _loadItems(props.bindings["TargetEntityType"].value, viewDefinitionRef.current.fetchxml, currentPageEntities.nextLink, pageSizeRef.current);

            if (result.entities.length > 0) {
                setCurrentPageEntities(result);
                setPageNumber(++pageNumberRef.current);
            }
            setIsLoading(false);
        }
    };
    const refreshPage = async () => {
        if (!isLoadingRef.current) {
            isLoadingRef.current = true;
            setIsLoading(true);
            pageNumberRef.current = 0;
            setPageNumber(0);
            previousPagesRef.current = [null];
            const result = await _loadItems(props.bindings["TargetEntityType"].value, viewDefinitionRef.current.fetchxml, previousPagesRef.current[previousPagesRef.current.length - 1], pageSizeRef.current);
            setCurrentPageEntities(result);
            setIsLoading(false);
        }
    };
    const loadPreviousPage = async () => {
        if (pageNumberRef.current > 0 && !isLoadingRef.current) {
            setIsLoading(true);
            isLoadingRef.current = true;

            // Remove current page from pages and get the previous one.
            previousPagesRef.current.pop();
            const previousPageLink = previousPagesRef.current.pop();
            const result = await _loadItems(props.bindings["TargetEntityType"].value, viewDefinitionRef.current.fetchxml, previousPageLink, pageSizeRef.current);

            setCurrentPageEntities(result);
            setPageNumber(--pageNumberRef.current);
            setIsLoading(false);
        }
    };
    const setPageSizeExternal = async (pageSize: number) => {
        if (pageSize > 0) {
            pageSizeRef.current = pageSize;
            setPageSize(pageSize);
            pageNumberRef.current = 0;
            setPageNumber(0);
            previousPagesRef.current = [null];

            setIsLoading(true);
            isLoadingRef.current = true;

            const result = await _loadItems(props.bindings["TargetEntityType"].value, viewDefinitionRef.current.fetchxml, null, pageSizeRef.current);

            setCurrentPageEntities(result);
            setPageNumber(pageNumberRef.current);
            setIsLoading(false);
        }
    };
    const addLinkedEntity = async (expression: ComponentFramework.PropertyHelper.DataSetApi.LinkEntityExposedExpression) => {
        const viewDefinitionMod = { ...viewDefinitionRef.current };

        const result = FetchXmlUtils.addLinkedEntity(viewDefinitionMod.fetchxml, expression);
        if (result != viewDefinitionMod.fetchxml) {
            viewDefinitionMod.fetchxml = result;
            viewDefinitionRef.current = viewDefinitionMod;

            // TODO: Call refresh on data?
        }
    };
    const getLinkedEntities = (): ComponentFramework.PropertyHelper.DataSetApi.LinkEntityExposedExpression[] => {
        return FetchXmlUtils.getLinkedEntities(viewDefinitionRef.current.fetchxml);
    };
    const setFilter = async (expression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression) => {
        setIsLoading(true);
        isLoadingRef.current = true;
        const fetchXml = FetchXmlUtils.setFilter((await ViewDefinition.getAsync(props.bindings["ViewId"].value, props.bindings["TargetEntityType"].value)).fetchxml, expression);
        currentFilterExpression.current = expression;

        const result = await _loadItems(props.bindings["TargetEntityType"].value, fetchXml, null, pageSizeRef.current);

        viewDefinitionRef.current.fetchxml = fetchXml;
        pageNumberRef.current = 0;
        setPageNumber(0);
        setCurrentPageEntities(result);
        previousPagesRef.current = [null];
        setIsLoading(false);
    };

    const getFilter = (): ComponentFramework.PropertyHelper.DataSetApi.FilterExpression => {
        return currentFilterExpression.current;
    };

    const setSorting = (sorting: ComponentFramework.PropertyHelper.DataSetApi.SortStatus[]) => {
        const fetchXml = FetchXmlUtils.setSorting(viewDefinitionRef.current.fetchxml, sorting);

        viewDefinitionRef.current.fetchxml = fetchXml;
    };
    const getSorting = (): ComponentFramework.PropertyHelper.DataSetApi.SortStatus[] => {
        return FetchXmlUtils.getSorting(viewDefinitionRef.current.fetchxml);
    };
    const addColumn = async (name: string) => {
        viewDefinitionRef.current.fetchxml = FetchXmlUtils.addColumn(viewDefinitionRef.current.fetchxml, name);
        const layout: ColumnLayoutJson.RootObject = JSON.parse(viewDefinitionRef.current.layoutjson);
        layout.Rows[0].Cells.push({
            Width: 150,
            Name: name,
            RelatedEntityName: '',
            IsHidden: false,
            ImageProviderFunctionName: '',
            ImageProviderWebresource: ''

        });
        viewDefinitionRef.current.layoutjson = JSON.stringify(layout);
        viewDefinitionRef.current.columns = await ViewDefinition.createColumnsAsync(viewDefinitionRef.current);
    };
    const removeColumn = async (name: string) => {
        viewDefinitionRef.current.fetchxml = FetchXmlUtils.removeColumn(viewDefinitionRef.current.fetchxml, name);
        const layout: ColumnLayoutJson.RootObject = JSON.parse(viewDefinitionRef.current.layoutjson);
        layout.Rows[0].Cells = layout.Rows[0].Cells.filter(cell => cell.Name !== name);
        viewDefinitionRef.current.layoutjson = JSON.stringify(layout);
        viewDefinitionRef.current.columns = await ViewDefinition.createColumnsAsync(viewDefinitionRef.current);
    };

    const updateColumnOrder = (columnOrder: string[]) => {
        columnOrderRef.current = columnOrder;
        viewDefinitionRef.current.columns.sort((a, b) => columnOrder.indexOf(a.name) - columnOrder.indexOf(b.name));
        const layout: ColumnLayoutJson.RootObject = JSON.parse(viewDefinitionRef.current.layoutjson);
        layout.Rows[0].Cells.sort((a, b) => columnOrder.indexOf(a.Name) - columnOrder.indexOf(b.Name));
        viewDefinitionRef.current.layoutjson = JSON.stringify(layout);
    };
    const clearSelectedRecordIds = () => {
        selectedRecordIdsRef.current = [];
        setSelectedRecordIds([]);
    };

    useEffect(() => {
        // When we run without from context - eg. standalone control like notifications, we are not expected to write back any changes.
        if (!formContext) return;

        const shouldUpdate = true;
        if (shouldUpdate && controlInstance != null && isInititalized) {
            const runAsync = async () => {
                try {
                    const context = await Context.createDatasetContext(props, props.bindings["TargetEntityType"].value, await controlRegistrationRef.current.registration, viewDefinitionRef.current, currentPageEntities, {
                        currentPage: pageNumberRef.current,
                        pageSize: pageSizeRef.current,
                        loading: isLoadingRef.current,
                        selectedRecordIds: selectedRecordIdsRef.current,
                        refreshPage: () => {
                            refreshPage();
                        },
                        loadNextPage: () => {
                            loadNextPage();
                        },
                        loadPreviousPage: () => {
                            loadPreviousPage();
                        },
                        setPageSize: (pageNumber: number) => {
                            setPageSizeExternal(pageNumber);
                        },
                        setSelectedRecordIds: setSelectedRecordIds,
                        clearSelectedRecordIds: clearSelectedRecordIds,
                        setFilter: setFilter,
                        getFilter: getFilter,
                        addLinkedEntity: addLinkedEntity,
                        getLinkedEntities: getLinkedEntities,
                        getSorting: getSorting,
                        setSorting: setSorting
                    }, gridContextRef.current, { ...formContext?.entity, ...formContext?.entityChanges });
                    extendPcfContextWithDatasetParams(context);
                    controlInstance.updateView(context);
                }
                catch (err) {
                    console.error(`Error message from custom control: ${props.name}`, err);
                }
            };
            runAsync();
        }
    }, [currentPageEntities, controlInstance, isInititalized]);

    const notifyOutputChanged = (): void => {
        // TODO: Incoming changes from subgrid are not supported at the moment.
        throw new Error("Not implemented!");
    };

    useEffect(() => {
        // As per https://www.debuggr.io/react-update-unmounted-component/
        let mounted = true;

        if (controlInstance !== null && controlDefinition !== null && !isInititalized) {
            const runAsync = async () => {
                try {
                    const context = await Context.createDatasetContext(props, props.bindings["TargetEntityType"].value, await controlRegistrationRef.current.registration, viewDefinitionRef.current, currentPageEntities, {
                        currentPage: pageNumberRef.current,
                        pageSize: pageSizeRef.current,
                        loading: isLoadingRef.current,
                        selectedRecordIds: selectedRecordIdsRef.current,
                        refreshPage: () => {
                            refreshPage();
                        },
                        loadNextPage: () => {
                            loadNextPage();
                        },
                        loadPreviousPage: () => {
                            loadPreviousPage();
                        },
                        setPageSize: (pageNumber: number) => {
                            setPageSizeExternal(pageNumber);
                        },
                        setSelectedRecordIds: setSelectedRecordIds,
                        clearSelectedRecordIds: clearSelectedRecordIds,
                        setFilter: setFilter,
                        getFilter: getFilter,
                        addLinkedEntity: addLinkedEntity,
                        getLinkedEntities: getLinkedEntities,
                        getSorting: getSorting,
                        setSorting: setSorting
                    }, gridContextRef.current, { ...formContext?.entity, ...formContext?.entityChanges });
                    extendPcfContextWithDatasetParams(context);
                    controlInstance.init(context, () => notifyOutputChanged(), null, container.current);

                    if (mounted) setIsInitialized(true);

                    controlInstance.updateView(context);
                }
                catch (err) {
                    console.error(`Error message from custom control: ${props.name}`, err);
                    if (mounted) setRenderFailed(true);
                }
            };

            runAsync();
        }

        return () => mounted = false;
    }, [controlInstance]);

    useEffect(() => {
        pageNumberRef.current = pageNumber;
        pageSizeRef.current = pageSize;
        isLoadingRef.current = isLoading;
        selectedRecordIdsRef.current = selectedRecordIds;

        if (controlInstance !== null && controlRegistrationRef.current !== null && isInititalized) {
            const updateContext = async () => {
                const context = await Context.createDatasetContext(props, props.bindings["TargetEntityType"].value, await controlRegistrationRef.current.registration, viewDefinitionRef.current, currentPageEntities, {
                    currentPage: pageNumberRef.current,
                    pageSize: pageSizeRef.current,
                    loading: isLoadingRef.current,
                    selectedRecordIds: selectedRecordIdsRef.current,
                    refreshPage: () => {
                        refreshPage();
                    },
                    loadNextPage: () => {
                        loadNextPage();
                    },
                    loadPreviousPage: () => {
                        loadPreviousPage();
                    },
                    setPageSize: (pageNumber: number) => {
                        setPageSizeExternal(pageNumber);
                    },
                    setSelectedRecordIds: setSelectedRecordIds,
                    clearSelectedRecordIds: clearSelectedRecordIds,
                    setFilter: setFilter,
                    getFilter: getFilter,
                    addLinkedEntity: addLinkedEntity,
                    getLinkedEntities: getLinkedEntities,
                    getSorting: getSorting,
                    setSorting: setSorting
                }, gridContextRef.current, { ...formContext?.entity, ...formContext?.entityChanges });
                extendPcfContextWithDatasetParams(context);
                controlInstance.updateView(context);
            };

            updateContext();
        }
    }, [pageNumber, pageSize, isLoading, selectedRecordIds]);

    useEffect(() => {
        if (controlInstance !== null && controlDefinition !== null && isInititalized) {
            if (props.onSelectedItems) {
                props.onSelectedItems(selectedRecordIds, refreshPage, () => { return viewDefinitionRef.current.fetchxml; });
            }
            if (selectedRecordIds.length === 1) {
                const triggerAsync = async () => {
                    const entityDefinition = await EntityDefinition.getAsync(props.bindings["TargetEntityType"].value);
                    const entity: ComponentFramework.WebApi.Entity = currentPageEntities.entities.find(x => x[entityDefinition.PrimaryIdAttribute] === selectedRecordIds[0]);
                    const executionContext: Xrm.Events.EventContext = {
                        /// @ts-ignore - We are not implementing entire EventContext, only required items by the scripts.
                        getFormContext: () => {
                            return {
                                data: {
                                    entity: {
                                        getId: () => {
                                            return `{${entity[entityDefinition.PrimaryIdAttribute]}}`;
                                        },
                                        attributes: {
                                            get: (arg1?: any): any => {
                                                if (typeof arg1 === "string") {
                                                    return {
                                                        getValue: () => {
                                                            if (entity[`_${arg1}_value`]) {
                                                                const attribute = entityDefinition.Attributes.find(x => x.LogicalName === arg1);
                                                                return {
                                                                    entityType: entityDefinition.ManyToOneRelationships.find(x => x.ReferencedAttribute === attribute.LogicalName).ReferencedEntity,
                                                                    id: entity[`_${arg1}_value`],
                                                                    name: entity[`_${arg1}_value@OData.Community.Display.V1.FormattedValue`]
                                                                };
                                                            }
                                                            return entity[arg1];
                                                        }
                                                    } as Xrm.Attributes.Attribute;
                                                }
                                            }
                                        }
                                    }
                                }
                            };
                        }
                    };
                    formContext?.onRecordSelect(props.id, executionContext);
                };
                triggerAsync();
            }
            if (selectedRecordIds.length >= 1) {
                const triggerAsync = async () => {
                    const executionContext: Xrm.Events.EventContext = {
                        /// @ts-ignore - We are not implementing entire EventContext, only required items by scripts.
                        getFormContext: () => {
                            return {
                                getSelectedRows: () => {
                                    return selectedRecordIds;
                                }
                            };
                        }
                    };
                    formContext?.onRecordsSelect(props.id, executionContext);
                };

                triggerAsync();
            }
        }
    }, [selectedRecordIds]);

    // if(renderFailed) {
    //     // TODO: Improve error handling when control load fails
    //     throw new Error("Failed to fetch");
    // }

    // TODO: First div should have classes like: .SampleNamespace\.TSDataSetGrid to support custom control's CSS with namespaces, this can be pulled from manifest.
    return (
        <div data-id={`dataSetRoot_${props.id}`}>
            <div className={props.name} ref={container} />
            {!props.disableLoading && !isRendered &&
                <ViewLoading />
            }
            {isRendered && formContext && !formContext.entityId && props.bindings["RelationshipName"]?.value &&
                <MessageBar>{window.TALXIS.Portal.Translations.getLocalizedString("@controls/DatasetControl/SaveNewForm")}</MessageBar>
            }
            {renderFailed &&
                <MessageBar>
                    {window.TALXIS.Portal.Translations.getLocalizedString("@controls/DatasetControl/ControlRenderError")}
                </MessageBar>
            }
        </div>
    );
});
