import { ICalendarDayProps } from '@fluentui/react/lib/components/Calendar/CalendarDay/CalendarDay.types';
import * as React from 'react';
import { Calendar, DayOfWeek, defaultCalendarStrings } from '@fluentui/react/lib/components/Calendar';
import { IDateTimePickerProps } from '../interfaces';
import { DatePicker } from '@talxis/react-components/dist/components/DatePicker';
import { IStackItemStyles, IStackStyles, IStackTokens, Stack } from '@fluentui/react/lib/components/Stack';
import { IComboBox } from '@fluentui/react/lib/components/ComboBox';
import { TimePicker } from '@talxis/react-components/dist/components/TimePicker';
import { useEffect } from 'react';

export const DatePickerField: React.FunctionComponent<IDateTimePickerProps> = (props) => {
    const [value, setValue] = React.useState<Date | undefined>(props.value);
    const [avaliableDates] = React.useState<Date[] | undefined>(props.availableDates);
    const [excludedDates] = React.useState<Date[] | undefined>(props.excludedDates);
    const [excludedDaysOfTheWeek] = React.useState<number[] | undefined>(props.excludeDaysOfTheWeekArray);
    const [timePickerErrorMessage, setTimePickerErrorMessage] = React.useState<string | undefined>();
    const [timeKey, setTimeKey] = React.useState<number>(0);
    const timePickerRef = React.useRef<IComboBox>(null);
    // STYLES & TOKENS
    const stackStyles: IStackStyles = {
        root: {
            overflow: 'hidden',
            width: `100%`,
            padding: 0
        },
    };
    const stackItemStyles: IStackItemStyles = {
        root: {
            alignItems: 'center',
            display: 'flex',
            height: 50,
            justifyContent: 'center',
            overflow: 'hidden',
            flexGrow: 0
        },
    };
    const nonShrinkingStackItemStyles: IStackItemStyles = stackItemStyles;
    nonShrinkingStackItemStyles.root = { width: 105 };
    const innerStackTokens: IStackTokens = {
        childrenGap: 5,
        padding: 10,
    };
    const timePickerStyles = {
        optionsContainerWrapper: {
            height: '300px',
        },
        root: {
            width: '100%',
        },
    };

    // ACTIONS
    const onFormatDate = (date?: Date): string => {
        if (props.hideDaySelection) {
            return date.toLocaleDateString('default', { year: 'numeric', month: 'long' });
        }
        else {
            return date.toLocaleDateString();
        }
    };
    const onFormatTime = (date?: Date): string => {
        return date?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const onSelectDate = (date: Date): void => {
        let _value: Date = new Date(date);
        if (value) {
            _value.setHours(value.getHours(), value.getMinutes(), value.getSeconds(), value.getMilliseconds());
        } else {
            // In PowerApps, this value is set in User Settings (Activities->Default Work Hours->Start Time)
            // Currently in Access Principals we don't have this information so default value is set to 8 (same as default start time in PowerApps)
            _value.setHours(8);
        }
        setValue(_value);
        props.onSelectDate(_value);
    };

    const onSelectTime = (event: React.FormEvent<IComboBox>, date: Date): void => {
        if (date.toString().toUpperCase() !== "INVALID DATE") {
            let _value: Date = new Date(value.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
            setValue(_value);
            setTimePickerErrorMessage(null);
            props.onSelectDate(_value);
        } else {
            setTimePickerErrorMessage("Invalid time. Reverted to previous value.");
        }
        setTimeKey(timeKey + 1);
    };

    const calendarStrings = {
        ...defaultCalendarStrings,
        ...(props.languageCode === 1029 &&
        {
            days: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
            shortDays: ['N', 'P', 'Ú', 'S', 'Č', 'P', 'S'],
            months: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Zaří', 'Říjen', 'Listopad', 'Prosinec'],
            shortMonths: ['led', 'úno', 'bře', 'dub', 'kvě', 'čvn', 'čvc', 'srp', 'zář', 'říj', 'lis', 'pro'],
            prevMonthAriaLabel: 'Přejít na předchozí měsíc',
            nextMonthAriaLabel: 'Přejít na další měsíc',
            prevYearAriaLabel: 'Přejít na předchozí rok',
            nextYearAriaLabel: 'Přejít na další rok',
        })
    };

    const onDeleteButtonClick = () => {
        setValue(null);
        props.onDeleteButtonClick();
    };

    const calendarDayProps: Partial<ICalendarDayProps> = {
        customDayCellRef: (element, date, classNames) => {
            if (element) {
                if (avaliableDates) {
                    if (!avaliableDates?.find(x => x.getDate() === date.getDate() && x.getMonth() === date.getMonth() && x.getFullYear() === date.getFullYear())) {
                        classNames.dayOutsideBounds && element.classList.add(classNames.dayOutsideBounds);
                        (element.children[0] as HTMLButtonElement).disabled = true;
                    }
                }
                if (excludedDates) {
                    if (excludedDates?.find(x => x.getDate() === date.getDate() && x.getMonth() === date.getMonth() && x.getFullYear() === date.getFullYear())) {
                        classNames.dayOutsideBounds && element.classList.add(classNames.dayOutsideBounds);
                        (element.children[0] as HTMLButtonElement).disabled = true;
                    }
                }
                if (excludedDaysOfTheWeek?.find(x => x == date.getDay())) {
                    classNames.dayOutsideBounds && element.classList.add(classNames.dayOutsideBounds);
                    (element.children[0] as HTMLButtonElement).disabled = true;
                }
            }
        },
    };

    useEffect(() => {
        let tempValue: Date = null;
        tempValue = props.value;
        setValue(tempValue);
        //@ts-ignore - accessing currentPendingValue to set custom time value
        if (timePickerRef?.current?.state) {
            //@ts-ignore - accessing currentPendingValue to set custom time value
            timePickerRef.current.state.currentPendingValue = onFormatTime(tempValue);
        }
    }, [props.value]);

    useEffect(() => {
        //@ts-ignore - accessing currentPendingValue to set custom time value
        if (timePickerRef?.current?.state) {
            //@ts-ignore - accessing currentPendingValue to set custom time value
            timePickerRef.current.state.currentPendingValue = onFormatTime(value);
        }
    }, [timeKey]);
    return (

        <Stack horizontal styles={stackStyles} tokens={innerStackTokens}>
            <Stack.Item grow styles={stackItemStyles}>
                <DatePicker
                    value={value}
                    readOnly={props.readOnly}
                    borderless={props.borderless}
                    showGoToToday={false}
                    calendarProps={avaliableDates || excludedDates || excludedDaysOfTheWeek ? { calendarDayProps: calendarDayProps } : null}
                    formatDate={onFormatDate}
                    onSelectDate={onSelectDate}
                    placeholder="---"
                    strings={calendarStrings}
                    deleteButtonProps={{
                        key: 'Delete',
                        onClick: onDeleteButtonClick,
                        showOnlyOnHover: true,
                        iconProps: {
                            iconName: 'Cancel'
                        }
                    }}
                    firstDayOfWeek={props.languageCode === 1029 ? DayOfWeek.Monday : DayOfWeek.Sunday}
                    calendarAs={
                        (calendarProps) =>
                            <Calendar
                                {...calendarProps}
                                isDayPickerVisible={!props.hideDaySelection}
                                isMonthPickerVisible={!props.hideMonthSelection}
                                strings={calendarStrings}
                            />
                    }
                />
            </Stack.Item>
            {value && props.format === 'DateAndTime' &&
                <Stack.Item grow disableShrink styles={nonShrinkingStackItemStyles}>
                    <TimePicker
                        key={timeKey}
                        // This should come from PCF's userSettings
                        useHour12={onFormatTime(new Date()).includes("AM") || onFormatTime(new Date()).includes("PM")}
                        componentRef={timePickerRef}
                        styles={timePickerStyles}
                        defaultValue={new Date()}
                        readOnly={props.readOnly}
                        errorMessage={timePickerErrorMessage}
                        useComboBoxAsMenuWidth
                        autoCapitalize="off"
                        autoComplete="off"
                        iconButtonProps={{
                            iconProps: {
                                iconName: "Clock",
                                styles: {
                                    root: {
                                        fontSize: 17
                                    }
                                }
                            }
                        }}
                        borderless={true}
                        onChange={onSelectTime}
                        onFormatDate={onFormatTime}
                    />
                </Stack.Item>
            }
        </Stack>
    );
};
export default DatePickerField;