import { CommandBar, DetailsListLayoutMode, IColumn as IColumnBase, Icon, ISelection, SelectionMode, Selection, Text, SearchBox, IDropdownOption, IGroup, DropdownMenuItemType, IDetailsGroupDividerProps, IContextualMenuProps, DirectionalHint, ContextualMenu, ColumnActionsMode, IContextualMenuItem, ContextualMenuItemType, mergeStyles, IColumnReorderOptions, useTheme } from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';
import { Dropdown } from "@talxis/react-components/dist/components/Dropdown";
import React, { useEffect, useRef, useState } from "react";
import { RibbonDefinition, RibbonLocationFilters } from "@definitions/RibbonDefinition";
import { DetailsList } from "../../../../../lists/DetailsList";
import { ViewLoading } from "../../../../../loadings/ViewLoading";
import { GroupHeader } from "../GroupHeader/GroupHeader";
import { ICustomGroup } from "../GroupHeader/ICustomGroup";
import { ItemColumn } from "../ItemColumn";
import { FilterCallout } from "../filtering/FilterCallout";
import { NestedPcfFactory } from "@ComponentFramework/interfaces/NestedPcf";
import { DataType } from "@ComponentFramework/interfaces/DataType";
import { IViewColumn } from "../../interfaces/viewdefinition";
import { ribbonButtonsColumnName } from "@app/Constants";
import { Ribbon } from "@models/Ribbon/Ribbon";
import { ColumnDragAndDropService } from './ColumnDragAndDropService';

export interface IColumn extends IColumnBase {
    dataType?: DataType;
}

interface IViewProps {
    dataset: ComponentFramework.PropertyTypes.DataSet;
    factory: ComponentFramework.Factory;
    formatting: ComponentFramework.Formatting;
    onOpenDatasetItem?: (entityReference: ComponentFramework.EntityReference) => void;
    isViewPage?: boolean;
    pageNumber: number;
    enableQuickFind: boolean;
    enableGroupBy: boolean;
    selectableRows: boolean | string;
    searchPlaceholder: string;
    groupByAttributes?: string[];
    aggregateObjectArray?: IAggregate[];
    primaryName?: string;
    enableFiltering: boolean;
    enableSorting: boolean;
    aggregationButtonPlacement?: AggregationButtonPlacementTypes;
    getTranslation: (value: string, variables?: {}) => string;
    fireEvent: (eventName: string, object: any) => void;
}
export enum AggregationButtonPlacementTypes {
    OnExpandedRow = 'OnExpandedRow',
    OnAggregatedRow = 'OnAggregatedRow',
    OnExpandedAndAggregatedRow = 'OnExpandedAndAggregatedRow'
}
export interface IAggregate {
    fieldName: string;
    aggregationType: AggregationType;
}
export type AggregationType = "SUM" | "AVG" | "COUNT";
export interface IViewItem {
    key: string | string[];
    record: ComponentFramework.PropertyHelper.DataSetApi.EntityRecord;
    compositeKey: string;
}
function getCompositeKey(item: ComponentFramework.PropertyHelper.DataSetApi.EntityRecord, groupBy: string[]): string {
    const compositeKey: string[] = [];
    for (const column of groupBy) {
        compositeKey.push(item.getFormattedValue(column));
    }
    return compositeKey.join("~$~");
}
const onColumnResize = (entries: ResizeObserverEntry[], ref: React.MutableRefObject<HTMLDivElement>) => {
    if (!ref.current) return;
    for (const entry of entries) {
        const columnKey = entry.target.getAttribute('data-item-key');
        const groupItems = ref.current.querySelectorAll(`[data-automation-key="${columnKey}"]`);
        for (const groupItem of groupItems) {
            groupItem.setAttribute('style', `width: ${entry.contentRect.width + 20}px`);
        }
    }
};
const MIN_COLUMN_WIDTH = 100; // this is the global min width
export const View: React.FC<IViewProps> = (props) => {
    const observedColumns = React.useRef<string[]>([]);
    const ref = useRef<HTMLDivElement>();
    const observer = new ResizeObserver((entries) => onColumnResize(entries, ref));
    const [ribbon, setRibbon] = useState<Ribbon>();
    const [columns, setColumns] = useState<IColumn[]>();
    const [items, setItems] = useState<IViewItem[]>([]);
    const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();
    const [dropdownOptions, setDropdownOptions] = React.useState<IDropdownOption[]>();
    const [groups, setGroups] = React.useState<IGroup[]>();
    const [filterByMenuProps, setFilterByMenuProps] = React.useState<IContextualMenuProps>();
    const [filterByColumnTarget, setFilterByColumnTarget] = useState<HTMLElement>();
    const [filterByColumn, setFilterByColumn] = useState<ComponentFramework.PropertyHelper.DataSetApi.Column>();
    const [isFilterByCalloutVisible, setIsFilterByCalloutVisible] = useBoolean(false);
    const [formContext, setFormContext] = useState<Xrm.FormContext>(null);
    const columnDragDropService = new ColumnDragAndDropService(useTheme());

    const lookupFiltersRef = useRef<{ [columnName: string]: ComponentFramework.LookupValue[] }>({});

    let selectionMode;

    if (props.selectableRows === "true" || props.selectableRows === "single") {
        selectionMode = SelectionMode.single;
    }
    else if (props.selectableRows === "multiple") {
        selectionMode = SelectionMode.multiple;
    }
    else selectionMode = SelectionMode.none;

    const [selection] = useState<ISelection>(new Selection({
        onSelectionChanged: () => {
            const selectedItems = selection.getSelection();
            props.dataset.setSelectedRecordIds(selectedItems.map(x => x.key as string));
        }
    }));
    useEffect(() => {
        if (props.dataset.loading) {
            props.dataset.clearSelectedRecordIds();
            selection.setItems([], true);
        }
    }, [props.dataset.loading]);
    useEffect(() => {
        const initAsync = async (): Promise<void> => {
            const ribbon = await RibbonDefinition.getRibbon(props.dataset.getTargetEntityType(), props.isViewPage ? RibbonLocationFilters.HomepageGrid : RibbonLocationFilters.SubGrid);
            try {
                props.fireEvent("__retrieveFormContext", (formContext: Xrm.FormContext) => {
                    setFormContext(formContext);
                });
            } catch (err) { }
            setRibbon(ribbon);
        };
        initAsync();
    }, []);
    useEffect(() => {
        if (items && props.groupByAttributes) {
            for (const attribute of props.groupByAttributes) {
                if (!observedColumns.current.includes(attribute)) {
                    const headerColumn = ref.current.querySelector(`[data-item-key="${attribute}"]`);
                    if (headerColumn) {
                        observer.observe(headerColumn);
                    }
                }
            }
        }
        return () => {
            observer.disconnect();
        };
    }, [items]);
    const getMinWidth = (visualSizeFactor: number) => {
        if (window.innerWidth <= 768) {
            return visualSizeFactor;
        }
        return 1;
    };
    const getColumnActionsMode = (columnName: string) => {
        if (columnName === ribbonButtonsColumnName) {
            return ColumnActionsMode.disabled;
        }
        if (props.enableGroupBy) {
            return ColumnActionsMode.disabled;
        }
        if (!props.enableFiltering && !props.enableSorting) {
            return ColumnActionsMode.disabled;
        }
        return ColumnActionsMode.hasDropdown;
    };
    const buildColumns = (): [IColumn[], IDropdownOption<any>[]] => {
        const mappedColumns: IColumn[] = [];
        const mappedOptions: IDropdownOption[] = [
            { key: null, text: 'Clear', },
            { key: 'divider', text: '-', itemType: DropdownMenuItemType.Divider }
        ];

        const filter = props.dataset.filtering.getFilter();
        for (const column of props.dataset.columns) {
            const sortStatus = props.dataset.sorting.find(x => x.name == column.name);
            mappedColumns.push({
                key: column.name,
                dataType: column.dataType as DataType,
                name: column.displayName,
                minWidth: getMinWidth(column.visualSizeFactor), //minWidth doesn't allow shrinking the column width below its value but maxWidth lets users expand the column width beyond its value. If you set it to 0 it defaults to more than 0 #smh.
                maxWidth: column.visualSizeFactor,
                fieldName: column.name,
                isResizable: true,
                onColumnClick: (ev, column) => onColumnClick(ev, column as IColumn),
                onColumnContextMenu: (column, ev) => onColumnContextMenu(column as IColumn, ev),
                columnActionsMode: getColumnActionsMode(column.name),
                isSorted: !props.enableGroupBy && sortStatus !== undefined,
                isSortedDescending: sortStatus?.sortDirection === 1 ? true : false,
                isFiltered: filter?.conditions.find(x => (!x.entityAliasName && x.attributeName === column.name) || `${x.entityAliasName}.${x.attributeName}` === column.name) !== undefined ? true : false
            });
            mappedOptions.push({
                key: column.name,
                text: column.displayName,
            });
        }
        return [mappedColumns, mappedOptions];
    };
    useEffect(() => {
        const [mappedColumns, mappedOptions] = buildColumns();
        setColumns(mappedColumns);
        setDropdownOptions(mappedOptions);
    }, [props.dataset.columns, props.dataset.sorting, ribbon]);
    //Trigger when option for sorting and filtering are out of view
    const filterByContextualMenuDissmised = (): void => {
        setFilterByMenuProps(undefined);
    };
    const onColumnContextMenu = (column: IColumn, ev: React.MouseEvent<HTMLElement>): void => {
        if (column.columnActionsMode !== ColumnActionsMode.disabled) {
            setFilterByMenuProps(filterByGetContextualMenuProps(ev, column));
        }
    };
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (column.columnActionsMode !== ColumnActionsMode.disabled) {
            setFilterByMenuProps(filterByGetContextualMenuProps(ev, column));
        }
    };
    const onSortColumn = (columnKey: string, isSortedDescending: boolean): void => {
        props.dataset.sorting = [{
            name: columnKey,
            sortDirection: isSortedDescending === true ? 1 : 0
        }];
        props.dataset.refresh();
    };
    const filterByGetContextualMenuProps = (ev: React.MouseEvent<HTMLElement>, column: IColumn): IContextualMenuProps => {
        // This type change is so that we can disable filtering on n-level expands - https://dev.azure.com/thenetworg/INT0015/_workitems/edit/22800
        const columnPcf = props.dataset.columns.find(x => x.name == column.key) as IViewColumn;

        let types: DataType[] = [DataType.SingleLineText, DataType.DateAndTimeDateAndTime, DataType.DateAndTimeDateOnly, DataType.OptionSet, DataType.TwoOptions, DataType.MultiSelectOptionSet, DataType.Currency, DataType.Decimal, DataType.WholeNone, DataType.FP, DataType.LookupSimple, DataType.LookupCustomer, DataType.LookupOwner];
        // https://dev.azure.com/thenetworg/INT0015/_workitems/edit/22791/
        if (columnPcf.alias.includes(".")) {
            types = types.splice(types.indexOf(DataType.MultiSelectOptionSet), 1);
        }

        let ascendingLabel = props.getTranslation("sortAtoZ");
        let descendingLabel = props.getTranslation("sortZtoA");

        const dataType = columnPcf.dataType as DataType;

        switch (dataType) {
            case DataType.DateAndTimeDateAndTime:
            case DataType.DateAndTimeDateOnly:
                ascendingLabel = props.getTranslation("sortOlderToNewer");
                descendingLabel = props.getTranslation("sortNewerToOlder");
                break;
            case DataType.Decimal:
            case DataType.FP:
            case DataType.WholeNone:
            case DataType.Currency:
                ascendingLabel = props.getTranslation("sortLowerToHigher");
                descendingLabel = props.getTranslation("sortHigherToLower");
                break;
        }

        // Sorting is disabled on expanded elements: https://dev.azure.com/thenetworg/INT0015/_workitems/edit/22630/
        const itemsOptions: IContextualMenuItem[] = [];
        if (props.enableSorting) {
            itemsOptions.push(
                {
                    key: 'ascending',
                    name: ascendingLabel,
                    iconProps: { iconName: 'SortUp' },
                    canCheck: true,
                    checked: column.isSorted && !column.isSortedDescending,
                    disabled: columnPcf.name.includes("."),
                    title: columnPcf.name.includes(".") ? props.getTranslation("sortNotSupportedOnExpanded") : null,
                    onClick: () => onSortColumn(column.key, false),
                },
                {
                    key: 'descending',
                    name: descendingLabel,
                    iconProps: { iconName: 'SortDown' },
                    canCheck: true,
                    checked: column.isSorted && column.isSortedDescending,
                    disabled: columnPcf.name.includes("."),
                    title: columnPcf.name.includes(".") ? props.getTranslation("sortNotSupportedOnExpanded") : null,
                    onClick: () => onSortColumn(column.key, true)
                }
            );
        }
        if (props.enableFiltering && props.enableSorting) {
            itemsOptions.push({
                key: 'divider_1',
                itemType: ContextualMenuItemType.Divider
            });
        }
        if (props.enableFiltering) {
            itemsOptions.push({
                key: 'filterBy',
                name: props.getTranslation("filterBy"),
                iconProps: { iconName: 'Filter' },
                canCheck: true,
                disabled: !types.includes(dataType) || columnPcf.odataExpandAttribute?.split(".").length > 1,
                title: !types.includes(dataType) ? props.getTranslation("filterByNotSupported") : null,
                onClick: (ev, item) => {
                    setIsFilterByCalloutVisible.setTrue();
                },
            });
            if (column.isFiltered) {
                itemsOptions.push({
                    key: 'clearFilter',
                    name: props.getTranslation("filterByDropdownClear"),
                    iconProps: { iconName: 'ClearFilter' },
                    canCheck: true,
                    onClick: (ev, item) => {
                        props.dataset.filtering.clearFilter();
                        // TODO: Clear only persisted filter for column when we manage to combine multiple filters at the same time
                        lookupFiltersRef.current = {};
                    },
                });
            }
        }

        setFilterByColumnTarget(ev.currentTarget as HTMLElement);
        setFilterByColumn(columnPcf);
        return {
            items: itemsOptions,
            target: ev.currentTarget as HTMLElement,
            directionalHint: DirectionalHint.bottomLeftEdge,
            isBeakVisible: false,
            onDismiss: filterByContextualMenuDissmised,
        };
    };
    const mapItems = () => {
        let mappedItems: IViewItem[] = [];
        if (columns != null) {
            let groupBy: string[] = [];
            if (props.enableGroupBy && props.groupByAttributes) {
                for (const column of columns) {
                    let attribute: string = props.groupByAttributes.find(x => x === column.key);
                    if (attribute) groupBy.push(attribute);
                }
            }

            for (const recordId of props.dataset.sortedRecordIds) {
                const record = props.dataset.records[recordId];
                const mappedItem: IViewItem = {
                    key: record.getRecordId(),
                    record: record,
                    compositeKey: groupBy?.length > 0 ? getCompositeKey(record, groupBy) : null,
                };
                mappedItems.push(mappedItem);
            }

            // TODO: This will enable support for external setting of record IDs, not really sure if we need it tho
            // for (const id of props.dataset.getSelectedRecordIds()) {
            //     selection.setKeySelected(id, true, false);
            // }

            // GROUP BY
            if (props.enableGroupBy && groupBy.length > 0) {
                mappedItems = mappedItems.sort((a, b) => {
                    return a.compositeKey.localeCompare(b.compositeKey);
                });

                const uniqueKeys = [...new Set(mappedItems.map(x => x.compositeKey))];
                const groups: ICustomGroup[] = [];

                for (const key of uniqueKeys) {
                    const groupItems = mappedItems.filter(x => x.compositeKey === key);
                    const lastGroup = groups.at(-1);
                    const group: ICustomGroup = {
                        key: key,
                        name: key.split("~$~").join(" - "),
                        groupItem: getGroupItem(groupItems),
                        startIndex: (lastGroup?.startIndex ?? 0) + (lastGroup?.count ?? 0),
                        count: groupItems.length,
                        level: 0,
                        isCollapsed: true,
                        columns: columns
                    };
                    groups.push(group);
                }
                setGroups(groups);
            }
            setItems(mappedItems);
        }
    };
    const getAggregatedValue = (items: IViewItem[], aggregationObject: IAggregate): number => {
        let aggregatedValue = 0;
        for (const item of items) {
            switch (aggregationObject.aggregationType) {
                case "SUM": {
                    if (item.record.getValue(aggregationObject.fieldName) && typeof item.record.getValue(aggregationObject.fieldName) === "number")
                        aggregatedValue += item.record.getValue(aggregationObject.fieldName) as number;
                    break;
                }
                case "AVG": {
                    throw new Error("Method not implemented");
                }
                case "COUNT": {
                    throw new Error("Method not implemented");
                }
            }
        }
        return aggregatedValue;
    };
    const getGroupItem = (items: IViewItem[]) => {
        const firstRecord = items[0].record;
        const _item: IViewItem = {
            key: items.map(x => x.key.toString()),
            compositeKey: null,
            record: {
                getRecordId: firstRecord.getRecordId,
                getFormattedValue: (columnName) => {
                    const aggregationObject = props.aggregateObjectArray?.find(x => x.fieldName === columnName);
                    if (aggregationObject) {
                        return props.formatting.formatDecimal(getAggregatedValue(items, aggregationObject));
                    }
                    return firstRecord.getFormattedValue(columnName);
                },
                getNamedReference: firstRecord.getNamedReference,
                getValue: (columnName) => {
                    const aggregationObject = props.aggregateObjectArray?.find(x => x.fieldName === columnName);
                    if (aggregationObject) {
                        return getAggregatedValue(items, aggregationObject);
                    }
                    return firstRecord.getValue(columnName);
                }
            }
        };
        return _item;
    };
    useEffect(() => {
        mapItems();
    }, [props.dataset.records, props.dataset.sortedRecordIds, columns]);

    const onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedItem(item);
        if (item && item.key) {
            let _column = columns.find(x => x.key === item.key);
            let _items = items.sort(function (a, b) {
                const nameA = a.record.getFormattedValue(_column.key)?.toLocaleLowerCase();
                const nameB = b.record.getFormattedValue(_column.key)?.toLocaleLowerCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            let groups: ICustomGroup[] = [];
            for (const item of _items) {
                let group: IGroup = groups.find(x => x.key === (item.record.getFormattedValue(_column.key) ?? 'noValue'));
                if (group) {
                    group.count++;
                }
                else {
                    let _group: ICustomGroup = {
                        key: item.record.getFormattedValue(_column.key) ?? 'noValue',
                        name: item.record.getFormattedValue(_column.key) ?? props.getTranslation('noValue'),
                        groupItem: getGroupItem(_items),
                        startIndex: _items.indexOf(item),
                        count: 1,
                        level: 0,
                        columns: columns
                    };
                    groups.push(_group);
                }
            }
            setItems(_items);
            setGroups(groups);
        } else {
            mapItems();
            setGroups(null);
        }
    };
    const handleColumnReorder = (draggedIndex: number, targetIndex: number) => {
        const draggedItems = columns[draggedIndex];
        const newColumns: IColumn[] = [...columns];

        // insert before the dropped item
        newColumns.splice(draggedIndex, 1);
        newColumns.splice(targetIndex, 0, draggedItems);
        props.fireEvent('__updateColumnOrder', newColumns.map(x => x.key));
        setColumns(newColumns);
    };

    const getColumnReorderOptions = (): IColumnReorderOptions => {
        return {
            handleColumnReorder: handleColumnReorder,
        };
    };
    const getViewStyles = (): string => {
        return mergeStyles({
            '.TALXIS-Portal-View-noData': {
                textAlign: 'center',
                paddingTop: '20px',
                paddingBottom: '20px',
                'i': {
                    display: 'block',
                    fontSize: 25
                }
            },
            '.ms-DetailsList': {
                overflow: props.dataset.loading && 'hidden',
                '.ms-DetailsList-contentWrapper': {
                    display: props.dataset.loading && 'none',
                    '.TALXIS__ribbon': {
                        borderBottom: 'none'
                    },
                    '.ms-DetailsRow-cell': {
                        textAlign: 'left',
                        ':has([data-type-number])': {
                            textAlign: 'right'
                        }
                    },
                }
            },
            '[class^="ViewLoading_root"]': {
                marginTop: columns && 0,
                hr: {
                    display: columns && 'none'
                }
            }
        });
    };

    const pageSize: number = props.dataset.paging.pageSize;
    const start = (props.pageNumber - 1) * pageSize + (props.dataset.paging.totalResultCount === 0 ? 0 : 1);
    let end = props.pageNumber * pageSize;
    if (props.dataset.paging.totalResultCount !== -1 && props.dataset.paging.totalResultCount < end) end = props.dataset.paging.totalResultCount;
    else if (props.dataset.paging.totalResultCount === -1 && props.dataset.paging.hasNextPage === false) end = start + Object.keys(props.dataset.records).length - 1;

    return (
        <div ref={ref} className={getViewStyles()}>
            {props.enableGroupBy && !props.groupByAttributes &&
                <Dropdown
                    label={props.getTranslation("groupby")}
                    selectedKey={selectedItem ? selectedItem.key : undefined}
                    onChange={onChange}
                    placeholder=" --- "
                    options={dropdownOptions}
                />
            }
            {isFilterByCalloutVisible && (
                <FilterCallout
                    getTranslation={props.getTranslation}
                    columnTarget={filterByColumnTarget}
                    column={filterByColumn}
                    dataset={props.dataset}
                    lookupFilter={lookupFiltersRef.current[filterByColumn.name]}
                    persistLookupFilter={(columnName, values) => {
                        // TODO: Replace only persisted filter for column when we manage to combine multiple filters at the same time
                        lookupFiltersRef.current = {};
                        lookupFiltersRef.current[filterByColumn.name] = values;
                    }}
                    factory={props.factory as NestedPcfFactory}
                    onFilterChange={(expression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression) => {
                        props.dataset.filtering.setFilter(expression);
                    }}
                    close={() => {
                        setIsFilterByCalloutVisible.setFalse();
                    }}
                />
            )}
            <DetailsList
                selectionMode={selectionMode}
                items={items}
                setKey={props.pageNumber.toString()}
                columns={columns}
                groups={groups}
                dragDropEvents={columnDragDropService.getDragDropEvents()}
                columnReorderOptions={getColumnReorderOptions()}
                onItemInvoked={(item) => {
                    if (props.onOpenDatasetItem && item.record) {
                        props.onOpenDatasetItem({
                            etn: props.dataset.getTargetEntityType(),
                            name: '',
                            id: {
                                guid: item.record.getRecordId()
                            }
                        });
                    }
                }}
                groupProps={{
                    onRenderHeader: (groupDividerProps: IDetailsGroupDividerProps) => {
                        if (props.aggregationButtonPlacement === "OnAggregatedRow" || props.aggregationButtonPlacement === "OnExpandedAndAggregatedRow") {
                            return (
                                <GroupHeader ribbon={ribbon} {...groupDividerProps} group={groupDividerProps.group as ICustomGroup} formContext={formContext} />
                            );
                        }
                        else {
                            return (
                                <GroupHeader {...groupDividerProps} group={groupDividerProps.group as ICustomGroup} formContext={formContext} />
                            );
                        }
                    }
                }}
                selection={selection}
                selectionPreservedOnEmptyClick={true}
                onRenderDetailsHeader={(props, defaultRender) => {
                    //TODO: Better solution is to implement onColumnResize logic
                    const _detailHeaderProps = {
                        ...props,
                        columns: [...props.columns]
                    };
                    for (const column of _detailHeaderProps.columns) {
                        if (column?.calculatedWidth === 1 && column?.maxWidth !== 1) {
                            column.calculatedWidth = MIN_COLUMN_WIDTH;
                            column.currentWidth = MIN_COLUMN_WIDTH;

                        }
                    }

                    _detailHeaderProps.onRenderColumnHeaderTooltip = (props, defaultRender) => {
                        return <div title={props?.column?.name}>
                            {defaultRender(props)}
                        </div>;
                    };
                    return defaultRender(_detailHeaderProps);
                }}
                onRenderItemColumn={(item: IViewItem, index, column) => {
                    const columnIndex = columns.findIndex(x => x.key === column.key);
                    const compositeKeys = item.compositeKey?.split('~$~') || [];
                    const isGroupedColumn = compositeKeys.find(key => key == item.record.getFormattedValue(column.key)) ? true : false;
                    return (
                        <div data-id={`cell-${index}-${columnIndex}`} style={{ width: 200 }}>
                            {!isGroupedColumn && !props.dataset.loading &&
                                <ItemColumn
                                    onOpenDatasetItem={props.onOpenDatasetItem}
                                    ribbon={props.aggregationButtonPlacement !== "OnAggregatedRow" && ribbon}
                                    column={column}
                                    item={item}
                                    formContext={formContext}
                                    primaryName={props.primaryName}
                                    refresh={() => props.dataset.refresh()} />
                            }
                        </div>
                    );
                }}
                compact={props.enableGroupBy}
                layoutMode={DetailsListLayoutMode.justified}
                listProps={{
                    onRenderRoot: (listProps, defaultRender) => {
                        if (listProps.pages.length === 0 || listProps.pages[0]?.itemCount === 0) {
                            return (
                                <div className='TALXIS-Portal-View-noData'>
                                    <Icon iconName='TextDocument' />
                                    <Text>{props.getTranslation("noRecordsFound")}</Text>
                                </div>
                            );
                        }
                        else {
                            return defaultRender(listProps);
                        }
                    }
                }}
            />
            {props.dataset.loading &&
                <ViewLoading />}
            <CommandBar
                items={[{
                    key: 'CurrentItems',
                    text: props.getTranslation("pages", { start: start, end: end, recordcount: props.dataset.paging.totalResultCount >= 0 ? props.dataset.paging.totalResultCount : "5000+" }),
                    ariaLabel: props.getTranslation("currentItems"),
                    disabled: true,
                }]}
                farItems={[{
                    key: 'FirstPage',
                    text: props.getTranslation("firstPage"),
                    ariaLabel: props.getTranslation("firstPage"),
                    iconOnly: true,
                    iconProps: { iconName: 'DoubleChevronLeft' },
                    disabled: !props.dataset.paging.hasPreviousPage,
                    onClick: () => props.dataset.paging.reset(),
                }, {
                    key: 'PreviousPage',
                    text: props.getTranslation("back"),
                    ariaLabel: props.getTranslation("back"),
                    iconOnly: true,
                    iconProps: { iconName: 'Back' },
                    disabled: !props.dataset.paging.hasPreviousPage,
                    onClick: () => props.dataset.paging.loadPreviousPage(),
                }, {
                    key: 'CurrentPage',
                    text: props.getTranslation("currentPage", { pagenumber: props.pageNumber }),
                    ariaLabel: 'Current Page',
                    disabled: true,
                }, {
                    key: 'NextPage',
                    text: props.getTranslation("next"),
                    ariaLabel: props.getTranslation("next"),
                    iconOnly: true,
                    iconProps: { iconName: 'Forward' },
                    disabled: !props.dataset.paging.hasNextPage,
                    onClick: () => props.dataset.paging.loadNextPage(),
                }]}
            />
            {filterByMenuProps && <ContextualMenu {...filterByMenuProps} />}
        </div>
    );
};