import React, { useEffect, useContext, useState } from 'react';
import { AppModule } from '@configuration/AppModule';
import { history, AppContext } from '@providers/AppProvider';
import { SitemapDefinition } from '@definitions/SitemapDefinition';
import { SpaConfiguration } from '@configuration/SpaConfiguration';
import { IAppContext } from '@providers/AppProvider/interfaces';
import { IPageRoute } from '@models/SiteMap';
import { replaceLast } from '@app/Functions';
import { requireAuthentication } from '@app/classes/Authentication';
import { FieldControl } from '@controls/FieldControl';
import { FormControlType } from '@controls/native/Form/interfaces/enums';
import { useRef } from 'react';
import { ControlLoader, ControlRegistration } from '@loaders/ControlLoader';
import { RouteComponentProps } from 'react-router-dom';
import { Placeholder } from '@controls/native/Form/components/Placeholder';
import { mergeStyles, useTheme } from '@fluentui/react';
import { useMemo } from 'react';
import styles from './HomePage.module.css';

const className = 'TALXIS__portal__page';
export interface IHomeProps extends RouteComponentProps {

}

const Home: React.FC<IHomeProps> = (props) => {
    const appContext: IAppContext = useContext(AppContext);
    const [route, setRoute] = useState<IPageRoute>();
    const [shouldReload, setShouldReload] = useState<boolean>(false);
    const controlDefinitionRef = useRef<ControlRegistration>(null);
    const theme = useTheme();
    const homePageClassNames = useMemo(() => {
        return `${className} ${styles.root} ${mergeStyles({
            padding: 15,
            backgroundColor: theme.semanticColors.cardStandoutBackground,
            boxShadow: theme.semanticColors.cardShadow
        })}`;
    }, []);

    useEffect(() => {
        const routes = SitemapDefinition.getStaticPageRoutes();
        const currentAppModule = AppModule.get().uniquename;
        let currentRoutePath = window.location.pathname;
        let routeContainsAppModule = false;
        if (currentRoutePath.startsWith(`/${currentAppModule}`)) {
            currentRoutePath = currentRoutePath.replace(`/${currentAppModule}`, "");
            routeContainsAppModule = true;
        }
        // Strip trailing slash
        if (currentRoutePath.endsWith("/") && currentRoutePath.length > 1) {
            currentRoutePath = replaceLast(currentRoutePath, "/", "");
        }
        const route = routes?.find(x => x.slug === currentRoutePath || x.slug === `${currentRoutePath}/`);
        if (route) {
            if (currentAppModule !== SpaConfiguration.get().defaultAppModule && !routeContainsAppModule) {
                history.push(`/${currentAppModule}/${currentRoutePath}`);
            }
            else {
                const key = SitemapDefinition.getCurrentPageKey();
                console.log(`URL has changed. Current page key: ${key}`);
                if (!key) return;
                appContext.setCurrentKey(key);
                (async () => {
                    if (route.requiresAuthentication) {
                        await requireAuthentication();
                    }
                    controlDefinitionRef.current = await ControlLoader.getAsync('TALXIS.PCF.WebPage');
                    setRoute(route);
                    setShouldReload(true);
                    appContext.SetLoading(false);
                })();
            }
        }
        else {
            // TODO: This is legacy fallback
            history.push(`/${AppModule.get().uniquename}/control/form`);
        }
    }, [props.location.pathname]);

    useEffect(() => {
        if (setShouldReload) {
            setShouldReload(false);
        }
    }, [shouldReload]);

    return (
        <div className={homePageClassNames}>
            {appContext.isNavbarLoaded && !shouldReload && route &&
                <>
                    {/*                     <div>Page ID: {route.id}</div>
                    <div>Slug: {route.slug}</div>
                    <div>Requires Authentication: {route.requiresAuthentication ? "true" : "false"}</div> */}
                    <FieldControl name="TALXIS.PCF.WebPage"
                        bindings={{
                            Id: {
                                isStatic: true,
                                value: route.id
                            }
                        }}
                        disableLoading
                        id=""
                        classId=""
                        datafieldname={null}
                        disabled={false}
                        type={FormControlType.Field}
                        visible={true}
                        isUnbound={true}
                        isRequired={false}
                        definition={controlDefinitionRef.current}

                    />
                </>
            }
            {!appContext.isNavbarLoaded &&
                <Placeholder />
            }
        </div>
    );
};
export default Home;