import React, { useState } from 'react';
import { getTheme, ICommandBar, ICommandBarProps, Image, mergeStyles, PanelType, Stylesheet, ThemeProvider, themeRulesStandardCreator, useTheme } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react';
import { CommandBar } from '@talxis/react-components/dist/components/CommandBar';
import { Link } from 'react-router-dom';
import { AppPanel } from './components/AppPanel';
import { useEffect } from 'react';
import { AppModule } from '@configuration/AppModule';
import { SpaConfiguration } from '@configuration/SpaConfiguration';
import styles from './Navbar.module.css';
import { useMemo } from 'react';
import { history } from '@providers/AppProvider';
import { ThemeDefinition } from '@definitions/ThemeDefinition';

interface INavbarProps {
    appRibbonCommandBarProps: ICommandBarProps;
    isSmallScreen: boolean;
    logoSrc?: string;
    verticalNavigationEnabled?: boolean;
    verticalNavigationVisible?: boolean;
    onToggleVerticalNavigation?: (visible: boolean) => void;
}

export const Navbar: React.FC<INavbarProps> = (props) => {
    const theme = ThemeDefinition.getTheme().getNavbarTheme();
    const [isAppPanelOpen, setIsAppPanelOpen] = useState<boolean>(false);
    const [showAppPanelBtn, setShowAppPanelBtn] = useState<boolean>(false);
    const commandBarStyles = useMemo(() => {
        return mergeStyles({
            '.TALXIS__ribbon__icon__svg path': {
                fill: theme.palette.themePrimary
            },
            ' > div > div > .ms-CommandBar': {
                height: 48,
                '> div > div > .TALXIS__ribbon__pcf>div>.ms-Button--commandBar': {
                    backgroundColor: 'transparent',
                    height: 48,
                    '> span .ms-Persona-primaryText, > span .ms-Button-label': {
                        color: theme.semanticColors.bodyText,
                        fontWeight: 600
                    },
                    ':hover': {
                        backgroundColor: theme.semanticColors.buttonBackgroundHovered
                    },
                    ':active': {
                        backgroundColor: theme.semanticColors.buttonBackgroundPressed
                    },
                }
            }
        });
    }, [theme]);
    const getLeftSideItems = () => {
        const items: ICommandBarItemProps[] = [];
        if (props.verticalNavigationEnabled && props.isSmallScreen) {
            const menuButton: ICommandBarItemProps = {
                key: 'menu',
                iconProps: {
                    iconName: 'GlobalNavButton'
                },
                onClick: () => props.onToggleVerticalNavigation(!props.verticalNavigationVisible)
            };
            items.push(menuButton);
        }
        if (props.logoSrc) {
            items.push({
                key: 'logo',
                className: `TALXIS__navbar__left-side__logo ${styles.logo}`,
                onClick: (e) => {
                    e.preventDefault();
                    history.push('/');
                },
                iconProps: {
                    imageProps: {
                        src: props.logoSrc
                    }
                }
            });
        }
        //TODO: show app panel button only when default app module is not set in configuration (shoud currenlty apply to dev instances only)
        //!SpaConfiguration.get().defaultAppModule && showAppPanelBt
        //currently disabled
        if (false) {
            items.push({
                key: 'appSwitcher',
                iconProps: {
                    iconName: 'WaffleOffice365'
                },
                onClick: () => setIsAppPanelOpen(true)
            });
        }
        return items;
    };
    const init = async () => {
        const appModules = await AppModule.getAllAppModules();
        if (appModules.length > 1) {
            setShowAppPanelBtn(true);
        }
    };
    useEffect(() => {
        init();
    }, []);
    return (
        <>
            <nav className={`TALXIS__navbar ${styles.root} ${mergeStyles({
                borderBottom: `1px solid ${theme.semanticColors.menuDivider}`
            })}`}>
                <ThemeProvider theme={theme}>
                    <CommandBar
                        className={`TALXIS__navbar__left-side ${styles.leftSide} ${commandBarStyles}`}
                        items={[]}
                        farItems={getLeftSideItems()} />
                    <CommandBar
                        {...props.appRibbonCommandBarProps}
                        className={`TALXIS__navbar__right-side ${props.appRibbonCommandBarProps.className} ${styles.rightSide} ${commandBarStyles}`} />
                </ThemeProvider>
            </nav>
            {/*
                <AppPanel
                    isOpen={isAppPanelOpen}
                    type={PanelType.customNear}
                    customWidth={'350px'}
                    isLightDismiss
                    headerText={window.TALXIS.Portal.Translations.getLocalizedString("components/dialogs/AppSwitcherDialog/Title")}
                    onDismiss={() => setIsAppPanelOpen(false)} />
            </nav> */}
        </>
    );
};
