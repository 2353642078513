import cloneDeep from "lodash/cloneDeep";

export interface IPromiseCache<T> {
    [key: string]: Promise<T>;
}

export const cachedWrapper = async <T>(key: string, func: () => Promise<T>, cache: IPromiseCache<T>): Promise<T> => {
    if (!cache[key]) {
        cache[key] = func();
    }

    const definition = await cache[key];

    // XMLDocument (for example ribbon) doesn't support deep copy, so we just return it as is
    if (definition instanceof XMLDocument) {
        return definition;
    }

    // We clone the object because if someone touches it when passed by reference, the cache would become dirty
    return cloneDeep(definition);
};