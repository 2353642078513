import JSZip from 'jszip';
import { ILocalizedLabel, LocalizeLabel } from '@localization/helpers';
import { APPLICATION_RIBBON_ENTITY_NAME, DomParser } from '../../../Constants';
import { IconLoader } from '@loaders/IconLoader';
import { ScriptLoader } from '@loaders/ScriptLoader';
import { IRibbonDefinition, IRibbonDefinitionButton, IRibbonDefinitionButtonFunction, IRibbonDefinitionButtonFunctionParameter, IRibbonDefinitionButtonMenuSection, IRibbonDefinitionButtonRule, IRibbonDefinitionGroup, RibbonDefinitionButtonType } from './interfaces';
import { RibbonButtonOverrider } from './RibbonButtonOverrider';
import { IPromiseCache, cachedWrapper } from '@utilities/MemoryCachingHelpers';
import { sendMetadataGetRequest, metadataRetrieveMultiple } from '../MetadataApi';
import { Ribbon } from '@models/Ribbon/Ribbon';

export enum RibbonLocationFilters {
    All = "All",
    Default = "Default",
    Form = "Form",
    HomepageGrid = "HomepageGrid",
    SubGrid = "SubGrid",
}
export class RibbonDefinition {
    private static _ribbonCache: IPromiseCache<Ribbon> = {};
    private static _ribbonDefinitionAll: IPromiseCache<Document> = {};
    private static _ribbonGlobalLabelCache: IPromiseCache<ILocalizedLabel[]> = {};

    static async getRibbon(entityName: string, filter: RibbonLocationFilters = RibbonLocationFilters.All): Promise<Ribbon> {
        const key = `${entityName}_${filter}`;
        return cachedWrapper(key, () => new Promise(async (resolve) => {
            // Initiate the full ribbon download request, but don't wait for it, because we need it when parsing commands
            this._getRibbonAll(entityName);
            let query = `v9.1/RetrieveEntityRibbon(EntityName='${entityName}',%20RibbonLocationFilter=Microsoft.Dynamics.CRM.RibbonLocationFilters'${filter}')`;
            let compressedXmlName = 'CompressedEntityXml';
            if (entityName === APPLICATION_RIBBON_ENTITY_NAME) {
                query = 'v9.1/RetrieveApplicationRibbon';
                compressedXmlName = 'CompressedApplicationRibbonXml';
            }
            const response = await sendMetadataGetRequest(query);
            const result = await response.json();
            const xmlDoc = await getRibbonFromApiResponse(result[compressedXmlName]);
            const ribbonDefinition = await this._parseRibbon(xmlDoc, result["__labels"], entityName);
            const ribbon = new Ribbon(entityName, ribbonDefinition);
            resolve(ribbon);
        }), this._ribbonCache);
    };
    private static async _parseButtons(xmlDoc: Document, labels: { [key: string]: ILocalizedLabel[] }, parent: Element, entityName: string): Promise<IRibbonDefinitionButton[]> {
        //full ribbon is xmlDoc in case of application ribbon
        const fullRibbon = await this._getRibbonAll(entityName) ?? xmlDoc;
        this._createSelector();
        const buttons = parent.querySelectorAll(this._createSelector());
        return Promise.all([...buttons].map(async button => {
            const id = button.getAttribute('Id');
            let label = await this._getLabel(xmlDoc, button, labels);
            const buttonType = this._getRibbonButtonType(button, label);
            if (buttonType === RibbonDefinitionButtonType.PCF) {
                label = label.split('__pcf_')[1];
            }
            const command = button.getAttribute('Command');
            const commandDefinition = fullRibbon.querySelector(`CommandDefinition[Id='${button.getAttribute('Command')}' i]`);
            const enableRules = commandDefinition?.querySelectorAll('EnableRule');
            const javascriptFunction = commandDefinition?.querySelector('JavaScriptFunction');
            const isInline = this._isInlineButton(enableRules, fullRibbon);
            const ribbonButton: IRibbonDefinitionButton = {
                id: id,
                command: command,
                label: label,
                isInline: isInline,
                type: buttonType,
                rules: await this._parseRules(fullRibbon, enableRules, command, entityName),
                function: await this._getFunction(javascriptFunction, command),
                hideCustomAction: this._isButtonHidden(button, xmlDoc),
                icon: await IconLoader.getAsync(button.getAttribute('ModernImage')),
                menuSections: await (async (): Promise<IRibbonDefinitionButtonMenuSection[]> => {
                    const menuSections = button.querySelectorAll(':scope > Menu > MenuSection');
                    return Promise.all([...menuSections].map(async menuSection => {
                        return {
                            id: menuSection.getAttribute('Id'),
                            label: await this._getLabel(xmlDoc, menuSection, labels),
                            buttons: await this._parseButtons(xmlDoc, labels, menuSection, entityName)
                        };
                    }));
                })()
            };
            RibbonButtonOverrider.override(ribbonButton, javascriptFunction);
            return ribbonButton;
        }));
    }
    private static _getRibbonButtonType(button: Element, label?: string) {
        if (!label?.startsWith('__pcf')) {
            return button.tagName as RibbonDefinitionButtonType;
        }
        return RibbonDefinitionButtonType.PCF;
    }
    private static _createSelector() {
        let customBtnSelector = "";
        let nativeBtnSelector = "";
        let topLevelSelector = "";
        topLevelSelector = ':scope>Controls>';
        ['Button', 'SplitButton', 'FlyoutAnchor'].forEach((buttonType) => {
            for (const command of RibbonButtonOverrider.supportedNativeButtons) {
                nativeBtnSelector += `${topLevelSelector}${buttonType}[Command="${command}"],`;
            }
            customBtnSelector += `${topLevelSelector}${buttonType}:not([Id^="mscrm" i]):not([Id^="AIBuilder" i]):not([Id^="adx." i]):not([Id^="AccessChecker." i]):not([Id^="MailApp" i]):not([Id^="Microsoft" i]):not([Id^="msdyn_" i]),`;
        });
        return nativeBtnSelector + customBtnSelector.slice(0, -1);
    }
    private static async _parseRibbon(xmlDoc: Document, labels: { [key: string]: ILocalizedLabel[] }, entityName: string): Promise<any> {
        const timeStart = RibbonDefinition.logGroupStart(entityName, 'Application Ribbon Loading');
        const ribbon: IRibbonDefinition = {
            groups: await (async (): Promise<IRibbonDefinitionGroup[]> => {
                let tabSelector = 'Tab';
                if (entityName === APPLICATION_RIBBON_ENTITY_NAME) {
                    tabSelector = 'Tab[Id="Mscrm.GlobalTab"]';
                }
                const groups = xmlDoc.querySelector(tabSelector).querySelectorAll('Group');
                return Promise.all([...groups].map(async (group) => {
                    return {
                        buttons: await this._parseButtons(xmlDoc, labels, group, entityName)
                    };
                }));
            })()
        };
        RibbonDefinition.logGroupEnd(entityName, "Loading of application ribbon took", timeStart);
        return ribbon;
    };
    private static _isButtonHidden = (button: Element, xmlDoc: Document) => {
        const hideActions = xmlDoc.querySelectorAll('HideCustomAction');
        for (const hideAction of hideActions) {
            if (hideAction.getAttribute('Location') === button.getAttribute('Id')) {
                return true;
            };
        };
        return false;
    }

    private static async _getRibbonAll(entityName: string): Promise<Document> | undefined {
        //application ribbon xmlDoc is the full ribbon
        if (entityName === APPLICATION_RIBBON_ENTITY_NAME) {
            return undefined;
        }
        return cachedWrapper(entityName, () => new Promise(async (resolve, reject) => {
            const response = await sendMetadataGetRequest(`v9.1/RetrieveEntityRibbon(EntityName='${entityName}',%20RibbonLocationFilter=Microsoft.Dynamics.CRM.RibbonLocationFilters'${RibbonLocationFilters.All}')`);
            const result = await response.json();
            resolve(await getRibbonFromApiResponse(result['CompressedEntityXml']));
        }), this._ribbonDefinitionAll);
    }
    private static async _parseRules(fullRibbon: Document, enableRules: NodeListOf<Element> | undefined, command: string, entityName: string): Promise<IRibbonDefinitionButtonRule[]> {
        const rules: IRibbonDefinitionButtonRule[] = [];
        if (!enableRules) {
            return [];
        }
        for (const enableRule of enableRules) {
            const id = enableRule.getAttribute('Id');
            const enableRuleDefinition = fullRibbon.querySelector('RuleDefinitions').querySelector(`EnableRule[Id='${id}']`);
            if (enableRuleDefinition?.querySelector('CustomRule')) {
                const timeStart = RibbonDefinition.logStart(entityName);
                const CustomRule = enableRuleDefinition.querySelector('CustomRule');
                const rule: IRibbonDefinitionButtonRule = {
                    type: "custom",
                    id: id,
                    function: await this._getFunction(CustomRule, command),
                    default: CustomRule.getAttribute('Default') === 'true',
                    invertResult: CustomRule.getAttribute('InvertResult') === 'true'
                };
                RibbonDefinition.logEnd(entityName, `Enable Rule ${id} loaded in`, timeStart);
                rules.push(rule);
            }
            else if (enableRuleDefinition?.querySelector('ValueRule')) {
                const CustomRule = enableRuleDefinition.querySelector('ValueRule');
                const rule: IRibbonDefinitionButtonRule = {
                    type: "value",
                    id: id,
                    function: {
                        command: null,
                        parameters: [{
                            type: "CrmParameter",
                            value: "entity"
                        }],
                        action: (entity: ComponentFramework.PropertyHelper.DataSetApi.EntityRecord) => {
                            if (!entity) {
                                return false;
                            }
                            const field = CustomRule.getAttribute('Field');
                            const value = CustomRule.getAttribute('Value');
                            // We are not doing strict type compare because value returned from API can be true but comparing to '1' would fail and we are not aware of types at this point.
                            if (entity?.getValue) {
                                if (entity.getValue(field) == value) {
                                    return true;
                                }
                            }
                            else if ((entity as ComponentFramework.WebApi.Entity)[field] == value) {
                                return true;
                            }
                            return false;
                        }
                    },
                    default: CustomRule.getAttribute('Default') === 'true',
                    invertResult: CustomRule.getAttribute('InvertResult') === 'true'
                };
                rules.push(rule);
            }
            else if (enableRuleDefinition?.querySelector("SelectionCountRule")) {
                const CustomRule = enableRuleDefinition.querySelector('SelectionCountRule');
                const rule: IRibbonDefinitionButtonRule = {
                    type: "selectionCount",
                    id: id,
                    function: {
                        command: null,
                        parameters: [{
                            type: "CrmParameter",
                            value: "SelectedControlSelectedItemIds"
                        }],
                        action: (selectedControlSelectedItemIds: string[]) => {
                            const minimum = parseInt(CustomRule.getAttribute('Minimum'));
                            const maximum = parseInt(CustomRule.getAttribute('Maximum'));

                            if (!isNaN(minimum) && isNaN(maximum)) {
                                return selectedControlSelectedItemIds.length >= minimum;
                            }
                            else if (isNaN(minimum) && !isNaN(maximum)) {
                                return selectedControlSelectedItemIds.length <= maximum;
                            }
                            else if (!isNaN(minimum) && !isNaN(maximum)) {
                                return selectedControlSelectedItemIds.length >= minimum && selectedControlSelectedItemIds.length <= maximum;
                            }
                            else {
                                //throw new Error(`Unsupported SelectionCountRule values! Min: ${minimum}, Max: ${maximum}`);
                            }
                        }
                    },
                    default: CustomRule.getAttribute('Default') === 'true',
                    invertResult: CustomRule.getAttribute('InvertResult') === 'true'
                };
                rules.push(rule);
            }
        }
        return rules;
    }

    private static async _getLabel(xmlDoc: Document, button: Element, labels: { [key: string]: ILocalizedLabel[] }): Promise<string> {
        const labelId = button.getAttribute('LabelText') ?? button.getAttribute('Title');
        if (!labelId) {
            return null;
        }
        const labelMatches = [...labelId?.matchAll(/#(.*)#(.*)/g) ?? []];
        const labelMatchResult = labelMatches.length === 1 ? labelMatches[0] : [];
        if (labelMatchResult.length > 2) {
            return labelMatchResult[2];
        }
        const parts = labelId.split(':');
        const labelIdPart = parts[1];
        if (labels && labels[labelIdPart]) {
            return LocalizeLabel(labels[labelIdPart]);
        }

        return LocalizeLabel(await this._getGlobalRibbonLabel(parts[1])) ?? labelId;
    }

    private static async _getGlobalRibbonLabel(key: string): Promise<ILocalizedLabel[]> {
        return cachedWrapper(key, () => new Promise(async (resolve, reject) => {
            const labelFromApi = await metadataRetrieveMultiple(`v9.1/ribbondiffs?$filter=tabid eq 'Mscrm.LocLabels' and diffid eq '${key}'&$select=rdx`);
            if (labelFromApi.entities.length === 0) {
                resolve([]);
            }
            else {
                const labels: ILocalizedLabel[] = [];
                const labelsXml = DomParser.parseFromString(labelFromApi.entities[0]["rdx"], "text/xml");
                for (const labelElement of labelsXml.getElementsByTagName("Title")) {
                    labels.push({
                        Label: labelElement.getAttribute("description"),
                        LanguageCode: parseInt(labelElement.getAttribute("languagecode"))
                    });
                }
                resolve(labels);
            }
        }), this._ribbonGlobalLabelCache);
    }

    private static async _getFunction(functionElement: Element, command: string): Promise<IRibbonDefinitionButtonFunction> {
        if (!functionElement) {
            return null;
        }
        const library = functionElement.getAttribute('Library').split(':')[1];
        const functionName = functionElement.getAttribute('FunctionName');
        if (functionName && library && !functionName.startsWith('XrmCore') && library !== 'Main_system_library.js') {
            await ScriptLoader.loadWebResourceLibraryAndDependencies(library);
        }
        const parameters: IRibbonDefinitionButtonFunctionParameter[] = [];

        for (const parameter of functionElement.children) {
            if (parameter.tagName !== "CrmParameter" && parameter.tagName !== "StringParameter") {
                console.warn("Encountered unknown parameter type when parsing function parameters!", parameter.tagName);
                continue;
            }
            parameters.push({
                type: parameter.tagName,
                value: parameter.getAttribute('Value')
            });
        }

        return {
            command: functionName,
            parameters: parameters
        };
    }
    private static _isInlineButton(enableRules: NodeListOf<Element> | undefined, fullRibbon: Document): boolean | null {
        if (!enableRules) {
            return false;
        }
        for (const enableRule of enableRules) {
            const rule = fullRibbon.querySelector('RuleDefinitions').querySelector(`EnableRule[Id='${enableRule.getAttribute('Id')}']`);
            const selectionCountRule = rule?.querySelector('SelectionCountRule');
            const min = selectionCountRule?.getAttribute('Minimum');
            const max = selectionCountRule?.getAttribute('Maximum');
            if (min === '1' && max === '1') {
                return true;
            }
        }
        return false;
    }

    public static logGroupStart(entityName: string, title: string): number {
        if (entityName !== APPLICATION_RIBBON_ENTITY_NAME) {
            return;
        }
        console.groupCollapsed(title);
        return performance.now();

    }
    public static logGroupEnd(entityName: string, title: string, timeStart: number): void {
        if (entityName !== APPLICATION_RIBBON_ENTITY_NAME) {
            return;
        }
        console.log(`${title} ${(performance.now() - timeStart)}ms`);
        console.groupEnd();
    }
    public static logStart(entityName: string): number {
        if (entityName !== APPLICATION_RIBBON_ENTITY_NAME) {
            return;
        }
        return performance.now();
    }
    public static logError(message?: any, ...optionalParams: any[]) {
        console.error(message, ...optionalParams);
    }
    public static logEnd(entityName: string, title: string, timeStart?: number): void {
        if (entityName !== APPLICATION_RIBBON_ENTITY_NAME) {
            return;
        }
        if (timeStart) {
            console.log(`${title} ${(performance.now() - timeStart)}ms`);
        }
        else {
            console.log(title);
        }
    }
}

export const getRibbonFromApiResponse = async (base64File: string): Promise<Document> => {
    const zip = new JSZip();
    const zipFile = atob(base64File);
    const unZipResult = await zip.loadAsync(zipFile);
    const file = unZipResult.files['RibbonXml.xml'];
    const xml = await file.async("string");
    return DomParser.parseFromString(xml, "text/xml");
};