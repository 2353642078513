
import { IDatePickerProps as IDatePickerPropsBase } from '@talxis/react-components/dist/components/DatePicker';
import { INativeControlProps } from '../../interfaces';

export enum DateTimeType {
    DateAndTime = 'DateAndTime',
    TimeOnly = 'TimeOnly'
}

export interface IDatePickerProps extends Omit<IDatePickerPropsBase, 'disabled'> {
    availableDates?: Date[];
    excludedDates?: Date[];
    availableOptions?: DateTimeType;
    excludeOptions?: DateTimeType;
    excludeDaysOfTheWeekArray?: number[];
    hideYearSelection?: boolean; //not implemented
    hideMonthSelection?: boolean;
    hideWeekSelection?: boolean; //not implemented
    hideDaySelection?: boolean;
    languageCode?: number;
}

export interface IDateTimePickerProps extends IDatePickerProps {
    format?: string;
    behavior?: ComponentFramework.FormattingApi.Types.DateTimeFieldBehavior;
    enableDeleteButton?: boolean;
    onDeleteButtonClick(): void;
}

export interface ITextFieldProps extends INativeControlProps {
    value: string;
    onNotifyOutputChanged(value: string): void;
}