import { IFetchXmlInterceptor, WebApi as PcfWebApi } from '@ComponentFramework/PropertyClasses/WebApi';

export class WebApi implements Xrm.WebApi {

    ///@ts-ignore - We are not implementing PromiseLike
    public online: Xrm.WebApiOnline = this;

    ///@ts-ignore - We are not implementing PromiseLike
    public offline: Xrm.WebApiOffline = this;

    private _pcfWebApi: PcfWebApi;

    constructor(pcfWebApi: PcfWebApi) {
        this._pcfWebApi = pcfWebApi;
    }

    public isAvailableOffline() {
        return false;
    }
    async executeAuthenticatedRequest(input: RequestInfo, init?: RequestInit): Promise<Response> {
        return this._pcfWebApi.executeAuthenticatedRequest(input, init);
    }

    registerFetchXmlInterceptor(name: string, interceptor: IFetchXmlInterceptor): void {
        PcfWebApi.registerFetchXmlInterceptor(name, interceptor);
    }

    ///@ts-ignore - We are not implementing PromiseLike
    async execute(request: any): Promise<Response> {
        return this._pcfWebApi.execute(request);
    }

    ///@ts-ignore - We are not implementing PromiseLike
    async executeMultiple(requests: any[]): Promise<Response[]> {
        let responses = [];
        for (const request of requests) {
            responses.push(await this.execute(request));
        }
        return responses;
    }

    ///@ts-ignore - We are not implementing PromiseLike
    public async createRecord(entityLogicalName: string, record: any): Promise<Xrm.CreateResponse> {
        const result = await this._pcfWebApi.createRecord(entityLogicalName, record);

        return {
            entityType: entityLogicalName,
            id: result.id
        };
    }
    ///@ts-ignore - We are not implementing PromiseLike
    public async deleteRecord(entityLogicalName: string, id: string): Promise<string> {
        const result = await this._pcfWebApi.deleteRecord(entityLogicalName, id);

        ///@ts-ignore - As per https://docs.microsoft.com/en-us/powerapps/developer/model-driven-apps/clientapi/reference/xrm-webapi/deleterecord#return-value an object should be returned instead of a string. This is likely a bug in @types/xrm
        return {
            entityType: entityLogicalName,
            id: result.id
        };
    }
    ///@ts-ignore - We are not implementing PromiseLike
    public async retrieveRecord(entityLogicalName: string, id: string, options?: string): Promise<any> {
        const result = await this._pcfWebApi.retrieveRecord(entityLogicalName, id, options);

        return result;
    }
    ///@ts-ignore - We are not implementing PromiseLike
    public async retrieveMultipleRecords(entityLogicalName: string, options?: string, maxPageSize?: number): Promise<Xrm.RetrieveMultipleResult> {
        const result = await this._pcfWebApi.retrieveMultipleRecords(entityLogicalName, options, maxPageSize);

        return result;
    }
    ///@ts-ignore - We are not implementing PromiseLike
    public async updateRecord(entityLogicalName: string, id: string, data: any): Promise<any> {
        const result = await this._pcfWebApi.updateRecord(entityLogicalName, id, data);

        return {
            entityType: entityLogicalName,
            id: result.id
        };
    }
}