export enum DataType {
    MultiSelectOptionSet = "MultiSelectOptionSet",
    SingleLineText = "SingleLine.Text",
    TwoOptions = "TwoOptions",
    DateAndTimeDateOnly = "DateAndTime.DateOnly",
    DateAndTimeDateAndTime = "DateAndTime.DateAndTime",
    OptionSet = "OptionSet",
    LookupCustomer = "Lookup.Customer",
    LookupSimple = "Lookup.Simple",
    LookupRegarding = "Lookup.Regarding",
    LookupOwner = "Lookup.Owner",
    Currency = "Currency",
    Decimal = "Decimal",
    FP = "FP",
    WholeNone = "Whole.None",
    WholeDuration = "Whole.Duration"
}