import { IControlProps } from "@controls/interfaces";

export class Mode implements InternalMode {
    constructor(props: IControlProps) {
        this.isControlDisabled = props.disabled;
        this.isVisible = props.visible;
        this.label = props.label;
    }
    allocatedHeight: number;
    allocatedWidth: number;
    isControlDisabled: boolean;
    isVisible: boolean;
    label: string;
    get fullPageParam(): { [key: string]: string } {
        const params = new URLSearchParams(window.location.search);
        const data = params.get("data");
        return data ? JSON.parse(data) : null;
    }
    setControlState(state: ComponentFramework.Dictionary): boolean {
        throw new Error("Method not implemented.");
    }
    setFullScreen(value: boolean): void {
        throw new Error("Method not implemented.");
    }
    trackContainerResize(value: boolean): void {
        //throw new Error("Method not implemented.");
    }
}

interface InternalMode extends ComponentFramework.Mode {
    fullPageParam: { [key: string]: string };
}