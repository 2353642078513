import React, { useEffect, useRef, useState, useCallback } from "react";
import { Callout, DefaultButton, Stack, PrimaryButton, mergeStyleSets, MessageBar, MessageBarType, DirectionalHint } from "@fluentui/react";
import { ConditionOperator, getEntityNameFromAlias } from "../../../../DatasetControl/FetchXmlUtils";
import { IDropdownOptionFilterBy } from "./interfaces/IDropdownOptionsFilterBy";
import { useBoolean } from "@fluentui/react-hooks";
import { filterByOptions, filterByHiddenTypes } from "../filtering/configuration";
import { NestedPcfFactory, IVirtualComponentProps } from "@ComponentFramework/interfaces/NestedPcf";
import { ComboBox } from "@talxis/react-components";
import { DataType } from "@ComponentFramework/interfaces/DataType";

interface IFilterCalloutProps {
    column: ComponentFramework.PropertyHelper.DataSetApi.Column;
    columnTarget: HTMLElement;
    dataset: ComponentFramework.PropertyTypes.DataSet;
    factory: NestedPcfFactory;
    lookupFilter: ComponentFramework.LookupValue[];
    getTranslation: (value: string, variables?: {}) => string;
    close: () => void;
    onFilterChange: (expression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression) => void;
    persistLookupFilter: (column: string, values: ComponentFramework.LookupValue[]) => void;
}

const styles = mergeStyleSets({
    callout: {
        width: 250,
        maxWidth: '90%',
    },
    calloutCloseBtn: {
        border: 'none',
        padding: '0px',
        minWidth: 0,
        height: 0
    },
    calloutHeader: {
        padding: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    calloutDropdown: {
        marginBottom: '15px',
    },
    calloutForm: {
        padding: '8px 15px'
    },
    calloutHeading: {
        margin: 0
    },
    calloutBtns: {
        margin: '15px 0 15px 0',
        justifyContent: 'flex-end',
    },
    calloutClearBtn: {
        margin: '0 0 0 10px!important'
    }
});

export const FilterCallout: React.FC<IFilterCalloutProps> = (props) => {
    const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useBoolean(true);
    const [selectedFilter, setSelectedFilter] = useState<IDropdownOptionFilterBy>();

    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    const [value, setValue] = useState<string | string[]>();
    const [shouldUpdate, setShouldUpdate] = useState<boolean>();

    const customPcfRef = useRef<HTMLDivElement>();
    const valueRef = useRef<string | string[]>(null);
    const lookupValueRef = useRef<ComponentFramework.LookupValue[]>(props.lookupFilter ?? []);

    const CHILD_PCF_ID = "filterByNestedPcf";

    useEffect(() => {
        const enabledFilterOptions = filterByOptions(props.getTranslation).filter(x => x.enabledDataTypes.includes(props.column.dataType));
        const currentFilters = props.dataset.filtering.getFilter();
        const existingFilterCondition = currentFilters?.conditions?.find(x => (!x.entityAliasName && x.attributeName === props.column.name) || `${x.entityAliasName}.${x.attributeName}` === props.column.name);
        if (existingFilterCondition) {
            const filterByOption = enabledFilterOptions.find(x => x.conditionOperator === existingFilterCondition.conditionOperator);
            if (filterByOption) {
                setSelectedFilter(filterByOption);
            }

            if (Array.isArray(existingFilterCondition.value)) {
                const result: string[] = [];
                for (const value of existingFilterCondition.value) {
                    result.push(String(value));
                }
                valueRef.current = result;
                setValue(valueRef.current);
            }
            else {
                valueRef.current = String(existingFilterCondition.value);
                setValue(valueRef.current);
            }
        }
        else {
            setSelectedFilter(enabledFilterOptions[0]);
        }

        return () => {
            props.factory.unbindDOMComponent(CHILD_PCF_ID);
        };
    }, []);

    useEffect(() => {
        if (((!value || value === "") || (Array.isArray(value) && value.length === 0)) && !filterByHiddenTypes.includes(selectedFilter?.conditionOperator)) {
            setIsApplyButtonDisabled.setTrue();
        }
        else {
            setIsApplyButtonDisabled.setFalse();
        }
    }, [value, selectedFilter]);

    useEffect(() => {
        if (!filterByHiddenTypes.includes(selectedFilter?.conditionOperator) && !value) {
            let message: string;
            switch (props.column.dataType) {
                case 'string':
                    message = props.getTranslation("filterByInvalidValue");
                    break;
                case 'datetime':
                    message = props.getTranslation("filterByInvalidValue");
                    break;
                default:
                    break;
            }
            setErrorMessage(message);
        }
        else {
            setErrorMessage("");
        }
    }, [value, selectedFilter]);

    const onRefChange = useCallback((node: HTMLDivElement) => {
        // If we are unrendering the component, attempt to unmount it correctly first
        if (node === null && customPcfRef.current !== null) {
            props.factory.unbindDOMComponent(CHILD_PCF_ID);
            customPcfRef.current = null;
        }

        if (node !== null) {
            customPcfRef.current = node;

            const properties: IVirtualComponentProps = {
                controlstates: {
                    isControlDisabled: false,
                },
                parameters: {}
            };

            let entityName = props.dataset.getTargetEntityType();
            let columnName = props.column.name;
            if (props.column.name.includes(".")) {
                const columnNameSplit = props.column.name.split(".");
                const linkedEntities = props.dataset.linking.getLinkedEntities();
                const linkedEntity = linkedEntities.find(x => x.alias === columnNameSplit[0]);
                entityName = linkedEntity.name;
                columnName = props.column.name.split(".").pop();
            }
            let pcfName: string;
            switch (props.column.dataType as DataType) {
                case DataType.SingleLineText:
                    pcfName = "TALXIS.PCF.Portal.TextField";
                    properties.parameters = {
                        value: {
                            Static: false,
                            Primary: true,
                            Type: DataType.SingleLineText,
                            Value: valueRef.current,
                            Callback: function (value: string) {
                                valueRef.current = value;
                                setValue(valueRef.current);
                            }
                        }
                    };
                    break;
                case DataType.DateAndTimeDateAndTime:
                case DataType.DateAndTimeDateOnly:
                    pcfName = "TALXIS.PCF.Portal.DateTime";
                    properties.parameters = {
                        value: {
                            Static: false,
                            Primary: true,
                            Type: DataType.DateAndTimeDateAndTime,
                            Value: valueRef.current,
                            Callback: function (value: string) {
                                valueRef.current = value;
                                setValue(valueRef?.current);
                            }
                        }
                    };
                    break;
                case DataType.OptionSet:
                case DataType.MultiSelectOptionSet:
                case DataType.TwoOptions:
                    pcfName = "TALXIS.PCF.Portal.MultiSelectOptionSet";
                    properties.parameters = {
                        value: {
                            Static: false,
                            Primary: true,
                            Type: DataType.MultiSelectOptionSet,
                            Value: valueRef.current,
                            Callback: function (value: string) {
                                valueRef.current = value;
                                setValue(valueRef?.current);
                            },
                            Attributes: {
                                EntityLogicalName: entityName,
                                LogicalName: columnName
                            }
                        }
                    };
                    break;
                case DataType.Currency:
                case DataType.Decimal:
                case DataType.FP:
                case DataType.WholeNone:
                    pcfName = "TALXIS.PCF.Portal.Decimal";
                    properties.parameters = {
                        value: {
                            Static: false,
                            Primary: true,
                            Type: DataType.Decimal,
                            Value: valueRef.current,
                            Callback: function (value: string) {
                                valueRef.current = value;
                                setValue(valueRef.current);
                            }
                        }
                    };
                    break;
                case DataType.LookupSimple:
                case DataType.LookupCustomer:
                case DataType.LookupOwner:
                    pcfName = "TALXIS.PCF.Portal.SimpleLookup";
                    properties.parameters = {
                        value: {
                            Static: false,
                            Primary: true,
                            Type: DataType.LookupSimple,
                            Value: lookupValueRef.current?.map(x => {
                                return {
                                    entityType: x.entityType,
                                    id: x.id,
                                    name: x.name
                                };
                            }) ?? [],
                            Callback: function (value: ComponentFramework.LookupValue[]) {
                                valueRef.current = value?.map(x => x.id);
                                lookupValueRef.current = value;
                                setValue(valueRef.current);
                            },
                            Attributes: {
                                EntityLogicalName: entityName,
                                LogicalName: columnName
                            }
                        },
                        IsInlineNewEnabled: {
                            Static: true,
                            Value: "false"
                        },
                        MultipleEnabled: {
                            Static: true,
                            Primary: false,
                            Type: DataType.SingleLineText,
                            Value: "true",
                        }
                    };
                    break;
                case DataType.WholeDuration:
                    pcfName = "TALXIS.PCF.Portal.Duration";
                    properties.parameters = {
                        value: {
                            Static: false,
                            Primary: true,
                            Type: DataType.WholeDuration,
                            Value: valueRef.current,
                            Callback: function (value: string) {
                                valueRef.current = value;
                                setValue(valueRef.current);
                            }
                        }
                    };
                    break;
                default:
                    throw new Error("Unsupported data type!");
            }
            properties.parameters.EnableBorder = {
                Static: true,
                Value: 'true',
                Type: DataType.SingleLineText
            };
            const component = props.factory.createComponent(pcfName, CHILD_PCF_ID, properties);
            props.factory.bindDOMElement(component, customPcfRef.current);
        }
    }, []);

    // Since we don't support updateComponent yet, we simply re-render the field to update value
    useEffect(() => {
        setShouldUpdate(false);
    }, [shouldUpdate]);

    return (
        <Callout
            isBeakVisible={false}
            ariaLabelledBy={props.getTranslation("filterBy")}
            directionalHint={DirectionalHint.bottomLeftEdge}
            role="dialog"
            gapSpace={0}
            target={props.columnTarget}
            onDismiss={() => props.close()}
            setInitialFocus
            className={styles.callout}
        >
            <div className={styles.calloutHeader}>
                <h3 className={styles.calloutHeading}>{props.getTranslation("filterBy")}</h3>
                <DefaultButton
                    className={styles.calloutCloseBtn}
                    iconProps={{ iconName: 'Cancel' }}
                    onClick={() => props.close()}
                />
            </div>
            <div>
                <form className={styles.calloutForm}>
                    <ComboBox
                        placeholder={props.getTranslation("selectOption")}
                        selectedKey={selectedFilter?.key}
                        onChange={(event, option, context) => {
                            if (option) {
                                setSelectedFilter(option as IDropdownOptionFilterBy);
                            }
                        }}
                        className={styles.calloutDropdown}
                        options={filterByOptions(props.getTranslation).filter(x => {
                            return x.enabledDataTypes.includes(props.column.dataType);
                        })}

                    />
                    {(!filterByHiddenTypes.includes(selectedFilter?.conditionOperator) && !shouldUpdate) &&
                        <div data-id="filterByCalloutPcf" ref={onRefChange}></div>
                    }
                    {errorMessage &&
                        <MessageBar messageBarType={MessageBarType.error} styles={{ root: { marginTop: 5 } }}>
                            {errorMessage}
                        </MessageBar>
                    }
                    <Stack className={styles.calloutBtns} horizontal tokens={{ childrenGap: 40 }}>
                        <PrimaryButton
                            text={props.getTranslation("filterByApply")}
                            disabled={isApplyButtonDisabled}
                            onClick={async () => {
                                if (selectedFilter.conditionOperator === ConditionOperator.Unknown) {
                                    throw new Error("Unsupported operator!");
                                }

                                let conditionOperator = selectedFilter.conditionOperator;
                                // Currently FetchXmlUtils is not aware of the column types we are passing into it (because it is not made to be async to access metadata), so we need to help it a little bit when creating the filter query
                                if (props.column.dataType === DataType.OptionSet || props.column.dataType === DataType.TwoOptions) {
                                    if (Array.isArray(valueRef.current) && valueRef.current.length > 1) {
                                        switch (conditionOperator) {
                                            case ConditionOperator.Equal:
                                                conditionOperator = ConditionOperator.In;
                                                break;
                                            case ConditionOperator.DoesNotEqual:
                                                conditionOperator = ConditionOperator.NotIn;
                                                break;
                                        }
                                    }
                                    else if (Array.isArray(valueRef.current) && valueRef.current.length === 1) {
                                        valueRef.current = valueRef.current[0];
                                    }
                                }

                                // Handle expanded values
                                if (props.column.name.includes(".")) {
                                    const expandedName = props.column.name.split(".");
                                    const linkedEntity = props.dataset.linking.getLinkedEntities().find(x => x.alias === expandedName[0]);
                                    props.dataset.filtering.setFilter({
                                        conditions: [
                                            {
                                                attributeName: expandedName.pop(),
                                                // @ts-ignore - not-contain-values (88) is not supported by PCF types
                                                conditionOperator: conditionOperator,
                                                value: valueRef.current,
                                                entityAliasName: linkedEntity.alias
                                            }
                                        ],
                                        filterOperator: 0
                                    });
                                }
                                else {
                                    props.dataset.filtering.setFilter({
                                        conditions: [
                                            {
                                                attributeName: props.column.name,
                                                // @ts-ignore - not-contain-values (88) is not supported by PCF types
                                                conditionOperator: conditionOperator,
                                                value: valueRef.current
                                            }
                                        ],
                                        filterOperator: 0
                                    });
                                }

                                if (props.column.dataType === DataType.LookupSimple || props.column.dataType === DataType.LookupCustomer || props.column.dataType === DataType.LookupOwner) {
                                    props.persistLookupFilter(props.column.name, lookupValueRef.current);
                                }

                                // TODO: Clear custom filters before filtering via our own or remove them post setFilter
                                props.close();
                            }} />
                        <DefaultButton
                            className={styles.calloutClearBtn}
                            text={props.getTranslation("filterByClear")}
                            onClick={() => {
                                setShouldUpdate(true);
                                valueRef.current = null;
                                setValue(valueRef.current);
                                lookupValueRef.current = [];
                            }} />
                    </Stack>
                </form>
            </div>
        </Callout>
    );
};