import { EntityDefinition as IEntityDefinition, LookupAttributeMetadataResponse } from '../../interfaces/entitydefinition';
import cloneDeep from 'lodash/cloneDeep';
import { Exception } from '../exceptions/Exception';
import { sendMetadataGetRequest } from './MetadataApi';
import { IPromiseCache, cachedWrapper } from '@utilities/MemoryCachingHelpers';
import { IODataResponse } from '../../interfaces/general';

export class EntityDefinition {
    private static _entityDefinition: IPromiseCache<IEntityDefinition> = {};
    private static _lookupTargets: { [entityName: string]: { [attributeName: string]: Promise<string[]> } } = {};

    static async getLookupTargets(entityName: string, attributeName: string): Promise<string[]> {
        if (!this._lookupTargets[entityName]) {
            this._lookupTargets[entityName] = {};
        }
        if (!this._lookupTargets[entityName][attributeName]) {
            this._lookupTargets[entityName][attributeName] = new Promise<string[]>(async (resolve) => {
                const response = await sendMetadataGetRequest(`v9.1/EntityDefinitions(LogicalName='${entityName}')/Attributes/Microsoft.Dynamics.CRM.LookupAttributeMetadata?$select=Targets&$filter=LogicalName eq '${attributeName}'`);
                if (!response.ok) {
                    throw new Error(`Entity ${entityName} could not be fetched!`);
                }
                const entityDefinition: LookupAttributeMetadataResponse = await response.json();

                resolve(entityDefinition.value[0]?.Targets ?? []);
            });
        }

        const definition = await this._lookupTargets[entityName][attributeName];

        // We clone the object because if someone touches it when passed by reference, the cache would become dirty
        return cloneDeep(definition);
    }

    static async preloadAsync(metadataIds: string[]): Promise<string[]> {
        console.groupCollapsed("Entity Definitions Preload");
        if (!metadataIds || metadataIds.length === 0) {
            console.log("No entities to preload");
            console.groupEnd();
            return;
        }
        const timeStart = performance.now();
        console.log(`Preloading entity definitions for:`, metadataIds);

        const entities: IEntityDefinition[] = [];

        // Querying EntityDefinitions has limit of 25 entities per request
        const chunkSize = 25;
        for (let i = 0; i < metadataIds.length; i += chunkSize) {
            const chunk = metadataIds.slice(i, i + chunkSize);
            const response = await sendMetadataGetRequest(`v9.1/EntityDefinitions?$filter=${chunk.map(x => `MetadataId eq '${x}'`).join(' or ')}&$select=LogicalName,DisplayCollectionName,PrimaryNameAttribute,PrimaryIdAttribute,SchemaName,EntitySetName,Description,DisplayName,MetadataId,IsQuickCreateEnabled,IsActivity&$expand=Attributes,OneToManyRelationships($select=SchemaName,RelationshipType,ReferencedAttribute,ReferencedEntity,ReferencingAttribute,ReferencingEntity,ReferencedEntityNavigationPropertyName,ReferencingEntityNavigationPropertyName),ManyToOneRelationships($select=SchemaName,RelationshipType,ReferencedAttribute,ReferencedEntity,ReferencingAttribute,ReferencingEntity,ReferencedEntityNavigationPropertyName,ReferencingEntityNavigationPropertyName),ManyToManyRelationships($select=SchemaName,RelationshipType,Entity1LogicalName,Entity1IntersectAttribute,Entity1NavigationPropertyName,Entity2LogicalName,Entity2IntersectAttribute,Entity2NavigationPropertyName,IntersectEntityName)`);
            if (!response.ok) {
                throw new Exception('Unable to preload entity definitions!', new Exception(await response.text()));
            }
            const entityDefinitions: IODataResponse<IEntityDefinition> = await response.json();
            entities.push(...entityDefinitions.value);
        }

        const entityLogicalNames: string[] = [];

        for (const entity of entities) {
            entityLogicalNames.push(entity.LogicalName);
            this._entityDefinition[entity.LogicalName] = new Promise<IEntityDefinition>(async (resolve) => {
                resolve(entity);
            });
        }

        console.log(`Preloading entity definitions took:`, performance.now() - timeStart);
        console.groupEnd();

        return entityLogicalNames;
    }

    static async getAsync(entityName: string): Promise<IEntityDefinition> {
        return cachedWrapper(entityName, () => new Promise<IEntityDefinition>(async (resolve) => {
            const response = await sendMetadataGetRequest(`v9.1/EntityDefinitions(LogicalName='${entityName}')?$select=LogicalName,DisplayCollectionName,PrimaryNameAttribute,PrimaryIdAttribute,SchemaName,EntitySetName,Description,DisplayName,MetadataId,IsQuickCreateEnabled,IsActivity&$expand=Attributes,OneToManyRelationships($select=SchemaName,RelationshipType,ReferencedAttribute,ReferencedEntity,ReferencingAttribute,ReferencingEntity,ReferencedEntityNavigationPropertyName,ReferencingEntityNavigationPropertyName),ManyToOneRelationships($select=SchemaName,RelationshipType,ReferencedAttribute,ReferencedEntity,ReferencingAttribute,ReferencingEntity,ReferencedEntityNavigationPropertyName,ReferencingEntityNavigationPropertyName),ManyToManyRelationships($select=SchemaName,RelationshipType,Entity1LogicalName,Entity1IntersectAttribute,Entity1NavigationPropertyName,Entity2LogicalName,Entity2IntersectAttribute,Entity2NavigationPropertyName,IntersectEntityName)`);
            if (!response.ok) {
                throw new Error(`Entity ${entityName} could not be fetched!`);
            }
            const entityDefinition: IEntityDefinition = await response.json();

            resolve(entityDefinition);
        }), this._entityDefinition);
    }
}