import { IWebResource } from "../../interfaces/IWebResource";
import { metadataRetrieveMultiple, getWebResourceUrl } from "@definitions/MetadataApi";
import { DomParser } from "../../Constants";
import { IPromiseCache, cachedWrapper } from "@utilities/MemoryCachingHelpers";

export class ScriptLoader {
    private static _script: IPromiseCache<boolean> = {};
    private static _libraryWithDependencies: IPromiseCache<IWebResource> = {};
    private static _resx: IPromiseCache<{ [key: string]: string }> = {};

    static loadAsync(name: string, url: string): Promise<boolean> {
        return cachedWrapper(name, () => new Promise((resolve, reject) => {
            const script: HTMLScriptElement = document.createElement("script");
            script.async = true;
            if (!url.startsWith('/') && !url.startsWith('http')) {
                //TODO: Scripts should be loaded from metadata service.
                // script.src = `data:text/javascript;base64,${url}`
                script.innerHTML = this._b64DecodeUnicode(url);
                resolve(true);
            }
            else {
                script.src = url;
            }
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                reject(false);
            };
            document.body.appendChild(script);
            console.log(`Loading remote webresource ${name} to the DOM.`);
        }), this._script);
    }

    static async loadWebResourceLibraryAndDependencies(libraryName: string): Promise<IWebResource> {
        const webResource: IWebResource = {
            translations: {}
        };
        return cachedWrapper(libraryName, () => new Promise(async (resolve, reject) => {
            // webresourcetype eq 3 => only load JScript web resources
            const webResourceDefinitions = (await metadataRetrieveMultiple(`v9.1/webresourceset?$select=dependencyxml&$filter=name eq '${libraryName}' and webresourcetype eq 3`))?.entities;

            if (webResourceDefinitions && webResourceDefinitions.length === 1) {
                const webResourceDefinition = webResourceDefinitions[0];

                if (webResourceDefinition.dependencyxml) { //contains dependency definition
                    const dependencyXmlDoc = DomParser.parseFromString(webResourceDefinition.dependencyxml, "text/xml");
                    const libraries = dependencyXmlDoc.querySelectorAll('Library');
                    for (const libDependency of libraries) {
                        const libDependencyName = libDependency.getAttribute('name');

                        if (libDependencyName.endsWith(".resx")) {
                            // TODO: We should fall back to the default language (English) in case we don't find the user's one
                            const resxNameWithLanguageId = libDependencyName.substr(0, libDependencyName.indexOf(".resx"));
                            if (resxNameWithLanguageId.endsWith(`${window.Xrm.Utility.getGlobalContext().userSettings.languageId}`)) {
                                const resxName = libDependencyName.substr(0, resxNameWithLanguageId.lastIndexOf("."));

                                const resx = await this._fetchResxAsync(libDependencyName);
                                webResource.translations[resxName] = resx;
                            }
                        }
                        else {
                            const nestedWebResource = await this.loadWebResourceLibraryAndDependencies(libDependencyName); // recursive call

                            webResource.translations = { ...webResource.translations, ...nestedWebResource.translations };
                        }
                    }
                }
                window.TALXIS.Portal.WebResouces = { ...window.TALXIS.Portal.WebResouces, ...webResource.translations };

                //dependencies are now loaded, proceed with loading this script to the current DOM
                await this.loadAsync(libraryName, getWebResourceUrl(`webresources/${libraryName}`));

                resolve(webResource);
            }
            else {
                reject(`Web Resource Library ${libraryName} could not be loaded! Check if it is available in the system.`);
            }
        }), this._libraryWithDependencies);
    }

    private static async _fetchResxAsync(name: string): Promise<{ [key: string]: string }> {
        return cachedWrapper(name, () => new Promise(async (resolve, reject) => {
            const response = await metadataRetrieveMultiple(`v9.1/webresourceset?$select=contentjson&$filter=webresourcetype eq 12 and name eq '${name}'`);
            resolve(JSON.parse(response.entities[0]["contentjson"]));
        }), this._resx);
    }

    public static _b64DecodeUnicode(str: string): string {
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }
}