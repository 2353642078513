import React, { useEffect, useRef } from 'react';
import { mergeStyles, IconButton, Nav, useTheme, INavLinkGroup, INavLink, Text, ThemeProvider, IRenderGroupHeaderProps, INav } from '@fluentui/react';
import styles from './VerticalNavigation.module.css';
import { SitemapDefinition } from '@definitions/SitemapDefinition';
import { useContext } from 'react';
import { AppContext } from '@providers/AppProvider';
import { useState } from 'react';
import { ThemeDefinition } from '@definitions/ThemeDefinition';
import { DefaultIcon } from '@loaders/IconLoader';

interface IVerticalPageNavigationProps {
    visible: boolean;
    expanded: boolean;
    headerHeight: number;
    onWidthChange: (width: number) => void;
    onToggleExpandVerticalNavigation(expand: boolean): void;
    onToggleVerticalNavigationVisibility(visible: boolean): void;
}

export const VerticalPageNavigation: React.FC<IVerticalPageNavigationProps> = (props) => {
    const theme = ThemeDefinition.getTheme().getNavigationTheme();
    const ref = useRef<HTMLDivElement>(null);
    const appContext = useContext(AppContext);
    const [groups, setGroups] = useState<INavLinkGroup[]>([]);
    const verticalNavObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
            props.onWidthChange(entry.contentRect.width);
        });
    });

    useEffect(() => {
        verticalNavObserver.observe(ref.current);
    }, []);

    useEffect(() => {
        setGroups(SitemapDefinition.getCurrentSiteMap().getVerticalNavigationItems());
    }, [appContext.refreshSiteMap]);

    const getVerticalNavigationClassName = () => {
        let className = 'TALXIS__navigation--vertical';
        const menuButton = ref.current?.querySelector(':scope > div > .ms-Button');
        return `${className} ${styles.root} ${mergeStyles({
            backgroundColor: theme.semanticColors.bodyBackground,
            borderRight: `1px solid ${theme.semanticColors.menuDivider}`,
            '.ms-Nav': {
                height: `calc(100vH - ${props.headerHeight + menuButton?.clientHeight + 5}px)`
            },
            '.ms-Nav-groupContent::after': {
                borderBottom: `1px solid ${theme.semanticColors.menuDivider}`
            },
            '.ms-Nav-groupContent:first-child::before': {
                borderTop: `1px solid ${theme.semanticColors.menuDivider}`
            },
            '> div > div:last-of-type': {
                left: ref.current?.clientWidth,
                top: ref.current?.offsetTop,
                backgroundColor: 'rgba(0,0,0,0.3)'
            },
            '[class*="VerticalNavigation_areaHeader"]': {
                borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`
            }
        })}`;
    };

    const renderLink = (props: INavLink) => {
        return (
            <div title={props.name} className={styles.link}>
                {!props.isExpanded &&
                    <svg
                        className={mergeStyles({
                            width: 18,
                            height: 18,
                            'path': {
                                fill: theme.palette.themePrimary
                            }
                        })}
                        data-src={props.dataIcon?.value ?? DefaultIcon}
                    />
                }
                <Text>{props.name}</Text>
            </div >);
    };

    const renderAreaHeader = (props: IRenderGroupHeaderProps) => {
        return (<div className={styles.areaHeader}>
            <Text variant='xLarge'>{props.name}</Text>
        </div>);
    };

    return (
        <aside ref={ref} data-visible={props.visible} data-expanded={props.expanded} className={getVerticalNavigationClassName()}>
            <ThemeProvider theme={theme}>
                {props.visible &&
                    <>
                        <IconButton
                            iconProps={{
                                iconName: 'GlobalNavButton'
                            }}
                            onClick={() => { props.onToggleExpandVerticalNavigation(!props.expanded); }}
                        />
                        <Nav selectedKey={appContext.currentKey} groups={groups} onRenderLink={(groups) => (renderLink(groups))} onRenderGroupHeader={renderAreaHeader} />
                        <div onClick={() => props.onToggleVerticalNavigationVisibility(false)}></div>
                    </>
                }
            </ThemeProvider>
        </aside>
    );
};