import { DataSet } from "@talxis/client-libraries";
import { IExtendedXrmGridControl } from "@controls/DatasetControl/interfaces/IExtendedXrmGridControl";
import { XrmFormContext } from "@controls/native/Form/Form";
import { EntityDefinition } from "../EntityDefinition";
import { history } from '@providers/AppProvider';
import { IRibbonDefinitionButton, IRibbonDefinitionButtonRule } from "./interfaces";
import { DefaultIcon } from "@loaders/IconLoader";
import { sanitizeGuid } from "../../../Functions";
import { XrmFormType } from "../../../../Xrm/XrmFormType";
import { XrmGridType } from "@src/components/controls/DatasetControl/interfaces/XrmGridType";
import { IXrmGridRelationship } from "@src/components/controls/DatasetControl/interfaces/IXrmGridRelationship";
import { XrmRelationshipType } from "@src/components/controls/DatasetControl/interfaces/XrmRelationshipType";
import { IAssociateRequest } from "@src/ComponentFramework/PropertyClasses/WebApi";
import { getLocalizedString } from "@src/localization/helpers";
import { sprintf } from "sprintf-js";
import { FormDefinition } from "../FormDefinition";

export class RibbonButtonOverrider {
    public static supportedNativeButtons = [
        'Mscrm.NewRecordFromGrid',
        'Mscrm.OpenRecordItem',
        'Mscrm.EditSelectedRecord',
        'Mscrm.Modern.refreshCommand',
        'Mscrm.SavePrimary',
        'Mscrm.NewRecordFromForm',
        'Mscrm.SaveAndClosePrimary',
        'Mscrm.DeletePrimaryRecord',
        'Mscrm.ExportToExcel',
        'Mscrm.HomepageGrid.DeleteSplitButtonCommand',
        'Mscrm.RemoveSelectedRecord',
        'Mscrm.HomepageGrid.Activate',
        'Mscrm.HomepageGrid.Deactivate',
        'Mscrm.Form.Deactivate',
        'Mscrm.Form.Activate',
        'Mscrm.AddExistingRecordFromSubGridAssociated'
    ]
    private static _changeStateDialogConstants = {
        action: "action_name",
        records: "entity_records",
        stateId: "state_id",
        statusId: "status_id",
        activate: "activate",
        deactivate: "deactivate",
    }
    private static _getLocalizedString(key: string) {
        return window.TALXIS.Portal.Translations.getLocalizedString(key);
    }
    private static async _openSetStateDialog(items: { Id: string }[], action: string) {
        const dialogArguments = {
            [this._changeStateDialogConstants.records]: JSON.stringify(items),
            [this._changeStateDialogConstants.action]: action,
            [this._changeStateDialogConstants.stateId]: -1,
            [this._changeStateDialogConstants.statusId]: -1,
        };
        const options: object = { position: 1, height: 720, width: 640 };
        //@ts-ignore - openDialog is not supported by official interface
        return Xrm.Navigation.openDialog("talxis_setstatedialog", options, dialogArguments);
    }
    public static override(originalButton: IRibbonDefinitionButton, functionElement?: Element) {
        if (!RibbonButtonOverrider.supportedNativeButtons.includes(originalButton.command)) {
            return;
        }
        Object.assign(originalButton, { ...originalButton });
        originalButton.label = window.TALXIS.Portal.Translations.getLocalizedString(`@definitions/RibbonDefinition/${originalButton.command}`);
        switch (originalButton.command) {
            case 'Mscrm.HomepageGrid.Activate': {
                originalButton.rules = [...originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm')), { ...RibbonButtonOverrider._getNativeRule('SelectionCountNotZero') }];
                //TODO: change the localized string location?
                RibbonButtonOverrider._overrideIcon(originalButton, 'ActivateOrders');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: ({ refresh(): void } | [{ Id: string }] | string)[]): boolean => {
                    const actionAsync = async () => {
                        const gridControl = arg[0] as { refresh(): void };
                        const items = arg[1] as [{ Id: string }];
                        const result = await this._openSetStateDialog(items, this._changeStateDialogConstants.activate);
                        gridControl.refresh();
                    };
                    actionAsync();
                    return true;
                });
                break;
            }
            case 'Mscrm.Form.Activate': {
                originalButton.rules = [...originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm')), { ...RibbonButtonOverrider._getNativeRule('ShowFormActivateButton') }];
                //TODO: change the localized string location?
                RibbonButtonOverrider._overrideIcon(originalButton, 'ActivateOrders');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: (string)[]): boolean => {
                    const actionAsync = async () => {
                        const indexOfPrimaryEntityTypeName = originalButton.function.parameters.findIndex(x => x.value === 'PrimaryEntityTypeName');
                        const indexOfFirstPrimaryItemId = originalButton.function.parameters.findIndex(x => x.value === 'FirstPrimaryItemId');
                        const entityName = arg[indexOfPrimaryEntityTypeName];
                        const entityId = arg[indexOfFirstPrimaryItemId];
                        const item = { Id: sanitizeGuid(entityId), TypeName: entityName };
                        const result = await this._openSetStateDialog([item], this._changeStateDialogConstants.activate);
                        //TODO: Figure out a better way to form refresh
                        Xrm.Navigation.navigateTo({ pageType: "entityrecord", entityName: entityName, entityId: entityId });
                    };
                    actionAsync();
                    return true;
                });
                break;
            }
            case 'Mscrm.HomepageGrid.Deactivate': {
                originalButton.rules = [...originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm')), { ...RibbonButtonOverrider._getNativeRule('SelectionCountNotZero') }];
                //TODO: change the localized string location?
                RibbonButtonOverrider._overrideIcon(originalButton, 'DeactivateOrders');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: ({ refresh(): void } | [{ Id: string, Name: string, TypeCode: number, TypeName: string }] | string)[]): boolean => {
                    const actionAsync = async () => {
                        const gridControl = arg[0] as { refresh(): void };
                        const items = arg[1] as [{ Id: string, Name: string, TypeCode: number, TypeName: string }];
                        const result = await this._openSetStateDialog(items, this._changeStateDialogConstants.deactivate);
                        gridControl.refresh();
                    };
                    actionAsync();
                    return true;
                });
                break;
            }
            case 'Mscrm.Form.Deactivate': {
                originalButton.rules = [...originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm')), { ...RibbonButtonOverrider._getNativeRule('ShowFormDeactivateButton') }];
                //TODO: change the localized string location?
                RibbonButtonOverrider._overrideIcon(originalButton, 'DeactivateOrders');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: (string)[]): boolean => {
                    const actionAsync = async () => {
                        const indexOfPrimaryEntityTypeName = originalButton.function.parameters.findIndex(x => x.value === 'PrimaryEntityTypeName');
                        const indexOfFirstPrimaryItemId = originalButton.function.parameters.findIndex(x => x.value === 'FirstPrimaryItemId');
                        const entityName = arg[indexOfPrimaryEntityTypeName];
                        const entityId = arg[indexOfFirstPrimaryItemId];
                        const item = { Id: sanitizeGuid(entityId), TypeName: entityName };
                        const result = await this._openSetStateDialog([item], this._changeStateDialogConstants.deactivate);
                        //TODO: Figure out a better way to form refresh
                        Xrm.Navigation.navigateTo({ pageType: "entityrecord", entityName: entityName, entityId: entityId });
                    };
                    actionAsync();
                    return true;
                });
                break;
            }
            case 'Mscrm.NewRecordFromGrid': {
                //PowerApps natively have two buttons - Mscrm.NewRecordFromGrid for homepage grid and Mscrm.AddNewRecordFromSubGridStandard for subgrid
                //Portal uses only one and decides based on grid type
                originalButton.rules = originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm'));
                //TODO: change the localized string location?
                RibbonButtonOverrider._overrideIcon(originalButton, 'Add');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: (string | { refresh(): void, getGridType(): XrmGridType, getRelationship(): IXrmGridRelationship })[]): boolean => {
                    const actionAsync = async () => {
                        const entityName = arg[0] as string;
                        const gridControl = arg[1] as { refresh(): void, getGridType(): XrmGridType, getRelationship(): IXrmGridRelationship | undefined };
                        const { IsQuickCreateEnabled } = await EntityDefinition.getAsync(entityName);
                        if (gridControl.getGridType() === XrmGridType.Subgrid && IsQuickCreateEnabled) {
                            let formId: string;
                            try {
                                formId = (await FormDefinition.getDefaultQuickCreateAsync(entityName))?.Id;
                            } catch (error) {
                                console.error(`${error} Proceeding to open main form.`);
                            }
                            if (formId) {
                                const entityFormOptions: Xrm.Navigation.EntityFormOptions = {
                                    entityName: entityName,
                                    useQuickCreateForm: true,
                                    formId,
                                };
                                const formParameters: Xrm.Utility.OpenParameters = {};
                                const relationship = gridControl.getRelationship();
                                if (relationship && relationship.relationshipType === XrmRelationshipType.OneToMany) {
                                    formParameters[`${relationship.attributeName}`] = relationship.recordId;
                                }
                                await window.Xrm.Navigation.openForm(entityFormOptions, formParameters);
                                gridControl.refresh();
                                return;
                            }
                        }
                        window.Xrm.Navigation.openForm({
                            entityName: entityName,
                        });
                    };
                    actionAsync();
                    return true;
                });
                break;
            }
            case 'Mscrm.EditSelectedRecord': {
                originalButton.rules = [...originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm')), { ...RibbonButtonOverrider._getNativeRule('SelectionCountOne') }];
                RibbonButtonOverrider._overrideIcon(originalButton, 'Edit');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: ({ refresh(): void } | [{ Id: string, TypeName: string }] | string)[]): boolean => {
                    const entityName = arg[2] as string;
                    const selectedEntities = arg[1] as [{ Id: string, TypeName: string }];
                    const entityId = selectedEntities[0].Id;
                    window.Xrm.Navigation.openForm({
                        entityName: entityName,
                        entityId: entityId
                    });
                    return true;
                });
                break;
            }
            case 'Mscrm.RemoveSelectedRecord': {
                originalButton.rules = [...originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm')), { ...RibbonButtonOverrider._getNativeRule('SelectionCountNotZero') }];
                RibbonButtonOverrider._overrideIcon(originalButton, 'Delete');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: ({ refresh(): void } | string[] | string)[]): boolean => {
                    const actionAsync = async () => {
                        //TODO: originalButton should reference the entire grid control in final implementation
                        const gridControl = arg[1] as { refresh(): void };
                        const items = arg[2] as string[];
                        const entityName = arg[0] as string;
                        const plural = items.length > 1;
                        const result = await window.Xrm.Navigation.openConfirmDialog({
                            title: this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Title'),
                            confirmButtonLabel: this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Confirm'),
                            cancelButtonLabel: this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Cancel'),
                            subtitle: plural ? this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Subtitle') : this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Subtitle'),
                            text: plural ? this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Text') : this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Text')
                        });
                        if (result.confirmed) {
                            window.Xrm.Utility.showProgressIndicator(window.TALXIS.Portal.Translations.getLocalizedString('app/classes/definitions/RibbonDefinition/Processing'));
                            for (const item of items) {
                                await window.Xrm.WebApi.deleteRecord(entityName, item);
                            }
                            window.Xrm.Utility.closeProgressIndicator();
                            gridControl.refresh();
                        }
                    };
                    actionAsync();
                    return true;
                });
                break;
            }
            case 'Mscrm.HomepageGrid.DeleteSplitButtonCommand': {
                originalButton.rules = [...originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm')), { ...RibbonButtonOverrider._getNativeRule('SelectionCountNotZero') }];
                RibbonButtonOverrider._overrideIcon(originalButton, 'Delete');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: ({ refresh(): void } | [{ Id: string }] | string)[]): boolean => {
                    const actionAsync = async () => {
                        //TODO: originalButton should reference the entire grid control in final implementation
                        const gridControl = arg[0] as { refresh(): void };
                        const items = arg[1] as [{ Id: string }];
                        const entityName = arg[2] as string;
                        const plural = items.length > 1;
                        const result = await window.Xrm.Navigation.openConfirmDialog({
                            title: this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Title'),
                            confirmButtonLabel: this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Confirm'),
                            cancelButtonLabel: this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Cancel'),
                            subtitle: plural ? this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Subtitle') : this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Subtitle'),
                            text: plural ? this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Text') : this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Text')
                        });
                        if (result.confirmed) {
                            window.Xrm.Utility.showProgressIndicator(window.TALXIS.Portal.Translations.getLocalizedString('@definitions/RibbonDefinition/Processing'));
                            for (const item of items) {
                                await window.Xrm.WebApi.deleteRecord(entityName, item.Id);
                            }
                            window.Xrm.Utility.closeProgressIndicator();
                            gridControl.refresh();
                        }
                    };
                    actionAsync();
                    return true;
                });
                break;
            }
            case 'Mscrm.Modern.refreshCommand': {
                originalButton.rules = originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm'));
                RibbonButtonOverrider._overrideIcon(originalButton, 'Refresh');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: (IExtendedXrmGridControl | Xrm.FormContext)[]): boolean => {
                    (async () => {
                        const control = arg[0];
                        if (control instanceof XrmFormContext) {
                            if (control.data.getIsDirty()) {
                                window.Xrm.Utility.showProgressIndicator(window.TALXIS.Portal.Translations.getLocalizedString('@definitions/RibbonDefinition/Saving'));
                                await control.data.refresh(true);
                                window.Xrm.Utility.closeProgressIndicator();
                                return;
                            }
                            await control.data.refresh();
                            return;
                        }
                        const gridControl = control as IExtendedXrmGridControl;
                        gridControl.refresh();
                    })();
                    return true;
                });
                break;
            }
            case 'Mscrm.ExportToExcel': {
                originalButton.rules = [...originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm'))];
                RibbonButtonOverrider._overrideIcon(originalButton, 'ExcelLogo');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: any[]): boolean => {
                    const gridControl = arg[0] as IExtendedXrmGridControl;
                    const fetchXml = gridControl.getFetchXml();
                    const viewColumns = gridControl.getViewColumns();
                    const viewName = gridControl.getViewSelector().getCurrentView().name;
                    const actionAsync = async () => {
                        try {
                            Xrm.Utility.showProgressIndicator(window.TALXIS.Portal.Translations.getLocalizedString("@definitions/RibbonDefinition/Mscrm.ExportToExcel/Export"));
                            const dataSet = new DataSet(window.Xrm.WebApi);
                            await dataSet.FetchData(fetchXml, viewColumns, viewName);
                            await dataSet.ToExcelFile(true);
                        } catch (err) {
                            const errorOptions = {
                                details: err as string,
                                message: window.TALXIS.Portal.Translations.getLocalizedString("@definitions/RibbonDefinition/Mscrm.ExportToExcel/Error")
                            };
                            Xrm.Navigation.openErrorDialog(errorOptions);
                            console.error("Failed to generate Excel file!", err);

                        }
                        finally {
                            Xrm.Utility.closeProgressIndicator();
                        }
                    };
                    actionAsync();
                    return true;
                });
                break;
            }
            case 'Mscrm.NewRecordFromForm': {
                originalButton.rules = originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm'));
                RibbonButtonOverrider._overrideIcon(originalButton, 'Add');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: (string)[]): boolean => {
                    const actionAsync = async () => {
                        const entityName = arg[0] as string;
                        await window.Xrm.Navigation.openForm({
                            entityName: entityName
                        });
                    };
                    actionAsync();
                    return true;
                });
                break;
            }
            case 'Mscrm.SavePrimary': {
                originalButton.rules = [...originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm')), { ...RibbonButtonOverrider._getNativeRule('RecordIsActive') }];
                RibbonButtonOverrider._overrideIcon(originalButton, 'Save');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: (Xrm.FormContext)[]): boolean => {
                    const actionAsync = async () => {
                        window.Xrm.Utility.showProgressIndicator(window.TALXIS.Portal.Translations.getLocalizedString('@definitions/RibbonDefinition/Saving'));
                        const formContext = arg[0];
                        await formContext.data.save();
                        window.Xrm.Utility.closeProgressIndicator();
                    };
                    actionAsync();
                    return true;
                });
                break;
            }
            case 'Mscrm.SaveAndClosePrimary': {
                originalButton.rules = [...originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm')), { ...RibbonButtonOverrider._getNativeRule('RecordIsActive') }];
                RibbonButtonOverrider._overrideIcon(originalButton, 'SaveAndClose');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: (Xrm.FormContext)[]): boolean => {
                    const actionAsync = async () => {
                        window.Xrm.Utility.showProgressIndicator(window.TALXIS.Portal.Translations.getLocalizedString('@definitions/RibbonDefinition/Saving'));
                        const formContext = arg[0];
                        const result = await formContext.data.save();
                        if (!result) {
                            //some form has failed to save
                            window.Xrm.Utility.closeProgressIndicator();
                            return;
                        }
                        window.Xrm.Utility.closeProgressIndicator();
                        history.goBack();
                    };
                    actionAsync();
                    return true;
                });
                break;
            }
            case 'Mscrm.DeletePrimaryRecord': {
                originalButton.rules = [...originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm')), { ...RibbonButtonOverrider._getNativeRule('ShowFormDeleteButton') }];
                RibbonButtonOverrider._overrideIcon(originalButton, 'Delete');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: (string)[]): boolean => {
                    const actionAsync = async () => {
                        const entityId = arg[0];
                        const entityName = arg[1];
                        const result = await window.Xrm.Navigation.openConfirmDialog({
                            title: this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Title'),
                            confirmButtonLabel: this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Confirm'),
                            cancelButtonLabel: this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Cancel'),
                            subtitle: this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Subtitle'),
                            text: this._getLocalizedString('@definitions/RibbonDefinition/Mscrm.DeletePrimaryRecord/Text')
                        });
                        if (result.confirmed) {
                            window.Xrm.Utility.showProgressIndicator(this._getLocalizedString('@definitions/RibbonDefinition/Processing'));
                            await window.Xrm.WebApi.deleteRecord(entityName, entityId);
                            window.Xrm.Utility.closeProgressIndicator();
                            history.goBack();
                        }
                    };
                    actionAsync();
                    return true;
                });
                break;
            }
            case 'Mscrm.AddExistingRecordFromSubGridAssociated': {
                originalButton.rules = originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm'));
                RibbonButtonOverrider._overrideIcon(originalButton, 'AddNotes');
                RibbonButtonOverrider._overrideFunction(originalButton, (arg: (string | IExtendedXrmGridControl)[]): boolean => {
                    const actionAsync = async () => {
                        const gridControl = arg[1] as IExtendedXrmGridControl;
                        const childEntityName = arg[0] as string;
                        const parentForm = gridControl.getParentForm();
                        const parentEntity = parentForm.entityName;
                        const parentId = parentForm.entityId;
                        const relationship = gridControl.getRelationship();
                        const entityDefinition = await EntityDefinition.getAsync(childEntityName);
                        const childEntityPrimaryIdAttribute = entityDefinition.PrimaryIdAttribute;
                        const childEntityPrimaryNameAttribute = entityDefinition.PrimaryNameAttribute;
                        const lookupObjects = await window.Xrm.Utility.lookupObjects({
                            entityTypes: [childEntityName],
                            allowMultiSelect: true,
                        });
                        if (lookupObjects.length === 0) {
                            return;
                        }
                        window.Xrm.Utility.showProgressIndicator("");
                        const filter = `?$filter=(${lookupObjects.map(lookupObject => `${childEntityPrimaryIdAttribute} eq ${lookupObject.id}`).join(' or ')})`;
                        const records = await window.Xrm.WebApi.retrieveMultipleRecords(childEntityName, filter);
                        const associationPromises: PromiseLike<Xrm.ExecuteResponse>[] = [];
                        let parentIssuesCount = 0;
                        for (const record of records.entities) {
                            if (relationship && relationship.relationshipType === XrmRelationshipType.OneToMany) {
                                //this is how MS does it
                                const relatedEntityId = record[`_${relationship.attributeName}_value`];
                                if (relatedEntityId) {
                                    if (relatedEntityId.toLowerCase() === parentId.toLocaleLowerCase()) {
                                        //already exists with the same parent, do nothing
                                        continue;
                                    }
                                    //already exists with different parent, show error or stack the alerts
                                    if (records.entities.length > 1) {
                                        parentIssuesCount++;
                                        continue;
                                    }
                                    else {
                                        window.Xrm.Navigation.openAlertDialog({
                                            text: getLocalizedString("@definitions/RibbonDefinition/Mscrm.AddExistingRecordFromSubGridAssociated/Alert/Body"),
                                            title: record[childEntityPrimaryNameAttribute]
                                        });
                                        continue;
                                    }
                                }
                            }
                            const associateRequest: IAssociateRequest = {
                                target: {
                                    id: parentId,
                                    entityType: parentEntity
                                },
                                relatedEntities: [{
                                    id: record[childEntityPrimaryIdAttribute],
                                    entityType: childEntityName
                                }],
                                relationship: relationship && relationship.name,
                                getMetadata: () => {
                                    return {
                                        boundParameter: null,
                                        parameterTypes: {},
                                        operationName: "Associate",
                                        operationType: 2,

                                    };
                                }
                            };
                            associationPromises.push(Xrm.WebApi.online.execute(associateRequest));

                        }
                        if (parentIssuesCount > 0) {
                            const numOfRecords = records.entities.length;
                            const numOfNotAddedRecords = parentIssuesCount;
                            const alertMessage = sprintf(getLocalizedString("@definitions/RibbonDefinition/Mscrm.AddExistingRecordFromSubGridAssociated/Alert/ParentIssues"), numOfRecords - parentIssuesCount, numOfRecords, numOfNotAddedRecords);
                            window.Xrm.Navigation.openAlertDialog({
                                title: getLocalizedString("@definitions/RibbonDefinition/Mscrm.AddExistingRecordFromSubGridAssociated/Alert/Title"),
                                text: alertMessage
                            });
                        }
                        await Promise.all(associationPromises);
                        window.Xrm.Utility.closeProgressIndicator();
                        if (associationPromises.length > 0) {
                            gridControl.refresh();
                        }
                    };
                    actionAsync();
                    return true;
                });
                break;
            }
            case 'Mscrm.OpenRecordItem': {
                originalButton.rules = [...originalButton.rules.filter(rule => !rule.id.startsWith('Mscrm')), { ...RibbonButtonOverrider._getNativeRule('SelectionCountOne') }];
                RibbonButtonOverrider._overrideIcon(originalButton, 'Open');
                const library = functionElement.getAttribute('Library').split(':')[1];
                const functionName = functionElement.getAttribute('FunctionName');
                if (functionName.startsWith('XrmCore') && library === 'Main_system_library.js') {
                    RibbonButtonOverrider._overrideFunction(originalButton, (arg: ({ refresh(): void } | [{ Id: string, TypeName: string }] | string)[]): boolean => {
                        const entityName = arg[2] as string;
                        const selectedEntities = arg[1] as [{ Id: string, TypeName: string }];
                        const entityId = selectedEntities[0].Id;
                        window.Xrm.Navigation.openForm({
                            entityName: entityName,
                            entityId: entityId
                        });
                        return true;
                    });
                }
                break;
            }
            default: {
                originalButton.rules = [];
                originalButton.function = null;
                console.error(`Native button ${originalButton.id} does not have any implementation!`);
                break;
            }
        }
    }
    private static _overrideIcon(originalButton: IRibbonDefinitionButton, iconName: string) {
        if (originalButton.icon?.value === DefaultIcon) {
            originalButton.icon = {
                type: 'fluent',
                value: iconName
            };
        }
    }
    private static _overrideFunction(originalButton: IRibbonDefinitionButton, action: (arg: any) => boolean) {
        originalButton.function = {
            ...originalButton.function,
            action: action
        };
    }
    private static _getNativeRule(name: string): IRibbonDefinitionButtonRule {
        switch (name) {
            case 'SelectionCountOne': {
                return {
                    id: 'SelectionCountOne',
                    type: 'custom',
                    default: true,
                    invertResult: false,
                    function: {
                        parameters: [
                            {
                                type: 'CrmParameter',
                                value: 'SelectionCount'
                            }
                        ],
                        action: (arg: ComponentFramework.PropertyHelper.DataSetApi.EntityRecord[][]): boolean => {
                            if (arg[0]?.length === 1) {
                                return true;
                            }
                            return false;
                        }
                    }
                };
            }
            case 'SelectionCountNotZero': {
                return {
                    id: 'SelectionCountNotZero',
                    type: 'custom',
                    default: true,
                    invertResult: false,
                    function: {
                        parameters: [
                            {
                                type: 'CrmParameter',
                                value: 'SelectionCount'
                            }
                        ],
                        action: (arg: ComponentFramework.PropertyHelper.DataSetApi.EntityRecord[][]): boolean => {
                            if (arg[0] && arg[0].length > 0) {
                                return true;
                            }
                            return false;
                        }
                    }
                };
            }
            case 'ShowFormDeleteButton': {
                return {
                    id: 'ShowFormDeleteButton',
                    type: 'custom',
                    default: true,
                    invertResult: false,
                    function: {
                        parameters: [
                            {
                                type: 'CrmParameter',
                                value: 'PrimaryControl'
                            }
                        ],
                        action: (arg: (Xrm.FormContext)[]): boolean => {
                            const formContext = arg[0];
                            //do not show delete button on create forms
                            if (!formContext.data.entity.getId()) {
                                return false;
                            }
                            return true;
                        }
                    }
                };
            }
            case 'ShowFormActivateButton': {
                return {
                    id: 'ShowFormActivateButton',
                    type: 'custom',
                    default: true,
                    invertResult: false,
                    function: {
                        parameters: [
                            {
                                type: 'CrmParameter',
                                value: 'PrimaryControl'
                            }
                        ],
                        action: (arg: (Xrm.FormContext)[]): boolean => {
                            const formContext = arg[0];
                            //show activate button on create forms
                            if (formContext.data.entity.getId() && formContext.getAttribute('statecode').getValue() === 1) {
                                return true;
                            }
                            return false;
                        }
                    }
                };
            }
            case 'ShowFormDeactivateButton': {
                return {
                    id: 'ShowFormDeactivateButton',
                    type: 'custom',
                    default: true,
                    invertResult: false,
                    function: {
                        parameters: [
                            {
                                type: 'CrmParameter',
                                value: 'PrimaryControl'
                            }
                        ],
                        action: (arg: (Xrm.FormContext)[]): boolean => {
                            const formContext = arg[0];
                            //show deactivate button on create forms
                            if (formContext.data.entity.getId() && formContext.getAttribute('statecode').getValue() === 0) {
                                return true;
                            }
                            return false;
                        }
                    }
                };
            }
            case 'RecordIsActive': {
                return {
                    id: 'RecordIsActive',
                    type: 'custom',
                    default: true,
                    invertResult: false,
                    function: {
                        parameters: [
                            {
                                type: 'CrmParameter',
                                value: 'PrimaryControl'
                            }
                        ],
                        action: (arg: (Xrm.FormContext)[]): boolean => {
                            const formContext = arg[0];
                            if (formContext.ui.getFormType() === 4) {
                                return false;
                            }
                            return true;
                        }
                    }
                };
            }
        }
    };
}