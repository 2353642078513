import { ContextualMenuItemType, getTheme, ICommandBarProps, IContextualMenuItem, mergeStyles, MessageBar, MessageBarType } from "@fluentui/react";
import { ICommandBarItemProps } from "@talxis/react-components/dist/components/CommandBar/CommandBar.types";
import React from "react";
import "external-svg-loader";
import styles from './Ribbon.module.css';
import { IRibbonDefinition, IRibbonDefinitionButton, IRibbonDefinitionButtonFunctionParameter, RibbonDefinitionButtonType } from "@definitions/RibbonDefinition/interfaces";
import { IExtendedXrmGridControl } from "@controls/DatasetControl/interfaces/IExtendedXrmGridControl";
import { FormControlType } from "@controls/native/Form/interfaces/enums";
import { PcfNavbarWrapper } from "@pages/Layout/components/Navbar/components/PcfNavbarWrapper";
import { ribbonButtonsColumnName } from "../../../Constants";
import { executeFunctionByName } from "../../../Functions";
import { RibbonDefinition } from "@definitions/RibbonDefinition";
import { ControlRegistration, ControlLoader } from "@loaders/ControlLoader";
import { ThemeDefinition } from "@definitions/ThemeDefinition";

export class Ribbon {
    private _ribbonDefinition: IRibbonDefinition;
    private _entityName: string;
    constructor(entityName: string, ribbonDefinition: IRibbonDefinition) {
        this._ribbonDefinition = ribbonDefinition;
        this._entityName = entityName;
    }
    public getDefinition() {
        return this._ribbonDefinition;
    }
    private _getParameterValues(parameters: IRibbonDefinitionButtonFunctionParameter[], primaryControl?: Xrm.FormContext, records?: ComponentFramework.PropertyHelper.DataSetApi.EntityRecord[], gridControl?: IExtendedXrmGridControl) {
        const parameterValues: any[] = [];
        for (const parameter of parameters) {
            if (parameter.type === "CrmParameter") {
                switch (parameter.value) {
                    case 'PrimaryControl':
                        parameterValues.push(primaryControl);
                        break;
                    case 'SelectedControlSelectedItemIds': {
                        const ids: string[] = records?.map(record => record.getRecordId()) ?? [];
                        parameterValues.push(ids);
                        break;
                    }
                    case 'FirstSelectedItemId':
                        const id: string = records[0].getRecordId();
                        parameterValues.push(id);
                        break;
                    case "entity":
                        const record = records && records[0];
                        parameterValues.push(record);
                        break;
                    case "SelectedControlSelectedItemReferences": {
                        parameterValues.push(records?.map(record => {
                            return {
                                Id: record.getRecordId(),
                                TypeName: this._entityName
                            };
                        }));
                        break;
                    }
                    case "SelectedEntityTypeName": {
                        parameterValues.push(this._entityName);
                        break;
                    }
                    case "SelectedEntityTypeCode": {
                        parameterValues.push(this._entityName);
                        break;
                    }
                    case "PrimaryEntityTypeName": {
                        parameterValues.push(this._entityName);
                        break;
                    }
                    case 'FirstPrimaryItemId': {
                        parameterValues.push(primaryControl?.data.entity.getId());
                        break;
                    }
                    case "SelectedControl":
                        parameterValues.push(gridControl ?? primaryControl);
                        break;
                    case 'SelectionCount': {
                        parameterValues.push(records);
                        break;
                    }
                    case 'SelectionCount': {
                        parameterValues.push(records);
                        break;
                    }
                    default:
                        parameterValues.push(null);
                        console.warn("Unsupported ribbon parameter!", parameter);
                        break;
                }
            }
            else if (parameter.type === "StringParameter") {
                parameterValues.push(parameter.value);
            }
        }
        return parameterValues;
    };
    private _onRenderIcon = (button: IRibbonDefinitionButton) => {
        const value = button.icon?.value;
        const type = button.icon?.type;
        if (type === 'url' && value.includes('.svg')) {
            const theme = ThemeDefinition.getTheme().getRibbonTheme();
            return () => <svg
                className={`TALXIS__ribbon__icon__svg ${mergeStyles({
                    width: 20,
                    height: 20,
                    'path': {
                        fill: theme.palette.themePrimary
                    }
                })}`}
                data-src={value}
            />;
        }
        return undefined;
    }
    private _onClick = (button: IRibbonDefinitionButton, primaryControl: Xrm.FormContext, records: ComponentFramework.PropertyHelper.DataSetApi.EntityRecord[], gridControl: IExtendedXrmGridControl) => {
        if (!button.function) {
            return;
        }
        const parameterValues = this._getParameterValues(button.function.parameters, primaryControl, records, gridControl);
        if (button.function.action) {
            button.function.action(parameterValues);
        }
        else {
            executeFunctionByName(button.function.command, window, parameterValues);
        }
    }
    private _createButton = async (button: IRibbonDefinitionButton, primaryControl: Xrm.FormContext, records: ComponentFramework.PropertyHelper.DataSetApi.EntityRecord[], gridControl: IExtendedXrmGridControl, inline?: boolean, addComponentToAwait?: (promise: Promise<void>) => void): Promise<ICommandBarItemProps> => {
        const showMenuProps = button.menuSections.flatMap(menuSection => menuSection.buttons).length > 0;
        let resolveControl: () => void = null;
        let controlInstance: any;
        let controlRegistration: ControlRegistration;
        if (button.type === RibbonDefinitionButtonType.PCF) {
            if (addComponentToAwait) {
                addComponentToAwait(new Promise((resolve) => {
                    resolveControl = resolve;
                }));
            }
            try {
                controlRegistration = await ControlLoader.getAsync(button.label);
                await controlRegistration.load();
                ///@ts-ignore - The official PCF context doesn't expose constructor, but we need to create a new instance.
                controlInstance = new (await controlRegistration.registration).code();
                controlInstance.init(null, resolveControl, null, null);
            }
            catch (err) {
                console.error(`Failed to render PCF ${button.label} \n ${err}`);
                return {
                    key: `${button.label}_pcf_error`,
                    onRender: () => <MessageBar className={styles.pcfError} messageBarType={MessageBarType.error}>
                        {window.TALXIS.Portal.Translations.getLocalizedString("@controls/DatasetControl/ControlRenderError")}
                    </MessageBar>
                };
            }
        }
        const commandBarItem: ICommandBarItemProps = {
            key: button.id,
            text: button.label,
            ["data-id"]: button.id,
            ["data-command"]: button.command,
            onRenderIcon: this._onRenderIcon(button),
            onRender: button.type === RibbonDefinitionButtonType.PCF ?
                () => {
                    return <div onClick={() => this._onClick(button, primaryControl, records, gridControl)} data-id={button.id} className={`TALXIS__ribbon__pcf ${styles.pcf}`}>
                        <PcfNavbarWrapper
                            bindings={{}}
                            id=""
                            classId=""
                            name={button.label}
                            datafieldname={null}
                            disabled={false}
                            type={FormControlType.Field}
                            visible={true}
                            isUnbound={true}
                            isRequired={false}
                            definition={controlRegistration}
                            instance={controlInstance}
                            onResolve={resolveControl} />
                    </div>;
                }
                : undefined,
            split: button.type === RibbonDefinitionButtonType.SplitButton,
            onClick: () => this._onClick(button, primaryControl, records, gridControl),
            iconProps: {
                iconName: button.icon?.type === 'fluent' && button.icon?.value,
                imageProps: button.icon?.type === 'url' && {
                    src: button.icon?.value
                }
            },
            subMenuProps: showMenuProps && await (async () => {
                return {
                    key: `${button.id}sub_menu`,
                    items: await (async () => {
                        return Promise.all(button.menuSections.map(async section => {
                            return {
                                key: section.id,
                                itemType: ContextualMenuItemType.Section,
                                sectionProps: {
                                    title: section.label,
                                    bottomDivider: true,
                                    items: await (() => {
                                        return Promise.all(section.buttons.map(async button => {
                                            return this._createButton(button, primaryControl, records, gridControl, inline);
                                        }));
                                    })()
                                }
                            } as IContextualMenuItem;
                        }));
                    })()
                };
            })(),
        };
        return commandBarItem;
    };

    private _getButtonVisibility = async (button: IRibbonDefinitionButton, primaryControl: Xrm.FormContext, records: ComponentFramework.PropertyHelper.DataSetApi.EntityRecord[], gridControl: IExtendedXrmGridControl, inlineOnly?: boolean) => {
        let checkInline = false;
        try {
            if (gridControl?.getViewColumns().some(column => column.name === ribbonButtonsColumnName)) {
                checkInline = true;
            }
        }
        catch (err) {
            if (inlineOnly) {
                checkInline = true;
            }
        }
        if (checkInline && ((inlineOnly && !button.isInline) || (!inlineOnly && button.isInline))) {
            return false;
        }
        if (button.rules.length === 0) {
            RibbonDefinition.logEnd(this._entityName, 'No enable rules found for this button, default result is true');
            return true;
        }
        let showButtonResult = false;
        for (const rule of button.rules) {
            let showButton = false;
            const timeStartRule = RibbonDefinition.logStart(this._entityName);
            const parameterValues = this._getParameterValues(rule.function.parameters, primaryControl, records, gridControl);
            if (rule.type === "custom") {
                if (rule.function.action) {
                    showButton = rule.function.action(parameterValues);
                }
                else {
                    try {
                        // TODO: Streamline and document timeouts for ribbon button rendering - now it is 5s
                        showButton = await executeFunctionByName(rule.function.command, window, parameterValues, null, 5000);
                    }
                    catch (err) {
                        RibbonDefinition.logError(`Failed to process ${rule.function.command}`, err);
                    }
                }
            }
            else if (rule.type === "value" || rule.type === "selectionCount") {
                showButton = rule.function.action.apply(null, [parameterValues[0]]);
            }
            if (rule.invertResult) {
                showButton = !showButton;
            }

            if (showButton === true) {
                showButtonResult = showButton;
                RibbonDefinition.logEnd(this._entityName, `Enable Rule ${rule.id} with result ${showButtonResult} evaluated in`, timeStartRule);
            }
            // False takes precendence over all results eg. stop processing this button and hide it
            else if (showButton === false) {
                showButtonResult = showButton;
                RibbonDefinition.logEnd(this._entityName, `Enable Rule ${rule.id} with result ${showButtonResult} evaluated in`, timeStartRule);
                break;
            }
            else {
                RibbonDefinition.logEnd(this._entityName, `Enable Rule ${rule.id} did not return any result, using default value ${rule.default}`, timeStartRule);
                showButtonResult = rule.default;
                if (rule.default === false) {
                    break;
                }
            }
        }
        return showButtonResult;
    }

    public getRibbonCommandBarProps = async (primaryControl: Xrm.FormContext, records: ComponentFramework.PropertyHelper.DataSetApi.EntityRecord[], gridControl: IExtendedXrmGridControl, inline?: boolean, addComponentToAwait?: (promise: Promise<void>) => void): Promise<ICommandBarProps> => {
        const timeStart = RibbonDefinition.logGroupStart(this._entityName, 'Application Ribbon Evaluation');
        const theme = ThemeDefinition.getTheme().getRibbonTheme();
        const commandBarItems: ICommandBarItemProps[] = [];
        for (const group of this._ribbonDefinition.groups) {
            for (const button of group.buttons) {
                const timeStart = RibbonDefinition.logGroupStart(this._entityName, `Button: ${button.id}`);
                const buttonVisible = await this._getButtonVisibility(button, primaryControl, records, gridControl, inline);
                RibbonDefinition.logGroupEnd(this._entityName, `Result: ${buttonVisible}, all rules evaluated in`, timeStart);
                if (buttonVisible) {
                    commandBarItems.push(await this._createButton(button, primaryControl, records, gridControl, inline, addComponentToAwait));
                }
            }
        }
        const props: ICommandBarProps = {
            className: `TALXIS__ribbon ${styles.root} ${mergeStyles({
                borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`
            })}`,
            items: commandBarItems
        };
        RibbonDefinition.logGroupEnd(this._entityName, 'Application Ribbon rules evaluated in', timeStart);
        return props;
    };

    public getRibbonButtonProps = async (primaryControl: Xrm.FormContext, records: ComponentFramework.PropertyHelper.DataSetApi.EntityRecord[], gridControl: IExtendedXrmGridControl, commandName: string, inline?: boolean, addComponentToAwait?: (promise: Promise<void>) => void): Promise<ICommandBarItemProps> => {
        let commandBarItem: ICommandBarItemProps | undefined;
        const button = this._ribbonDefinition.groups.reduce(function (acc, group) {
            return acc.concat(group.buttons);
        }, []).filter(function (button) {
            return button.command === commandName;
        })?.[0];
        if (button) {
            const buttonVisible = await this._getButtonVisibility(button, primaryControl, records, gridControl, inline);
            if (buttonVisible) {
                commandBarItem = await this._createButton(button, primaryControl, records, gridControl, inline, addComponentToAwait);
            }
            return commandBarItem;
        }
        else {
            return undefined;
        }
    };
}