import React, { useContext, useEffect, useRef, useState } from 'react';
import MainLoading from '../../components/loadings/MainLoading';
import { IAppContext } from '@providers/AppProvider/interfaces';
import { AppContext } from '@providers/AppProvider';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import AlertDialog from '../../components/dialogs/AlertDialog';
import FormDialog from '../../components/dialogs/FormDialog';
import Footer from './components/Footer';
import { AppModule } from '@configuration/AppModule';
import { Navbar } from './components/Navbar';
import { ICommandBarProps } from '@fluentui/react/lib/components/CommandBar/CommandBar.types';
import './css/styles.css';
import { HorizontalPageNavigation } from './components/PageNavigation/components/HorizontalPageNavigation';
import { VerticalPageNavigation } from './components/PageNavigation/components/VerticalPageNavigation';
import { mergeStyles, useTheme } from '@fluentui/react';
import { Notifications } from './components/Notifications';
import { RibbonDefinition } from '@definitions/RibbonDefinition';
import { APPLICATION_RIBBON_ENTITY_NAME, BODY_BACKGROUND_COLOR } from '@app/Constants';
import { SitemapDefinition } from '@definitions/SitemapDefinition';
import { ThemeDefinition } from '@definitions/ThemeDefinition';
import { SitemapOrientation } from '@models/Theme';
import { LookupPanel } from '@src/components/navigation/panels/LookupPanel';

const Layout: React.FC = (props) => {
    const appContext: IAppContext = useContext(AppContext);
    const [appRibbonCommandBarProps, setAppRibbonCommandBarProps] = useState<ICommandBarProps>(null);
    const theme = useTheme();
    const [navbarLoaded, setNavbarLoaded] = useState<boolean>(false);
    const [smallScreen, setSmallScreen] = useState<boolean>(window.innerWidth < 992);
    const [verticalNavigationVisible, setVerticalNavigationVisibility] = useState<boolean>(!smallScreen);
    const [verticalNavigationExpanded, setVerticalNavigationExpanded] = useState<boolean>(true);
    const [verticalNavWidth, setVerticalNavWidth] = useState<number>(0);
    const [headerHeight, setHeaderHeight] = useState<number>(0);
    const [navbarHidden, setNavbarHidden] = useState<boolean>(false);
    const componentsToAwait = useRef<Promise<void>[]>([]);
    const ref = useRef<HTMLDivElement>(null);
    const portalContentRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLElement>(null);
    const horizontalNavRef = useRef<HTMLElement>(null);
    const isVertical = ThemeDefinition.getTheme().sitemapOrientation === SitemapOrientation.Vertical;

    const onScroll = () => {
        if (window.top.scrollY < 15) {
            horizontalNavRef.current?.setAttribute('style', 'transform: translateY(0px)');
        }
        else if (window.top.scrollY > headerRef.current?.clientHeight) {
            horizontalNavRef.current?.setAttribute('style', `transform: translateY(-${horizontalNavRef.current?.clientHeight + 1}px)`);
        }
    };

    const setDocumentHeadTitle = () => {
        const siteMapDefinition = SitemapDefinition.getCurrentSiteMap().getDefinition();
        for (const area of siteMapDefinition.areas) {
            for (const group of area.groups) {
                for (const subArea of group.subAreas) {
                    if (subArea.url === appContext.currentKey) {
                        document.querySelector('head > title').innerHTML = `${AppModule.get().name} | ${subArea.title}`;
                        break;
                    }
                }
            }
        }
    };

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const navbar = queryParameters.get("navbar");

        if (navbar === 'off') {
            setNavbarHidden(true);
        }
        if (navbar !== 'off' && !navbarHidden) {
            const resizeObserver = new ResizeObserver(entries => {
                entries.forEach(entry => {
                    if (entry.target === headerRef.current) {
                        portalContentRef.current.setAttribute('style', `margin-top: ${entry.contentRect.height + 15}px`);
                        setHeaderHeight(entry.contentRect.height);
                        return;
                    }
                    if (window.innerWidth <= 992 && isVertical) {
                        setSmallScreen(true);
                        setVerticalNavigationExpanded(true);
                        setVerticalNavigationVisibility(false);
                        return;
                    }
                    setSmallScreen(false);
                    setVerticalNavigationVisibility(true);
                });
            });
            if (!isVertical) {
                window.addEventListener('scroll', onScroll);
            }
            resizeObserver.observe(headerRef.current);
            resizeObserver.observe(portalContentRef.current);
            init();
            return () => {
                window.removeEventListener('scroll', onScroll);
                resizeObserver.disconnect();
            };
        }
        else {
            appContext.setIsNavbarLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (appContext.currentKey) {
            setDocumentHeadTitle();
        }
    }, [appContext.currentKey]);

    const init = async (): Promise<void> => {
        const applicationRibbon = await RibbonDefinition.getRibbon(APPLICATION_RIBBON_ENTITY_NAME);
        const appRibbonCommandBarProps = (await applicationRibbon.getRibbonCommandBarProps(null, null, null, false, (promise: Promise<void>) => {
            componentsToAwait.current.push(promise);
        }));
        setAppRibbonCommandBarProps(appRibbonCommandBarProps);
        setNavbarLoaded(true);
        await Promise.all(componentsToAwait.current);
        appContext.setIsNavbarLoaded(true);
    };
    const getPortalStyles = () => {
        return mergeStyles({
            '.TALXIS__portal__content': {
                maxWidth: !isVertical && 1140,
                marginLeft: isVertical && verticalNavWidth
            },
            ':global(body)': {
                backgroundColor: BODY_BACKGROUND_COLOR
            },
            '.TALXIS__navigation--horizontal': {
                borderBottom: `1px solid ${theme.semanticColors.menuDivider}`,
            }
        });
    };
    return (
        <div ref={ref} className={`TALXIS__portal ${getPortalStyles()}`}>
            {!navbarHidden &&
                <header ref={headerRef} className='TALXIS__portal__header'>
                    <Notifications isGlobal notifications={appContext.globalNotifications} />
                    {navbarLoaded &&
                        <Navbar
                            logoSrc={ThemeDefinition.getTheme().getLogoUrl()}
                            isSmallScreen={smallScreen}
                            verticalNavigationEnabled={isVertical}
                            verticalNavigationVisible={verticalNavigationVisible}
                            onToggleVerticalNavigation={(value) => setVerticalNavigationVisibility(value)}
                            appRibbonCommandBarProps={appRibbonCommandBarProps} />
                    }
                    {!appContext.isLoading && appContext.isNavbarLoaded && !isVertical &&
                        <section ref={horizontalNavRef} className={`TALXIS__navigation--horizontal animate--slideInDown`}>
                            <HorizontalPageNavigation />
                        </section>

                    }
                    {!appContext.isLoading && appContext.isNavbarLoaded && isVertical &&
                        <VerticalPageNavigation
                            headerHeight={headerHeight}
                            visible={verticalNavigationVisible}
                            expanded={verticalNavigationExpanded}
                            onWidthChange={setVerticalNavWidth}
                            onToggleExpandVerticalNavigation={setVerticalNavigationExpanded}
                            onToggleVerticalNavigationVisibility={setVerticalNavigationVisibility} />
                    }
                </header>
            }
            <div ref={portalContentRef} className={`TALXIS__portal__content`}>
                <main>
                    {props.children}
                    <Footer />
                </main>
            </div>
            <ConfirmationDialog {...appContext.confirmationDialogProps} />
            <AlertDialog {...appContext.alertDialogProps} />
            {
                appContext.formDialogs.map((dialogProps, index) => {
                    return <FormDialog key={dialogProps.id} {...dialogProps} />;
                })
            }
            <LookupPanel {...appContext.lookupPanelProps} />
        </div >
    );
};
export default Layout;
