import { isDev } from "../../Functions";
import { ThemeDesigner, ThemeType } from "@utilities/ThemeDesigner";
import { getTheme, IPalette, ITheme, loadTheme } from "@fluentui/react";
import { buildMetadataUrl, getWebResourceUrl } from "@definitions/MetadataApi";

export enum SitemapOrientation {
    Horizontal,
    Vertical
}
export class Theme {
    private _logoUrl?: string;
    private _ribbonTheme?: ITheme;
    private _navbarTheme?: ITheme;
    private _navigationTheme?: ITheme;
    private _customCSS?: string;
    public sitemapOrientation = SitemapOrientation.Horizontal;

    constructor(entity?: ComponentFramework.WebApi.Entity) {
        const primaryColor = entity?.["talxis_maincolor"];
        const textColor = entity?.["talxis_textcolor"];
        const backgroundColor = entity?.["talxis_backgroundcolor"];
        const ribbonBackgroundColor = entity?.["talxis_ribbonbackgroundcolor"];
        const ribbonTextColor = entity?.["talxis_ribbontextcolor"];
        const ribbonPrimaryColor = entity?.["talxis_ribbonprimarycolor"];
        const navbarBackgroundColor = entity?.["talxis_navbarbackgroundcolor"];
        const navbarTextColor = entity?.["talxis_navbartextcolor"];
        const navbarPrimaryColor = entity?.["talxis_navbarprimarycolor"];
        const navigationBackgroundColor = entity?.["talxis_navigationbackgroundcolor"];
        const navigationTextColor = entity?.["talxis_navigationtextcolor"];
        const navigationPrimaryColor = entity?.["talxis_navigationprimarycolor"];

        this._customCSS = entity?.["talxis_cascadingstylesheets"];
        if (entity) {
            this._logoUrl = buildMetadataUrl(`v9.1/talxis_configuration_themes(${entity['talxis_configuration_themeid']})/talxis_logotooltip/$value`, false, isDev());
        }
        this.sitemapOrientation = entity?.["talxis_sitemaporientationtypecode"] === 742070001 ? SitemapOrientation.Vertical : SitemapOrientation.Horizontal;
        //needed any to for dynamic override of palette colors
        const theme: any = this._generateTheme(backgroundColor, primaryColor, textColor, ThemeType.MAIN);
        this._ribbonTheme = this._generateTheme(ribbonBackgroundColor, ribbonPrimaryColor, ribbonTextColor, ThemeType.RIBBON, theme);
        this._navbarTheme = this._generateTheme(navbarBackgroundColor, navbarPrimaryColor, navbarTextColor, ThemeType.NAVBAR, theme);
        this._navigationTheme = this._generateTheme(navigationBackgroundColor, navigationPrimaryColor, navigationTextColor, ThemeType.NAVIGATION, theme);

        //placeholder should be replaced with correct property name
        const overridenColors: IPalette = JSON.parse(entity?.["talxis_overridecolorsjson"] ?? null);
        if (overridenColors) {
            for (const [key, value] of Object.entries(overridenColors)) {
                theme.palette[key] = value;
            }
        }
        this._injectCustomCss();
        loadTheme(theme);
    }

    public getRibbonTheme(): ITheme {
        return this._ribbonTheme;;
    }
    public getNavigationTheme(): ITheme {
        return this._navigationTheme;
    }
    public getNavbarTheme(): ITheme {
        return this._navbarTheme;
    }

    public getLogoUrl() {
        if (!this._logoUrl) {
            return getTheme().isInverted ? `images/talxis_logo_white.png` : `images/talxis_logo_black.png`;
        }
        return this._logoUrl;
    }
    private _injectCustomCss() {
        if (!this._customCSS) {
            return;
        }
        const styleElement = document.createElement('style');
        styleElement.setAttribute('type', 'text/css');
        styleElement.setAttribute('id', 'customCSS');
        styleElement.innerHTML = this._replaceWebResourceLinksInCss();
        document.head.appendChild(styleElement);
    }

    private _replaceWebResourceLinksInCss() {
        const generatedCss = this._customCSS.replace(/: url\(['"]?\/webresources\/(.*)['"]?\)/i, `: url(${getWebResourceUrl(`webresources/$1`)})`);
        return generatedCss;
    }
    private _generateTheme(backgroundColor: string, primaryColor: string, textColor: string, themeType: ThemeType, defaultTheme?: ITheme): ITheme {
        return ThemeDesigner.generateTheme({
            backgroundColor: backgroundColor,
            primaryColor: primaryColor,
            textColor: textColor,
            themeType: themeType,
            defaultTheme: defaultTheme
        });
    }
}