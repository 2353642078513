import { metadataRetrieveMultiple } from "./MetadataApi";
import { Theme } from "@models/Theme";
export class ThemeDefinition {
    private static _theme: Theme;
    public static getTheme(): Theme {
        if (!this._theme) {
            throw new Error('Theme is not defined, have you called ThemeDefinition.loadAsync()?');
        }
        return this._theme;
    }
    public static async loadTheme() {
        let configurationThemeResponse = await metadataRetrieveMultiple('v9.1/talxis_configuration_themes');
        if (!configurationThemeResponse?.entities) {
            configurationThemeResponse = await metadataRetrieveMultiple('v9.1/talxis_configuration_themes');
            if (!configurationThemeResponse?.entities) {
                this._theme = new Theme();
                return;
            }
        }
        const configurationTheme = configurationThemeResponse.entities[0];
        this._theme = new Theme(configurationTheme);
    }
}
