import { Card } from '@uifabric/react-cards';
import { Shimmer } from '@fluentui/react';
import { ShimmerElementType } from '@fluentui/react/lib/components/Shimmer/Shimmer.types';
import React, { useEffect, useRef } from 'react';

export const Placeholder: React.FC = () => {
    const lines = [1, 2, 3, 4];
    const columns = [1, 2, 3];
    const rows = [1, 2];
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const containerWidth = ref.current.clientWidth;
        if (containerWidth <= 768) {
            ref.current.classList.add('TALXIS__form__placeholder--mobile');
        }
    }, []);
    return (
        <div ref={ref} className='TALXIS__form__placeholder'>
            {rows.map((value) =>
                <div key={value}>
                    {columns.map((column) =>
                        <Card className='TALXIS__form__placeholder__column' key={column}>
                            <Card.Item>
                                <Shimmer
                                    shimmerElements={[
                                        { type: ShimmerElementType.gap, width: 15, height: 30 },
                                        { type: ShimmerElementType.line, height: 10 },
                                        { type: ShimmerElementType.gap, width: 15, height: 30 }
                                    ]} />
                            </Card.Item>
                            <Card.Item>
                                <Shimmer
                                    shimmerElements={[
                                        { type: ShimmerElementType.gap, height: 5, width: '100%' }
                                    ]} />
                            </Card.Item>
                            {lines.map((line) =>
                                <Card.Item
                                    key={line}
                                >
                                    <Shimmer
                                        key={line}
                                        width='100%'
                                        shimmerElements={[
                                            { type: ShimmerElementType.gap, width: 15, height: 30 },
                                            { type: ShimmerElementType.line, height: 10 },
                                            { type: ShimmerElementType.gap, width: 15, height: 30 }
                                        ]} />
                                </Card.Item>
                            )}
                            <Card.Item>
                                <Shimmer
                                    shimmerElements={[
                                        { type: ShimmerElementType.gap, height: 21, width: '100%' }
                                    ]} />
                            </Card.Item>
                        </Card>
                    )}
                </div>
            )}
        </div>
    );
};