import { mergeStyles } from "@fluentui/react/lib/Styling";

export const rootStyles = mergeStyles({
    selectors: {
        '.TALXIS-Portal-errorDialog-title-icon': {
            position: 'relative',
            top: 4,
            marginRight: 5,
            color: "red",
            fontSize: 24
        }
    }
});