import { Manifest } from "@controls/interfaces/manifest";
import { DataType } from "@ComponentFramework/interfaces/DataType";
import { DomParser } from "../../Constants";

const _parseDatasets = (datasetCollection: HTMLCollectionOf<Element>): Manifest.DataSet[] => {
    const properties: Manifest.DataSet[] = [];
    for (const property of datasetCollection) {
        properties.push({
            name: property.getAttribute('name'),
            cdsOptions: property.getAttribute('cds-data-set-options'),
        });
    }
    return properties;
};

const _parseProperties = (propertyCollection: HTMLCollectionOf<Element>): Manifest.Property[] => {
    const properties: Manifest.Property[] = [];
    for (const property of propertyCollection) {
        properties.push({
            name: property.getAttribute('name'),
            // TODO: We need to handle mapping of type groups
            ofType: property.getAttribute('of-type') as DataType,
            usage: property.getAttribute('usage') as ("bound" | "input")
        });
    }
    return properties;
};

const _parseCode = (codeElement: Element): Manifest.Code => {
    const code: Manifest.Code = {
        path: `/controls/${codeElement.getAttribute('path')}`,
        order: codeElement.getAttribute('order')
    };
    return code;
};

const _parseCss = (cssElements?: HTMLCollectionOf<Element>): Manifest.CSS[] => {
    if (!cssElements) {
        return [];
    }
    return [...cssElements].map(x => {
        return {
            path: x.getAttribute('path'),
            order: x.getAttribute('order')
        };
    });
};

const _parseImg = (imgElements: NodeListOf<Element>): Manifest.Img[] => {
    return [...imgElements].map(x => {
        return {
            path: x.getAttribute('path'),
        };
    });
};

const _parseResx = (resxElements?: HTMLCollectionOf<Element>): Manifest.Resx[] => {
    if (!resxElements) {
        return undefined;
    }
    return [...resxElements].map(x => {
        return {
            path: x.getAttribute('path')
        };
    });
};

const _parseResources = (resourceElement: Element): Manifest.Resources => {
    const resources: Manifest.Resources = {
        code: _parseCode(resourceElement.getElementsByTagName('code')[0]),
        css: _parseCss(resourceElement.getElementsByTagName('css')),
        resx: _parseResx(resourceElement.getElementsByTagName('resx')),
        img: _parseImg(resourceElement.querySelectorAll('img'))
    };
    return resources;
};

const _parseControl = (controlElement: Element): Manifest.Control => {
    const control: Manifest.Control = {
        namespace: controlElement.getAttribute('namespace'),
        constructor: controlElement.getAttribute('constructor'),
        properties: _parseProperties(controlElement.getElementsByTagName('property')),
        datasets: _parseDatasets(controlElement.getElementsByTagName('data-set')),
        resources: _parseResources(controlElement.getElementsByTagName('resources')[0])
    };
    return control;
};
export class ManifestMapper {
    public static map(manifestXml: any): Manifest.Control {
        const xmlDoc = DomParser.parseFromString(manifestXml, "text/xml");
        const manifest = _parseControl(xmlDoc.getElementsByTagName('control')[0]);
        return manifest;
    }
}