import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import Dialog from '../Dialog';
import { rootStyles } from './Styles';
import { IFormDialogProps } from './interfaces';
import { defaultProps } from '../Dialog/Constants';
import { Control } from '../../controls';
import { FormMapper } from '@mappers/FormMapper';
import { AppContext } from '@providers/AppProvider';
import { IAppContext } from '@providers/AppProvider/interfaces';
import Panel from '../../../components/navigation/panels/Panel';
import { FormControlType } from "@controls/native/Form/interfaces/enums";
import './styles.css';
import { DefaultButton, DialogContent, DialogFooter, IButtonProps, mergeStyles, PrimaryButton, useTheme } from '@fluentui/react';
import { Form } from '@controls/native/Form/interfaces/form';
import { executeFunctionByName } from '@src/app/Functions';
import { XrmEventContext } from '@src/components/controls/native/Form/Form';
import styles from './FormDialog.module.css';

const FormDialog: React.FC<IFormDialogProps> = (props) => {
    const appContext: IAppContext = useContext(AppContext);
    const [title, setTitle] = useState<string | undefined>("");
    const formDataRef = useRef<ComponentFramework.WebApi.Entity>({});
    const theme = useTheme();
    const executionContext = useRef<XrmEventContext>(null);
    const [footerButtonCells, setFooterButtonCells] = useState<Form.Cell[]>(null);

    const init = async (formId?: string): Promise<void> => {
        const formDefinition = await (new FormMapper(null, null, formId, props.formName)).getForm(); //TODO: replace with form definition once ready
        setTitle(formDefinition.title);
    };

    // Load the dialog
    useEffect(() => {
        if ((props.formName || props.formId) && !props.customTitle) {
            init(props.formId);
        }
        else if (props.customTitle) {
            setTitle(props.customTitle);
        }
    }, [props.formName, props.formId, props.customTitle]);

    const onDataChange = useCallback((entity: ComponentFramework.WebApi.Entity) => {
        formDataRef.current = entity;
    }, []);

    const onFooterUpdate = (arg: { currentTab: Form.Tab; executionContext: XrmEventContext }) => {
        const cells = arg.currentTab.tabFooter?.rows.flatMap(row => row.cells).filter(cell => cell.visible !== false && cell.control?.visible !== false);
        executionContext.current = arg.executionContext;
        setFooterButtonCells(cells ?? []);
    };

    const onFooterButtonClick = (button: Form.Control) => {
        if (button.onClickEventHandlers) {
            for (const handler of button.onClickEventHandlers) {
                const parameters: any[] = [];
                if (handler.passExecutionContext) {
                    parameters.push(executionContext.current);
                }
                console.log(`Executing ${handler.functionName} function on ${button.id} button.`);
                executeFunctionByName(handler.functionName, window, parameters);
            }
        }
    };

    const dialogButtonsVisible = (): boolean => {
        if (footerButtonCells?.length === 0 || !executionContext.current) {
            return false;
        }
        return true;
    };

    const renderDialogButtons = (): JSX.Element[] => {
        const buttonPropsArray: IButtonProps[] = footerButtonCells.map((cell, index) => {
            return {
                key: index,
                text: cell.label,
                disabled: cell.control?.disabled,
                onClick: () => onFooterButtonClick(cell.control)
            };
        });
        return buttonPropsArray.map((props, index) => {
            if (index === 0) {
                return <PrimaryButton {...props} />;
            }
            return <DefaultButton {...props} />;
        });
    };

    const controlElement = <Control
        name="TALXIS.PCF.Portal.Form"
        bindings={{
            "entityName": {
                isStatic: true,
                value: props.parameters?.["entityName"]
            },
            "entityId": {
                isStatic: true,
                value: props.parameters?.["entityId"],
            },
            "formId": {
                isStatic: true,
                value: props.parameters?.["formId"]
            },
            "extraQs": {
                isStatic: true,
                value: props.extraqs,
            },
            "formUniqueName": {
                isStatic: true,
                value: props.formName,
            },
            "isQuickCreate": {
                isStatic: true,
                value: props.isQuickCreate?.toString(),
            },
            "isNavbarLoaded": {
                isStatic: true,
                value: "true"
            }
        }}
        childeventlisteners={[
            {
                eventname: "onCreateCallback",
                eventhandler: props.onCreateCallback
            },
            // Do not confuse with props.onCloseCallback, those two are different
            // TODO: Sort out the difference between onCloseCallback and the one coming from props
            {
                eventname: "onCloseCallback",
                eventhandler: () => {
                    onDismissHandler();
                }
            },
            {
                eventname: "onDataChange",
                eventhandler: onDataChange
            },
            {
                eventname: "__onFooterUpdate",
                eventhandler: onFooterUpdate
            }
        ]}
        id=""
        classId=""
        datafieldname={null}
        disabled={false}
        type={FormControlType.Field}
        visible={true}
        isUnbound={true}
        isRequired={false}
        definition={null}
    />;

    let dialogElement: JSX.Element;
    const onDismissHandler = async () => {
        if (props.onBeforeClose) {
            if (await props.onBeforeClose(formDataRef.current)) {
                appContext.closeFormDialog(props.id, formDataRef.current);
            }
        }
        else {
            appContext.closeFormDialog(props.id, formDataRef.current);
        }
    };

    switch (props.position) {
        case 2: // Side Panel
            dialogElement = <Panel
                className={mergeStyles({
                    '.ms-Panel-main': {
                        overflow: 'hidden'
                    },
                    '.ms-Panel-commands': {
                        zIndex: 1
                    },
                    '.ms-Dialog-actions': {
                        position: 'absolute',
                        bottom: 17,
                        zIndex: 1,
                        right: 23
                    },
                    '.ms-Panel-footer': {
                        height: 70,
                        backgroundColor: theme.semanticColors.bodyBackground
                    }
                })}
                isOpen={true}
                hidden={false}
                onRenderFooterContent={() => <div></div>}
                onDismiss={(ev) => {
                    if (!ev) {
                        onDismissHandler();
                        return;
                    }
                    const srcElement = ev.currentTarget as Element | null;
                    if (srcElement && srcElement.className.indexOf('ms-Button--icon') !== -1) {
                        onDismissHandler();
                        return;
                    }
                }}
                onOuterClick={() => { }}
                isLightDismiss={false}
                isBlocking={true}
                headerText={title}>
                {props.formName && controlElement}
            </Panel>;
            break;
        case -100:
            dialogElement = <div className={`${styles.inline} ${mergeStyles({
                '&': {
                    backgroundColor: theme.semanticColors.bodyBackground,
                    boxShadow: theme.semanticColors.cardShadow,
                    border: `1px solid ${theme.semanticColors.bodyDivider}`
                }
            })}`}>
                {controlElement}
                {dialogButtonsVisible() &&
                    <DialogFooter>
                        {renderDialogButtons()}
                    </DialogFooter>
                }
            </div>;
            break;
        case 1: // Center Dialog
        default:
            dialogElement = <Dialog
                {...props}
                onDismiss={() => {
                    onDismissHandler();
                }}

                modalProps={{
                    ...props.modalProps,
                    isBlocking: true,
                    className: `TALXIS__dialog--form ${props.modalProps.className} ${rootStyles}`
                }}
                dialogContentProps={{
                    ...props.dialogContentProps,
                    title: title,

                }}>
                {controlElement}
                {dialogButtonsVisible() &&
                    <DialogFooter>
                        {renderDialogButtons()}
                    </DialogFooter>
                }
            </Dialog>;
            break;
    }

    return dialogElement;
};

export default FormDialog;
FormDialog.defaultProps = defaultProps;