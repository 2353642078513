import { Client, Formatting, NumberFormattingInfo, Resources, Utility, Factory } from "../Implementation";
import { WebApi } from "./WebApi";
import { Mode } from "./Mode";
import { Navigation } from "./Navigation";
import { getTheme, ITheme } from "@fluentui/react";
import { Manifest } from "@controls/interfaces/manifest";
import { Form } from '@controls/native/Form/interfaces/form';
import { IControlProps } from "@controls/interfaces";
import { EntityDefinition } from "@definitions/EntityDefinition";
import { OptionSetDefinition } from "@definitions/OptionSetDefinition";
import { IViewDefinition } from "@controls/native/View/interfaces/viewdefinition";
import { OptionSet, IOptionSetDefinition as OptionSetValue, Option } from "@app/interfaces/optionset";
import { ICustomControl } from "@controls/interfaces/customcontrol";
import { GlobalOptionSet } from "@app/interfaces/twooptions";
import { Authentication } from "@app/classes/Authentication";
import { Attribute, RequiredLevel } from "@app/interfaces/entitydefinition";
import { IAttributeConfiguration } from "@controls/native/Form/interfaces/IAttributeConfiguration";
import * as LRU from 'lru-cache';
import { ViewDefinition } from "@definitions/ViewDefinition";
import { MultiSelectOptionSet, IMultiSelectOptionSetDefinition as MultiSelectGlobalOptionSetValue } from "@app/interfaces/multiselectoptionset";
import { UserSettings } from "@app/classes/UserSettings";
import { LocalizeLabel } from "@localization/helpers";
import { DataType } from "../interfaces/DataType";
import { sanitizeGuid } from "@app/Functions";
import { DomParser } from "@app/Constants";
import { RibbonDefinition, RibbonLocationFilters } from "@src/app/classes/definitions/RibbonDefinition";
import { IExtendedXrmGridControl } from "@src/components/controls/DatasetControl/interfaces/IExtendedXrmGridControl";
import { XrmGridType } from "@src/components/controls/DatasetControl/interfaces/XrmGridType";

interface ITransactionCurrency {
    transactioncurrencyid: string;
    currencysymbol: string;
    currencyprecision: number;
}

// The cache here is used for short-term caching when handling lookup values for PCF Context, mostly cuts down time on nested forms which receive values via extraqs
const lookupEntityCache = new LRU.default<string, ComponentFramework.WebApi.Entity>({
    maxAge: 1000 * 5
});

let transactionCurrencyCache: Promise<ITransactionCurrency[]> = null;

export const getRequiredLevel = (attributeConfiguration: null | IAttributeConfiguration, fieldRequired?: boolean): ComponentFramework.PropertyHelper.Types.RequiredLevel => {
    let requiredLevel: ComponentFramework.PropertyHelper.Types.RequiredLevel = -1;
    switch (attributeConfiguration?.entityRequiredLevel) {
        case "ApplicationRequired":
            requiredLevel = 2;
            break;
        case "None":
            requiredLevel = 0;
            break;
        case "Recommended":
            requiredLevel = 3;
            break;
        case "SystemRequired":
            requiredLevel = 1;
            break;
    }

    if (fieldRequired) {
        requiredLevel = 2;
    }

    if (attributeConfiguration && requiredLevel !== 1) {
        switch (attributeConfiguration.customRequiredLevel) {
            case "none":
                requiredLevel = 0;
                break;
            case "recommended":
                requiredLevel = 3;
                break;
            case "required":
                requiredLevel = 1;
                break;
        }
    }
    return requiredLevel;
};

async function getBindingParameters(entityName: string, manifest: Manifest.Control, bindings: Form.ControlBindings, entity: ComponentFramework.WebApi.Entity, attributeConfiguration: { [name: string]: IAttributeConfiguration }, context: Context) {
    const parameters: { [propertyName: string]: ComponentFramework.PropertyTypes.Property } = {};

    for (const [property, binding] of Object.entries(bindings ?? {})) {
        const fieldType = manifest?.properties.find(x => x.name === property)?.ofType;
        if (fieldType === DataType.LookupSimple || fieldType == DataType.LookupCustomer || fieldType === DataType.LookupOwner) {
            let lookupTargets: string[] = [];
            let logicalName: string;
            let displayName: string;
            let description: string;
            let viewId: string;
            let fieldName = binding.value;

            if (!entityName) {
                const targetEntities = DomParser.parseFromString(bindings["TargetEntities"].value, "text/xml");
                const entityLogicalNameElements = targetEntities.querySelectorAll('EntityLogicalName');
                for (const entityLogicalNameElement of entityLogicalNameElements) {
                    lookupTargets.push(entityLogicalNameElement.textContent);
                }
                logicalName = lookupTargets[0];
                const defaultViewIdElement = targetEntities.querySelector("DefaultViewId");
                if (defaultViewIdElement) {
                    // Getting lookup view of target entity. Current implementation does not support lookup to multiple entities.
                    viewId = sanitizeGuid(defaultViewIdElement.textContent);
                }
                else {
                    viewId = (await ViewDefinition.getLookupViewAsync(logicalName)).savedqueryid;
                }
                const entityDefinition = await EntityDefinition.getAsync(logicalName);
                description = LocalizeLabel(entityDefinition.Description.LocalizedLabels);
                displayName = LocalizeLabel(entityDefinition.DisplayName.LocalizedLabels);
            }
            else {
                const entityDefinition = await EntityDefinition.getAsync(entityName);
                // if (binding.type === "Lookup.Simple") {
                //     // TODO: This is a workaround to parse the body of parameters
                //     const lookupParameterObject = DomParser.parseFromString(`<root>${binding.extraParameters}</root>`, "text/xml");
                //     const bindingAttribute = lookupParameterObject.getElementsByTagName("BindAttribute")?.[0]?.textContent;
                //     if (bindingAttribute) {
                //         attribute = entityDefinition.Attributes.find(x => x.LogicalName === bindingAttribute);
                //     }
                //     else {
                //         attribute = entityDefinition.Attributes.find(x => x.LogicalName === binding.value);
                //     }
                // }
                const attribute = entityDefinition.Attributes.find(x => x.LogicalName === binding.value);
                // TODO: This should come from binding.extraParamters if provided instead of global DefaultViewId in case there are multiple lookups bound, see code above
                lookupTargets = await EntityDefinition.getLookupTargets(entityName, attribute.LogicalName);
                if (bindings["DefaultViewId"]?.value) {
                    viewId = sanitizeGuid(bindings["DefaultViewId"]?.value);
                }
                if (!viewId) {
                    // Getting lookup view of target entity. Current implementation does not support lookup to multiple entities.
                    viewId = (await ViewDefinition.getLookupViewAsync(lookupTargets[0])).savedqueryid;
                }
                logicalName = attribute.LogicalName;
                description = LocalizeLabel(attribute.Description.LocalizedLabels);
                displayName = LocalizeLabel(attribute.DisplayName.LocalizedLabels);
                fieldName = attribute.LogicalName;
            }

            const value: ComponentFramework.LookupValue[] = entity[fieldName];

            const boundValue: ComponentFramework.PropertyTypes.LookupProperty = {
                error: false,
                errorMessage: null,
                type: fieldType,
                getTargetEntityType: () => {
                    return lookupTargets[0];
                },
                getViewId: () => {
                    if (viewId) {
                        return viewId;
                    }

                    // TODO: This is used for filtering, probably use the default QuickView?
                    throw new Error("Unable to find QuickFindView!");
                },
                attributes: {
                    Description: description,
                    DisplayName: displayName,
                    LogicalName: logicalName,
                    SourceType: null,
                    RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                    IsSecured: null,
                    Targets: lookupTargets
                } as ComponentFramework.PropertyHelper.FieldPropertyMetadata.LookupMetadata,
                raw: []
            };
            if (value != null && value.length > 0) {
                for (const lookupValue of value) {
                    let name = lookupValue.name;
                    if (!name) {
                        const cacheKey = `${lookupValue.entityType}(${lookupValue.id})`;
                        const cachedResult = lookupEntityCache.get(cacheKey);
                        const targetEntityDefinition = await EntityDefinition.getAsync(lookupValue.entityType);
                        if (cachedResult) {
                            name = cachedResult[targetEntityDefinition.PrimaryNameAttribute];
                        }
                        else {
                            const targetEntityValue = await window.Xrm.WebApi.retrieveRecord(lookupValue.entityType, lookupValue.id, `?$select=${targetEntityDefinition.PrimaryNameAttribute}`);
                            name = targetEntityValue[targetEntityDefinition.PrimaryNameAttribute];
                            lookupEntityCache.set(cacheKey, targetEntityValue);
                        }
                    }
                    boundValue.raw.push({
                        id: lookupValue.id,
                        entityType: lookupValue.entityType,
                        name: name
                    });
                }
            }

            parameters[property] = boundValue;
        }
        else if (fieldType === DataType.OptionSet) {
            let optionSetsDefinition: OptionSetValue;
            let optionSet: OptionSet;
            let requiredLevel: RequiredLevel;
            // Check if Lookup is bound or unbound (dialog)
            if (entityName) {
                const definition = await OptionSetDefinition.getAsync(entityName);
                optionSetsDefinition = definition.value.find(x => x.LogicalName === binding.value);
                optionSet = optionSetsDefinition.OptionSet;
                requiredLevel = optionSetsDefinition.RequiredLevel;
            }
            else {
                const optionSetNameBinding = bindings["OptionSetName"] ?? binding;
                if (optionSetNameBinding) {
                    optionSet = await OptionSetDefinition.getGlobalAsync(optionSetNameBinding.value);
                }
                else {
                    throw new Error("Unable to find OptionSetName for unbound control in dialog!");
                }
            }

            // TODO: Get OptionSet definition from metadata and set default option and map available options

            const options: ComponentFramework.PropertyHelper.OptionMetadata[] = [];
            for (const option of attributeConfiguration?.[binding.value]?.options || optionSet.Options) {
                options.push({
                    Color: option.Color,
                    Label: LocalizeLabel(option.Label.LocalizedLabels),
                    Value: option.Value
                });
            }

            const parameter: ComponentFramework.PropertyTypes.OptionSetProperty = {
                raw: entity[binding.value],
                error: false,
                errorMessage: null,
                type: fieldType,
                attributes: {
                    DefaultValue: optionSetsDefinition?.DefaultFormValue,
                    Description: LocalizeLabel(optionSet.Description.LocalizedLabels),
                    DisplayName: LocalizeLabel(optionSet.DisplayName.LocalizedLabels),
                    LogicalName: optionSet.Name,
                    Options: options,
                    SourceType: null,
                    RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                    IsSecured: null
                }
            };

            parameters[property] = parameter;
        }
        else if (fieldType === DataType.TwoOptions) {
            let optionSet: GlobalOptionSet;
            let requiredLevel: RequiredLevel;
            if (entityName) {
                const response = await OptionSetDefinition.getTwoOptionsAsync(entityName);
                const attribute = response.value.find(x => x.LogicalName === binding.value);
                optionSet = attribute.OptionSet;
                requiredLevel = attribute.RequiredLevel;
            }
            else {
                // When we are unbound, we are going to GlobalOptionSets
                const optionSetNameBinding = bindings["OptionSetName"] ?? binding;
                if (optionSetNameBinding) {
                    try {
                        optionSet = await OptionSetDefinition.getGlobalTwoOptionsAsync(optionSetNameBinding.value);
                    } catch (error) {
                        // If boolean doesn't exist as global, we can use an empty object
                        optionSet = {
                            Name: null,
                            IsCustomOptionSet: false,
                            IsGlobal: null,
                            OptionSetType: "bool",
                            Description: { LocalizedLabels: null },
                            DisplayName: { LocalizedLabels: null },
                            TrueOption: {
                                Value: null,
                                Description: { LocalizedLabels: null },
                                ParentValues: null,
                                Label: { LocalizedLabels: null }
                            },
                            FalseOption: {
                                Value: null,
                                Description: { LocalizedLabels: null },
                                ParentValues: null,
                                Label: { LocalizedLabels: null }
                            }
                        };
                    }

                }
                else {
                    throw new Error("Unable to find OptionSetName for unbound control in dialog!");
                }
            }

            const parameter: ComponentFramework.PropertyTypes.TwoOptionsProperty = {
                raw: entity[binding.value],
                error: false,
                errorMessage: null,
                type: fieldType,
                attributes: {
                    DefaultValue: false,
                    Description: LocalizeLabel(optionSet.Description.LocalizedLabels),
                    DisplayName: LocalizeLabel(optionSet.DisplayName.LocalizedLabels),
                    LogicalName: optionSet.Name,
                    Options: [
                        {
                            Color: optionSet.TrueOption.Color,
                            Label: LocalizeLabel(optionSet.TrueOption.Label.LocalizedLabels),
                            Value: optionSet.TrueOption.Value
                        },
                        {
                            Color: optionSet.FalseOption.Color,
                            Label: LocalizeLabel(optionSet.FalseOption.Label.LocalizedLabels),
                            Value: optionSet.FalseOption.Value
                        }
                    ],
                    SourceType: null,
                    RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                    IsSecured: null
                }
            };

            parameters[property] = parameter;
        }
        else if (fieldType === DataType.MultiSelectOptionSet) {
            let optionSetsDefinition: MultiSelectGlobalOptionSetValue;
            let optionSet: MultiSelectOptionSet;
            let requiredLevel: RequiredLevel;
            // Check if Lookup is bound or unbound (dialog)
            if (entityName) {
                const definition = await OptionSetDefinition.getMultiSelectOptionSetAsync(entityName);
                optionSetsDefinition = definition.value.find(x => x.LogicalName === binding.value);
                // This covers filtering cases in grid (we could technically move this away into attributeConfiguration)
                if (!optionSetsDefinition?.OptionSet) {
                    const definition = await OptionSetDefinition.getAsync(entityName);
                    optionSetsDefinition = definition.value.find(x => x.LogicalName === binding.value);
                    if (!optionSetsDefinition?.OptionSet) {
                        const definition = await OptionSetDefinition.getTwoOptionsAsync(entityName);
                        const twoOptions = definition.value.find(x => x.LogicalName === binding.value);
                        optionSet = { ...twoOptions.OptionSet, Options: [twoOptions.OptionSet.TrueOption, twoOptions.OptionSet.FalseOption] };
                        optionSetsDefinition = { ...twoOptions, DefaultFormValue: twoOptions.OptionSet.FalseOption.Value, OptionSet: optionSet };
                    }
                    else {
                        optionSet = optionSetsDefinition.OptionSet;
                    }
                }
                else {
                    optionSet = optionSetsDefinition.OptionSet;
                }
                requiredLevel = optionSetsDefinition.RequiredLevel;
            }
            else {
                const optionSetNameBinding = bindings["OptionSetName"] ?? binding;
                if (optionSetNameBinding) {
                    optionSet = await OptionSetDefinition.getGlobalMultiSelectOptionSetAsync(optionSetNameBinding.value);
                }
                else {
                    throw new Error("Unable to find OptionSetName for unbound control in dialog!");
                }
            }

            // TODO: Get OptionSet definition from metadata and set default option and map available options

            const options: ComponentFramework.PropertyHelper.OptionMetadata[] = [];
            for (const option of attributeConfiguration?.[binding.value]?.options || optionSet.Options) {
                options.push({
                    Color: option.Color,
                    Label: LocalizeLabel(option.Label.LocalizedLabels),
                    Value: option.Value
                });
            }

            const value: number[] = [];
            if (Array.isArray(entity[binding.value])) {
                for (const val of entity[binding.value]) {
                    value.push(parseInt(val));
                }
            }
            else if (entity[binding.value]) {
                value.push(parseInt(entity[binding.value]));
            }

            const parameter: ComponentFramework.PropertyTypes.MultiSelectOptionSetProperty = {
                raw: value,
                error: false,
                errorMessage: null,
                type: fieldType,
                attributes: {
                    DefaultValue: optionSetsDefinition?.DefaultFormValue,
                    Description: LocalizeLabel(optionSet.Description.LocalizedLabels),
                    DisplayName: LocalizeLabel(optionSet.DisplayName.LocalizedLabels),
                    LogicalName: optionSet.Name,
                    Options: options,
                    SourceType: null,
                    RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                    IsSecured: null
                }
            };

            parameters[property] = parameter;
        }
        // TODO: We don't support of-type group in parsing at the moment
        else if (fieldType as string === 'DateTime' || fieldType === DataType.DateAndTimeDateAndTime || fieldType == DataType.DateAndTimeDateOnly) {
            if (binding.isStatic) {
                parameters[property] = {
                    raw: new Date(binding.value),
                    error: false,
                    errorMessage: null,
                    type: fieldType,
                    formatted: binding.value,
                    attributes: {
                        Description: null,
                        DisplayName: null,
                        LogicalName: binding.value,
                        SourceType: null,
                        RequiredLevel: null,
                        IsSecured: null,
                    }
                };
            }
            else {
                const entityDefinition = entityName ? await EntityDefinition.getAsync(entityName) : null;
                const field = entityDefinition?.Attributes.find(x => x.LogicalName === binding.value);

                const formattedValue = entity[`${binding.value}@OData.Community.Display.V1.FormattedValue`];
                let dateTimeBehavior: ComponentFramework.FormattingApi.Types.DateTimeFieldBehavior = null;
                switch (field?.DateTimeBehavior.Value) {
                    case "None":
                        dateTimeBehavior = 0;
                        break;
                    case "UserLocal":
                        dateTimeBehavior = 1;
                        break;
                    case "TimeZoneIndependent":
                        dateTimeBehavior = 3;
                        break;

                }
                const _attributes: ComponentFramework.PropertyHelper.FieldPropertyMetadata.DateTimeMetadata = {
                    Description: LocalizeLabel(field?.Description.LocalizedLabels),
                    DisplayName: LocalizeLabel(field?.DisplayName.LocalizedLabels),
                    LogicalName: field?.LogicalName ?? binding.value,
                    SourceType: null,
                    RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                    IsSecured: null,
                    Format: field?.Format,
                    Behavior: dateTimeBehavior,
                    ImeMode: null
                };
                let value: Date = null;
                if (entity[binding.value] !== null && entity[binding.value] !== undefined) {
                    value = new Date(entity[binding.value]);
                    if (_attributes.Behavior !== 1) {
                        const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
                        value = new Date(new Date(entity[binding.value]).getTime() + tzoffset);
                    }
                }
                const _property: ComponentFramework.PropertyTypes.DateTimeProperty = {
                    raw: value,
                    error: false,
                    errorMessage: null,
                    type: fieldType,
                    formatted: formattedValue ?? entity[binding.value],
                    attributes: _attributes
                };
                parameters[property] = _property;
            }

        }
        else if (fieldType === DataType.WholeNone) {
            if (binding.isStatic) {
                parameters[property] = {
                    raw: parseInt(binding.value),
                    error: false,
                    errorMessage: null,
                    type: fieldType,
                    formatted: binding.value,
                    attributes: {
                        Description: null,
                        DisplayName: null,
                        LogicalName: binding.value,
                        SourceType: null,
                        RequiredLevel: null,
                        IsSecured: null,
                    }
                };
            } else {
                const entityDefinition = entityName ? await EntityDefinition.getAsync(entityName) : null;
                const field = entityDefinition?.Attributes.find(x => x.LogicalName === binding.value);

                const formattedValue = entity[`${binding.value}@OData.Community.Display.V1.FormattedValue`];
                parameters[property] = {
                    raw: parseInt(entity[binding.value]),
                    error: false,
                    errorMessage: null,
                    type: fieldType,
                    formatted: formattedValue ?? entity[binding.value],
                    attributes: {
                        Description: LocalizeLabel(field?.Description.LocalizedLabels),
                        DisplayName: LocalizeLabel(field?.DisplayName.LocalizedLabels),
                        LogicalName: field?.LogicalName ?? binding.value,
                        SourceType: null,
                        RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                        IsSecured: null,
                    }
                };
            }
        }
        else if (fieldType === DataType.Currency) {
            if (!transactionCurrencyCache) {
                transactionCurrencyCache = new Promise(async (resolve) => {
                    const result = await Xrm.WebApi.retrieveMultipleRecords('transactioncurrency');
                    resolve(result.entities);
                });
            }
            let currency: ITransactionCurrency = null;
            const currentCurrencyId = entity["transactioncurrencyid"]?.length > 0 && entity["transactioncurrencyid"][0].id;
            if (currentCurrencyId) {
                currency = (await transactionCurrencyCache).find(x => x.transactioncurrencyid === currentCurrencyId);
            }
            else {
                currency = (await transactionCurrencyCache)[0];
            }
            if (binding.isStatic) {
                parameters[property] = {
                    raw: binding.value,
                    error: false,
                    errorMessage: null,
                    type: fieldType ?? DataType.SingleLineText,
                    formatted: binding.value,
                    attributes: {
                        Description: null,
                        DisplayName: null,
                        LogicalName: binding.value,
                        SourceType: null,
                        RequiredLevel: null,
                        IsSecured: null,
                    }
                };
            }
            else {
                const entityDefinition = entityName ? await EntityDefinition.getAsync(entityName) : null;
                const field = entityDefinition?.Attributes.find(x => x.LogicalName === binding.value);
                let value = entity[binding.value];
                const formattedValue = context.formatting.formatCurrency(value, currency.currencyprecision, currency.currencysymbol);
                parameters[property] = {
                    raw: entity[binding.value],
                    type: fieldType,
                    error: value != null && isNaN(value),
                    errorMessage: value != null && isNaN(value) ? window.TALXIS.Portal.Translations.getLocalizedString('@ComponentFramework/PropertyClasses/Context') : undefined,
                    formatted: formattedValue ?? entity[binding.value],
                    attributes: {
                        Description: LocalizeLabel(field?.Description.LocalizedLabels),
                        DisplayName: LocalizeLabel(field?.DisplayName.LocalizedLabels),
                        LogicalName: field?.LogicalName ?? binding.value,
                        SourceType: null,
                        RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                        IsSecured: null,
                    }
                };
            }
        }
        else {
            if (binding.isStatic) {
                parameters[property] = {
                    raw: binding.value,
                    error: false,
                    errorMessage: null,
                    type: fieldType ?? DataType.SingleLineText,
                    formatted: binding.value,
                    attributes: {
                        Description: null,
                        DisplayName: null,
                        LogicalName: binding.value,
                        SourceType: null,
                        RequiredLevel: null,
                        IsSecured: null,
                    }
                };
            }
            else {
                const entityDefinition = entityName ? await EntityDefinition.getAsync(entityName) : null;
                const field = entityDefinition?.Attributes.find(x => x.LogicalName === binding.value);

                const formattedValue = entity[`${binding.value}@OData.Community.Display.V1.FormattedValue`];
                parameters[property] = {
                    raw: entity[binding.value],
                    error: false,
                    errorMessage: null,
                    type: fieldType ?? DataType.SingleLineText,
                    formatted: formattedValue ?? entity[binding.value],
                    attributes: {
                        Description: LocalizeLabel(field?.Description.LocalizedLabels),
                        DisplayName: LocalizeLabel(field?.DisplayName.LocalizedLabels),
                        LogicalName: field?.LogicalName ?? binding.value,
                        SourceType: null,
                        RequiredLevel: getRequiredLevel(attributeConfiguration?.[binding.value], binding.isRequired),
                        IsSecured: null,
                    }
                };
            }
        }
    }

    // Add properties which weren't explicitly specified in form, but are in the control manifest
    const unboundProperties = manifest?.properties.filter(x => !Object.keys(parameters).includes(x.name));
    for (const unboundProperty of unboundProperties) {
        parameters[unboundProperty.name] = {
            raw: null,
            error: false,
            errorMessage: null,
            type: unboundProperty.ofType,
            formatted: null,
        };
    }

    return parameters;
}
async function getDatasetParameters(
    entityName: string,
    manifest: Manifest.Control,
    viewDefinition: IViewDefinition,
    currentPage: ComponentFramework.WebApi.RetrieveMultipleResponse,
    datasetInputs: IDatasetInputs,
    gridControl: IExtendedXrmGridControl
) {
    const parameters: { [propertyName: string]: ComponentFramework.PropertyTypes.DataSet } = {};

    const entityDefinition = await EntityDefinition.getAsync(entityName);

    // TODO: I personally don't like this sort of value mapping... ~HH
    const records: { [id: string]: ComponentFramework.PropertyHelper.DataSetApi.EntityRecord } = {};
    for (const record of currentPage.entities) {
        records[record[entityDefinition.PrimaryIdAttribute]] = {
            getFormattedValue: (columnName: string): string => {
                const column = viewDefinition.columns.find(x => x.name === columnName);
                let aliasedRecord = record;
                // This handles case of $expands
                if (columnName.includes('.')) {
                    const odataExpands = column.odataExpandAttribute.split(".");
                    let expandedValue: ComponentFramework.WebApi.Entity = record;
                    for (const navigationProperty of odataExpands) {
                        expandedValue = expandedValue?.[navigationProperty];
                    }
                    // This handles M:M $expands, it should be eventually moved to the DataSet Control which handles data retrieval and paging
                    if (Array.isArray(expandedValue)) {
                        if (expandedValue.length > 0) {
                            aliasedRecord = expandedValue[0];
                        }
                    }
                    else if (expandedValue) {
                        aliasedRecord = expandedValue;
                    }
                    else {
                        aliasedRecord = {};
                    }

                    columnName = columnName.split('.')[1];
                }

                if (aliasedRecord !== null) {
                    let formattedValue = aliasedRecord[`${columnName}@OData.Community.Display.V1.FormattedValue`];
                    if (!formattedValue) formattedValue = aliasedRecord[`_${columnName}_value@OData.Community.Display.V1.FormattedValue`];

                    // Case when lookup field has empty primary field.
                    if (!formattedValue && aliasedRecord[`_${columnName}_value`] !== undefined) formattedValue = null;

                    return formattedValue !== undefined ? formattedValue : aliasedRecord[columnName];
                }
                else {
                    return null;
                }
            },
            getNamedReference: (): ComponentFramework.EntityReference => {
                return {
                    id: {
                        guid: record[entityDefinition.PrimaryIdAttribute]
                    },
                    name: record[entityDefinition.PrimaryNameAttribute],
                    etn: entityDefinition.LogicalName
                };
            },
            getRecordId: (): string => {
                return record[entityDefinition.PrimaryIdAttribute];
            },
            getValue: (columnName: string): string | Date | number | number[] | boolean | ComponentFramework.EntityReference | ComponentFramework.EntityReference[] | ComponentFramework.LookupValue => {
                // This handles case of $expands
                const column = viewDefinition.columns.find(x => x.name === columnName);
                let aliasedRecord = record;
                if (columnName.includes('.')) {
                    const odataExpands = column.odataExpandAttribute.split(".");
                    let expandedValue: ComponentFramework.WebApi.Entity = record;
                    for (const navigationProperty of odataExpands) {
                        expandedValue = expandedValue?.[navigationProperty];
                    }
                    // This handles M:M $expands, it should be eventually moved to the DataSet Control which handles data retrieval and paging
                    if (Array.isArray(expandedValue)) {
                        if (expandedValue.length > 0) {
                            aliasedRecord = expandedValue[0];
                        }
                    }
                    else if (expandedValue) {
                        aliasedRecord = expandedValue;
                    }
                    else {
                        aliasedRecord = {};
                    }

                    columnName = columnName.split('.')[1];
                }

                if (aliasedRecord !== null) {
                    if (aliasedRecord[`_${columnName}_value`]) {
                        return {
                            id: aliasedRecord[`_${columnName}_value`],
                            entityType: aliasedRecord[`_${columnName}_value@Microsoft.Dynamics.CRM.lookuplogicalname`],
                            name: aliasedRecord[`_${columnName}_value@OData.Community.Display.V1.FormattedValue`]
                        };
                    }
                    else {
                        return aliasedRecord[columnName];
                    }
                }
                else {
                    return null;
                }
            }
        };
    }

    for (const dataset of manifest.datasets) {
        const sorting = datasetInputs.getSorting();
        const datasetParameter: ComponentFramework.PropertyTypes.DataSet = {
            refresh: (): void => { datasetInputs.refreshPage(); },
            clearSelectedRecordIds: () => { datasetInputs.clearSelectedRecordIds(); },
            getSelectedRecordIds: () => { return datasetInputs.selectedRecordIds; },
            setSelectedRecordIds: (ids: string[]) => { datasetInputs.setSelectedRecordIds(ids); },
            getTitle: () => { return viewDefinition.name; },
            getViewId: () => { return viewDefinition.savedqueryid; },
            getTargetEntityType: () => { return entityName; },
            openDatasetItem: async (entityReference: ComponentFramework.EntityReference) => {
                const locationFilter = gridControl.getGridType() === XrmGridType.Subgrid ? RibbonLocationFilters.SubGrid : RibbonLocationFilters.HomepageGrid;
                const ribbon = (await RibbonDefinition.getRibbon(viewDefinition.returnedtypecode, locationFilter));
                const button = await ribbon.getRibbonButtonProps(null, [{
                    getFormattedValue: null,
                    getValue: null,
                    getRecordId: () => {
                        return entityReference.id.guid;
                    },
                    getNamedReference: null
                }], gridControl, 'Mscrm.OpenRecordItem');
                if (button) {
                    button.onClick();
                }
                else {
                    window.Xrm.Navigation.openForm({
                        entityName: entityReference.etn,
                        entityId: entityReference.id.guid,
                    });
                }
            },
            columns: viewDefinition.columns,
            error: false,
            errorMessage: null,
            filtering: {
                setFilter: (expression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression) => {
                    datasetInputs.setFilter(expression);
                },
                clearFilter: () => {
                    datasetInputs.setFilter(null);
                },
                getFilter: () => {
                    return datasetInputs.getFilter();
                }
            },
            linking: {
                addLinkedEntity: (expression: ComponentFramework.PropertyHelper.DataSetApi.LinkEntityExposedExpression) => {
                    datasetInputs.addLinkedEntity(expression);
                },
                getLinkedEntities: () => {
                    return datasetInputs.getLinkedEntities();
                }
            },
            loading: datasetInputs.loading,
            paging: {
                hasNextPage: currentPage.nextLink !== null ? true : false,
                hasPreviousPage: datasetInputs.currentPage > 0,
                // @ts-ignore - This comes from our custom implementation of PcfWebApi
                totalResultCount: currentPage._totalRecordCount,
                loadNextPage: (): void => { datasetInputs.loadNextPage(); },
                loadPreviousPage: (): void => { datasetInputs.loadPreviousPage(); },
                setPageSize: (pageSize: number): void => { datasetInputs.setPageSize(pageSize); },
                reset: (): void => { datasetInputs.setPageSize(datasetInputs.pageSize); },
                loadExactPage: (pageNumber: number): void => { throw new Error("Not implemented!"); },
                // @ts-ignore - pageSize is included in paging, but not in types
                pageSize: datasetInputs.pageSize,
                firstPageNumber: null,
                lastPageNumber: null
            },
            records: records,
            sortedRecordIds: currentPage.entities.map(x => x[entityDefinition.PrimaryIdAttribute]),
            get sorting(): ComponentFramework.PropertyHelper.DataSetApi.SortStatus[] {
                return sorting;
            },
            set sorting(sortStatus: ComponentFramework.PropertyHelper.DataSetApi.SortStatus[]) {
                datasetInputs.setSorting(sortStatus);
            },
            addColumn: null
        };

        parameters[dataset.name] = datasetParameter;
    }

    return parameters;
}

export class Context implements ComponentFramework.Context<any> {
    theme: ITheme;
    client: ComponentFramework.Client;
    factory: ComponentFramework.Factory;
    formatting: ComponentFramework.Formatting;
    mode: ComponentFramework.Mode;
    navigation: ComponentFramework.Navigation;
    resources: ComponentFramework.Resources;
    userSettings: ComponentFramework.UserSettings;
    utils: ComponentFramework.Utility;
    webAPI: ComponentFramework.WebApi;
    parameters: { [propertyName: string]: ComponentFramework.PropertyTypes.Property | ComponentFramework.PropertyTypes.DataSet };
    updatedProperties: string[];
    device: ComponentFramework.Device;
    page: {
        entityTypeName: string;
        entityId: string;
    };

    private constructor(props: IControlProps, controlDefinition: ICustomControl, entityName: string, entityId?: string) {
        this.resources = new Resources(props.name, controlDefinition);
        this.mode = new Mode(props);
        this.webAPI = new WebApi();
        this.navigation = new Navigation();
        this.utils = new Utility();
        this.factory = new Factory(props.childeventlisteners);
        this.client = new Client();
        this.userSettings = {
            // TODO: This should come from AppContext
            userId: Authentication.getUser()?.accessPrincipalId,
            userName: Authentication.getUser()?.displayName,
            // TODO: Pull user settings from AppContext, stored in EDS
            languageId: UserSettings.getUserSettings()?.languageId ?? window.TALXIS.Portal.Translations._lcid,
            dateFormattingInfo: null,
            getTimeZoneOffsetMinutes: null,
            isRTL: null,
            numberFormattingInfo: new NumberFormattingInfo(),
            securityRoles: null
        };
        this.formatting = new Formatting(this.userSettings);
        this.page = {
            entityTypeName: entityName,
            entityId: entityId
        };
    }
    public static async createFieldContext(props: IControlProps, entityName: string, entity: ComponentFramework.WebApi.Entity, controlDefinition: ICustomControl, attributeConfiguration: { [name: string]: IAttributeConfiguration }, entityId?: string, updatedProperties?: string[]): Promise<Context> {
        const context = new Context(props, controlDefinition, entityName, entityId);

        context.parameters = await getBindingParameters(entityName, controlDefinition.manifest, props.bindings, entity, attributeConfiguration, context);
        context.updatedProperties = updatedProperties ?? [];

        return context;
    }
    public static async createRibbonContext(props: IControlProps) {
        const context = new Context(props, await props.definition.registration, null, null);
        return context;
    }
    public static async createDatasetContext(
        props: IControlProps,
        entityName: string,
        controlDefinition: ICustomControl,
        viewDefinition: IViewDefinition,
        currentPage: ComponentFramework.WebApi.RetrieveMultipleResponse,
        datasetInputs: IDatasetInputs,
        gridControl: IExtendedXrmGridControl,
        entity?: ComponentFramework.WebApi.Entity,
    ): Promise<Context> {
        const context = new Context(props, controlDefinition, entityName);

        const bindingParameters = await getBindingParameters(entityName, controlDefinition.manifest, props.bindings, entity, {}, context);
        const datasetParameters = await getDatasetParameters(entityName, controlDefinition.manifest, viewDefinition, currentPage, datasetInputs, gridControl);

        context.parameters = { ...bindingParameters, ...datasetParameters };
        return context;
    }
}

interface IDatasetInputs {
    currentPage: number;
    pageSize: number;
    loading: boolean;
    refreshPage: () => void;
    loadNextPage: () => void;
    loadPreviousPage: () => void;
    setPageSize: (pageSize: number) => void;
    selectedRecordIds: string[];
    clearSelectedRecordIds: () => void;
    setSelectedRecordIds: (ids: string[]) => void;
    setFilter: (expression: ComponentFramework.PropertyHelper.DataSetApi.FilterExpression) => void;
    getFilter: () => ComponentFramework.PropertyHelper.DataSetApi.FilterExpression;
    addLinkedEntity: (expression: ComponentFramework.PropertyHelper.DataSetApi.LinkEntityExposedExpression) => void;
    setSorting: (sorting: ComponentFramework.PropertyHelper.DataSetApi.SortStatus[]) => void;
    getSorting: () => ComponentFramework.PropertyHelper.DataSetApi.SortStatus[];
    getLinkedEntities: () => ComponentFramework.PropertyHelper.DataSetApi.LinkEntityExposedExpression[];
}