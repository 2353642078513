import React, { useContext, useRef } from 'react';
import { Control } from '../../../../';
import { IFormContext } from '../../interfaces/IFormContext';
import { FormContext } from '../../Form';
import { Form } from "../../interfaces/form";
import { Label } from '@fluentui/react/lib/components/Label/Label';
import { Icon } from '@fluentui/react/lib/components/Icon/Icon';
import { mergeStyles, useTheme } from '@fluentui/react';
import { getRequiredLevel } from '@ComponentFramework/PropertyClasses/Context';

export interface ICellProps {
    cell: Form.Cell;
    formContext?: IFormContext;
    onFormUpdated: (arg: { formContext: Xrm.FormContext }) => void;
};

export const Cell: React.FC<ICellProps> = (props) => {
    const formContext: IFormContext = props.formContext ?? useContext(FormContext);
    const theme = useTheme();
    const cell = props.cell;
    const control = props.cell.control;
    const ref = useRef<HTMLDivElement>(null);
    let shouldDisplay = ((control && (control.visible ?? true)) || (!cell.control && (cell?.visible ?? true))) && (!formContext.isMobile || (cell?.availableForPhone ?? true));
    let dataIdClass = "fieldControl_container";
    const requiredLevel = getRequiredLevel(formContext.attributeConfiguration[control?.datafieldname], control?.isRequired);
    if (cell?.control?.classId.toLowerCase() === "{4273edbd-ac1d-40d3-9fb2-095c621b552d}") {
        dataIdClass = "fieldControl-text-box-container";
    }
    const labelVisible = () => {
        if (cell?.showLabel && cell?.control && cell?.control?.bindings?.EnableViewPicker?.value !== 'true' && cell?.control?.name !== 'TALXIS.PCF.Portal.Label') {
            return true;
        }
        return false;
    };
    return (
        //In dialogs controls may not be defined for label placeholders and in that case labels should be displayed
        <div ref={ref} className={`TALXIS__form__cell ${mergeStyles({
            gridColumnEnd: cell.colspan && `span ${cell.colspan}`,
            gridRowEnd: cell.rowspan && `span ${cell.rowspan}`,
            '.TALXIS__form__cell__required-indicator': {
                color: theme.semanticColors.errorIcon,
                marginLeft: 3
            }

        })}`}>
            {shouldDisplay &&
                <div>
                    {labelVisible() &&
                        <>
                            <Label><span>{cell.label}</span>
                                {(requiredLevel === 1 || requiredLevel === 2) &&
                                    <span className='TALXIS__form__cell__required-indicator'>*</span>
                                }
                                {cell.control.disabled && <Icon iconName='Lock12' />}
                            </Label>
                        </>
                    }
                    {cell?.control &&
                        <div data-id={`${cell.control?.id}.${dataIdClass}`}>
                            <Control formContext={formContext} {...cell.control} label={cell.label} childeventlisteners={cell.control.name?.includes('PCF.Portal.Form') ? [
                                {
                                    eventname: "__onFormUpdated",
                                    eventhandler: props.onFormUpdated
                                }
                            ] : undefined} />
                        </div>
                    }
                </div>
            }
        </div>
    );
};
export default Cell;