import { EntityDefinition as IEntityDefinition, Attribute, Relationship, FormatType, DateTimeBehaviorType } from "@app/interfaces/entitydefinition";
import { LocalizeLabel } from "@localization/helpers";
import { IOptionSetDefinition } from "@app/interfaces/optionset";
import { IODataResponse } from "@app/interfaces/general";

export class GetEntityMetadataResponse {
    private _entityMetadata: IEntityDefinition;
    private _attributes: string[];
    public _optionSets: IOptionSetDefinition[];
    constructor(entityMetadata: IEntityDefinition, attributes: string[], optionSets: IODataResponse<IOptionSetDefinition>) {
        this._entityMetadata = entityMetadata;
        this._attributes = attributes?.map(x => x.toLowerCase()) ?? [];
        this._optionSets = optionSets.value;
    }
    public get ActivityTypeMask(): number {
        throw new Error("Not implemented!");
    }
    public get AutoRouteToOwnerQueue(): boolean {
        throw new Error("Not implemented!");
    }
    public get CanEnableSyncToExternalSearchIndex(): boolean {
        throw new Error("Not implemented!");
    }
    public get CanTriggerWorkflow(): boolean {
        throw new Error("Not implemented!");
    }
    public get Description(): string {
        return LocalizeLabel(this._entityMetadata.Description.LocalizedLabels);
    }
    public get DisplayCollectionName(): string {
        return LocalizeLabel(this._entityMetadata.DisplayCollectionName.LocalizedLabels);
    }
    public get DisplayName(): string {
        return LocalizeLabel(this._entityMetadata.DisplayName.LocalizedLabels);
    }
    public get EnforceStateTransitions(): boolean {
        throw new Error("Not implemented!");
    }
    public get EntityColor(): string {
        throw new Error("Not implemented!");
    }
    public get EntitySetName(): string {
        return this._entityMetadata.EntitySetName;
    }
    public get IsActivity(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsActivityParty(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsBusinessProcessEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsChildEntity(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsConnectionsEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsCustomEntity(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsCustomizable(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsDocumentManagementEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsDocumentRecommendationsEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsDuplicateDetectionEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsEnabledForCharts(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsImportable(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsInteractionCentricEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsKnowledgeManagementEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsMailMergeEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsManaged(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsOneNoteIntegrationEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsOptimisticConcurrencyEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsQuickCreateEnabled(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsReadOnlyInMobileClient(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsStateModelAware(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsValidForAdvancedFind(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsVisibleInMobileClient(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsEnabledInUnifiedInterface(): boolean {
        throw new Error("Not implemented!");
    }
    public get LogicalCollectionName(): string {
        throw new Error("Not implemented!");
    }
    public get LogicalName(): string {
        return this._entityMetadata.LogicalName;
    }
    public get ObjectTypeCode(): number {
        throw new Error("Not implemented!");
    }
    public get OwnershipType(): number { // Is actually EntityOwnershipType
        throw new Error("Not implemented!");
    }
    public get PrimaryIdAttribute(): string {
        return this._entityMetadata.PrimaryIdAttribute;
    }
    public get PrimaryImageAttribute(): string {
        throw new Error("Not implemented!");
    }
    public get PrimaryNameAttribute(): string {
        return this._entityMetadata.PrimaryNameAttribute;
    }
    public get Privileges(): SecurityPrivilegeMetadata[] {
        throw new Error("Not implemented!");
    }
    public get SchemaName(): string {
        return this._entityMetadata.SchemaName;
    }
    public get Attributes(): IItemCollection<AttributeMetadata> {
        return {
            getAll: () => {
                const attributes: AttributeMetadata[] = [];

                for (const attribute of this._entityMetadata.Attributes.filter(x => this._attributes.includes(x.LogicalName.toLowerCase()))) {
                    attributes.push(new AttributeMetadata(this, this._entityMetadata, attribute, this._optionSets.find(x => x.LogicalName == attribute.LogicalName)));
                }

                return attributes;
            },
            getByName: (name: string) => {
                // If you are explicitly requesting an attribute which you didn't ask for, we return null
                if (!this._attributes.includes(name.toLowerCase())) return null;

                const attribute = this._entityMetadata.Attributes.find(x => x.LogicalName.toLowerCase() === name.toLowerCase());
                if (!attribute) return null;
                return new AttributeMetadata(this, this._entityMetadata, attribute, this._optionSets.find(x => x.LogicalName == attribute.LogicalName));
            },
            getLength: () => {
                return this._entityMetadata.Attributes.filter(x => this._attributes.includes(x.LogicalName.toLowerCase())).length;
            }
        };
    }
    public get ManyToOneRelationships(): IItemCollection<ManyToOneRelationship> {
        return {
            getAll: () => {
                const attributes: ManyToOneRelationship[] = [];

                for (const attribute of this._entityMetadata.ManyToOneRelationships) {
                    attributes.push(new ManyToOneRelationship(this._entityMetadata, attribute));
                }

                return attributes;
            },
            getByName: (name: string) => {
                const attribute = this._entityMetadata.ManyToOneRelationships.find(x => x.SchemaName.toLowerCase() === name.toLowerCase());
                if (!attribute) return null;
                return new ManyToOneRelationship(this._entityMetadata, attribute);
            },
            getLength: () => {
                return this._entityMetadata.Attributes.length;
            }
        };
    }
}

class AttributeMetadata implements Xrm.Metadata.AttributeMetadata {
    private _attribute: Attribute;
    private _entityMetadata: IEntityDefinition;
    private _optionSet: IOptionSetDefinition;
    private _parent: GetEntityMetadataResponse;
    constructor(parent: GetEntityMetadataResponse, entityMetadata: IEntityDefinition, attribute: Attribute, optionSet?: IOptionSetDefinition) {
        this._attribute = attribute;
        this._entityMetadata = entityMetadata;
        this._optionSet = optionSet;
        this._parent = parent;
    }
    public get attributeDescriptor(): ExtendedAttribute {
        const entityMetadata = this._entityMetadata;
        const descriptor = {
            ...this._attribute,
            get Targets(): string[] {
                return entityMetadata.ManyToOneRelationships.filter(x => x.ReferencingAttribute === this.LogicalName && x.ReferencingEntity === this.EntityLogicalName).map(x => x.ReferencedEntity);
            }
        };
        return descriptor;
    };
    public get AttributeType(): XrmEnum.AttributeTypeCode {
        switch (this._attribute.AttributeType) {
            case "Boolean":
                return AttributeTypeCode.Boolean;
            case "BigInt":
                return AttributeTypeCode.BigInt;
            case "CalendarRules":
                return AttributeTypeCode.CalendarRules;
            case "Customer":
                return AttributeTypeCode.Customer;
            case "DateTime":
                return AttributeTypeCode.DateTime;
            case "Decimal":
                return AttributeTypeCode.Decimal;
            case "Double":
                return AttributeTypeCode.Double;
            case "EntityName":
                return AttributeTypeCode.EntityName;
            case "Integer":
                return AttributeTypeCode.Integer;
            case "Lookup":
                return AttributeTypeCode.Lookup;
            case "ManagedProperty":
                return AttributeTypeCode.ManagedProperty;
            case "Memo":
                return AttributeTypeCode.Memo;
            case "Money":
                return AttributeTypeCode.Money;
            case "Owner":
                return AttributeTypeCode.Owner;
            case "PartyList":
                return AttributeTypeCode.PartyList;
            case "Picklist":
                return AttributeTypeCode.Picklist;
            case "State":
                return AttributeTypeCode.State;
            case "Status":
                return AttributeTypeCode.Status;
            case "String":
                return AttributeTypeCode.String;
            case "Uniqueidentifier":
                return AttributeTypeCode.Uniqueidentifier;
            case "Virtual":
                return AttributeTypeCode.Virtual;
            default:
                throw new Error("Unknown attribute type!");
        }
    }
    public get AttributeTypeName(): string {
        return this._attribute.AttributeTypeName.Value;
    }
    public get DisplayName(): string {
        return LocalizeLabel(this._attribute.DisplayName.LocalizedLabels);
    }
    public get EntityLogicalName(): string {
        return this._attribute.EntityLogicalName;
    }
    public get Format(): FormatType {
        return this._attribute.Format;
    }
    public get DateTimeBehavior(): DateTimeBehaviorType {
        return this._attribute.DateTimeBehavior.Value;
    }
    public get IsValidForGrid(): string {
        throw new Error("Not implemented!");
    }
    public get LogicalName(): string {
        return this._attribute.LogicalName;
    }
    public get DefaultFormValue(): number {
        throw new Error("Not implemented!");
    }
    public getDefaultStatus(state: number): number {
        if (this._attribute.LogicalName !== "statecode") {
            throw "getDefaultStatus can be only called on statecode field!";
        }
        const stateDefinition = this._optionSet.OptionSet.Options.find(x => x.Value === state);
        return stateDefinition.DefaultStatus;
    }
    public getStatusValuesForState(state: number): number[] {
        if (this._attribute.LogicalName !== "statecode") {
            throw "getDefaultStatus can be only called on statecode field!";
        }
        const statusCodes = this._parent._optionSets.find(x => x.LogicalName === "statuscode").OptionSet;
        return statusCodes?.Options.filter(x => x.State === state).map(x => x.Value) ?? [];
    }
    public getState(status: number): number {
        if (this._attribute.LogicalName !== "statuscode") {
            throw "getState can be only called on statuscode field!";
        }
        const stateDefinition = this._optionSet.OptionSet.Options.find(x => x.Value === status);
        if (!stateDefinition) {
            throw `Invalid value ${status} for status`;
        }
        return stateDefinition.State;
    }
    // @ts-ignore - OptionSet type is incorrect in Xrm
    public get OptionSet(): { [key: number]: object } {
        return this._optionSet.OptionSet.Options.reduce((accumulator: { [key: number]: object }, x) => {
            // For some reason, Xrm types are wrong, because OptionSet in Power Apps returns only `text` and `value` properties, so we return them too and also return the required values as undefined
            accumulator[x.Value] = {
                text: LocalizeLabel(x.Label.LocalizedLabels),
                value: x.Value
            };

            return accumulator;
        }, {});
    }
}
class ManyToOneRelationship {
    private _attribute: Relationship;
    private _entityMetadata: IEntityDefinition;
    constructor(entityMetadata: IEntityDefinition, attribute: Relationship) {
        this._attribute = attribute;
        this._entityMetadata = entityMetadata;
    }
    public get relationshipMetadata(): Relationship {
        return this._attribute;
    }
    public get AssociatedMenuConfiguration(): object {
        throw new Error("Not implemented!");
    }
    public get CascadeConfiguration(): object {
        throw new Error("Not implemented!");
    }
    public get IntroducedVersion(): string {
        throw new Error("Not implemented!");
    }
    public get IsCustomRelationship(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsCustomizable(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsHierarchical(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsManaged(): boolean {
        throw new Error("Not implemented!");
    }
    public get IsValidForAdvancedFind(): boolean {
        throw new Error("Not implemented!");
    }
    public get ReferencedAttribute(): string {
        return this._attribute.ReferencedAttribute;
    }
    public get ReferencedEntity(): string {
        return this._attribute.ReferencedEntity;
    }
    public get ReferencedEntityNavigationPropertyName(): string {
        return this._attribute.ReferencedEntityNavigationPropertyName;
    }
    public get ReferencingAttribute(): string {
        return this._attribute.ReferencingAttribute;
    }
    public get ReferencingEntity(): string {
        return this._attribute.ReferencingEntity;
    }
    public get ReferencingEntityNavigationPropertyName(): string {
        return this._attribute.ReferencingEntityNavigationPropertyName;
    }
    public get RelationshipType(): string {
        return this._attribute.RelationshipType;
    }
    public get SchemaName(): string {
        return this._attribute.SchemaName;
    }
    public get SecurityTypes(): string {
        throw new Error("Not implemented!");
    }
}

interface ExtendedAttribute extends Attribute {
    Targets: string[];
}

interface SecurityPrivilegeMetadata {
    CanBeBasic: boolean;
    CanBeDeep: boolean;
    CanBeGlobal: boolean;
    CanBeLocal: boolean;
    CanBeEntityReference: boolean;
    CanBeParentEntityReference: boolean;
    Name: string;
    PrivilegeId: string;
    PrivilegeType: Constants.PrivilegeType;
}

declare namespace Constants {
    /**
     * Entity privilege types.
     */
    const enum PrivilegeType {
        None = 0,
        Create = 1,
        Read = 2,
        Write = 3,
        Delete = 4,
        Assign = 5,
        Share = 6,
        Append = 7,
        AppendTo = 8,
    }
}
// Comes from XrmEnum.AttributeTypeCode
class AttributeTypeCode {
    static Boolean: number = 0;
    static Customer: number = 1;
    static DateTime: number = 2;
    static Decimal: number = 3;
    static Double: number = 4;
    static Integer: number = 5;
    static Lookup: number = 6;
    static Memo: number = 7;
    static Money: number = 8;
    static Owner: number = 9;
    static PartyList: number = 10;
    static Picklist: number = 11;
    static State: number = 12;
    static Status: number = 13;
    static String: number = 14;
    static Uniqueidentifier: number = 15;
    static CalendarRules: number = 16;
    static Virtual: number = 17;
    static BigInt: number = 18;
    static ManagedProperty: number = 19;
    static EntityName: number = 20;
}
interface IItemCollection<T> {
    // forEach(delegate: IterativeDelegate<T>): void;
    // get(delegate: MatchingDelegate<T>): T[];
    // get(itemNumber: number): T;
    // get(itemName: string): T;
    // get(): T[];
    getAll(): T[];
    getByName(name: string): T;
    getLength(): number;
}
interface IterativeDelegate<T> {
    /**
     * Called for each item in an array
     *
     * @param	{T} item   The item.
     * @param	{number} index	 Zero-based index of the item array.
     */
    (item: T, index: number): void;
}
interface MatchingDelegate<T> {
    /**
     * Called for each item in an array
     *
     * @param	{T} item   The item.
     * @param	{number} index	 Zero-based index of the item array.
     *
     * @return	true if the item matches, false if it does not.
     */
    (item: T, index: number): boolean;
}