import { AppComponent as IAppComponent } from '../../interfaces/appcomponent';
import { AppModule } from './AppModule';
import { Descriptor } from '../../interfaces/appmodule';
import { EntityDefinition } from '@definitions/EntityDefinition';
import { IODataResponse } from '../../interfaces/general';
import { sendMetadataGetRequest, metadataRetrieveMultiple } from '@definitions/MetadataApi';

export class AppComponents {
    private static _appComponents: IAppComponent[];
    public static async loadAsync(): Promise<void> {
        if (!this._appComponents) {
            const appModule = AppModule.get();

            const descriptor: Descriptor = JSON.parse(appModule.descriptor);

            const appComponents: IAppComponent[] = [];

            // For unmanaged app modules, we can use the descriptor straight away since it is returned in the correct order, for managed, we need to resolve order ourselves
            if (!appModule.ismanaged) {
                console.info("No managed configuration found for app module, using values from descriptor.");

                const entityMetadataIds: string[] = [];

                for (const appComponent of descriptor.appInfo.Components) {
                    if (appComponent.Type === 1) {
                        entityMetadataIds.push(appComponent.Id);
                    }
                    appComponents.push({
                        appmodulecomponentid: null,
                        componenttype: appComponent.Type,
                        objectid: appComponent.Id
                    });
                }

                await EntityDefinition.preloadAsync(entityMetadataIds);
            }
            else {
                // SiteMap is always first
                const sitemap = descriptor.appInfo.Components.find(x => x.Type === 62);
                appComponents.unshift({
                    appmodulecomponentid: null,
                    componenttype: sitemap.Type,
                    objectid: sitemap.Id
                });

                const entityComponents = descriptor.appInfo.Components.filter(x => x.Type === 1);
                const formComponents = descriptor.appInfo.Components.filter(x => x.Type === 60);
                const viewComponents = descriptor.appInfo.Components.filter(x => x.Type === 26);

                if (entityComponents.length > 0) {
                    let views: Xrm.RetrieveMultipleResult = { entities: [], nextLink: null };
                    let forms: Xrm.RetrieveMultipleResult = { entities: [], nextLink: null };

                    let formReq: Promise<Xrm.RetrieveMultipleResult> = null;
                    let viewReq: Promise<Xrm.RetrieveMultipleResult> = null;

                    if (formComponents.length > 0) {
                        formReq = metadataRetrieveMultiple(`v9.1/systemforms?$select=formid,objecttypecode&$filter=${formComponents.map(x => `formid eq ${x.Id}`).join(" or ")}`);
                    }
                    if (viewComponents.length > 0) {
                        viewReq = metadataRetrieveMultiple(`v9.1/savedqueries?$select=savedqueryid,returnedtypecode&$filter=${viewComponents.map(x => `savedqueryid eq ${x.Id}`).join(" or ")}`);
                    }

                    const entities = await EntityDefinition.preloadAsync(entityComponents.map(x => x.Id));

                    if (formReq !== null) forms = await formReq;
                    if (viewReq !== null) views = await viewReq;

                    for (const entity of entities) {
                        const definition = await EntityDefinition.getAsync(entity);
                        appComponents.push({
                            componenttype: 1,
                            objectid: definition.MetadataId,
                            appmodulecomponentid: null
                        });
                        for (const form of forms.entities.filter(x => x.objecttypecode === definition.LogicalName)) {
                            appComponents.push({
                                componenttype: 60,
                                objectid: form["formid"],
                                appmodulecomponentid: null
                            });
                        }
                        for (const view of views.entities.filter(x => x.returnedtypecode === definition.LogicalName)) {
                            appComponents.push({
                                componenttype: 26,
                                objectid: view["savedqueryid"],
                                appmodulecomponentid: null
                            });
                        }
                    }
                }

            }

            this._appComponents = appComponents;
        }
    }
    public static get(): IAppComponent[] {
        if (!this._appComponents) {
            throw new Error("AppComponents is unitialized!");
        }
        return this._appComponents;
    }
    public static async filterAppComponents(entityName: string, componentType: number): Promise<string[]> {
        const appComponents = this.get();
        const entityDefinition = await EntityDefinition.getAsync(entityName);
        const filteredAppComponents = appComponents.filter(x => x.componenttype === 1 || x.componenttype === componentType);
        const componentIds: string[] = [];
        const entityIndex = filteredAppComponents.findIndex(x => x.componenttype === 1 && x.objectid === entityDefinition.MetadataId);
        if (entityIndex !== -1) {
            for (let i = entityIndex + 1; i < filteredAppComponents.length; i++) {
                if (filteredAppComponents[i].componenttype === componentType) {
                    componentIds.push(filteredAppComponents[i].objectid);
                }
                else if (filteredAppComponents[i].componenttype === 1) {
                    break;
                }
            }
        }
        return componentIds;
    }
};