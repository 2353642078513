import { IPromiseCache, cachedWrapper } from "@utilities/MemoryCachingHelpers";
import { getWebResourceUrl } from "@definitions/MetadataApi";

export const DefaultIcon = require("./../../../resources/icons/default.svg");

export interface IIcon {
    type: 'url' | 'fluent',
    value: string;
};

export class IconLoader {
    private static _icon: IPromiseCache<IIcon> = {};
    static async getAsync(str?: string, defaultOnError?: boolean): Promise<IIcon> {
        if (!str && !defaultOnError) {
            return null;
        }
        else if (!str) {
            return {
                type: 'url',
                value: DefaultIcon
            };
        }
        const labelMatches = [...str.matchAll(/#(.*)#(.*)/g)];
        const labelMatchResult = labelMatches.length === 1 ? labelMatches[0] : [];
        if (labelMatchResult.length > 2) {
            return {
                type: 'fluent',
                value: labelMatchResult[1]
            };
        }
        const name = this._getIconName(str);
        if (!name) {
            return {
                type: 'url',
                value: DefaultIcon
            };
        }

        return cachedWrapper(name, () => new Promise(async (resolve) => {
            resolve({ type: 'url', value: getWebResourceUrl(`webresources/${name}`) });
        }), this._icon);
    };

    private static _getIconName(str: string): string {
        if (str.startsWith("/WebResource")) {
            const parts = str.split('/');
            parts.shift();
            parts.shift();
            return parts.join("/");
        }
        else if (str.startsWith("$webresource")) {
            return str.split(":")[1];
        }
        return null;
    }
}