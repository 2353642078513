export enum RibbonDefinitionButtonType {
    Button = 'Button',
    SplitButton = 'SplitButton',
    FlyoutAnchor = 'FlyoutAnchor',
    PCF = 'PCF'
}
export interface IRibbonDefinitionGroup {
    buttons: IRibbonDefinitionButton[];
}

export interface IRibbonDefinitionButtonMenuSection {
    id: string;
    label: string;
    buttons: IRibbonDefinitionButton[];
}
export interface IRibbonDefinitionButtonFunction {
    command?: string;
    action?: (arg1?: any) => boolean;
    parameters: IRibbonDefinitionButtonFunctionParameter[];
}
export interface IRibbonDefinitionButtonFunctionParameter {
    type: "CrmParameter" | "StringParameter";
    value: string;
}
export interface IRibbonDefinitionButtonRule {
    id: string;
    type: "custom" | "value" | "selectionCount";
    function: IRibbonDefinitionButtonFunction;
    default: boolean;
    invertResult: boolean;
}

export interface IRibbonDefinitionButton {
    id: string;
    command: string;
    label: string;
    type: RibbonDefinitionButtonType,
    isInline: boolean;
    function?: IRibbonDefinitionButtonFunction
    hideCustomAction?: boolean;
    rules: IRibbonDefinitionButtonRule[];
    icon?: {
        type: 'url' | 'fluent',
        value: string;
    }
    menuSections?: IRibbonDefinitionButtonMenuSection[]
}
export interface IRibbonDefinition {
    groups: IRibbonDefinitionGroup[];
}