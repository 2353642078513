import * as React from 'react';
import { ComboBox } from '@talxis/react-components/dist/components/ComboBox';
import { IMultiSelectOptionSetProps } from '../interfaces/index';
import { IComboBox, IComboBoxOption, IContextualMenuItem } from '@fluentui/react';
import { useEffect } from 'react';

export const MultiSelectOptionSetPicker: React.FunctionComponent<IMultiSelectOptionSetProps> = (props) => {
    const [selectedKeys, setSelectedKeys] = React.useState<number[]>(props.selectedKey as number[] ?? []);
    const [options, setOptions] = React.useState<IComboBoxOption[]>([]);

    useEffect(() => {
        setOptions(props.options ?? []);
    }, [props.options]);

    const onChange = React.useCallback((event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => {
        let _value: number[] = [];
        if (option) {
            _value = option.selected ? [...selectedKeys, option.key as number] : selectedKeys.filter(k => k !== option.key);
            setSelectedKeys(_value);
            props.onOptionsChange(_value);
        }
    }, [selectedKeys]);

    const onClick = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem): void => {
        setSelectedKeys([]);
        props.onOptionsChange([]);
    };

    return (
        <ComboBox
            {...props}
            options={options}
            selectedKey={selectedKeys}
            onChange={onChange}
            deleteButtonProps={selectedKeys.length !== 0 ? { onClick: onClick, iconProps: { iconName: 'Cancel' }, showOnlyOnHover: true, key: 'Delete' } : null}
        />
    );
};
export default MultiSelectOptionSetPicker;