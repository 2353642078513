import React, { useEffect, useState, createContext, useRef } from 'react';
import { IComponent, IParameterDefinitionMap } from './interfaces/NestedPcf';
import { Form } from '@controls/native/Form/interfaces/form';
import { FormControlType } from '@controls/native/Form/interfaces/enums';
import { ControlLoader } from '@loaders/ControlLoader';
import { Control } from '../components/controls';
import { IFormContext } from '@controls/native/Form/interfaces/IFormContext';
import { IControlProps } from '@controls/interfaces';

interface INestedPcfWrapperProps {
    component: IComponent
}

const mapParametersToBindings = (parameters: IParameterDefinitionMap): Form.ControlBindings => {
    const bindings: Form.ControlBindings = {};

    for (const [key, value] of Object.entries(parameters)) {
        if (value.Static) {
            bindings[key] = {
                isStatic: value.Static,
                value: value.Value,
                type: value.Type,
                isRequired: false
            };
        }
        else {
            // This maps the value to either unknown type or entity bound field based on provided LogicalName in Attributes
            let mappedValue = value.Attributes?.LogicalName ?? key;
            bindings[key] = {
                isStatic: value.Static,
                value: mappedValue,
                type: value.Type,
                isRequired: false
            };
        }
    }

    return bindings;
};

const mapDynamicValuesFromParameters = (parameters: IParameterDefinitionMap): ComponentFramework.WebApi.Entity => {
    const entity: ComponentFramework.WebApi.Entity = {};

    for (const [key, value] of Object.entries(parameters)) {
        if (!value.Static) {
            entity[value.Attributes?.LogicalName ?? key] = value.Value;
        }
    }

    return entity;
};

const stripPcfNamePrefix = (name: string): string => {
    const delim = "_";
    return name.slice(name.indexOf(delim) + delim.length);
};

export const FormContext = createContext<IFormContext | null>(null);

export const NestedPcfWrapper: React.FC<INestedPcfWrapperProps> = (props) => {
    const [control, setControl] = useState<IControlProps>(null);

    const [entityChanges, setEntityChanges] = useState<ComponentFramework.WebApi.Entity>({});
    const entityChangesRef = useRef<ComponentFramework.WebApi.Entity>({});

    useEffect(() => {
        const runAsync = async () => {
            const pcfName = stripPcfNamePrefix(props.component.getType());
            const control: IControlProps = {
                name: pcfName,
                type: FormControlType.Field,
                classId: null,
                id: props.component.getComponentId(),
                disabled: props.component.getProperties()?.controlstates?.isControlDisabled ?? false,
                visible: true,
                isRequired: false,
                isUnbound: true,
                datafieldname: null,
                bindings: mapParametersToBindings(props.component.getProperties()?.parameters),
                definition: await ControlLoader.getAsync(pcfName),
                childeventlisteners: props.component.getProperties()?.childeventlisteners
            };

            entityChangesRef.current = mapDynamicValuesFromParameters(props.component.getProperties()?.parameters);
            setEntityChanges(entityChangesRef.current);
            setControl(control);
        };

        runAsync();
    }, []);

    useEffect(() => {
        const parameters = props.component.getProperties()?.parameters;
        if (!parameters) return;
        for (const [key, value] of Object.entries(entityChanges)) {
            const parameter = parameters[key] || Object.values(parameters).find(x => x.Attributes?.LogicalName === key);
            if (parameter && parameter.Callback) {
                // TODO: We need to transform value to correct data type, since it all comes back as string
                parameter.Callback(value);
            }
        }
    }, [entityChanges]);

    const entityName = Object.values(props.component.getProperties()?.parameters ?? {}).find(x => x.Primary === true)?.Attributes?.EntityLogicalName;
    return (
        <FormContext.Provider value={{
            entityId: null,
            entityName: entityName,
            entity: {},
            entityChanges: entityChangesRef.current,
            xrmExecutionContext: null,
            setEntityChanges: (entityChanges: ComponentFramework.WebApi.Entity) => {
                let changed = false;
                for (const [key, value] of Object.entries(entityChanges)) {
                    if (value != entityChangesRef.current[key]) {
                        changed = true;
                        break;
                    }
                }
                if (changed) {
                    entityChangesRef.current = { ...entityChangesRef.current, ...entityChanges };
                    setEntityChanges(entityChangesRef.current);
                }
            },
            onRecordSelect: null,
            onRecordsSelect: null,
            registerChildControl: null,
            isMobile: null,
            validate: false,
            attributeConfiguration: null,
        }}>
            <div id={props.component.getComponentId()}>
                {control ?
                    <Control {...control} />
                    :
                    <>Loading nested PCF: {props.component.getComponentId()}</>
                }

            </div>
        </FormContext.Provider>
    );
};