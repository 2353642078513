import { mergeStyles, getTheme } from "@fluentui/react/lib/Styling";

export const getRootStyles = (): string => {
    const theme = getTheme();
    const rootStyles = mergeStyles({
        selectors: {
            '.ms-Spinner-circle': {
                borderColor: `${theme.palette.themePrimary} ${theme.palette.neutralLight} ${theme.palette.neutralLight}`
            },
        }
    });
    return rootStyles;
};