import { CommandBarButton, IButtonProps, ICommandBarItemProps, ICommandBarProps, IconButton, mergeStyles, MessageBar, Panel, SearchBox, ThemeProvider, useTheme } from '@fluentui/react';
import { ContextualMenuItemType } from '@fluentui/react/lib/components/ContextualMenu/ContextualMenu.types';
import { CommandBar, TextField } from '@talxis/react-components';
import React, { useCallback, useRef } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { RibbonLocationFilters } from '@definitions/RibbonDefinition';
import { RibbonDefinition } from '@definitions/RibbonDefinition';
import { ViewDefinition } from '@definitions/ViewDefinition';
import { ControlLoader, ControlRegistration } from '@loaders/ControlLoader';
import { FormControlType } from '@controls/native/Form/interfaces/enums';
import { ViewLoading } from '../../../components/loadings/ViewLoading';
import { AppContext, history } from '@providers/AppProvider';
import styles from './ViewPage.module.css';
import { DatasetControl, IDataSetControlRef } from '@controls/DatasetControl';
import { Form } from '@controls/native/Form/interfaces/form';
import { FormContext } from '@controls/native/Form/Form';
import { IExtendedXrmGridControl } from '@controls/DatasetControl/interfaces/IExtendedXrmGridControl';
import { DomParser } from '@app/Constants';
import { ConditionOperator } from '@controls/DatasetControl/FetchXmlUtils';
import { Ribbon } from '@models/Ribbon/Ribbon';
import { EditColumnsPanel } from './EditColumns/EditColumnsPanel';
import { BackButton } from '../../../components/navigation/buttons/BackButton';
import { ThemeDefinition } from '@definitions/ThemeDefinition';

interface IViewPageProps {
    viewId?: string;
    bindings?: Form.ControlBindings;
}

const ViewPage: React.FC<IViewPageProps> = (props) => {
    const dataSetControlRef = useRef<IDataSetControlRef>(null);
    const quickFindFiltersRef = useRef<ComponentFramework.PropertyHelper.DataSetApi.ConditionExpression[]>(null);
    const searchPlaceholder = props.bindings?.SearchPlaceholder?.value ?? window.TALXIS.Portal.Translations.getLocalizedString('@pages/Control/View/index.tsx');
    const [controlDefinition, setControlDefinition] = useState<ControlRegistration>(null);
    const [viewName, setViewName] = useState<string>(null);
    const [editColumnPanelOpen, setEditColumnPanelOpen] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const [entityName, setEntityName] = useState<string>(null);
    const [viewId, setViewId] = useState<string>(null);
    const [viewPickerItems, setViewPickerItems] = useState<ICommandBarItemProps[]>([]);
    const [ribbonCommandBarProps, setRibbonCommandBarProps] = useState<ICommandBarProps>(null);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [error, setError] = useState<Error>(null);
    const appContext = useContext(AppContext);
    const formContext = useContext(FormContext);
    const theme = useTheme();
    const [isViewPage, setIsViewPage] = useState<boolean>(props.bindings?.["IsViewPage"]?.value === "true" ? true : false);

    const gridContextRef = useRef<IExtendedXrmGridControl>({
        refresh: () => { throw new Error("refresh has not been initalized yet!"); },
        getFetchXml: () => { throw new Error("getFetchXml has not been initalized yet!"); },
        getViewColumns: () => { throw new Error("getViewColumns has not been initalized yet!"); },
        getViewSelector: () => { throw new Error("getViewSelector has not been initalized yet!"); },
        getGridType: () => { throw new Error("getGridType has not been initalized yet!"); },
        getRelationship: () => { throw new Error("getRelationship has not been initalized yet!"); },
    });

    const ribbonRef = useRef<Ribbon>(null);

    const getViewPageClassName = () => {
        let className = 'TALXIS__portal__view-page';
        return `${className} ${styles.root} ${mergeStyles({
            boxShadow: theme.semanticColors.cardShadow,
            border: isViewPage && `1px solid ${theme.semanticColors.bodyDivider}`,
            backgroundColor: theme.semanticColors.bodyBackground,
            borderTop: !props.bindings && `1px solid ${theme.semanticColors.bodyDivider}`,
            padding: isViewPage && 15,
            '[class*="ViewPage_ribbonWrapper"]': {
                borderBottom: isViewPage && `1px solid ${theme.semanticColors.bodyDivider}`,
            }
        })}`;
    };

    const getSelectedViewStyles = () => {
        return mergeStyles({
            backgroundColor: theme.semanticColors.menuItemBackgroundPressed,
            '.ms-ContextualMenu-itemText': {
                fontWeight: 600
            }
        });
    };

    const getViewPickerItems = async (): Promise<ICommandBarItemProps[]> => {
        let viewIds: string[] = null;
        if (props.bindings?.ViewIds && props.bindings?.ViewIds?.value !== "") {
            viewIds = props.bindings?.ViewIds?.value.split(',');
        }
        const allViewNamesAndIds = await ViewDefinition.getViewNamesAndIds(entityName, viewIds);
        const items: ICommandBarItemProps[] = allViewNamesAndIds.sort((a, b) => a.name.localeCompare(b.name)).map(x => {
            return {
                key: x.savedqueryid,
                text: x.name,
                className: viewId.toLowerCase().includes(x.savedqueryid.toLocaleLowerCase()) && getSelectedViewStyles(),
                onClick: () => {
                    if (!isViewPage) {
                        setViewId(x.savedqueryid);
                        return;
                    }
                    const data = {
                        entityName: entityName,
                        viewId: x.savedqueryid,
                    };
                    const url = `${window.location.pathname}?data=${JSON.stringify(data)}`;
                    history.push(url);
                }
            };
        });
        return items;
    };
    const createQuickFindFilters = async () => {
        const quickFindViewDefinition = await ViewDefinition.getQuickFindViewAsync(entityName);
        const parsedDefinition = DomParser.parseFromString(quickFindViewDefinition.fetchxml, "text/xml");
        const quickFindFilters = [...parsedDefinition.getElementsByTagName("condition")].filter(x => x.getAttribute("value") === "{0}");
        const conditions: ComponentFramework.PropertyHelper.DataSetApi.ConditionExpression[] = [];

        for (const filter of quickFindFilters) {
            let operator = ConditionOperator.Like;
            conditions.push({
                attributeName: filter.getAttribute("attribute"),
                conditionOperator: operator,
                value: null,
                entityAliasName: filter.getAttribute("entityname")
            });
        }
        // Distinct aliases https://codeburst.io/javascript-array-distinct-5edc93501dc4
        const aliases = [...new Set(conditions.map(x => x.entityAliasName).filter(x => x !== null && x !== undefined))];
        const linkedEntities = [...parsedDefinition.getElementsByTagName("link-entity")];
        for (const alias of aliases) {
            const link = linkedEntities.find(x => x.getAttribute("alias") === alias);
            dataSetControlRef.current.addLinkedEntity({
                alias: alias,
                from: link.getAttribute("from"),
                linkType: link.getAttribute("link-type"),
                name: link.getAttribute("name"),
                to: link.getAttribute("to")
            });
        }
        quickFindFiltersRef.current = conditions;
    };
    const onSearch = (value: string) => {
        if (value !== "") {
            const conditions: ComponentFramework.PropertyHelper.DataSetApi.ConditionExpression[] = [];
            for (const filter of quickFindFiltersRef.current) {
                // Copy the object so we don't touch the reference
                const condition = { ...filter };
                condition.value = value;
                conditions.push(condition);
            }
            dataSetControlRef.current.setFilter({
                conditions: conditions,
                filterOperator: 1
            });
        }
        else {
            dataSetControlRef.current.setFilter(null);
        }
    };
    const updateRibbon = async (selectedIds: string[]) => {
        const viewDefinition = await ViewDefinition.getAsync(viewId, entityName);
        const ribbon = await RibbonDefinition.getRibbon(viewDefinition.returnedtypecode, isViewPage ? RibbonLocationFilters.HomepageGrid : RibbonLocationFilters.SubGrid);
        const ribbonCommandBarProps = await ribbon.getRibbonCommandBarProps(formContext?.xrmExecutionContext?.getFormContext(), selectedIds.map(x => {
            return {
                getFormattedValue: null,
                getValue: null,
                getRecordId: () => {
                    return x;
                },
                getNamedReference: null
            };
        }), gridContextRef.current, false);
        setRibbonCommandBarProps(ribbonCommandBarProps);
    };

    const init = async () => {
        try {
            setControlDefinition(null);
            setError(null);
            const promises: Promise<ControlRegistration | Ribbon | ICommandBarItemProps[]>[] = [];
            promises.push(ControlLoader.getAsync("TALXIS.PCF.Portal.View"));
            promises.push(RibbonDefinition.getRibbon(entityName, isViewPage ? RibbonLocationFilters.HomepageGrid : RibbonLocationFilters.SubGrid));
            if (viewPickerEnabled()) {
                promises.push(getViewPickerItems());
            }
            const [_controlDefinition, _ribbon, _viewPickerItems] = await Promise.all(promises);
            ribbonRef.current = _ribbon as Ribbon;
            if (_viewPickerItems) {
                setViewPickerItems(_viewPickerItems as ICommandBarItemProps[]);
            }
            setControlDefinition(_controlDefinition as ControlRegistration);
            setInitialized(true);
        } catch (error) {
            setError(error as Error);
            throw error;
        }
    };

    const getBindings = (): Form.ControlBindings => {
        const _bindings = { ...props.bindings };
        // Needed for the view to update if view switcher changes the view
        _bindings.ViewId.value = viewId;
        return _bindings;
    };

    const ribbonEnabled = () => {
        if (isViewPage) {
            return true;
        }
        //do not show on new main forms
        if (!formContext?.entityId && !formContext?.formUniqueName) {
            return false;
        }
        //otherwise enabled by default
        return true;
    };

    const viewPickerEnabled = () => {
        //enable view picker on all view pages
        if (isViewPage) {
            return true;
        }
        //only show view picker if specified in parameter and not present on new form
        if (props.bindings?.EnableViewPicker?.value === 'true' && formContext?.entityId) {
            return true;
        }
        //otherwise disabled by default
        return false;
    };

    const quickFindEnabled = () => {
        if (props.bindings?.EnableQuickFind?.value === 'true') {
            return true;
        }
        return false;
    };

    useEffect(() => {
        let mounted = true;
        setEntityName(props.bindings["TargetEntityType"].value);
        setIsViewPage(props.bindings?.["IsViewPage"]?.value === "true" ? true : false);
        const _viewId = props.viewId ?? props.bindings?.ViewId?.value;
        if (!_viewId) {
            const getInitialViewAsync = async () => {
                const availableViews = await ViewDefinition.getViewNamesAndIds(props.bindings["TargetEntityType"].value, props.bindings?.ViewIds?.value.split(','));

                if (mounted) {
                    if (availableViews.length > 0) {
                        setViewId(availableViews[0].savedqueryid);
                    }
                    else {
                        throw new Error(`Not view found for entity ${props.bindings["TargetEntityType"].value}!`);
                    }
                }
            };
            getInitialViewAsync();
        }
        else {
            setViewId(_viewId);
        }

        return () => mounted = false;
    }, [props.bindings]);

    useEffect(() => {
        if (!viewId || !entityName || (appContext && !appContext?.isNavbarLoaded)) {
            return;
        }
        init();
    }, [viewId, entityName, appContext?.isNavbarLoaded]);

    useEffect(() => {
        const viewName = viewPickerItems.find(x => viewId.toLocaleLowerCase().includes(x.key.toLocaleLowerCase()))?.text;
        setViewName(viewName);
    }, [viewPickerItems]);

    const getGridContextCallback = useCallback(async (gridContext: IExtendedXrmGridControl) => {
        gridContextRef.current.getEntityName = gridContext.getEntityName;
        gridContextRef.current.getFetchXml = gridContext.getFetchXml;
        gridContextRef.current.refresh = gridContext.refresh;
        gridContextRef.current.getViewColumns = gridContext.getViewColumns;
        gridContextRef.current.getViewSelector = gridContext.getViewSelector;
        gridContextRef.current.getGridType = gridContext.getGridType;
        gridContextRef.current.getRelationship = gridContext.getRelationship;
        gridContextRef.current.getParentForm = gridContext.getParentForm;
        if (ribbonEnabled()) {
            const ribbonCommandBarProps = await ribbonRef.current.getRibbonCommandBarProps(formContext?.xrmExecutionContext?.getFormContext(), [], gridContextRef.current, false);
            setRibbonCommandBarProps(ribbonCommandBarProps);
        }
    }, []);

    return (
        <section className={getViewPageClassName()}>
            {initialized &&
                <>
                    <div className={styles.spacingWrapper}>
                        <ThemeProvider theme={isViewPage ? ThemeDefinition.getTheme().getRibbonTheme() : undefined} className={styles.ribbonWrapper}>
                            {isViewPage &&
                                <BackButton />
                            }
                            {ribbonEnabled() && ribbonCommandBarProps?.items?.length > 0 &&
                                <CommandBar className={styles.ribbon} items={ribbonCommandBarProps.items} />
                            }
                        </ThemeProvider>
                        <div className={styles.viewPageHeader}>
                            {viewPickerEnabled() && viewPickerItems.length > 0 &&
                                <CommandBar className={styles.viewPicker} items={[]} farItems={[
                                    {
                                        key: 'viewSwitcher',
                                        text: viewName,
                                        subMenuProps: {
                                            items: [
                                                {
                                                    key: 'header',
                                                    itemType: ContextualMenuItemType.Header,
                                                    text: window.TALXIS.Portal.Translations.getLocalizedString("@pages/control/view/systemViews")
                                                },
                                                ...viewPickerItems
                                            ]
                                        }
                                    }
                                ]} />
                            }
                            {isViewPage &&
                                <CommandBarButton className={styles.editColumns}
                                    text={window.TALXIS.Portal.Translations.getLocalizedString("@pages/Control/View/EditColumns")} iconProps={{
                                        iconName: 'ColumnOptions'
                                    }}
                                    onClick={() => setEditColumnPanelOpen(true)}
                                />
                            }
                            {quickFindEnabled() &&
                                <TextField
                                    value={searchValue}
                                    placeholder={searchPlaceholder}

                                    deleteButtonProps={{
                                        key: 'delete',
                                        iconProps: {
                                            styles: {
                                                root: {
                                                    fontSize: 14
                                                }
                                            },
                                            iconName: 'ChromeClose'
                                        },
                                        onClick: () => {
                                            setSearchValue("");
                                            dataSetControlRef.current.setFilter(null);
                                        }
                                    }}
                                    suffixItems={[
                                        {
                                            key: 'search',
                                            iconProps: {
                                                iconName: 'Search'
                                            },
                                            onClick: () => onSearch(searchValue)
                                        }
                                    ]}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            onSearch(searchValue);
                                        }
                                    }}
                                    onChange={(e, newValue) => setSearchValue(newValue)}
                                    onSubmit={() => alert('hey')} />
                            }
                        </div>
                    </div>
                    {controlDefinition &&
                        <DatasetControl name="TALXIS.PCF.Portal.View"
                            id=""
                            ref={dataSetControlRef}
                            classId=""
                            datafieldname={null}
                            disabled={false}
                            type={FormControlType.DataSet}
                            visible={true}
                            isUnbound={true}
                            isRequired={false}
                            definition={controlDefinition}
                            {...props}
                            bindings={getBindings()}
                            onSelectedItems={updateRibbon}
                            childeventlisteners={[{
                                eventname: "__retrieveFormContext",
                                eventhandler: (action: (formContext: Xrm.FormContext) => void) => {
                                    action(formContext?.xrmExecutionContext?.getFormContext());
                                }
                            },
                            {
                                eventname: "__updateColumnOrder",
                                eventhandler: (columOrder: string[]) => {
                                    dataSetControlRef.current.updateColumnOrder(columOrder);
                                }
                            }
                            ]}
                            onDataSetControlInitialized={() => createQuickFindFilters()}
                            getGridContextCallback={getGridContextCallback}
                        />
                    }
                </>
            }
            {editColumnPanelOpen &&
                <EditColumnsPanel
                    onDismiss={() => setEditColumnPanelOpen(false)}
                    gridContext={gridContextRef.current}
                    datasetControl={dataSetControlRef.current}
                    isOpen={editColumnPanelOpen} />
            }
            {!initialized && !error &&
                <ViewLoading />
            }
            {error &&
                <MessageBar>
                    An error occurred while loading the view.
                </MessageBar>
            }
        </section>
    );
};
export default ViewPage;