import React from 'react';
import { Spinner, ISpinnerProps } from '@fluentui/react/lib/Spinner';
import { defaultProps } from './Constants';
import { getRootStyles } from './Styles';

const Loading: React.FC<ISpinnerProps> = (props) => {
    return (
        <Spinner
            {...props}
            className={`${getRootStyles()} ${props.className}`}
        />
    );
};
export default Loading;
Loading.defaultProps = defaultProps;