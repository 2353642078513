import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { FRONTEND_VERSION } from '@app/Constants';

let reactPlugin: ReactPlugin = null;
let appInsights: ApplicationInsights = null;

let AI_DEBUG = false;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {

    /**
     * Initialize the Application Insights class
     * @param {string} connectionString - Application Insights Connection string
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = (connectionString: string, browserHistory: History, appId: string) => {
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (!connectionString) {
            throw new Error('Connection string not provided in ./src/telemetry-provider.jsx');
        }

        let debugPlugin = null;
        let debugPluginInstance = null;
        if (AI_DEBUG) {
            debugPlugin = require("@microsoft/applicationinsights-debugplugin-js").DebugPlugin;
            debugPluginInstance = new debugPlugin();
        }

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
                appId: appId,
                connectionString: connectionString,
                maxBatchInterval: 10000, //Batch telemetry every 10 seconds
                disableFetchTracking: false,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
                correlationHeaderExcludedDomains: [
                    '*.smartsuppchat.com',
                    '*.smartsuppcdn.com',
                    // Exclusion for CORS for https://www.cloudflare.com/cdn-cgi/trace
                    'www.cloudflare.com',
                    // Exclusion for PCFs
                    'maps.googleapis.com'
                ],
                extensions: [
                    ...(AI_DEBUG === true ? [debugPluginInstance] : []),
                    reactPlugin
                ],
                extensionConfig: {
                    ...(AI_DEBUG === true && {
                        [debugPlugin.identifier]: {
                            trackers: [
                                'trackEvent',
                                'trackPageView',
                                'trackPageViewPerformance',
                                'trackException',
                                'trackTrace',
                                'trackMetric',
                                'trackDependencyData',
                                'throwInternal',        // called when a message is logged internally
                                'logInternalMessage',   // called when a message is logged internally
                                'triggerSend',          // called when data is queued to be sent to the server
                                '_sender',              // called when data is sent to the server
                            ]
                        }
                    }),
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });

        appInsights.loadAppInsights();

        appInsights.context.application.ver = FRONTEND_VERSION;

        appInsights.addTelemetryInitializer((item) => {
            // Filter out ResizeObserver loop errors as per - https://github.com/microsoft/ApplicationInsights-JS/issues/1300
            if (item.name.includes("Exception") || item.name.includes("exception")) {
                if (item.data.message.includes("ResizeObserver loop limit exceeded") === true) {
                    return false;
                }
            }
        });
    };

    return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;