import React from 'react';
import { TextField, DialogFooter, Icon, ChoiceGroup, IChoiceGroupOption, mergeStyles, PrimaryButton } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';
import Dialog from '../Dialog';
import { defaultProps } from '../Dialog/Constants';
import { IDialogProps } from '../Dialog/interfaces';
import { Dropdown } from '@talxis/react-components';
import { RingProvider } from '@app/classes/RingProvider';

const isLocalDev = (): boolean => {
    return window.location.port === "3000" && window.location.hostname === "localhost";
};

const RING_DEFAULT = "fast";
const RING_LOCAL = "local";

const EnvironmentSelectionDialog: React.FC<IDialogProps> = (props) => {
    const [envHostname, setEnvHostname] = React.useState<string>('');
    const [metadataSource, setMetadataSource] = React.useState<string>('hostedFast');
    const [backendEndpoint, setBackendEndpoint] = React.useState<string>('hostedFast');
    const [authenticationSource, setAuthenticationSource] = React.useState<string>('b2c');
    const [localHostMetadataPort, setLocalHostMetadataPort] = React.useState<Number>(7071);
    const [localHostBackendPort, setLocalHostBackendPort] = React.useState<Number>(5001);
    const [customMetadataHostname, setCustomMetadataHostname] = React.useState<string>('http://localhost:7071');
    const [customBackendHostname, setCustomBackendHostname] = React.useState<string>('https://localhost:5001/api/data');
    const [envHostnameErrorMessage, setEnvHostnameErrorMessage] = React.useState<string>('');
    const [ring, setRing] = React.useState<string>(RingProvider.get() ?? (isLocalDev() ? RING_LOCAL : RING_DEFAULT));
    const onChange = (e: any, value: string) => {
        setEnvHostname(value);
    };
    const isRunningOnLocalhost = window.location.hostname === 'localhost';

    const choiceGroupFieldStyles = mergeStyles({ display: 'flex', alignItems: 'center' });
    const choiceGroupCustomInputStyles = { fieldGroup: { height: "20px", }, };

    const handleSubmit = async () => {
        if (envHostname.length === 0) {
            setEnvHostnameErrorMessage(`Invalid hostname`);
            return;
        }

        let metadataUrl: string;
        switch (metadataSource) {
            case 'hostedFast':
                metadataUrl = 'https://fast.metadata.portals.talxis.com';
                break;
            case 'localhost':
                metadataUrl = 'http://localhost:' + localHostMetadataPort.toString();
                break;
            case 'custom':
                metadataUrl = customMetadataHostname;
                break;
        }

        let backendUrl: string;
        switch (backendEndpoint) {
            case 'metadataServiceProvided':
                backendUrl = null;
                break;
            case 'localhost':
                backendUrl = `https://localhost:${localHostBackendPort.toString()}/api/data`;
                break;
            case 'custom':
                backendUrl = customBackendHostname;
                break;
        }

        let dnsResponse = await (await fetch(`https://cloudflare-dns.com/dns-query?name=${envHostname}.crm4.dynamics.com`, {
            headers: {
                Accept: "application/dns-json"
            }
        })).json();

        if (dnsResponse.Status !== 0) {
            setEnvHostnameErrorMessage(`Invalid hostname ${envHostname}.crm4.dynamics.com`);
            return;
        }

        if ((ring !== RING_DEFAULT || (RingProvider.get() !== null && RingProvider.get() !== ring))) {
            // This is to prevent excessive caching when running from local ring (this will change once we route metadata through AFD by default)
            if (ring === "local" && metadataSource === "hostedFast") {
                RingProvider.clear();
            }
            else {
                RingProvider.set(ring);
            }
        }

        localStorage.setItem('METADATA_HOST_OVERRIDE', metadataUrl);
        if (backendUrl) localStorage.setItem('BACKEND_HOST_OVERRIDE', backendUrl);
        localStorage.setItem('HOSTNAME_OVERRIDE', envHostname + `.ngcrm4-dev-defaults${authenticationSource === "b2b" ? "-b2b" : ""}.portals.talxis.com`);
        window.location.reload();
    };

    return (
        <Dialog
            {...props}
            width={400}
            minWidth={400}
            modalProps={{
                ...props.modalProps,
                className: `${props.modalProps.className}`
            }}
            dialogContentProps={{
                ...props.dialogContentProps,
                subText: "You opened the portal " + (isRunningOnLocalhost ? "on localhost. " : "without a registered hostname. ") +
                    "Please configure endpoints you want to use here or using environment variables in the .env.local file.",
                title: <Text variant='xLarge'>
                    <Icon className='TALXIS-Portal-alertDialog-title-icon' iconName='ServerEnviroment' /> {"Development Configuration"}
                </Text>
            }}>

            <Dropdown
                placeholder="Select an option"
                label="Ring"
                selectedKey={ring}
                options={[
                    { key: "fast", text: "Fast" },
                    { key: "slow", text: "Slow" },
                    { key: "release", text: "Release" },
                    { key: "local", text: "Local" },
                ]}
                required={true}
                onChange={(ev, option, index) => {
                    setRing(option.key as string);
                }}
                disabled={isLocalDev()}
            />
            <br />
            <ChoiceGroup defaultSelectedKey="b2c" options={[
                { key: 'b2c', text: 'B2C' },
                { key: 'b2b', text: 'B2B' },
            ]} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                setAuthenticationSource(option.key);
            }} label="Authentication" required={true} />
            <br />
            <ChoiceGroup defaultSelectedKey="hostedFast" options={[
                { key: 'hostedFast', text: 'fast.metadata.portals.talxis.com' },
                {
                    key: 'localhost', text: 'Localhost', onRenderField: (props, render) => {
                        return (
                            <div className={mergeStyles(choiceGroupFieldStyles, { gap: '5px' })}>
                                {render!(props)}
                                {(metadataSource === 'localhost') ? ":" : <div />}
                                {(metadataSource === 'localhost') ? (<TextField
                                    styles={choiceGroupCustomInputStyles}
                                    value={localHostMetadataPort.toString()}
                                    onChange={(e: any, value: string) => {
                                        setLocalHostMetadataPort(parseInt(value));
                                    }}
                                    type="text"
                                />) : <div />}
                            </div>
                        );
                    }
                },
                {
                    key: 'custom', text: '', onRenderField: (props, render) => {
                        return (
                            <div className={choiceGroupFieldStyles}>
                                {render!(props)}
                                {metadataSource !== 'custom' ? "Other" : <div />}
                                {(metadataSource === 'custom') ? (<TextField
                                    styles={choiceGroupCustomInputStyles}
                                    value={customMetadataHostname}
                                    onChange={(e: any, value: string) => {
                                        setCustomMetadataHostname(value);
                                    }}
                                    type="text"
                                />) : <div />}
                            </div>
                        );
                    }
                }
            ]} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                setMetadataSource(option.key);
            }} label="Metadata Source" required={true} />
            <br />
            <ChoiceGroup defaultSelectedKey="metadataServiceProvided" options={[
                { key: 'metadataServiceProvided', text: 'Configuration.json (Metadata Service)' },
                {
                    key: 'localhost', text: 'Localhost', onRenderField: (props, render) => {
                        return (
                            <div className={mergeStyles(choiceGroupFieldStyles, { gap: '5px' })}>
                                {render!(props)}
                                {(backendEndpoint === 'localhost') ? ":" : <div />}
                                {(backendEndpoint === 'localhost') ? (<TextField
                                    styles={choiceGroupCustomInputStyles}
                                    value={localHostBackendPort.toString()}
                                    onChange={(e: any, value: string) => {
                                        setLocalHostBackendPort(parseInt(value));
                                    }}
                                    type="text"
                                />) : <div />}
                            </div>
                        );
                    }
                },
                {
                    key: 'custom', text: '', onRenderField: (props, render) => {
                        return (
                            <div className={choiceGroupFieldStyles}>
                                {render!(props)}
                                {backendEndpoint !== 'custom' ? "Other" : <div />}
                                {(backendEndpoint === 'custom') ? (<TextField
                                    styles={choiceGroupCustomInputStyles}
                                    value={customBackendHostname}
                                    onChange={(e: any, value: string) => {
                                        setCustomBackendHostname(value);
                                    }}
                                    type="text"
                                />) : <div />}
                            </div>
                        );
                    }
                }
            ]} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                setBackendEndpoint(option.key);
            }} label="Backend API Endpoint" required={true} />
            <br />
            <TextField
                label="Environment URL"
                suffix=".crm4.dynamics.com"
                value={envHostname}
                onChange={onChange}
                errorMessage={envHostnameErrorMessage}
                type="text"
                onKeyPress={async (e) => {
                    if (e.key === 'Enter') {
                        await handleSubmit();
                    }
                }
                }
            />

            {props.hidden !== undefined &&
                <DialogFooter>
                    <PrimaryButton
                        text={'Set Browser Configuration'}
                        onClick={handleSubmit} />
                </DialogFooter>
            }
        </Dialog>
    );
};
export default EnvironmentSelectionDialog;
EnvironmentSelectionDialog.defaultProps = defaultProps;