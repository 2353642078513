import { ITheme, mergeStyles } from "@fluentui/react";
import { IDragDropContext, IDragDropEvents } from "@fluentui/react/lib/components/DetailsList/DetailsList.types";

export class ColumnDragAndDropService {
  private _draggedItem: any | undefined;
  private _draggedIndex: number;
  private _theme: ITheme;
  constructor(theme: ITheme) {
    this._draggedIndex = -1;
    this._theme = theme;
  }
  public getDragDropEvents(): IDragDropEvents {
    return {
      canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
        return true;
      },
      canDrag: (item?: any) => {
        return true;
      },
      onDragEnter: (item?: any, event?: DragEvent) => {
        // return string is the css classes that will be added to the entering element.
        return mergeStyles({
          backgroundColor: this._theme.palette.neutralLight,
        });
      },
      onDragLeave: (item?: any, event?: DragEvent) => {
        return;
      },
      onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
        this._draggedItem = item;
        this._draggedIndex = itemIndex!;
      },
      onDragEnd: (item?: any, event?: DragEvent) => {
        this._draggedItem = undefined;
        this._draggedIndex = -1;
      },
    };
  }
}